import { formatPrice } from 'src/utils/helpers/formatPrice';
import {
  ItemContent,
  ItemImage,
  ItemName,
  ItemPrice,
  ItemWrapper,
} from './style';

import { Link } from 'react-router-dom';
import { ROUTES } from 'src/constants/routes';
import { VariablePrice } from 'src/network/services/lubricants/addLubricant';
import { getPriceByQuantity } from 'src/utils/helpers/getPriceByQuantity';

interface CheckoutItemProps {
  details: {
    brandProductId: string;
    name: string;
    specification: string;
    volume: string;
    fixedPrice: number;
    variablePrice: VariablePrice[];
    imageUrl: string;
    orderQuantity: number;
    category: string;
  };
}

const CheckoutItem: React.FC<CheckoutItemProps> = ({
  details,
}) => {
  const {
    brandProductId,
    name,
    specification,
    volume,
    fixedPrice,
    variablePrice,
    orderQuantity,
    category,
    imageUrl,
  } = details;

  return (
    <ItemWrapper>
      <Link to={ROUTES.products + `/${brandProductId}`}>
        <ItemImage>
          <img src={imageUrl} alt="product" />
        </ItemImage>
      </Link>

      <ItemContent>
        <ItemName>
          <h3>
            {name} {specification} {volume}
          </h3>
          <p>{category}</p>
        </ItemName>

        <ItemPrice>
          <p data-price>
            {formatPrice(
              getPriceByQuantity(
                orderQuantity,
                fixedPrice,
                variablePrice
              )
            )}
          </p>
          <p>Qty : {orderQuantity}</p>
        </ItemPrice>
      </ItemContent>
    </ItemWrapper>
  );
};

export default CheckoutItem;
