import MainHeader from 'src/components/molecules/Headers/MainHeader';
import { styled } from 'styled-components';

const AuthLayout: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  return (
    <>
      <MainHeader hideCart hideMenu hideSearch />
      <main>
        <Content>{children}</Content>
      </main>
    </>
  );
};

export default AuthLayout;

const Content = styled.section`
  width: ${p => p.theme.width.container};
  max-width: 593px;
  margin: 0 auto;
  padding-block: 40px;
`;
