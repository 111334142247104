import { toast } from 'react-hot-toast';
import { useMutation } from 'react-query';
import createOrderService from 'src/network/services/orders/createOrder';

const usePayOnline = () => {
  return useMutation(createOrderService, {
    onSuccess: res => {
      toast.success('Redirecting to payment page', {
        id: 'order',
        duration: 5000,
      });
      window.location.assign(res.data.paymentLink);
    },
  });
};

export default usePayOnline;
