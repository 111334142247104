import { forwardRef } from 'react';
import {
  CheckBoxInput,
  CheckBoxText,
  CheckMark,
  CustomCheckBox,
  Label,
} from './style';

interface Props
  extends React.InputHTMLAttributes<HTMLInputElement> {
  id: string;
  error?: boolean;
  children?: React.ReactNode;
}

const CheckBox = forwardRef<HTMLInputElement, Props>(
  ({ id, error = false, children, ...props }, ref) => {
    return (
      <fieldset>
        <Label htmlFor={id}>
          <CustomCheckBox>
            <CheckBoxInput
              id={id}
              type="checkbox"
              ref={ref}
              {...props}
            />
            <CheckMark data-error={error} />
          </CustomCheckBox>

          {children ? (
            <CheckBoxText>{children}</CheckBoxText>
          ) : (
            false
          )}
        </Label>
      </fieldset>
    );
  }
);

CheckBox.displayName = 'CustomCheckBox';

export default CheckBox;
