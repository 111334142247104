import Button from 'src/components/atoms/Button';
import { Divider } from 'src/components/atoms/Divider';
import CheckIcon from 'src/components/atoms/icons/CheckIcon';
import DeliveryIcon from 'src/components/atoms/icons/DeliveryIcon';
import StarOutline from 'src/components/atoms/icons/StarOutline';
import ScrollView from 'src/components/atoms/Scrollview';
import { ROUTES } from 'src/constants/routes';
import { OrderData } from 'src/network/services/orders/getOrders';
import { formatDate } from 'src/utils/helpers/formatData';
import { formatPrice } from 'src/utils/helpers/formatPrice';
import { PaymentType } from '../OrderStatusCard/style';
import {
  Card,
  CardHead,
  ImageWrapper,
  MoreItems,
  OrderDetails,
  OrderImages,
} from './style';

interface Props {
  data: OrderData;
  action: 'track' | 'rate';
}

const OrderDetailsCard: React.FC<Props> = ({
  action,
  data,
}) => {
  return (
    <Card>
      <CardHead>
        <OrderDetails>
          <h3>
            Order #:{' '}
            <span>
              {data.orderId ? data.orderId : data._id}
            </span>
          </h3>

          <div>
            <span>{formatDate(data.createdAt)}</span>
            <span>{formatPrice(data.totalPrice)}</span>
          </div>

          <PaymentType
            $hasPaid={data.paymentMethod === 'payNow'}
          >
            {data.paymentMethod === 'payNow' ? (
              <>
                <CheckIcon />
                Paid
              </>
            ) : (
              <>
                <DeliveryIcon />
                Pay on delivery
              </>
            )}
          </PaymentType>
        </OrderDetails>

        {action === 'track' ? (
          <Button
            variant="secondary"
            asLink
            href={ROUTES.orderDetails + '/' + data._id}
          >
            View details
          </Button>
        ) : (
          <Button
            variant="secondary"
            asLink
            href={ROUTES.orderDetails + '/' + data._id}
          >
            <StarOutline />
            Rate Order
          </Button>
        )}
      </CardHead>

      <Divider />

      <ScrollView horizontal>
        <OrderImages>
          {data.orderItems
            .slice(0, 4)
            .map((orderItem, i) => (
              <ImageWrapper key={i}>
                <img
                  src={
                    orderItem.productImageId.mainImageUrl
                  }
                  alt={orderItem.name}
                />
              </ImageWrapper>
            ))}

          {data.orderItems.length - 4 > 0 && (
            <MoreItems
              to={ROUTES.orderDetails + '/' + data._id}
            >
              +{data.orderItems.length - 4}
            </MoreItems>
          )}
        </OrderImages>
      </ScrollView>
    </Card>
  );
};

export default OrderDetailsCard;
