import { atom } from 'recoil';

interface Props {
  userId: string;
  phoneNumber: string;
  otpAccess: boolean;
  completeSignupAccess: boolean;
}

export const registrationState = atom<Props>({
  key: 'registrationState',
  default: {
    userId: '',
    phoneNumber: '',
    otpAccess: false,
    completeSignupAccess: false,
  },
});
