import { useQuery } from 'react-query';
import getFilterLubricantService, {
  FilterQueryParams,
} from 'src/network/services/lubricants/filterLubricants';

const useFilterLubricants = (
  queryParams: FilterQueryParams
) => {
  return useQuery(['lubricants', queryParams], () =>
    getFilterLubricantService(queryParams)
  );
};

export default useFilterLubricants;
