import { styled } from 'styled-components';

export const OrderTimeline = styled.ul`
  padding-left: 40px;
  position: relative;
`;

export const TimelineItem = styled.li`
  line-height: 24px;
  height: 120px;
  position: relative;

  p {
    font-weight: 600;
  }

  span {
    font-size: 0.875rem;
    color: ${p => p.theme.colors.grey[500]};
  }

  &:last-child {
    height: auto;

    &::after {
      display: none;
    }
  }

  &::before {
    content: '';
    position: absolute;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    left: -45px;
    top: 10px;
    background-color: ${p => p.theme.colors.neutral[300]};
  }

  &::after {
    content: '';
    position: absolute;
    left: -38px;
    top: 12px;
    width: 9px;
    height: 100%;
    background-color: ${p => p.theme.colors.neutral[300]};
  }

  &[data-active='true'] {
    &::before,
    &::after {
      background-color: ${p => p.theme.colors.primary[500]};
    }
  }

  &[data-next='true'] {
    &::before {
      background-color: ${p => p.theme.colors.white};
      border: 2px solid ${p => p.theme.colors.primary[500]};
      z-index: 2;
    }
  }
`;
