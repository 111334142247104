import { useLocation } from 'react-router-dom';
import { Spinner } from 'src/components/atoms/Button/style';
import { Divider } from 'src/components/atoms/Divider';
import Heading from 'src/components/atoms/Heading';
import ChevronLeft from 'src/components/atoms/icons/ChevronLeft';
import AccountLayout from 'src/components/layouts/AccountLayout';
import CheckoutItem from 'src/components/molecules/CheckoutItem';
import { Wrapper as AddressWrapper } from 'src/components/molecules/DeliveryInfo/style';
import OrderStatusCard from 'src/components/molecules/OrderStatusCard';
import { ROUTES } from 'src/constants/routes';
import useGetOrderById from 'src/network/react-query/query/orders/getOrderById';
import { OrderStatus } from 'src/network/services/orders/getOrders';
import { formatDate } from 'src/utils/helpers/formatData';
import { formatPrice } from 'src/utils/helpers/formatPrice';
import useCurrentUser from 'src/utils/hooks/useCurrentUser';
import { Card } from '../Profile/style';
import {
  HeadingGroup,
  OrderCount,
  OrderError,
  OrderItemWrapper,
  StyledLink,
  Wrapper,
} from './style';

const OrderDetailsPage = () => {
  const { user } = useCurrentUser();
  const location = useLocation();
  const pathSegments = location.pathname.split('/');
  const orderId = pathSegments[pathSegments.length - 1];
  // const setRateModal = useSetRecoilState(ratingModalState);

  const { data, isLoading } = useGetOrderById(orderId);

  if (!user) return null;

  if (isLoading) {
    return (
      <AccountLayout>
        <Wrapper>
          <StyledLink to={ROUTES.history}>
            <ChevronLeft />
            Order details
          </StyledLink>

          <OrderError>
            <Spinner data-variant="secondary" />
          </OrderError>
        </Wrapper>
      </AccountLayout>
    );
  }

  if (data) {
    const { orderItems } = data.order;

    return (
      <AccountLayout>
        <Wrapper>
          <StyledLink to={ROUTES.history}>
            <ChevronLeft />
            Order details
          </StyledLink>

          <OrderStatusCard
            details={{
              orderId: data.order.orderId
                ? data.order.orderId
                : data.order._id,
              _id: data.order._id,
              date: formatDate(data.order.createdAt),
              status: data.order.status as OrderStatus,
              totalPrice: data.order.totalPrice,
              hasPaid:
                data.order.paymentMethod === 'payNow',
            }}
          />

          <Card>
            <HeadingGroup>
              <Heading as="h2" level={6}>
                Items in this Order{' '}
                <OrderCount>
                  ({orderItems.length})
                </OrderCount>
              </Heading>

              {/* <Button
                variant="secondary"
                asLink
                href={ROUTES.trackOrder + '/orderID'}
              >
                Track Order
              </Button> */}
            </HeadingGroup>

            <Divider />

            {orderItems.map((product, index) => (
              <OrderItemWrapper key={index}>
                <CheckoutItem
                  details={{
                    brandProductId: product.brandProductId,
                    name: product.name,
                    specification: product.specification,
                    category: product.brandCategoryId.name,
                    fixedPrice: product.fixedPrice,
                    imageUrl:
                      product.productImageId.mainImageUrl,
                    orderQuantity: product.orderQuantity,
                    variablePrice: product.variablePrice,
                    volume: product.volume,
                  }}
                />
                {/* {order.status === 'Order delivered' ? (
                  <Button
                    onClick={() =>
                      setRateModal({
                        isOpen: true,
                        productId:
                          product.brandProductDetailsId,
                      })
                    }
                  >
                    Rate & Review
                  </Button>
                ) : null} */}
              </OrderItemWrapper>
            ))}
          </Card>

          <Card>
            <Heading
              as="h2"
              level={6}
              style={{ margin: 0 }}
            >
              Delivery details
            </Heading>

            <Divider margin="0" />

            <AddressWrapper>
              <li>
                <h4>Name</h4>
                <p>
                  {user.firstName} {user.lastName}
                </p>
              </li>
              <li>
                <h4>Address</h4>
                <p>{data.order.deliveryLocation}</p>
              </li>
              <li>
                <h4>City</h4>
                <p>
                  {data.order.city ? data.order.city : ''}
                </p>
              </li>
              <li>
                <h4>State</h4>
                <p>
                  {data.order.state ? data.order.state : ''}
                </p>
              </li>
              <li>
                <h4>Phone No</h4>
                <p>{user.phoneNumber}</p>
              </li>
              <li>
                <h4>Delivery Fee</h4>
                <p>
                  {formatPrice(data.order.deliveryPrice)}
                </p>
              </li>
              <li>
                <h4>Payment Type</h4>
                <p>
                  {data.order.paymentMethod === 'payNow'
                    ? 'Online Payment'
                    : 'Pay On Delivery'}
                </p>
              </li>
            </AddressWrapper>
          </Card>
        </Wrapper>
      </AccountLayout>
    );
  }

  return (
    <AccountLayout>
      <Wrapper>
        <StyledLink to={ROUTES.history}>
          <ChevronLeft />
          Order details
        </StyledLink>

        <OrderError>
          <h2>Failed to fetch order</h2>
          <p>The order Id provided may not exist.</p>
        </OrderError>
      </Wrapper>
    </AccountLayout>
  );
};

export default OrderDetailsPage;
