import { useQuery } from 'react-query';
import getFavouritesService from 'src/network/services/favourites/getFavourites';

const useGetFavourites = () => {
  const { data, isLoading, refetch, isFetching } = useQuery(
    ['userFavourites'],
    getFavouritesService
  );

  return {
    data,
    isLoading,
    refetchFavourites: refetch,
    isFetching,
  };
};

export default useGetFavourites;
