import { IconProps } from './icon';

const DeleteIcon: React.FC<IconProps> = props => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 12 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.83983 2.83301L8.66667 2.83301C8.66667 1.72844 7.77124 0.833008 6.66667 0.833008H5.33333C4.22876 0.833008 3.33333 1.72844 3.33333 2.83301L2.89552 2.83301L2.66667 2.83301L2.66588 2.83301L0.666667 2.83301C0.298477 2.83301 0 3.13148 0 3.49967C0 3.86786 0.298477 4.16634 0.666667 4.16634L1.33333 4.16634L1.33333 12.1663C1.33333 13.2709 2.22877 14.1663 3.33333 14.1663H8.66667C9.77124 14.1663 10.6667 13.2709 10.6667 12.1663L10.6667 4.16634L11.3333 4.16634C11.7015 4.16634 12 3.86786 12 3.49967C12 3.13148 11.7015 2.83301 11.3333 2.83301L9.33456 2.83301L9.33333 2.83301L8.83983 2.83301ZM9.33333 4.16634H8L7.33333 4.16634L4.66667 4.16634L4 4.16634L2.66667 4.16634L2.66667 5.49967L2.66667 12.1663C2.66667 12.5345 2.96514 12.833 3.33333 12.833H8.66667C9.03486 12.833 9.33333 12.5345 9.33333 12.1663L9.33333 5.49967V4.16634ZM4.66667 2.83301H5.33333H6H6.66667H7.33333C7.33333 2.46482 7.03486 2.16634 6.66667 2.16634L5.33333 2.16634C4.96514 2.16634 4.66667 2.46482 4.66667 2.83301ZM4.66667 5.49967C5.03486 5.49967 5.33333 5.79815 5.33333 6.16634V10.833C5.33333 11.2012 5.03486 11.4997 4.66667 11.4997C4.29848 11.4997 4 11.2012 4 10.833V6.16634C4 5.79815 4.29848 5.49967 4.66667 5.49967ZM8 6.16634C8 5.79815 7.70152 5.49967 7.33333 5.49967C6.96514 5.49967 6.66667 5.79815 6.66667 6.16634V10.833C6.66667 11.2012 6.96514 11.4997 7.33333 11.4997C7.70152 11.4997 8 11.2012 8 10.833V6.16634Z"
      fill="currentColor"
    />
  </svg>
);

export default DeleteIcon;
