import Skeleton from 'react-loading-skeleton';
import { ROUTES } from 'src/constants/routes';
import useGetBrandProducts from 'src/network/react-query/query/lubricants/getBrandProducts';
import useGetBrands from 'src/network/react-query/query/lubricants/getBrands';
import {
  ErrorDisplay,
  SubMenuGroup,
  SubMenuLink,
} from './style';

interface MenuItemProps {
  closeNav: () => void;
}

export const AllProductsMenu: React.FC<MenuItemProps> = ({
  closeNav,
}) => {
  const brandQuery = useGetBrands();
  const brandProductQuery = useGetBrandProducts();

  if (brandQuery.isLoading) {
    return (
      <>
        {Array(3)
          .fill(null)
          .map((_, index) => (
            <SubMenuGroup data-small key={index}>
              <div style={{ padding: '10px' }}>
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </div>
            </SubMenuGroup>
          ))}
      </>
    );
  }

  if (brandQuery.data) {
    if (brandQuery.data?.data?.length === 0)
      return (
        <ErrorDisplay>This Category is Empty</ErrorDisplay>
      );

    return (
      <>
        {brandQuery.data?.data?.map((brand, i) => (
          <SubMenuGroup data-small key={i}>
            <SubMenuLink
              to={ROUTES.products + `?brands=${brand.name}`}
              onClick={closeNav}
            >
              {brand.name}
            </SubMenuLink>

            {brandProductQuery.data?.data
              .filter(
                brandProduct =>
                  brandProduct.brandId === brand._id
              )
              .map((brandProduct, i) => (
                <SubMenuLink
                  key={i}
                  to={
                    ROUTES.products +
                    `?search=${brandProduct.name}`
                  }
                  data-small
                  onClick={closeNav}
                >
                  {brandProduct.name}
                </SubMenuLink>
              ))}
          </SubMenuGroup>
        ))}
      </>
    );
  }

  return (
    <ErrorDisplay>Unable to load products</ErrorDisplay>
  );
};
