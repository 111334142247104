import { toast } from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';
import { useSetRecoilState } from 'recoil';
import addAddressService from 'src/network/services/address/addAddress';
import { addressFormModalState } from 'src/stores/menus/addressModal';

const useAddAddress = () => {
  const queryClient = useQueryClient();
  const setFormOpen = useSetRecoilState(
    addressFormModalState
  );

  const mutation = useMutation(addAddressService, {
    onSuccess: () => {
      queryClient.invalidateQueries('address');
      setFormOpen(false);
      toast.success('Address added successfully');
    },
  });

  const {
    mutate: addAddress,
    isLoading: isAddingAddress,
    data,
  } = mutation;

  return { addAddress, isAddingAddress, data };
};

export default useAddAddress;
