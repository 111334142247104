import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Breadcrumb, {
  BreadcrumbItem,
} from 'src/components/atoms/BreadCrumb';
import Button from 'src/components/atoms/Button';
import { Divider } from 'src/components/atoms/Divider';
import FavouriteButton from 'src/components/atoms/FavouriteButton';
import Heading from 'src/components/atoms/Heading';
import CardIcon from 'src/components/atoms/icons/CardIcon';
import CartIcon from 'src/components/atoms/icons/CartIcon';
import ClipboardIcon from 'src/components/atoms/icons/ClipboardIcon';
import LoginIcon from 'src/components/atoms/icons/LoginIcon';
import TruckIcon from 'src/components/atoms/icons/TruckIcon';
import { WhatsappIcon } from 'src/components/atoms/icons/WhatsappIcon';
import NumberSelect from 'src/components/atoms/NumberSelect';
import Tabs, { Tab } from 'src/components/atoms/Tabs';
import Text from 'src/components/atoms/Text';
import PageLayout from 'src/components/layouts/PageLayout';
import ActionModal from 'src/components/molecules/ActionModal';
import PriceDisplay from 'src/components/molecules/PriceDisplay';
import { FlexWrapper } from 'src/components/molecules/ProductCard/style';
import ProductGrid from 'src/components/molecules/ProductGrid';
import ProductGridSkeleton from 'src/components/molecules/ProductGrid/skeleton';
import { ROUTES } from 'src/constants/routes';
import { WHATSPP_ORDER_NUMBER } from 'src/constants/socialMedia';
import useAddCart from 'src/network/react-query/mutation/cart/addCart';
import useGetFavourites from 'src/network/react-query/query/favourites/getFavourites';
import useFilterLubricants from 'src/network/react-query/query/lubricants/filterLubricants';
import useGetSingleLubricant from 'src/network/react-query/query/lubricants/getSingleLubricant';
import { formatPrice } from 'src/utils/helpers/formatPrice';
import { getPriceByQuantity } from 'src/utils/helpers/getPriceByQuantity';
import useCurrentUser from 'src/utils/hooks/useCurrentUser';
import useToggleFavourite from 'src/utils/hooks/useToggleFavourite';
import { BreadCrumbContainer } from '../Products/style';
import ProductDetailsSkeleton from './skeleton';
import {
  CheckoutButtons,
  Danger,
  DeliveryDetails,
  DeliveryText,
  DetailsGroup,
  DetailsSection,
  DetailsWrapper,
  ErrorNotice,
  ImageGroup,
  MainProductImage,
  OrderGroup,
  PriceGroup,
  ProductFixedPrice,
  ProductPrices,
  ProductQuantity,
  Quantity,
  RelatedProductText,
  SubImage,
  SubProductImages,
  TabsContainer,
  TitleGroup,
  TotalPrice,
} from './style';

const breadcrumbItems: BreadcrumbItem[] = [
  {
    label: 'Home',
    url: '/',
  },
  {
    label: 'Products',
    url: '/products',
  },
];

const ProductDetails = () => {
  const [quantity, setQuantity] = useState(1);
  const { user } = useCurrentUser();
  const [isLoginModalOpen, setLoginModalOpen] =
    useState(false);

  const { productId = '' } = useParams();
  const { productData, isLoading } =
    useGetSingleLubricant(productId);
  const { toggleFavourite, disableFavourite } =
    useToggleFavourite();
  const addCartMutation = useAddCart({
    shouldNavigate: false,
  });
  const buyNowMutation = useAddCart({
    shouldNavigate: true,
  });

  const { data: favourites } = useGetFavourites();

  useEffect(() => {
    setQuantity(productData?.minimumOrderQuantity || 1);
  }, [productData]);

  if (isLoading) {
    return (
      <PageLayout>
        <ProductDetailsSkeleton />
      </PageLayout>
    );
  }

  if (productData) {
    const handleAddToCart = (shouldNavigate?: boolean) => {
      if (!user) {
        setLoginModalOpen(true);
        return;
      }

      if (shouldNavigate) {
        buyNowMutation.addToCart({
          brandProductDetailId: productData._id,
          orderQuantitys: quantity,
        });
      } else {
        addCartMutation.addToCart({
          brandProductDetailId: productData._id,
          orderQuantitys: quantity,
        });
      }

      setQuantity(productData.minimumOrderQuantity);
    };

    const handleBuyNow = () => {
      handleAddToCart(true);
    };

    return (
      <PageLayout>
        <BreadCrumbContainer>
          <Breadcrumb
            items={[
              ...breadcrumbItems,
              {
                label: `${productData.name} ${productData.specification}`,
              },
            ]}
          />
        </BreadCrumbContainer>

        <DetailsSection>
          <DetailsWrapper>
            <ImageGroup>
              <MainProductImage>
                <img
                  src={
                    productData.productImageId.mainImageUrl
                  }
                  alt="product"
                  width={215}
                />
              </MainProductImage>
              <SubProductImages>
                <SubImage>
                  <img
                    src={
                      productData.productImageId
                        .frontImageUrl
                    }
                    alt="product"
                  />
                </SubImage>
                <SubImage>
                  <img
                    src={
                      productData.productImageId
                        .sideImageUrl
                    }
                    alt="product"
                  />
                </SubImage>
                <SubImage>
                  <img
                    src={
                      productData.productImageId
                        .rearImageUrl
                    }
                    alt="product"
                  />
                </SubImage>
              </SubProductImages>
            </ImageGroup>

            <DetailsGroup>
              <TitleGroup>
                <Heading
                  as="h2"
                  level={6}
                  style={{ marginTop: 0 }}
                >
                  {productData.name}{' '}
                  {productData.specification}{' '}
                  {productData.volume}
                </Heading>

                {
                  <FavouriteButton
                    disabled={disableFavourite}
                    checked={
                      !!favourites?.find(
                        value =>
                          value.brandProductId ===
                          productData.brandProductId._id
                      )
                    }
                    onToggle={checked =>
                      toggleFavourite(
                        checked,
                        productData.brandProductId._id
                      )
                    }
                  />
                }
              </TitleGroup>

              <Quantity>
                Minimum Order Qty:{' '}
                {productData.minimumOrderQuantity}{' '}
                {productData.minimumOrderQuantity > 1
                  ? 'cartons'
                  : 'carton'}
              </Quantity>

              {/* <FlexWrapper>
                <Rating stars={productData.rating} />
                <Text>
                  ( {productData.verifiedRatings} ratings)
                </Text>
              </FlexWrapper> */}

              <FlexWrapper>
                <Text>{productData.packageSize}</Text>
              </FlexWrapper>

              {productData.fixedPrice > 0 ? (
                <PriceDisplay>
                  <ProductFixedPrice>
                    {formatPrice(productData.fixedPrice)}
                  </ProductFixedPrice>
                </PriceDisplay>
              ) : null}

              <Divider margin="1.5rem 0" />

              {productData.variablePrice.length > 0 ? (
                <>
                  <PriceDisplay>
                    <ProductPrices>
                      {productData.variablePrice.map(
                        (value, index) => (
                          <PriceGroup key={index}>
                            <Text shade={600}>
                              {value.minimumOrderQuantity}-
                              {value.maximumOrderQuantity}{' '}
                              cartons
                            </Text>
                            <h3>
                              {formatPrice(value.amount)}
                            </h3>
                          </PriceGroup>
                        )
                      )}
                    </ProductPrices>
                  </PriceDisplay>

                  <Divider margin="1.5rem 0" />
                </>
              ) : null}

              <DeliveryDetails>
                <DeliveryText>
                  <TruckIcon />
                  <div>
                    <h3>Delivery duration</h3>
                    <Text shade={600}>
                      Delivery within 3 to 5 working days
                    </Text>
                  </div>
                </DeliveryText>

                <Divider />

                <DeliveryText>
                  <ClipboardIcon />
                  <div>
                    <h3>Return delivery</h3>
                    <Text shade={600}>
                      Free 10days Delivery Returns.
                    </Text>
                  </div>
                </DeliveryText>
              </DeliveryDetails>
            </DetailsGroup>

            <OrderGroup>
              <ProductQuantity>
                <Heading
                  as="h2"
                  level={6}
                  style={{ margin: 0 }}
                >
                  Quantity (cartons):
                </Heading>

                {productData.quantity > 0 && (
                  <NumberSelect
                    min={productData.minimumOrderQuantity}
                    max={productData.quantity}
                    value={quantity}
                    onChange={value => setQuantity(value)}
                  />
                )}

                {productData.quantity > 0 ? (
                  <Text style={{ fontWeight: 500 }}>
                    Only{' '}
                    <Danger>
                      {productData.quantity} cartons Left!
                    </Danger>{' '}
                    Don&#39;t miss it
                  </Text>
                ) : (
                  <Text style={{ fontWeight: 500 }}>
                    This product is currently{' '}
                    <Danger>out of stock!</Danger>
                  </Text>
                )}
              </ProductQuantity>

              <div data-price-section>
                {productData.quantity > 0 && (
                  <PriceDisplay>
                    <TotalPrice>
                      <Text shade={600}>Total Price:</Text>
                      <span>
                        {formatPrice(
                          getPriceByQuantity(
                            quantity,
                            productData.fixedPrice,
                            productData.variablePrice
                          )
                        )}
                      </span>
                    </TotalPrice>
                  </PriceDisplay>
                )}

                <Divider margin="2rem 0" />

                <CheckoutButtons>
                  <Button
                    fluid
                    onClick={() => handleAddToCart()}
                    loading={addCartMutation.isAddingToCart}
                    disabled={
                      addCartMutation.isAddingToCart ||
                      buyNowMutation.isAddingToCart ||
                      productData.quantity < 1 ||
                      productData.quantity <
                        productData.minimumOrderQuantity
                    }
                  >
                    <CartIcon />
                    Add to cart
                  </Button>

                  <Button
                    fluid
                    variant="secondary"
                    onClick={handleBuyNow}
                    loading={buyNowMutation.isAddingToCart}
                    disabled={
                      addCartMutation.isAddingToCart ||
                      buyNowMutation.isAddingToCart ||
                      productData.quantity < 1 ||
                      productData.quantity <
                        productData.minimumOrderQuantity
                    }
                  >
                    <CardIcon />
                    Buy Now
                  </Button>
                  <Button
                    asLink
                    target="_blank"
                    rel="noreferrer"
                    fluid
                    variant="outlined"
                    href={getWhatsappLink({
                      id: productId,
                      name: productData.name,
                      quantity,
                    })}
                  >
                    <WhatsappIcon />
                    Order on WhatsApp
                  </Button>
                </CheckoutButtons>
              </div>
            </OrderGroup>
          </DetailsWrapper>
        </DetailsSection>

        <TabsContainer>
          <Tabs
            tabs={[
              {
                id: 'description',
                label: 'Product Description',
                content: productData.description,
              },
              ...TabItems,
              {
                id: 'relatedProducts',
                label: 'Related Products',
                content: (
                  <RelatedProducts
                    brandName={
                      productData.brandProductId.name
                    }
                  />
                ),
              },
            ]}
          />
        </TabsContainer>

        <ActionModal
          isOpen={isLoginModalOpen}
          onClose={() => setLoginModalOpen(false)}
          heading="Register or Sign In"
          text={`You have to create a Virpem account to add a product to cart`}
          icon={<LoginIcon />}
          footer={
            <div style={{ display: 'flex', gap: '12px' }}>
              <Button asLink href={ROUTES.register} fluid>
                Register
              </Button>
              <Button
                variant="secondary"
                asLink
                href={ROUTES.login}
                fluid
              >
                Login
              </Button>
            </div>
          }
        />
      </PageLayout>
    );
  }

  return (
    <PageLayout>
      <ErrorNotice>
        <h2>Product Unavailable</h2>
        <p>
          we were unable to get the details for this product
        </p>
      </ErrorNotice>
    </PageLayout>
  );
};

export default ProductDetails;

const TabItems: Tab[] = [
  {
    id: 'ratings',
    label: 'Ratings and Reviews',
    content: (
      <div style={{ display: 'grid', gap: '3rem' }}>
        <p>No reviews yet.</p>
        {/* {Array(4)
          .fill(null)
          .map((_, i) => (
            <ReviewCard key={i} />
          ))} */}
      </div>
    ),
  },
  // {
  //   id: 'relatedProducts',
  //   label: 'Related Products',
  //   content: <TopRankingProducts />,
  // },
];

const RelatedProducts = (props: { brandName: string }) => {
  const { brandName } = props;
  const {
    data: lubricants,
    isLoading: isLoadingLubricants,
  } = useFilterLubricants({
    product: brandName,
  });

  return isLoadingLubricants ? (
    <ProductGridSkeleton />
  ) : lubricants?.length ? (
    <ProductGrid data={lubricants} />
  ) : (
    <RelatedProductText>
      No Related products available
    </RelatedProductText>
  );
};

interface WhatsappOrderDetails {
  id: string;
  name: string;
  quantity: number;
}

function getWhatsappLink(details: WhatsappOrderDetails) {
  const { id, name, quantity } = details;

  const line1 = encodeURIComponent('Hello,');
  const line2 = encodeURIComponent(
    'I would like to make an order on Virpem for the following product:'
  );
  const line3 = encodeURIComponent(`Product ID: ${id};`);
  const line4 = encodeURIComponent(
    `Product name: ${name};`
  );
  const line5 = encodeURIComponent(
    `Quantity: ${quantity} carton(s);`
  );

  return `https://wa.me/${WHATSPP_ORDER_NUMBER}?text=${line1}%0a%0a${line2}%0a%0a${line3}%0a${line4}%0a${line5}`;
}
