import { styled } from 'styled-components';

export const NumberInputContainer = styled.div<{
  size?: 'default' | 'sm';
}>`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
  align-items: center;
  justify-items: center;
  width: 100%;
  height: 56px;
  background-color: ${p => p.theme.colors.neutral[50]};
  border-radius: 8px;
  overflow: hidden;

  &[data-size='sm'] {
    gap: 4px;
    width: 100px;
    height: 20px;
    border-radius: 20px;
    background-color: transparent;
    overflow: visible;
  }
`;

export const Button = styled.button<{
  size?: 'default' | 'sm';
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 100%;
  border: none;
  background-color: transparent;
  color: #1f1f1f;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${p => p.theme.colors.neutral[100]};
  }

  &:disabled {
    opacity: 0.1;
  }

  &[data-size='sm'] {
    background-color: ${p => p.theme.colors.neutral[50]};
    height: 20px;
    width: 20px;
    border-radius: 100%;
    color: #8f8f8f;
    font-size: 0.625rem;

    @media (min-width: 1024px) {
      font-size: 0.75rem;
    }
  }
`;

export const ValueInput = styled.input`
  width: 100%;
  height: 80%;
  border: none;
  text-align: center;
  background-color: transparent;
  color: ${p => p.theme.colors.primary[500]};
  font-weight: 600;

  &[data-size='sm'] {
    min-width: 50px;
    background-color: transparent;
    font-size: 0.625rem;

    @media (min-width: 1024px) {
      font-size: 0.75rem;
    }
  }
`;
