import styled from 'styled-components';

export const ItemWrapper = styled.article`
  display: grid;
  width: 100%;
  padding-block: 20px;
  grid-template-columns: 80px 1fr;
  gap: 12px;
  border-bottom: 1px solid
    ${p => p.theme.colors.neutral[200]};
`;

export const ItemImage = styled.div`
  background-color: #f6f7f8;
  border-radius: 8px;
  padding: 1rem;
  display: grid;
  place-items: center;
  min-height: 100%;
`;

export const ItemContent = styled.div`
  display: grid;
  gap: 20px;
`;

export const ItemName = styled.div`
  h3 {
    font-weight: 600;
    font-size: 14px;
    color: #1f1f1f;
  }

  p {
    font-size: 12px;
    color: #6a6a6a;
  }
`;

export const ItemPrice = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  p {
    font-size: 12px;
    color: #6a6a6a;

    &[data-price='true'] {
      font-weight: 600;
      font-size: 14px;
      color: #1f1f1f;
    }
  }
`;
