import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { useMutation } from 'react-query';
import verifyPhoneService from 'src/network/services/auth/verifyPhone';

const useVerifyPhone = () => {
  const [userId, setUserId] = useState('');

  const verifyPhoneMutation = useMutation(
    verifyPhoneService,
    {
      onSuccess: res => {
        toast.dismiss();
        toast.success('Phone verification successful');
        setUserId(res.data._id);
      },
    }
  );

  const {
    mutate: verifyPhone,
    isLoading: isVerifyingPhone,
    isSuccess,
  } = verifyPhoneMutation;

  return {
    verifyPhone,
    isVerifyingPhone,
    isSuccess,
    userId,
  };
};

export default useVerifyPhone;
