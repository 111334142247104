export const CITIES = [
  'Dutse',
  'Yola',
  'Maiduguri',
  'Damaturu',
  'Lafia',
  'Gusau',
  'Birnin Kebbi',
  'Yenagoa',
  'Jalingo',
  'Umuahia',
  'Ekiti',
  'Gombe',
  'Awka',
  'Minna',
  'Osogbo',
  'Bauchi',
  'Lokoja',
  'Makurdi',
  'Ado Ekiti',
  'Asaba',
  'Sokoto',
  'Akure',
  'Ilorin',
  'Jos',
  'Warri',
  'Uyo',
  'Calabar',
  'Owerri',
  'Abeokuta',
  'Enugu',
  'Kaduna',
  'Ibadan',
  'Kano',
  'Port Harcourt',
  'Abuja',
  'Lagos',
  'Isihor',
  'Oluku',
  'Egor',
  'Ugbekun',
  'Ikpoba Hill',
  'Ologbo',
  'Ugbowo',
  'Fugar',
  'Uzebba',
  'Agenebode',
  'Abudu',
  'Ubiaja',
  'Okada',
  'Igarra',
  'Uromi',
  'Irrua',
  'Auchi',
  'Ekpoma',
  'Benin',
];
