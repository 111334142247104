import { IconProps } from './icon';

const LPGIcon = (props: IconProps) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1 16.5H7M7 19.5H1M15 16.5H21M21 19.5H15M12.5 11H9.5V15H12.5V11Z"
        stroke="#1F1F1F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 6L12.5 4.5M16 6C16 7.32608 15.4732 8.59785 14.5355 9.53553C13.5979 10.4732 12.3261 11 11 11C9.67392 11 8.40215 10.4732 7.46447 9.53553C6.52678 8.59785 6 7.32608 6 6C6 4.67392 6.52678 3.40215 7.46447 2.46447C8.40215 1.52678 9.67392 1 11 1C12.3261 1 13.5979 1.52678 14.5355 2.46447C15.4732 3.40215 16 4.67392 16 6ZM13 15H9C8.057 15 7.586 15 7.293 15.293C7 15.586 7 16.057 7 17V19C7 19.943 7 20.414 7.293 20.707C7.586 21 8.057 21 9 21H13C13.943 21 14.414 21 14.707 20.707C15 20.414 15 19.943 15 19V17C15 16.057 15 15.586 14.707 15.293C14.414 15 13.943 15 13 15Z"
        stroke="#1F1F1F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LPGIcon;
