import notFoundSvg from 'src/assets/images/pageNotFound.svg';
import Button from 'src/components/atoms/Button';
import { Container } from 'src/components/atoms/Container';
import PageLayout from 'src/components/layouts/PageLayout';
import styled from 'styled-components';

const NotFoundPage = () => {
  return (
    <PageLayout>
      <Container>
        <Wrapper>
          <img src={notFoundSvg} alt="page not found" />
          <Button asLink href="/">
            Homepage
          </Button>
        </Wrapper>
      </Container>
    </PageLayout>
  );
};

export default NotFoundPage;

const Wrapper = styled.div`
  display: grid;
  place-items: center;
  padding-block: 50px;
  gap: 50px;

  img {
    width: 400px;
  }
`;
