import { atom } from 'recoil';

interface ActiveState {
  isOpen: true;
  productId: string;
}

interface InactiveState {
  isOpen: false;
}

type Props = ActiveState | InactiveState;

export const ratingModalState = atom<Props>({
  key: 'ratingModalState',
  default: {
    isOpen: false,
  },
});
