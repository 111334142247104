import { AddressData } from 'src/network/services/address/getAddress';

export const getDefaultAddress = (
  addressData: AddressData[]
) => {
  if (addressData.length) {
    const addressInfo =
      addressData.find(address => address.isDefault) ||
      addressData[0];

    return addressInfo.address;
  }

  return null;
};
