import { Link } from 'react-router-dom';
import { styled } from 'styled-components';

export const Group = styled.div`
  display: grid;
  align-items: start;
  grid-template-columns: 1fr 1fr;
  gap: 16px;

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
`;

export const StyledLink = styled(Link)`
  color: ${p => p.theme.colors.primary[500]};
  text-decoration: underline;
`;
