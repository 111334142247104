export const CONTACT_DETAILS = {
  phoneNumber: {
    text: '08144705621',
    href: 'tel:+2348144705621',
  },
  emailAddress: {
    text: 'virpem@mephalti.com',
    href: 'mailto:virpem@mephalti.com',
  },
} as const;
