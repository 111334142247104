import { toast } from 'react-hot-toast';
import { useMutation } from 'react-query';
import updatePassowordService from 'src/network/services/auth/updatePassword';

const useUpdatePassword = () => {
  const updatePasswordMutation = useMutation(
    updatePassowordService,
    {
      onSuccess: res => {
        toast.dismiss();
      },
    }
  );

  const {
    mutate: updatePassword,
    isLoading: isUpdatingPassword,
    isSuccess,
  } = updatePasswordMutation;

  return {
    updatePassword,
    isUpdatingPassword,
    isSuccess,
  };
};

export default useUpdatePassword;
