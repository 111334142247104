import { toast } from 'react-hot-toast';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { ROUTES } from 'src/constants/routes';
import verifyPasswordOtpService from 'src/network/services/auth/verifyPasswordOtp';
import { resetPasswordState } from 'src/stores/auth/resetPasswordState';

const useVerifyPasswordOtp = () => {
  const navigate = useNavigate();
  const setResetPasswordState = useSetRecoilState(
    resetPasswordState
  );

  const verifyPasswordOtpMutation = useMutation(
    verifyPasswordOtpService,
    {
      onSuccess: res => {
        toast.dismiss();

        toast.success('OTP verification Successful');
        setResetPasswordState(prev => ({
          ...prev,
          userId: res.data._id,
          newPasswordAccess: true,
        }));

        navigate(ROUTES.updatePassword);
      },
      onError: () => {
        toast.dismiss();
        toast.error('OTP verification failed');
        return;
      },
    }
  );

  const {
    mutate: verifyPasswordOtp,
    isLoading: isVerifyingOtp,
    data,
  } = verifyPasswordOtpMutation;

  return { verifyPasswordOtp, isVerifyingOtp, data };
};

export default useVerifyPasswordOtp;
