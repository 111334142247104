import { toast } from 'react-hot-toast';
import { useMutation } from 'react-query';
import changeCurrentPassowordService from 'src/network/services/auth/changeCurrentPassword';

const useChangeCurrentPassword = () => {
  return useMutation(changeCurrentPassowordService, {
    onSuccess: () => {
      toast.dismiss();
    },
  });
};

export default useChangeCurrentPassword;
