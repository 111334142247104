import { useQuery } from 'react-query';
import getUserProfileService from 'src/network/services/profile/getUserProfile';

const useGetUserProfile = () => {
  const { data, isLoading, error } = useQuery(
    'userProfile',
    getUserProfileService
  );

  return { data, isLoading, error };
};

export default useGetUserProfile;
