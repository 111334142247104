import { toast } from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';
import { useSetRecoilState } from 'recoil';
import deleteCartItemService from 'src/network/services/cart/deleteCartItem';
import { cartModalState } from 'src/stores/menus/cartModalState';

const useDeleteCartItem = () => {
  const queryClient = useQueryClient();
  const setCartModal = useSetRecoilState(cartModalState);

  const mutation = useMutation(deleteCartItemService, {
    onSuccess: res => {
      queryClient.refetchQueries('userCart');
      toast.dismiss();
      toast.success('Item removed from cart', {
        id: 'cart',
      });
      setCartModal({ isOpen: false });
    },
  });

  const {
    mutate: deleteCartItem,
    isLoading: isDeletingCartItem,
    data,
  } = mutation;

  return { deleteCartItem, isDeletingCartItem, data };
};

export default useDeleteCartItem;
