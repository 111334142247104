import nlng from 'src/assets/images/icons/nlng.png';
import nnpc from 'src/assets/images/icons/nnpc.png';
import saipem from 'src/assets/images/icons/saipem.png';
import total from 'src/assets/images/icons/total.png';

export const NNPCIcon = () => {
  return (
    <img
      src={nnpc}
      alt="NNPC logo"
      width={24}
      height={24}
    />
  );
};

export const NLNGIcon = () => {
  return (
    <img
      src={nlng}
      alt="NLNG logo"
      width={24}
      height={24}
    />
  );
};

export const SAIPEMIcon = () => {
  return (
    <img
      src={saipem}
      alt="SAIPEM logo"
      width={24}
      height={20.8}
    />
  );
};

export const TOTALIcon = () => {
  return (
    <img
      src={total}
      alt="TOTAL logo"
      width={24}
      height={18.93}
    />
  );
};
