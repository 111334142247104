import { IconProps } from './icon';

const LoginIcon: React.FC<IconProps> = props => (
  <svg
    {...props}
    width="108"
    height="138"
    viewBox="0 0 108 138"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M67.125 59.5625H106.094V78.4375H67.125H65.625V79.9375V87.2537L47.3713 69L65.625 50.7463V58.0625V59.5625H67.125Z"
      fill="#FEF6F8"
      stroke="#370617"
      stroke-width="3"
    />
    <path
      d="M1.90625 122.615V15.3847L40.4688 2.19226V135.808L1.90625 122.615ZM31.3847 74.1153L32.4454 73.0546L31.3847 74.1153C31.8711 74.6017 32.5308 74.875 33.2188 74.875C33.9067 74.875 34.5664 74.6017 35.0528 74.1153C35.5392 73.6289 35.8125 72.9691 35.8125 72.2812V65.7188C35.8125 65.0309 35.5392 64.3711 35.0528 63.8847L33.9921 64.9454L35.0528 63.8847C34.5664 63.3983 33.9067 63.125 33.2188 63.125C32.5308 63.125 31.8711 63.3983 31.3847 63.8847C30.8983 64.3711 30.625 65.0309 30.625 65.7188V72.2812C30.625 72.9691 30.8983 73.6289 31.3847 74.1153Z"
      fill="#FEF6F8"
      stroke="#370617"
      stroke-width="3"
    />
    <path
      d="M70.8125 20.875V19.375H69.3125H45.6562V15.8125H75.4688V54.375H70.8125V20.875Z"
      fill="#FEF6F8"
      stroke="#370617"
      stroke-width="3"
    />
    <path
      d="M70.8125 118.906V83.625H75.4688V118.906H70.8125Z"
      fill="#FEF6F8"
      stroke="#370617"
      stroke-width="3"
    />
    <mask id="path-5-inside-1_1373_16638" fill="white">
      <path d="M106.5 124.781H44.1562V122.594H106.5C106.79 122.594 107.068 122.709 107.273 122.914C107.479 123.119 107.594 123.397 107.594 123.688C107.594 123.978 107.479 124.256 107.273 124.461C107.068 124.666 106.79 124.781 106.5 124.781Z" />
    </mask>
    <path
      d="M106.5 124.781H44.1562V122.594H106.5C106.79 122.594 107.068 122.709 107.273 122.914C107.479 123.119 107.594 123.397 107.594 123.688C107.594 123.978 107.479 124.256 107.273 124.461C107.068 124.666 106.79 124.781 106.5 124.781Z"
      fill="#FEF6F8"
    />
    <path
      d="M44.1562 124.781H41.1562V127.781H44.1562V124.781ZM44.1562 122.594V119.594H41.1562V122.594H44.1562ZM106.5 121.781H44.1562V127.781H106.5V121.781ZM47.1562 124.781V122.594H41.1562V124.781H47.1562ZM44.1562 125.594H106.5V119.594H44.1562V125.594ZM106.5 125.594C105.994 125.594 105.51 125.393 105.152 125.035L109.395 120.793C108.627 120.025 107.586 119.594 106.5 119.594V125.594ZM105.152 125.035C104.795 124.678 104.594 124.193 104.594 123.688H110.594C110.594 122.602 110.162 121.561 109.395 120.793L105.152 125.035ZM104.594 123.688C104.594 123.182 104.795 122.697 105.152 122.34L109.395 126.582C110.162 125.814 110.594 124.773 110.594 123.688H104.594ZM105.152 122.34C105.51 121.982 105.994 121.781 106.5 121.781V127.781C107.586 127.781 108.627 127.35 109.395 126.582L105.152 122.34Z"
      fill="#370617"
      mask="url(#path-5-inside-1_1373_16638)"
    />
    <path
      d="M65.625 24.5625V43.4084L45.6562 63.3771V24.5625H65.625Z"
      fill="#FEF6F8"
      stroke="#370617"
      stroke-width="3"
    />
    <path
      d="M65.625 94.592V117.813H45.6562V74.6233L65.625 94.592Z"
      fill="#FEF6F8"
      stroke="#370617"
      stroke-width="3"
    />
  </svg>
);

export default LoginIcon;
