import { IconProps } from './icon';

const DotIcon: React.FC<IconProps> = props => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 9 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="4.5" cy="4" r="4" fill="currentColor" />
  </svg>
);

export default DotIcon;
