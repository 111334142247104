import {
  CompleteUpdateDialog,
  IsUpdatingDialog,
  PendingUpdateDialog,
} from './AppUpdateDialogs';
import ConfirmDialogPortal, {
  portalRoot,
} from './ConfirmDialogPortal';

type DialogType =
  | 'pendingUpdate'
  | 'isUpdatingApp'
  | 'completeAppUpdate';

export function showConfirmDialog(type: DialogType) {
  const dialogComponent: Record<
    DialogType,
    React.ReactNode
  > = {
    pendingUpdate: <PendingUpdateDialog />,
    isUpdatingApp: <IsUpdatingDialog />,
    completeAppUpdate: <CompleteUpdateDialog />,
  };

  portalRoot.render(
    <ConfirmDialogPortal>
      {dialogComponent[type]}
    </ConfirmDialogPortal>
  );
}
