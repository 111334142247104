import { Container } from 'src/components/atoms/Container';
import styled from 'styled-components';

export const StyledHeader = styled.header`
  position: sticky;
  z-index: 999;
  height: 80px;
  left: 0px;
  top: 0px;
  border-bottom: 1px solid
    ${p => p.theme.colors.neutral[300]};
  background-color: ${p => p.theme.colors.white};
`;

export const HeaderContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  height: 100%;

  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: 100px 1fr;
  }
`;

export const Group = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;

  &[data-icons='true'] {
    justify-self: right;
  }
`;

export const IconGroup = styled(Group)`
  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: 1fr 300px;
  }

  ${Group} {
    justify-content: right;
  }
`;

export const MenuButton = styled.button`
  font-size: 1.5rem;

  @media (min-width: 768px) {
    display: none;
  }
`;

// checkout header

export const CheckoutHeaderContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  height: 100%;

  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: 1fr 1.3fr;
  }
`;

export const TextGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  p {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #1f1f1f;
  }
`;
