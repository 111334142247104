import { StarsRating } from 'src/components/molecules/Rating';
import { ApiResponse } from 'src/network/apiResponse';
import { API_ROUTES } from 'src/network/apiRoutes';
import { axiosInstance } from 'src/network/axios';
import handleServiceError from 'src/utils/helpers/handleServiceError';
import { VariablePrice } from '../lubricants/addLubricant';

interface OrderDetailsData {
  customerName: string;
  order: {
    _id: string;
    userId: string;
    orderItems: {
      brandProductDetailsId: string;
      name: string;
      quantity: number;
      description: string;
      volume: string;
      specification: string;
      packageSize: string;
      brandProductId: string;
      variablePrice: VariablePrice[];
      fixedPrice: number;
      productImageId: {
        frontImageUrl: string;
        sideImageUrl: string;
        rearImageUrl: string;
        mainImageUrl: string;
        brandProductId: string;
      };
      brandCategoryId: {
        _id: string;
        name: string;
      };
      rating: StarsRating;
      remainingQuantity: number;
      orderQuantity: number;
      totalSelectedProductPrice: number;
      _id: string;
    }[];
    orderItemsTotalPrice: number;
    deliveryPrice: number;
    totalPrice: number;
    status: string;
    deliveryLocation: string;
    orderId: number;
    paymentMethod: string;
    city: string;
    state: string;
    createdAt: string;
  };
}

const getOrderByIdService = async (orderId: string) => {
  const route = API_ROUTES.getOrders + `/${orderId}`;

  try {
    const response =
      await axiosInstance.get<
        ApiResponse<OrderDetailsData>
      >(route);
    return response.data.data;
  } catch (error) {
    throw handleServiceError(error);
  }
};

export default getOrderByIdService;
