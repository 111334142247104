import { isPasswordValid } from 'src/components/atoms/Input/useValidatePassword';
import { capitalizeFirstLetter } from 'src/utils/helpers/capitalizeFirstLetter';
import * as z from 'zod';

export const userSchema = z.object({
  firstName: z
    .string()
    .trim()
    .nonempty('Please provide a firstname')
    .min(2, 'Name should have atleast 2 alphabets')
    .regex(/^[\S]+$/, 'First name should be one word')
    .toLowerCase()
    .refine(
      value => /^[a-zA-Z]+[-'s]?[a-zA-Z ]+$/.test(value),
      'Name should contain only alphabets'
    )
    .transform(value => capitalizeFirstLetter(value)),

  lastName: z
    .string()
    .trim()
    .nonempty('Please provide a lastname')
    .min(2, 'Name should have atleast 2 alphabets')
    .regex(/^[\S]+$/, 'Last name should be one word')
    .toLowerCase()
    .refine(
      value => /^[a-zA-Z]+[-'s]?[a-zA-Z ]+$/.test(value),
      'Name should contain only alphabets'
    )
    .transform(value => capitalizeFirstLetter(value)),

  phoneNumber: z
    .string()
    .trim()
    .nonempty('Please provide a phone number')
    .length(10, 'Phone number should be 10 digits')
    .refine(
      value => /^[0-9]+$/.test(value),
      'Only numbers are allowed'
    ),

  password: z
    .string()
    .trim()
    .nonempty('Please provide a password')
    .min(8, 'Password should be at least 8 characters')
    .max(30, 'Password is too long')
    .regex(/^[\S]+$/, 'Password should be one phrase')
    .refine(
      value => isPasswordValid(value),
      'Password does not match required format'
    ),

  hasSignedTerms: z.boolean(),
});

export type UserRegisterDetails = z.infer<
  typeof userSchema
>;

export const registerFormSchema = userSchema
  .extend({
    confirm_password: z
      .string()
      .trim()
      .nonempty('Please provide a password'),
  })
  .refine(data => data.password === data.confirm_password, {
    message: 'Passwords do not match',
    path: ['confirm_password'],
  });

export type RegisterFormProps = z.infer<
  typeof registerFormSchema
>;
