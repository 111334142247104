import { IconProps } from './icon';

const CheckIcon: React.FC<IconProps> = () => (
  <svg
    width="1em"
    height="0.875em"
    viewBox="0 0 12 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.1318 0.689706C11.3952 0.94695 11.4002 1.36903 11.143 1.63245L4.63257 8.29912C4.50139 8.43345 4.31964 8.50623 4.132 8.49958C3.94435 8.49293 3.76821 8.40746 3.64687 8.26417L0.823953 4.93084C0.586006 4.64987 0.620883 4.2292 0.901854 3.99126C1.18283 3.75331 1.60349 3.78819 1.84144 4.06916L4.19071 6.8432L10.1891 0.700885C10.4463 0.437467 10.8684 0.432462 11.1318 0.689706Z"
      fill="currentColor"
    />
  </svg>
);

export default CheckIcon;
