import { styled } from 'styled-components';

export const PopoverContainer = styled.div`
  position: relative;
  display: inline-block;
`;

export const PopoverContent = styled.div`
  position: absolute;
  background-color: ${p => p.theme.colors.white};
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1;
  width: fit-content;
  min-width: 100%;
  margin-top: 8px;
  right: 0;
`;

export const TriggerButton = styled.button`
  display: flex;
  align-items: center;
  gap: 12px;
`;
