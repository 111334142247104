import { Link } from 'react-router-dom';
import ChevronRight from 'src/components/atoms/icons/ChevronRight';
import styled from 'styled-components';

export const StyledNav = styled.nav`
  position: fixed;
  height: 100vh;
  overflow-y: auto;
  width: 100%;
  max-width: 350px;
  z-index: 9999;
  background-color: #ffffff;
  border-right: 1px solid #dee2e6;
  top: 0;
  left: -100%;
  transition: 250ms ease-in-out;

  &[data-open='true'] {
    left: 0;
  }

  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  scrollbar-track-color: transparent;

  /* Styling for WebKit browsers (Chrome, Safari) */
  &::-webkit-scrollbar {
    width: 4px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #a5adb5;
    border-radius: 50px;
  }

  @media (min-width: 768px) {
    background-color: ${p => p.theme.colors.secondary};
    height: 56px;
    z-index: 888;
    position: sticky;
    top: 80px;
    left: 0;
    max-width: 100%;
    overflow-y: visible;
  }
`;

export const NavbarHeader = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;
  height: 80px;
  padding-inline: 2rem;
  justify-content: space-between;
  background-color: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.04);
  position: sticky;
  top: 0;
  z-index: 1;

  h2 {
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
  }

  @media (min-width: 768px) {
    display: none;
  }
`;

export const NavbarContainer = styled.ul`
  width: 100%;
  padding: 1rem 2rem;

  @media (min-width: 768px) {
    display: flex;
    justify-content: center;
    padding: 0;
    width: ${p => p.theme.width.container};
    margin-inline: auto;
  }

  @media (min-width: 1024px) {
    gap: 40px;
  }
`;

export const StyledNavBarItem = styled.li`
  display: grid;
  gap: 1rem;

  @media (min-width: 768px) {
    display: block;
    height: 56px;
    width: 100%;
    position: relative;
    width: fit-content;
  }
`;

export const NavbarLinkWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dee2e6;
  padding-block: 1rem;

  @media (min-width: 768px) {
    padding-block: 0;
    border-bottom: none;
  }
`;

export const NavbarDropdownButton = styled.button<{
  $isOpen: boolean;
}>`
  padding-inline: 8px;
  transform: rotate(${p => (p.$isOpen ? '90deg' : '0')});
  transition: transform 150ms ease;

  @media (min-width: 768px) {
    display: none;
  }
`;

export const NavBarLink = styled(Link)`
  font-weight: 500;
  text-transform: uppercase;

  @media (min-width: 768px) {
    height: 100%;
    display: flex;
    align-items: center;
    font-weight: 600;
    border: none;
    color: ${p => p.theme.colors.primary[500]};
    padding-inline: 1.5rem;
    transition: 150ms;

    &:hover {
      background-color: ${p => p.theme.colors.primary[500]};
      color: ${p => p.theme.colors.white};
    }
  }
`;

export const NavBarIcon = styled(ChevronRight)`
  font-size: 1.8rem;
`;

export const SubMenuWrapper = styled.div<{
  $isOpen: boolean;
}>`
  height: 100%;
  display: grid;
  overflow: hidden;
  grid-template-rows: ${p => (p.$isOpen ? '1fr' : '0fr')};
  transition: grid-template-rows 150ms ease;
  pointer-events: none;
`;

export const SubMenu = styled.div<{ $isOpen: boolean }>`
  pointer-events: all;
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(120px, 1fr)
  );

  overflow: hidden;

  @media (min-width: 768px) {
    height: auto;
    background-color: ${p => p.theme.colors.white};
    /* padding: 24px; */
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    position: absolute;
    z-index: 777;
    top: 56px;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0px 15px 35px -5px rgba(0, 0, 0, 0.25);

    visibility: hidden;
    opacity: 0;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);

    max-height: 60vh;
    max-height: 60dvh;
    overflow-y: auto;

    &[data-align='right'] {
      left: unset;
      transform: translate(0);
      right: 0;

      &::before {
        left: unset;
        right: 25px;
      }
    }

    &[data-align='left'] {
      left: 0;
      transform: translate(0);

      &::before {
        right: unset;
        left: 25px;
      }
    }

    ${StyledNavBarItem}:hover & {
      visibility: visible;
      opacity: 1;
      width: auto;
      height: auto;
      padding: 24px;
      overflow: auto;
      clip: unset;
      transition: 200ms;
    }
  }

  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  scrollbar-track-color: transparent;

  /* Styling for WebKit browsers (Chrome, Safari) */
  &::-webkit-scrollbar {
    width: 4px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #a5adb5;
    border-radius: 50px;
  }
`;

export const SubMenuGroup = styled.div`
  display: grid;
  align-items: start;
  align-content: start;
  overflow: hidden;

  &[data-small='true'] {
    font-size: 0.875em;
    gap: 0;
  }

  @media (min-width: 768px) {
    min-width: 160px;
    display: grid;
    gap: 14px;
  }
`;

export const SubMenuLink = styled(Link)`
  font-style: normal;
  font-weight: 600;
  line-height: 19px;
  padding: 8px;
  transition: 200ms;

  &[data-small='true'] {
    color: #6a6a6a;
    font-weight: 400;
  }

  &:hover {
    background-color: ${p => p.theme.colors.secondary};
  }
`;

export const ErrorDisplay = styled.div`
  width: 200px;
  display: flex;
  font-size: 0.75rem;

  @media (min-width: 768px) {
    justify-content: center;
  }
`;
