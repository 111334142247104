import { Link } from 'react-router-dom';
import { styled } from 'styled-components';

export const BreadcrumbContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;

  & > *:last-child {
    font-weight: 600;
  }

  svg {
    font-size: 1.5rem;
  }
`;

export const BreadcrumbItemLink = styled(Link)`
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;
