export const COLORS = {
  //brand
  primary: {
    100: '#d7cdd1',
    200: '#af9ba2',
    300: '#876a74',
    400: '#5f3845',

    500: '#370617',

    600: '#2c0512',
    700: '#21040e',
    800: '#160209',
    900: '#0b0105',
  },

  secondary: '#FDEDF2',

  //contrast
  white: '#ffffff',
  black: '#1F1F1F',

  neutral: {
    50: '#F8FAFC',
    100: '#F5F6F8',
    200: '#E2E8F0',
    300: '#DEE2E6',
    400: '#B4B4B4',
    500: '#64748B',
    600: '#475569',
    700: '#334155',
    800: '#1E293B',
    900: '#0F172A',
  },

  grey: {
    100: '#E6E9EC',
    200: '#EFF1F3',
    300: '#8F8F8F',
    400: '#6a6a6a',
    500: '#444444',
  },

  danger: {
    50: '#FEF2F2',
    100: '#FEE2E2',
    200: '#FECACA',
    300: '#FCA5A5',
    400: '#F87171',
    500: '#EF4444',
    600: '#DC2626',
    700: '#B91C1C',
    800: '#991B1B',
    900: '#7F1D1D',
  },

  warning: {
    50: '#FFFBEB',
    100: '#FEF3C7',
    200: '#FDE68A',
    300: '#FCD34D',
    400: '#FBBF24',
    500: '#F59E0B',
    600: '#D97706',
    700: '#B45309',
    800: '#92400E',
    900: '#78350F',
  },

  success: {
    50: '#ECFDF5',
    100: '#D1FAE5',
    200: '#A7F3D0',
    300: '#6EE7B7',
    400: '#34D399',
    500: '#10B981',
    600: '#059669',
    700: '#047857',
    800: '#065F46',
    900: '#064E3B',
    1000: '#002100',
  },
};
