import { IconProps } from "./icon";

const MinusBar: React.FC<IconProps> = props => (
  <svg
    width="15"
    height="5"
    viewBox="0 0 15 5"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.286133"
      y="0.245605"
      width="14.4271"
      height="4.50848"
      fill="currentColor"
    />
  </svg>
);

export default MinusBar;
