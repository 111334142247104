import { styled } from 'styled-components';

export const InputWrapper = styled.div`
  display: grid;
  width: 100%;
  gap: 12px;
  position: relative;
`;

export const StyledInputWrapper = styled.div`
  position: relative;

  svg {
    color: ${p => p.theme.colors.grey[300]};
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    display: none;
  }

  &[data-disabled='true'] {
    svg {
      display: block;
    }
  }
`;

export const StyledInput = styled.input`
  display: block;
  width: 100%;
  padding: 12px 16px;
  border: 1px solid ${p => p.theme.colors.neutral[300]};
  border-radius: 4px;
  background: transparent;
  outline: none;
  transition: border-color 250ms;

  &[data-error='true'] {
    border-color: ${p => p.theme.colors.danger[700]};
  }

  &[data-bg='gray'] {
    background-color: ${p => p.theme.colors.neutral[100]};
    color: ${p => p.theme.colors.black};
  }

  &[data-bg='transparent'] {
    color: ${p => p.theme.colors.black};
  }

  &:disabled {
    background-color: ${p => p.theme.colors.grey[100]};
  }
`;

export const StyledLabel = styled.label`
  color: inherit;
`;

export const InputMessage = styled.p`
  font-size: 0.875rem;
  color: ${p => p.theme.colors.danger[700]};
`;

export const StyledSearchInput = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  padding-left: 10px;
  width: 100%;
  border: 1px solid ${p => p.theme.colors.neutral[300]};
  border-radius: 4px;
  background: transparent;

  svg {
    color: ${p => p.theme.colors.neutral[500]};
    transition: 250ms;

    &:hover {
      transform: scale(1.05);
    }
  }

  &[data-error='true'] {
    border-color: ${p => p.theme.colors.danger[700]};
  }

  &[data-bg='gray'] {
    background-color: ${p => p.theme.colors.neutral[100]};
  }

  input {
    padding: 12px 16px;
    width: 100%;
    outline: none;
    border: none;
    background: transparent;
  }
`;

export const PasswordInputWrapper = styled.div`
  position: relative;
  width: 100%;

  ${StyledInput} {
    padding-right: 50px;

    &::-ms-clear,
    &::-ms-reveal {
      display: none;
    }
  }
`;

export const VisibilityIcon = styled.button`
  position: absolute;
  font-size: 1.5rem;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  cursor: pointer;
  color: #8f8f8f;
`;
