import { AxiosError } from 'axios';

const handleServiceError = (error: unknown) => {
  if (error instanceof AxiosError) {
    if (!error.response) {
      return Error(error.message);
    }

    if (typeof error.response.data.data === 'string') {
      return Error(error.response.data.data);
    }

    if (typeof error.response.data.data === 'object') {
      if (error.response.data.data.code === 11000) {
        return Error(error.response.data.message);
      }

      return Error(error.response.data.data.error);
    }
  } else {
    return Error('Unable to send request');
  }
};

export default handleServiceError;
