import { useSetRecoilState } from 'recoil';
import { Accordion } from 'src/components/atoms/Accordion';
import Button from 'src/components/atoms/Button';
import CheckBox from 'src/components/atoms/Checkbox';
import { Divider } from 'src/components/atoms/Divider';
import Input from 'src/components/atoms/Input';
import { PRODUCT_VOLUME_DIGITS } from 'src/constants/productDetails';
import useGetBrands from 'src/network/react-query/query/lubricants/getBrands';
import useGetCategories from 'src/network/react-query/query/lubricants/getCategories';
import { filterMenuState } from 'src/stores/menus/filterMenuState';
import useSearchAndFilter from 'src/utils/hooks/useSearchAndFilter';
import {
  FilterButton,
  FilterContainer,
  OptionGroup,
  PriceGroup,
} from './style';

// filter accordion should be expanded by default
const DEFAULT_OPEN = false;

interface ProductFilterProps {
  handleClose: () => void;
}

const ProductFilters: React.FC<ProductFilterProps> = ({
  handleClose,
}) => {
  const brandsQuery = useGetBrands();
  const categoriesQuery = useGetCategories();
  const setFilterMenu = useSetRecoilState(filterMenuState);

  const {
    searchAndFilterState: {
      brands,
      oiltype,
      minPrice,
      maxPrice,
      sizes,
    },
    clearSearchParams,
    toggleBrand,
    toggleOilType,
    toggleSize,
    updateSearchAndFilterState,
  } = useSearchAndFilter();

  return (
    <FilterContainer>
      <Accordion heading="Price" defaultOpen={DEFAULT_OPEN}>
        <PriceGroup>
          <Input
            value={minPrice}
            type="number"
            placeholder="Min.Price"
            min={0}
            onChange={e =>
              updateSearchAndFilterState({
                minPrice: e.target.value,
              })
            }
          />
          <span>-</span>
          <Input
            value={maxPrice}
            type="number"
            placeholder="Max.Price"
            min={0}
            onChange={e =>
              updateSearchAndFilterState({
                maxPrice: e.target.value,
              })
            }
          />
        </PriceGroup>
      </Accordion>

      <Divider />

      <Accordion
        heading="Brands"
        defaultOpen={DEFAULT_OPEN}
        loading={brandsQuery.isLoading}
      >
        <OptionGroup>
          {brandsQuery.data?.data.map((brand, index) => (
            <CheckBox
              checked={brands
                .split(',')
                .includes(brand.name)}
              id={brand.name + index.toString()}
              key={index.toString()}
              onChange={() => toggleBrand(brand.name)}
            >
              {brand.name}
            </CheckBox>
          ))}
        </OptionGroup>
      </Accordion>

      <Divider />

      <Accordion heading="Size" defaultOpen={DEFAULT_OPEN}>
        <OptionGroup>
          {PRODUCT_VOLUME_DIGITS.map((volume, index) => (
            <CheckBox
              checked={sizes.split(',').includes(volume)}
              id={'volume' + index}
              key={index}
              onChange={() => toggleSize(volume)}
            >
              {volume}L
            </CheckBox>
          ))}
        </OptionGroup>
      </Accordion>
      <Divider />

      <Accordion
        heading="Oil Type"
        defaultOpen={DEFAULT_OPEN}
        loading={categoriesQuery.isLoading}
      >
        <OptionGroup>
          {categoriesQuery.data?.data.map(
            (category, index) => (
              <CheckBox
                checked={oiltype
                  .split(',')
                  .includes(category.name)}
                id={category.name + index.toString()}
                key={index.toString()}
                onChange={() =>
                  toggleOilType(category.name)
                }
              >
                {category.name}
              </CheckBox>
            )
          )}
        </OptionGroup>
      </Accordion>
      <Divider />

      <FilterButton>
        <Button
          variant="secondary"
          fluid
          onClick={() => {
            clearSearchParams();
            setFilterMenu(false);
          }}
        >
          Clear
        </Button>
        <Button fluid onClick={() => handleClose()}>
          Apply
        </Button>
      </FilterButton>
    </FilterContainer>
  );
};

export default ProductFilters;
