import Skeleton from 'react-loading-skeleton';
import { Divider } from 'src/components/atoms/Divider';
import Heading from 'src/components/atoms/Heading';
import { Summary } from './style';

const OrderSummarySkeleton = () => {
  return (
    <Summary>
      <Heading as="h2" level={6}>
        Order Summary
      </Heading>

      <Divider margin="24px 0" />

      <Skeleton />
      <Skeleton width={100} />
      <Skeleton height={40} />
      <Skeleton />
      <Skeleton />
      <Skeleton />
      <Skeleton />
      <Skeleton height={40} />
    </Summary>
  );
};

export default OrderSummarySkeleton;
