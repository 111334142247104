import { toast } from 'react-hot-toast';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { ROUTES } from 'src/constants/routes';
import { axiosInstance } from 'src/network/axios';
import loginService from 'src/network/services/auth/login';
import { registrationState } from 'src/stores/auth/registrationState';
import useCurrentUser from 'src/utils/hooks/useCurrentUser';

const useLogin = () => {
  const { logUserIn } = useCurrentUser();
  const navigate = useNavigate();
  const setRegState = useSetRecoilState(registrationState);

  const loginMutation = useMutation(loginService, {
    onSuccess: res => {
      toast.dismiss();
      const data = res.data;

      if (data.businessType !== 'buyer') {
        toast.error(
          'Non-buyer account detected. Please login through the correct platform.'
        );
        return;
      }

      if (!data.isCompleteSignup) {
        setRegState(prev => ({
          ...prev,
          completeSignupAccess: true,
          phoneNumber: data.phoneNumber,
          userId: data._id,
        }));
        axiosInstance.defaults.headers.common.Authorization = `Bearer ${data.token}`;
        navigate(ROUTES.completeSignup);
        return;
      }

      logUserIn({
        userId: data._id,
        userToken: data.token,
        phoneNumber: data.phoneNumber,
        firstName: data.firstName,
        lastName: data.lastName,
      });
    },
  });

  const {
    mutate: login,
    isLoading: isLoginIn,
    data,
  } = loginMutation;

  return { login, isLoginIn, data };
};

export default useLogin;
