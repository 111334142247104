export const BASE_URL = process.env.REACT_APP_API_BASE_URL;

function getApiRoute(path: string) {
  return BASE_URL + '/api/v1' + path;
}

export const API_ROUTES = {
  //authentication
  signUp: getApiRoute('/sign-up'),
  completeSignUp: getApiRoute('/buyer-sign-up'),
  verifyPhone: getApiRoute('/verify-otp'),
  signIn: getApiRoute('/auth/sign-in'),
  resetPasswordOtp: getApiRoute('/auth/forgot-password'),
  verifyPasswordOtp: getApiRoute(
    '/auth/forgot-password/verify-otp'
  ),
  updatePassword: getApiRoute('/auth/reset-password'),
  getTradeType: getApiRoute('/trade-types'),
  resendOtp: getApiRoute('/resend-otp'),
  changeCurrentPassword: getApiRoute('/update-password'),

  //lubricants
  getBrandProducts: getApiRoute('/get-brand-products'),
  getBrands: getApiRoute('/get-brands'),
  getLubricants: getApiRoute('/get-lubricants'),
  getLubricant: getApiRoute('/get-lubricant'),
  getCategories: getApiRoute('/get-categories'),
  addLubricant: getApiRoute('/add-lubricants'),
  addProductPrice: getApiRoute('/add-price'),
  addBrand: getApiRoute('/add-brand'),
  addBrandProduct: getApiRoute('/add-brand-products'),
  getTopRankingLubricants: getApiRoute(
    '/get-top-ranked-lubricants'
  ),

  //favourites
  addFavourite: getApiRoute('/add-favorite'),
  getFavourites: getApiRoute('/get-favorite'),
  deleteFavourite: getApiRoute('/delete-favorite'),

  //cart
  addCart: getApiRoute('/add-cart'),
  getCart: getApiRoute('/cart'),
  updateCart: getApiRoute('/update-cart'),
  deleteCart: getApiRoute('/delete-cart'),

  //address
  getAddress: getApiRoute('/user-address'),
  addAddress: getApiRoute('/create-address'),
  updateAddress: getApiRoute('/update-address'),
  deleteAddress: getApiRoute('/delete-address'),
  allAddress: getApiRoute('/all-address'),

  //profile
  getProfile: getApiRoute('/user-details'),
  updateEmail: getApiRoute('/update-email'),

  //orders
  createOrder: getApiRoute('/orders'),
  getOrders: getApiRoute('/orders'),
  getDeliveryFee: getApiRoute('/orders/fee'),
  cancelOrder: getApiRoute('/orders'),

  //search and filters
  filter: getApiRoute('/get-search-filter-products'),
  search: getApiRoute('/get-searched-products'),
  getLubricantsByCategory: getApiRoute(
    '/get-groupby-brand-filter'
  ),

  //rating and review
  rating: getApiRoute('/set-product-rating'),

  //featured products
  engineOilProducts: getApiRoute('/get-engine-oil-type'),
  atpOilProducts: getApiRoute('/get-atp-oil-type'),
  petrolOilProducts: getApiRoute('/get-petrol-oil-type'),
  dieselOilProducts: getApiRoute('/get-diesel-oil-type'),
  gearOilProducts: getApiRoute('/get-gear-oil-type'),
} as const;
