import React, { forwardRef, useState } from 'react';
import { Divider } from '../Divider';
import {
  Container,
  CountryCodeDropdown,
  InputMessage,
  PhoneNumberInput,
  Wrapper,
} from './style';

interface PhoneInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  bg?: 'gray' | 'transparent';
  id?: string;
  label?: string;
  message?: string;
  error?: boolean;
  onCountryCodeChange?: (value: string) => void;
}

const PhoneInput = forwardRef<
  HTMLInputElement,
  PhoneInputProps
>(
  (
    {
      bg = 'gray',
      id,
      label,
      message,
      error = false,
      onCountryCodeChange,
      ...rest
    },
    ref
  ) => {
    const [countryCode, setCountryCode] = useState('+234');

    const handleCountryCodeChange = (
      e: React.ChangeEvent<HTMLSelectElement>
    ) => {
      setCountryCode(e.target.value);
      onCountryCodeChange &&
        onCountryCodeChange(e.target.value);
    };

    return (
      <Wrapper>
        <label htmlFor={id}>{label}</label>

        <Container data-bg={bg} data-error={error}>
          <CountryCodeDropdown
            value={countryCode}
            onChange={handleCountryCodeChange}
          >
            <option value="+234">+234</option>

            {/* Add more country codes as needed */}
          </CountryCodeDropdown>

          <Divider orientation="vertical" />

          <PhoneNumberInput
            ref={ref}
            {...rest}
            type="number"
            maxLength={10}
            minLength={10}
            id={id}
          />
        </Container>

        {message ? (
          <InputMessage>{message}</InputMessage>
        ) : (
          false
        )}
      </Wrapper>
    );
  }
);

export default PhoneInput;
