import { styled } from 'styled-components';

export const ModalOverlay = styled.div`
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
`;

export const ModalContentWrapper = styled.div`
  position: relative;
  width: min(90%, 500px);

  animation: fadeIn 200ms linear forwards;

  @keyframes fadeIn {
    from {
      scale: 0.5;
      opacity: 0;
    }

    to {
      scale: 1;
      opacity: 1;
    }
  }
`;

export const ModalCloseButton = styled.button`
  font-size: 1.5rem;
  position: absolute;
  z-index: 5;
  top: 32px;
  right: 32px;
  background: none;
  border: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${p => p.theme.colors.black};
  background-color: ${p => p.theme.colors.white};
`;

export const ModalContent = styled.div`
  background-color: ${p => p.theme.colors.white};
  padding: 64px 32px 32px 32px;
  border-radius: 4px;
  overflow-y: auto;

  max-height: calc(100vh - 30px);
`;
