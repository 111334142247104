import { styled } from 'styled-components';
import { PasswordValidateProps } from './useValidatePassword';
import XIcon from '../icons/XIcon';
import CheckIcon from '../icons/CheckIcon';
import { forwardRef } from 'react';

interface PasswordPopopProps {
  passwordStrength: PasswordValidateProps;
}

const PasswordPopop = forwardRef<
  HTMLDivElement,
  PasswordPopopProps
>(
  (
    {
      passwordStrength: {
        hasLowercase,
        hasSpecialCharacter,
        hasUppercase,
        matchesLength,
      },
    },
    ref
  ) => {
    return (
      <Wrapper ref={ref}>
        <HeadText>Password must:</HeadText>

        <Text data-active={hasUppercase}>
          {hasUppercase ? <CheckIcon /> : <XIcon />}
          Have one uppercase letter
        </Text>
        <Text data-active={hasLowercase}>
          {hasLowercase ? <CheckIcon /> : <XIcon />}
          Have one lowercase letter
        </Text>
        <Text data-active={hasSpecialCharacter}>
          {hasSpecialCharacter ? <CheckIcon /> : <XIcon />}
          Have one special character
        </Text>
        <Text data-active={matchesLength}>
          {matchesLength ? <CheckIcon /> : <XIcon />}
          Have 8 characters minimum
        </Text>
      </Wrapper>
    );
  }
);

export default PasswordPopop;

const Wrapper = styled.div`
  display: grid;
  gap: 12px;
  position: absolute;
  bottom: calc(100% + 10px);
  right: 0;
  z-index: 777;
  width: 250px;
  padding: 1rem;
  background-color: ${p => p.theme.colors.white};
  border: 1px solid ${p => p.theme.colors.neutral[100]};
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
`;

const HeadText = styled.h4`
  font-weight: 600;
  line-height: 19px;
`;

const Text = styled.p`
  color: ${p => p.theme.colors.grey[300]};
  font-size: 14px;
  line-height: 17px;
  display: grid;
  grid-template-columns: 1rem 1fr;
  align-items: center;
  gap: 8px;

  svg {
    font-size: 0.875rem;
    color: ${p => p.theme.colors.danger[600]};
  }

  &[data-active='true'] {
    color: ${p => p.theme.colors.success[600]};

    svg {
      color: ${p => p.theme.colors.success[600]};
    }
  }
`;
