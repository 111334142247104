import PageLayout from 'src/components/layouts/PageLayout';
import {
  ContactContainer,
  ContactDetail,
  ContactDetailsSection,
  ContactDetailsTextGroup,
  ContactDetailText,
  ContactFormSection,
  ContactHeadingGroup,
  ContactSocialLink,
  ContactSocialLinks,
  ContactWrapper,
} from './style';

import EnvelopeIcon from 'src/components/atoms/icons/EnvelopeIcon';
import MessageIcon from 'src/components/atoms/icons/MessageIcon';
import { CONTACT_DETAILS } from 'src/constants/contactDetails';
import { SOCIAL_MEDIA_LINKS } from 'src/constants/socialMedia';

const ContactPage = () => {
  return (
    <PageLayout>
      <ContactWrapper>
        <ContactContainer>
          <ContactFormSection>
            <ContactHeadingGroup>
              <h2>Get in touch</h2>
              <p>
                We&#39;d love to hear from you. A member of
                our team will reach out to you within a
                short time.
              </p>
            </ContactHeadingGroup>
          </ContactFormSection>

          <ContactDetailsSection>
            <ContactDetailsTextGroup>
              <ContactDetail>
                <span>
                  <EnvelopeIcon />
                </span>
                <ContactDetailText>
                  <h3>Send us an email</h3>
                  <p>Our team is ready to help you out</p>
                  <a
                    target="_blank"
                    rel="noreferrer noopener"
                    href={CONTACT_DETAILS.emailAddress.href}
                  >
                    {CONTACT_DETAILS.emailAddress.text}
                  </a>
                </ContactDetailText>
              </ContactDetail>

              <ContactDetail>
                <span>
                  <MessageIcon />
                </span>
                <ContactDetailText>
                  <h3>Call us</h3>
                  <p>Mon-Fri from 9am - 5pm</p>
                  <a
                    target="_blank"
                    rel="noreferrer noopener"
                    href={CONTACT_DETAILS.phoneNumber.href}
                  >
                    {CONTACT_DETAILS.phoneNumber.text}
                  </a>
                </ContactDetailText>
              </ContactDetail>
            </ContactDetailsTextGroup>

            <ContactSocialLinks>
              {SOCIAL_MEDIA_LINKS.map(link => (
                <ContactSocialLink
                  key={link.name}
                  href={link.href}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {link.icon}
                </ContactSocialLink>
              ))}
            </ContactSocialLinks>
          </ContactDetailsSection>
        </ContactContainer>
      </ContactWrapper>
    </PageLayout>
  );
};

export default ContactPage;
