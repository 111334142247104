import Button from 'src/components/atoms/Button';
import { Card } from 'src/pages/account/Profile/style';
import { styled } from 'styled-components';

export const HeadSection = styled(Card)`
  @media (min-width: 1024px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const HeadingItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const OrderTitleGroup = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  flex-wrap: wrap;

  h3 {
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 21.78px;

    span {
      font-weight: 400;
    }
  }
`;

export const OrderStatusTag = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 14px;
  border-radius: 4px;

  &[data-status='Pending'] {
    background-color: ${p => p.theme.colors.warning[50]};
    color: ${p => p.theme.colors.warning[500]};
  }

  &[data-status='Order delivered'] {
    background-color: ${p => p.theme.colors.success[50]};
    color: ${p => p.theme.colors.success[600]};
  }

  &[data-status='Canceled'] {
    background-color: ${p => p.theme.colors.danger[50]};
    color: ${p => p.theme.colors.danger[600]};
  }

  svg {
    font-size: 0.5rem;
  }
`;

export const TimeStamp = styled.p`
  color: ${p => p.theme.colors.grey[500]};
  margin-block: 10px;
`;

export const OrderTotalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media (min-width: 768px) {
    flex-direction: row;
    gap: 2rem;
    align-items: center;
  }
`;

export const OrderTotal = styled.p`
  font-weight: 600;
  font-size: 1.025rem;
`;

export const PaymentType = styled.p<{
  $hasPaid: boolean;
}>`
  padding: 4px 10px;
  background-color: ${p =>
    p.$hasPaid
      ? p.theme.colors.success[100]
      : p.theme.colors.warning[50]};
  color: ${p =>
    p.$hasPaid
      ? p.theme.colors.success[500]
      : p.theme.colors.warning[500]};
  border-radius: 4px;
  display: grid;
  align-items: center;
  gap: 8px;
  grid-template-columns: max-content max-content;
  width: max-content;

  svg {
    width: 12px;
    height: 12px;
  }
`;

export const CancelButton = styled(Button)`
  span {
    color: ${p => p.theme.colors.danger[500]};
  }

  @media (min-width: 1024px) {
    align-self: last baseline;
  }
`;

export const StatusCardButton = styled(Button)`
  @media (min-width: 1024px) {
    align-self: last baseline;
  }
`;
