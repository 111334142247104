import { styled } from 'styled-components';

export const StyledButton = styled.button`
  font-style: normal;
  padding: 10px 32px;
  border: none;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  position: relative;
  cursor: pointer;
  transition: 250ms;
  font-weight: 600;

  &:hover {
    opacity: 0.75;
  }
  &:active {
    transform: scale(0.96);
  }

  &[data-fluid='true'] {
    width: 100%;
  }

  &[data-loading='true'] {
    opacity: 0.5;
  }

  &[data-variant='ghost'] {
    padding: 0;
    justify-content: start;

    &:hover {
      transform: none;
    }
  }
  &[data-variant='primary'] {
    background-color: ${p => p.theme.colors.primary[500]};
    color: ${p => p.theme.colors.white};

    &:disabled {
      pointer-events: none;
      background-color: ${p => p.theme.colors.neutral[400]};
      cursor: not-allowed;
    }
  }
  &[data-variant='light'] {
    background-color: ${p => p.theme.colors.white};
    color: ${p => p.theme.colors.primary[500]};

    &:disabled {
      pointer-events: none;
      background-color: ${p => p.theme.colors.neutral[400]};
    }
  }

  &[data-variant='secondary'] {
    background-color: ${p => p.theme.colors.secondary};
    color: ${p => p.theme.colors.primary[500]};

    &:disabled {
      pointer-events: none;
      background-color: ${p => p.theme.colors.neutral[400]};
      color: ${p => p.theme.colors.white};
    }
  }

  &[data-variant='outlined'] {
    background-color: transparent;
    color: ${p => p.theme.colors.primary[500]};
    border: 1px solid ${p => p.theme.colors.primary[500]};

    &:disabled {
      pointer-events: none;
      background-color: ${p => p.theme.colors.neutral[400]};
    }
  }

  svg {
    font-size: 1.5rem;
  }

  @media (max-width: 767px) {
    padding: 12px 24px;
  }
`;

export const Spinner = styled.span`
  display: inline-block;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  animation: rotate 500ms linear infinite;

  position: absolute;
  inset: 0;
  margin: auto;

  &[data-variant='ghost'],
  &[data-variant='outlined'],
  &[data-variant='secondary'] {
    border: 2px solid ${p => p.theme.colors.primary[500]};
    border-top-color: transparent;
    border-bottom-color: transparent;
  }

  &[data-variant='primary'] {
    border: 2px solid ${p => p.theme.colors.white};
    border-top-color: transparent;
    border-bottom-color: transparent;
  }

  @keyframes rotate {
    to {
      transform: rotate(360deg);
    }
  }
`;
