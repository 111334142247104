import styled from 'styled-components';

const ConfirmDialog: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  return (
    <ModalWrapper id="modal">
      <Dialog>{children}</Dialog>
    </ModalWrapper>
  );
};

export default ConfirmDialog;

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

const Dialog = styled.div`
  background: white;
  margin: 20px;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
`;
