import { toast } from 'react-hot-toast';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { ROUTES } from 'src/constants/routes';
import resetPasswordOtpService from 'src/network/services/auth/resetPasswordOtp';
import { resetPasswordState } from 'src/stores/auth/resetPasswordState';

const useResetPasswordOtp = () => {
  const navigate = useNavigate();
  const setResetPasswordState = useSetRecoilState(
    resetPasswordState
  );

  const resetPasswordOtpMutation = useMutation(
    resetPasswordOtpService,
    {
      onSuccess: res => {
        toast.dismiss();
        toast.success('Otp Sent');
        setResetPasswordState(prev => ({
          ...prev,
          userId: res.data.userId,
          passwordOtpAccess: true,
        }));
        navigate(ROUTES.verifyPasswordOtp);
      },
    }
  );

  const {
    mutate: resetPasswordOtp,
    isLoading: isSendingOtp,
    data,
  } = resetPasswordOtpMutation;

  return { resetPasswordOtp, isSendingOtp, data };
};

export default useResetPasswordOtp;
