import { IconProps } from './icon';

const CartIcon: React.FC<IconProps> = props => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 2C2.44772 2 2 2.44772 2 3C2 3.55228 2.44772 4 3 4V3L3.00001 4H3.00005H3.00021H3.00033H3.00048H3.00065H3.00085H3.0019H3.00337H3.00426H3.00474H3.00525H3.00754H3.01022H3.01096H3.01171H3.01331H3.05176H3.19531H3.6875H4.19158L7.0222 17.2095C7.121 17.6706 7.52846 18 8 18H10C8.89543 18 8 18.8954 8 20C8 21.1046 8.89543 22 10 22C11.1046 22 12 21.1046 12 20C12 18.8954 11.1046 18 10 18H16C14.8954 18 14 18.8954 14 20C14 21.1046 14.8954 22 16 22C17.1046 22 18 21.1046 18 20C18 18.8954 17.1046 18 16 18H18.5C19.0523 18 19.5 17.5523 19.5 17C19.5 16.4477 19.0523 16 18.5 16H8.80842L8.59413 15H18C18.4168 15 18.79 14.7414 18.9363 14.3511L21.9363 6.35112C22.0515 6.04403 22.0087 5.69998 21.8219 5.43041C21.6351 5.16083 21.328 5 21 5H6.45127L5.9778 2.79047C5.879 2.3294 5.47154 2 5 2H3.6875H3.19531H3.05176H3.04565H3.03991H3.03853H3.03717H3.03453H3.02953H3.02065H3.01964H3.01867H3.01771H3.01678H3.01588H3.01499H3.01331H3.00754H3.00525H3.00474H3.00426H3.00337H3.00085H3.00048H3.00033H3.00021H3.00005H3.00001L3 3V2ZM6.87984 7L8.16556 13H17.307L19.557 7H6.87984Z"
      fill="currentColor"
    />
  </svg>
);

export default CartIcon;
