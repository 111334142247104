import { IconProps } from './icon';

const CompletedIcon: React.FC<IconProps> = props => (
  <svg
    {...props}
    width="140"
    height="140"
    viewBox="0 0 140 140"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M93.1555 50.9795L97.7711 55.5951L61.2508 92.1045L42.2305 73.0951L46.8461 68.4795L60.4633 82.0967C60.5653 82.2025 60.6876 82.2867 60.8229 82.3442C60.9582 82.4018 61.1038 82.4314 61.2508 82.4314C61.3978 82.4314 61.5433 82.4018 61.6786 82.3442C61.8139 82.2867 61.9363 82.2025 62.0383 82.0967L93.1555 50.9795Z"
      fill="#FEF6F8"
      stroke="#370617"
      strokeWidth="2"
    />
    <path
      d="M70 20.7813C60.2655 20.7812 50.7495 23.6679 42.6556 29.0761C34.5616 34.4843 28.2531 42.1713 24.5278 51.1648C20.8026 60.1584 19.8279 70.0546 21.727 79.6021C23.6261 89.1496 28.3137 97.9195 35.1971 104.803C42.0805 111.686 50.8504 116.374 60.3979 118.273C69.9454 120.172 79.8417 119.197 88.8352 115.472C97.8288 111.747 105.516 105.438 110.924 97.3445C116.332 89.2505 119.219 79.7346 119.219 70C119.219 63.5365 117.946 57.1363 115.472 51.1648C112.999 45.1933 109.373 39.7675 104.803 35.1971C100.233 30.6267 94.8067 27.0013 88.8352 24.5278C82.8637 22.0543 76.4635 20.7813 70 20.7813ZM100.133 56.3828C59.4891 97.0266 61.961 94.8063 61.25 94.8063C60.5391 94.8063 62.3438 96.3047 39.8672 73.8828C39.7614 73.7808 39.6772 73.6585 39.6196 73.5232C39.5621 73.3878 39.5325 73.2423 39.5325 73.0953C39.5325 72.9483 39.5621 72.8028 39.6196 72.6675C39.6772 72.5322 39.7614 72.4098 39.8672 72.3078L46.0578 66.1172C46.2709 65.9165 46.5526 65.8047 46.8453 65.8047C47.1381 65.8047 47.4198 65.9165 47.6328 66.1172L61.25 79.7344L92.3672 48.6172C92.5803 48.4165 92.862 48.3047 93.1547 48.3047C93.4474 48.3047 93.7291 48.4165 93.9422 48.6172L100.133 54.8078C100.239 54.9098 100.323 55.0322 100.38 55.1675C100.438 55.3028 100.468 55.4483 100.468 55.5953C100.468 55.7423 100.438 55.8878 100.38 56.0232C100.323 56.1585 100.239 56.2808 100.133 56.3828Z"
      fill="#FEF6F8"
      stroke="#370617"
      strokeWidth="2"
    />
    <path
      d="M138.906 70C138.906 64.05 133.284 58.9203 131.819 53.4297C130.298 47.7531 132.562 40.5125 129.631 35.5359C126.7 30.5594 119.284 28.8531 115.194 24.7516C111.103 20.65 109.452 13.2344 104.409 10.3141C99.3672 7.39375 92.2469 9.70156 86.5703 8.18125C81.0797 6.71562 75.95 1.09375 70 1.09375C64.05 1.09375 58.9203 6.71562 53.4297 8.18125C47.7531 9.70156 40.4688 7.4375 35.5359 10.3141C30.6031 13.1906 28.8531 20.6609 24.7516 24.7516C20.65 28.8422 13.2344 30.4938 10.3141 35.5359C7.39375 40.5781 9.70156 47.7531 8.18125 53.4297C6.71562 58.9203 1.09375 64.05 1.09375 70C1.09375 75.95 6.71562 81.0797 8.18125 86.5703C9.70156 92.2469 7.4375 99.5312 10.3141 104.464C13.1906 109.397 20.6609 111.147 24.7516 115.248C28.8422 119.35 30.4938 126.766 35.5359 129.686C40.5781 132.606 47.7531 130.298 53.4297 131.873C58.8984 133.339 64.05 138.961 70 138.961C75.95 138.961 81.0797 133.339 86.5703 131.873C92.2469 130.353 99.4875 132.617 104.464 129.686C109.441 126.755 111.147 119.339 115.248 115.248C119.35 111.158 126.766 109.506 129.686 104.464C132.606 99.4219 130.298 92.2469 131.873 86.5703C133.284 81.0797 138.906 75.95 138.906 70ZM70 121.406C59.8328 121.406 49.8939 118.391 41.4402 112.743C32.9865 107.094 26.3976 99.0656 22.5068 89.6723C18.616 80.2791 17.598 69.943 19.5815 59.9711C21.565 49.9993 26.461 40.8396 33.6503 33.6503C40.8396 26.461 49.9993 21.565 59.9711 19.5815C69.943 17.598 80.2791 18.616 89.6723 22.5068C99.0656 26.3976 107.094 32.9865 112.743 41.4402C118.391 49.8939 121.406 59.8328 121.406 70C121.386 83.6276 115.963 96.6912 106.327 106.327C96.6912 115.963 83.6276 121.386 70 121.406Z"
      fill="#FEF6F8"
      stroke="#370617"
      strokeWidth="2"
    />
  </svg>
);

export default CompletedIcon;
