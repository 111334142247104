import { useEffect, useRef, useState } from 'react';
import Button from 'src/components/atoms/Button';
import SearchIcon from 'src/components/atoms/icons/SearchIcon';
import XIcon from 'src/components/atoms/icons/XIcon';
import Input from 'src/components/atoms/Input';
import useClickOutside from 'src/utils/hooks/useClickOutside';
import useResponsive from 'src/utils/hooks/useResponsive';
import useSearchAndFilter from 'src/utils/hooks/useSearchAndFilter';
import {
  FixedWrapper,
  Search,
  SearchbarWrapper,
  SearchButton,
  SearchContainer,
} from './style';

const SearchBar = () => {
  const searchRef = useRef<HTMLDivElement>(null);
  const { windowSize } = useResponsive();
  const [isSearchOpen, setSearchOpen] = useState(false);

  const {
    searchAndFilterState,
    updateSearchAndFilterState,
  } = useSearchAndFilter();

  const [searchTerm, setSearchTerm] = useState(
    searchAndFilterState.search
  );

  useEffect(() => {
    setSearchTerm(searchAndFilterState.search);
  }, [searchAndFilterState.search]);

  //display search component on larger screens
  useEffect(() => {
    if (windowSize >= 1024) {
      setSearchOpen(true);
    } else {
      setSearchOpen(false);
    }
  }, [windowSize]);

  //clickoutside should only work on small screens
  useClickOutside(searchRef, () => {
    if (windowSize < 1024) {
      setSearchOpen(false);
    }
  });

  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
    if (!searchTerm.trim()) return;
    updateSearchAndFilterState({ search: searchTerm });
  };

  return (
    <SearchbarWrapper>
      <SearchButton
        title="open search"
        onClick={() => setSearchOpen(true)}
      >
        <SearchIcon />
      </SearchButton>

      {isSearchOpen ? (
        <FixedWrapper ref={searchRef}>
          <SearchContainer>
            <SearchButton
              title="close search"
              onClick={() => setSearchOpen(false)}
            >
              <XIcon />
            </SearchButton>

            <Search>
              <Input
                type="search"
                placeholder="Search brands or products"
                value={searchTerm}
                onChange={e =>
                  setSearchTerm(e.target.value)
                }
              />
              {windowSize < 768 ? (
                <SearchButton
                  type="submit"
                  title="Search"
                  data-main
                  onClick={handleSearch}
                >
                  <SearchIcon />
                </SearchButton>
              ) : (
                <Button
                  type="submit"
                  title="Search"
                  onClick={handleSearch}
                >
                  Search
                </Button>
              )}
            </Search>
          </SearchContainer>
        </FixedWrapper>
      ) : (
        false
      )}
    </SearchbarWrapper>
  );
};

export default SearchBar;
