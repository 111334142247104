import { Navigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import Heading from 'src/components/atoms/Heading';
import Text from 'src/components/atoms/Text';
import AuthLayout from 'src/components/layouts/AuthLayout';
import CreatePasswordForm from 'src/components/molecules/CreatePasswordForm';
import { resetPasswordState } from 'src/stores/auth/resetPasswordState';
import { styled } from 'styled-components';

const NewPasswordPage = () => {
  const { newPasswordAccess } = useRecoilValue(
    resetPasswordState
  );

  if (!newPasswordAccess) {
    return <Navigate to="/" />;
  }

  return (
    <AuthLayout>
      <Group>
        <Heading as="h1" level={4}>
          Choose new password
        </Heading>
        <Text size="lg" shade={400}>
          Please create a secure password different from the
          previously used password.
        </Text>
      </Group>

      <CreatePasswordForm />
    </AuthLayout>
  );
};

export default NewPasswordPage;

const Group = styled.div`
  margin-bottom: 2rem;
`;
