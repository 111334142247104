import { Card } from 'src/pages/Checkout/style';
import { styled } from 'styled-components';

export const Summary = styled(Card)`
  h2 {
    margin: 0;
  }
`;

export const OrderInfo = styled.ul`
  margin-block: 32px;
  display: grid;
  gap: 16px;

  li {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;

    h4,
    p {
      font-weight: 500;
    }

    &[data-total='true'] {
      h4,
      p {
        font-size: 18px;
      }

      p {
        font-weight: 700;
      }
    }
  }
`;
