import { Container } from 'src/components/atoms/Container';
import { styled } from 'styled-components';

export const ProfileContent = styled(Container)`
  margin-block: 48px;
  display: grid;
  gap: 24px;

  @media (min-width: 768px) {
    width: 100%;
  }

  h1 {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
  }
`;

export const Card = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px 24px;
  gap: 24px;
  background: ${p => p.theme.colors.white};
  border: 1px solid #f5f6f8;
  box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.03);
  border-radius: 4px;

  h2 {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
  }

  @media (min-width: 768px) {
    button {
      justify-self: right;
    }
  }
`;

export const FieldGroup = styled.div`
  display: grid;
  gap: 24px;

  @media (min-width: 580px) {
    display: flex;
    align-items: start;
  }
`;
