import React, { useState, useRef } from 'react';
import MinusBar from '../icons/MinusBar';
import {
  OtpInputContainer,
  OtpInputField,
  OtpMessage,
} from './style';

//otp length
const LENGHT = 6;

interface OtpInputProps {
  // length: number;
  onChange: (otp: string) => void;
  error?: boolean;
  message?: React.ReactNode;
}

const OtpInput: React.FC<OtpInputProps> = ({
  onChange,
  error = false,
  message,
}) => {
  const [otp, setOtp] = useState<string[]>(
    Array(LENGHT).fill('')
  );

  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const newOtp = [...otp];
    newOtp[index] = e.target.value;

    setOtp(newOtp);
    onChange(newOtp.join(''));

    // Focus on next input field
    if (
      e.target.value !== '' &&
      index < LENGHT - 1 &&
      inputRefs.current[index + 1]
    ) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    if (
      e.key === 'Backspace' &&
      e.currentTarget.value === '' &&
      index > 0
    ) {
      inputRefs.current[index - 1]?.focus();
    }
  };

  return (
    <>
      <OtpInputContainer>
        <OtpInputField
          data-error={error}
          key="0"
          type="text"
          maxLength={1}
          value={otp[0]}
          onChange={e => handleChange(e, 0)}
          onKeyDown={e => handleKeyDown(e, 0)}
          ref={ref => (inputRefs.current[0] = ref)}
        />
        <OtpInputField
          data-error={error}
          key="1"
          type="text"
          maxLength={1}
          value={otp[1]}
          onChange={e => handleChange(e, 1)}
          onKeyDown={e => handleKeyDown(e, 1)}
          ref={ref => (inputRefs.current[1] = ref)}
        />
        <OtpInputField
          data-error={error}
          key="2"
          type="text"
          maxLength={1}
          value={otp[2]}
          onChange={e => handleChange(e, 2)}
          onKeyDown={e => handleKeyDown(e, 2)}
          ref={ref => (inputRefs.current[2] = ref)}
        />

        <MinusBar />

        <OtpInputField
          data-error={error}
          key="3"
          type="text"
          maxLength={1}
          value={otp[3]}
          onChange={e => handleChange(e, 3)}
          onKeyDown={e => handleKeyDown(e, 3)}
          ref={ref => (inputRefs.current[3] = ref)}
        />
        <OtpInputField
          data-error={error}
          key="4"
          type="text"
          maxLength={1}
          value={otp[4]}
          onChange={e => handleChange(e, 4)}
          onKeyDown={e => handleKeyDown(e, 4)}
          ref={ref => (inputRefs.current[4] = ref)}
        />
        <OtpInputField
          data-error={error}
          key="5"
          type="text"
          maxLength={1}
          value={otp[5]}
          onChange={e => handleChange(e, 5)}
          onKeyDown={e => handleKeyDown(e, 5)}
          ref={ref => (inputRefs.current[5] = ref)}
        />
      </OtpInputContainer>
      {message ? <OtpMessage>{message}</OtpMessage> : null}
    </>
  );
};

export default OtpInput;
