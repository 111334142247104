import axios from 'axios';
import { ApiResponse } from 'src/network/apiResponse';
import { API_ROUTES } from 'src/network/apiRoutes';
import handleServiceError from 'src/utils/helpers/handleServiceError';

interface Payload {
  _id: string;
  newPassword: string;
  confirmNewPassword: string;
}

const updatePassowordService = async (payload: Payload) => {
  try {
    const response = await axios.post<ApiResponse<string>>(
      API_ROUTES.updatePassword,
      payload
    );

    return response.data;
  } catch (error) {
    throw handleServiceError(error);
  }
};

export default updatePassowordService;
