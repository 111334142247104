import { styled } from 'styled-components';

export const OtpInputContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  align-items: center;

  svg {
    color: #828282;
  }
`;

export const OtpInputField = styled.input`
  width: 35px;
  height: 35px;
  text-align: center;
  font-size: 18px;
  border: 1px solid #cccccc;
  color: #666666;
  border-radius: 8px;
  outline: none;

  &[data-error='true'] {
    border-color: ${p => p.theme.colors.danger[500]};
  }

  @media (min-width: 580px) {
    width: 50px;
    height: 50px;
  }

  @media (min-width: 768px) {
    width: 60px;
    height: 60px;
    font-size: 20px;
  }
`;

export const OtpMessage = styled.p`
  color: ${p => p.theme.colors.danger[500]};
`;
