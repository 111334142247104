import { useRecoilState } from 'recoil';
import { Spinner } from 'src/components/atoms/Button/style';
import AccountLayout from 'src/components/layouts/AccountLayout';
import OrderDetailsCard from 'src/components/molecules/OrderDetailsCard';
import {
  useGetCanceledOrders,
  useGetCompletedOrders,
  useGetPendingOrders,
} from 'src/network/react-query/query/orders/getOrders';
import { OrderHistoryTabStore } from 'src/stores/menus/orderHistoryTabState';
import useCurrentUser from 'src/utils/hooks/useCurrentUser';
import {
  Heading,
  LoadingWrapper,
  TabButton,
  TabHeader,
  Wrapper,
} from './style';

const OrderHistoryPage = () => {
  const [activeTab, setActiveTab] = useRecoilState(
    OrderHistoryTabStore
  );
  const { user } = useCurrentUser();
  if (!user) return null;

  return (
    <AccountLayout>
      <Wrapper>
        <Heading>Order History</Heading>

        <TabHeader>
          <TabButton
            data-active={activeTab === 'ongoing'}
            onClick={() => setActiveTab('ongoing')}
          >
            Ongoing <span>Orders</span>
          </TabButton>
          <TabButton
            data-active={activeTab === 'completed'}
            onClick={() => setActiveTab('completed')}
          >
            Completed <span>Orders</span>
          </TabButton>
          <TabButton
            data-active={activeTab === 'canceled'}
            onClick={() => setActiveTab('canceled')}
          >
            Cancelled <span>Orders</span>
          </TabButton>
        </TabHeader>

        {activeTab === 'ongoing' ? (
          <OngoingOrders />
        ) : activeTab === 'completed' ? (
          <CompletedOrders />
        ) : activeTab === 'canceled' ? (
          <CanceledOrders />
        ) : (
          false
        )}
      </Wrapper>
    </AccountLayout>
  );
};

export default OrderHistoryPage;

const OngoingOrders = () => {
  const { data, isLoading } = useGetPendingOrders();

  if (isLoading)
    return (
      <LoadingWrapper>
        <Spinner data-variant="secondary" />
      </LoadingWrapper>
    );

  if (data) {
    if (!data.length)
      return <p>You have no pending orders</p>;

    return (
      <>
        {data.map((order, i) => (
          <OrderDetailsCard
            action="track"
            key={i}
            data={order}
          />
        ))}
      </>
    );
  }

  return <p>Orders not available</p>;
};

const CanceledOrders = () => {
  const { data, isLoading } = useGetCanceledOrders();

  if (isLoading)
    return (
      <LoadingWrapper>
        <Spinner data-variant="secondary" />
      </LoadingWrapper>
    );

  if (data) {
    if (!data.length)
      return <p>You have no canceled orders</p>;

    return (
      <>
        {data.map((order, i) => (
          <OrderDetailsCard
            action="track"
            key={i}
            data={order}
          />
        ))}
      </>
    );
  }

  return <p>Orders not available</p>;
};

const CompletedOrders = () => {
  const { data, isLoading } = useGetCompletedOrders();

  if (isLoading)
    return (
      <LoadingWrapper>
        <Spinner data-variant="secondary" />
      </LoadingWrapper>
    );

  if (data) {
    if (!data.length)
      return <p>You have no completed orders</p>;

    return (
      <>
        {data.map((order, i) => (
          <OrderDetailsCard
            action="track"
            key={i}
            data={order}
          />
        ))}
      </>
    );
  }

  return <p>Orders not available</p>;
};
