import { Link } from 'react-router-dom';
import CartIcon from 'src/components/atoms/icons/CartIcon';
import { ROUTES } from 'src/constants/routes';
import useGetCart from 'src/network/react-query/query/cart/getCart';
import { styled } from 'styled-components';

const CartButton = () => {
  const { data } = useGetCart();

  const items = data?.items.length || 0;

  return (
    <Link to={ROUTES.cart}>
      <LinkContent>
        <CartIconGroup>
          <CartIcon />
          {items > 0 ? (
            <CartTag>{items > 99 ? 99 : items}</CartTag>
          ) : (
            false
          )}
        </CartIconGroup>

        <CartText>Cart</CartText>
      </LinkContent>
    </Link>
  );
};

export default CartButton;

const LinkContent = styled.span`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const CartIconGroup = styled.div`
  position: relative;

  svg {
    color: ${p => p.theme.colors.primary[500]};
    font-size: 1.625rem;
    transition: 250ms;
  }
`;

const CartTag = styled.div`
  position: absolute;
  top: -8px;
  right: -8px;
  font-size: 9px;
  background-color: ${p => p.theme.colors.danger[700]};
  color: #ffffff;
  width: 18px;
  height: 18px;
  border-radius: 100%;
  display: grid;
  place-items: center;
`;

const CartText = styled.span`
  display: none;

  @media (min-width: 768px) {
    display: contents;
  }
`;
