import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
/* clears the ‘X’ from Internet Explorer */
input[type=search]::-ms-clear { display: none; width : 0; height: 0; }
input[type=search]::-ms-reveal { display: none; width : 0; height: 0; }
/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }

body:has(#modal) {
  overflow: hidden;
}

//reset styles by tailwind preflight
*,
::before,
::after {
  box-sizing: border-box; 
  border-width: 0; 
  border-style: solid;
  
}

:focus-visible {
    outline: none;
    box-shadow: 0 0 0 4px rgba(0, 0, 255, 0.159);
}

html {
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  -moz-tab-size: 4; 
  tab-size: 4;
}

body {
  font-family: Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  margin: 0;
 color: #1F1F1F;
 font-size: 14px;
}

@media (min-width: 768px) {
  font-size: 16px;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; 
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit; 
  color: inherit; 
  margin: 0; 
  padding: 0; 
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

button,
select {
  text-transform: none;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
  appearance: button;
  background-color: transparent;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  -webkit-appearance: textfield;
  appearance: textfieled;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
  margin: 0;
  overflow-wrap: break-word;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol,
ul,
menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder,
textarea::placeholder {
  opacity: 1;
  color: #9c9c9c;
}

::-ms-input-placeholder {
  color: #9c9c9c;

}

button,
[role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block; 
}

img,
video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

img, picture, video, canvas, svg {
  display: block;
  max-width: 100%;
}

#root {
  isolation: isolate;
}

//Swiper customization

/* :root {
  --swiper-navigation-top-offset: 40%;
} */

:root {
  --swiper-theme-color: ${p => p.theme.colors.primary[500]};
  --swiper-pagination-bullet-size: 12px;
}

.swiper-wrapper {
  align-items: center;
}

.swiper-wrapper.itemsOnSale {
  padding-bottom: 30px;
}

.swiper-pagination {
  position: relative;
  bottom: -4px !important;
}

.swiper-button-next, .swiper-button-prev {
  background-color: ${p => p.theme.colors.primary[500]};
  width: 40px;
  height: 40px;
  border-radius: 100%;
  box-shadow: 0 2px 10px #a9babd74;

  &::after {
    font-size: 16px;
    color: ${props => props.theme.colors.white};
    font-weight: 800;
    }

  &[aria-disabled='true'] {
    opacity: 1;

    &::after {
    color: ${props => props.theme.colors.neutral[100]};
    }
  }

  @media(max-width: 1023px) {
    display: none;
    width: 1px;
    height: 1px;
    overflow: hidden;
  }
}


//react-quill editor

.ql-editor {
  min-height: 200px;
  font-size: inherit;
  font-family: inherit;
}

.ql-container {
  font-family: Inter, sans-serif;
  font-size: 1rem;
}

`;

export default GlobalStyle;
