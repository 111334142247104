import { ApiResponse } from 'src/network/apiResponse';
import { API_ROUTES } from 'src/network/apiRoutes';
import { axiosInstance } from 'src/network/axios';
import handleServiceError from 'src/utils/helpers/handleServiceError';

export interface AddressData {
  isDefault: boolean;
  _id: string;
  country: string;
  state: string;
  city: string;
  address: string;
  customerId: string;
  businessId: string;
}

const getAddressService = async () => {
  try {
    const response = await axiosInstance.get<
      ApiResponse<AddressData[]>
    >(API_ROUTES.allAddress);
    return response.data.data;
  } catch (error) {
    throw handleServiceError(error);
  }
};

export default getAddressService;
