import { IconProps } from './icon';

const ChevronLeft: React.FC<IconProps> = props => (
  <svg
    {...props}
    width="0.375em"
    height="0.688em"
    viewBox="0 0 6 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.47173 1.02827C5.21138 0.76792 4.78927 0.76792 4.52892 1.02827L0.528921 5.02827C0.268571 5.28862 0.268571 5.71073 0.528921 5.97108L4.52892 9.97108C4.78927 10.2314 5.21138 10.2314 5.47173 9.97108C5.73208 9.71073 5.73208 9.28862 5.47173 9.02827L1.94313 5.49967L5.47173 1.97108C5.73208 1.71073 5.73208 1.28862 5.47173 1.02827Z"
      fill="currentColor"
    />
  </svg>
);

export default ChevronLeft;
