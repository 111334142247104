import { useQuery } from 'react-query';
import getBrandProductService from 'src/network/services/lubricants/getBrandProducts';

const useGetBrandProducts = () => {
  const { data, isLoading, isError, error } = useQuery(
    'brandProducts',
    getBrandProductService
  );

  return { data, isLoading, isError, error };
};

export default useGetBrandProducts;
