import { StarsRating } from 'src/components/molecules/Rating';
import { ApiResponse } from 'src/network/apiResponse';
import { API_ROUTES } from 'src/network/apiRoutes';
import { axiosInstance } from 'src/network/axios';
import getUserState from 'src/utils/helpers/getUserState';
import handleServiceError from 'src/utils/helpers/handleServiceError';
import { VariablePrice } from '../lubricants/addLubricant';

interface FavouriteLubricantData {
  _id: string;
  name: string;
  description: string;
  volume: string;
  specification: string;
  packageSize: string;
  quantity: number;
  fixedPrice: number;
  brandProductId: string;
  variablePrice: VariablePrice[];
  productImageId: string;
  brandCategoryId: string;
  rating: StarsRating;
  minimumOrderQuantity: number;
  createdAt: string;
  updatedAt: string;
  reviews: [];
  verifiedRatings: number;
}

export interface FavouriteProduct {
  _id: string;
  isLubricant: boolean;
  brandProductId: string;
  userId: string;
  brandProductDetails: FavouriteLubricantData;
  productImage: {
    _id: string;
    frontImageUrl: string;
    sideImageUrl: string;
    rearImageUrl: string;
    mainImageUrl: string;
    brandProductId: string;
  };
}

const getFavouritesService = async () => {
  try {
    const user = await getUserState();
    if (!user) {
      throw Error('User is not signed in');
    }

    const response = await axiosInstance.get<
      ApiResponse<FavouriteProduct[]>
    >(API_ROUTES.getFavourites + `/${user.userId}`);

    return response.data.data;
  } catch (error) {
    throw handleServiceError(error);
  }
};

export default getFavouritesService;
