import { useQuery } from 'react-query';
import getAddressService from 'src/network/services/address/getAddress';

const useGetAddress = () => {
  const { data, isLoading, error } = useQuery(
    ['address'],
    getAddressService
  );
  return { data, isLoading, error };
};

export default useGetAddress;
