import { forwardRef, useRef, useState } from 'react';
import useClickOutside from 'src/utils/hooks/useClickOutside';
import EyeIcon from '../icons/EyeIcon';
import EyeSlashIcon from '../icons/EyeSlashIcon';
import LockIcon from '../icons/LockIcon';
import SearchIcon from '../icons/SearchIcon';
import PasswordPopop from './PasswordPopop';
import {
  InputMessage,
  InputWrapper,
  PasswordInputWrapper,
  StyledInput,
  StyledInputWrapper,
  StyledLabel,
  StyledSearchInput,
  VisibilityIcon,
} from './style';
import useValidatePassword from './useValidatePassword';

interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  error?: boolean;
  message?: string;
  bg?: 'gray' | 'transparent';
  showPasswordValidation?: boolean;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      label,
      error = false,
      message,
      id,
      type,
      bg = 'gray',
      showPasswordValidation = false,
      ...rest
    },
    ref
  ) => {
    const [isPasswordVisible, setPasswordVisible] =
      useState(false);
    const [password, setPassword] = useState('');
    const [showPasswordPopup, setPasswordPopup] =
      useState(false);
    const passwordPopopRef = useRef<HTMLDivElement>(null);

    const passwordStrength = useValidatePassword(password);

    useClickOutside(passwordPopopRef, () =>
      setPasswordPopup(false)
    );

    const handlePasswordChange = (value: string) => {
      setPassword(value);
      if (value.length > 0) {
        setPasswordPopup(true);
      } else {
        setPasswordPopup(false);
      }
    };

    //default input
    let CustomInput = (
      <StyledInputWrapper data-disabled={rest.disabled}>
        <StyledInput
          id={id}
          ref={ref}
          type={type}
          data-bg={bg}
          data-error={error}
          {...rest}
        />
        <LockIcon />
      </StyledInputWrapper>
    );

    //search input
    if (type === 'search') {
      CustomInput = (
        <StyledSearchInput data-error={error} data-bg={bg}>
          <SearchIcon />
          <input id={id} ref={ref} type={type} {...rest} />
        </StyledSearchInput>
      );
    }

    //password input
    if (type === 'password') {
      const {
        onChange: onPasswordChange,
        ...passwordProps
      } = rest;

      CustomInput = (
        <PasswordInputWrapper>
          {showPasswordValidation && showPasswordPopup ? (
            <PasswordPopop
              ref={passwordPopopRef}
              passwordStrength={passwordStrength}
            />
          ) : null}
          <StyledInput
            ref={ref}
            id={id}
            data-bg={bg}
            data-error={error}
            type={isPasswordVisible ? 'text' : 'password'}
            {...passwordProps}
            onChange={e => {
              onPasswordChange && onPasswordChange(e);
              handlePasswordChange(e.target.value);
            }}
          />
          <VisibilityIcon
            onClick={e => {
              e.preventDefault();
              setPasswordVisible(!isPasswordVisible);
            }}
          >
            {isPasswordVisible ? (
              <EyeIcon />
            ) : (
              <EyeSlashIcon />
            )}
          </VisibilityIcon>
        </PasswordInputWrapper>
      );
    }

    return (
      <InputWrapper>
        {label ? (
          <StyledLabel htmlFor={id}>{label}</StyledLabel>
        ) : (
          false
        )}

        {CustomInput}

        {message ? (
          <InputMessage>{message}</InputMessage>
        ) : (
          false
        )}
      </InputWrapper>
    );
  }
);

Input.displayName = 'Input';

export default Input;
