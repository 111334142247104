import { IconProps } from './icon';

const PlusRound: React.FC<IconProps> = props => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5 22C6.97715 22 2.5 17.5228 2.5 12C2.5 6.47715 6.97715 2 12.5 2C18.0228 2 22.5 6.47715 22.5 12C22.5 17.5228 18.0228 22 12.5 22ZM12.5 4C8.08172 4 4.5 7.58172 4.5 12C4.5 16.4183 8.08172 20 12.5 20C16.9183 20 20.5 16.4183 20.5 12C20.5 7.58172 16.9183 4 12.5 4ZM12.5005 6.75736C13.0527 6.75736 13.5005 7.20507 13.5005 7.75736V11H16.7426C17.2949 11 17.7426 11.4477 17.7426 12C17.7426 12.5523 17.2949 13 16.7426 13H13.5005V16.2426C13.5005 16.7949 13.0527 17.2426 12.5005 17.2426C11.9482 17.2426 11.5005 16.7949 11.5005 16.2426V13H8.25736C7.70507 13 7.25736 12.5523 7.25736 12C7.25736 11.4477 7.70507 11 8.25736 11H11.5005V7.75736C11.5005 7.20507 11.9482 6.75736 12.5005 6.75736Z"
      fill="currentColor"
    />
  </svg>
);

export default PlusRound;
