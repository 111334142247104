import { Container } from 'src/components/atoms/Container';
import styled from 'styled-components';

export const StyledSection = styled(Container)`
  overflow: hidden;

  h2 {
    font-family: Inter;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 29.05px;
    margin-bottom: 32px;
  }

  @media (min-width: 768px) {
    h2 {
      font-size: 2rem;
      font-weight: 800;
      line-height: 40px;
      letter-spacing: -0.02em;
      margin-bottom: 48px;
    }
  }
`;

export const Scroll = styled.div`
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 5px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #a5adb5;
    border-radius: 50px;
  }

  &[data-horizontal='true'] {
    flex-direction: row;
    overflow-y: hidden;
    overflow-x: auto;

    &::-webkit-scrollbar {
      height: 5px;
    }
  }
`;

export const CardWrapper = styled.div`
  display: flex;
  width: 100%;
  min-width: max-content;
  gap: 24px;
`;

export const Card = styled.div`
  width: 240px;
  height: 300px;
  flex: 1;
  overflow: hidden;
  border-radius: 6px;
  display: grid;
  isolation: isolate;

  background: rgba(246, 247, 248, 1);
  box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.04);

  &:nth-of-type(even) > img {
    top: -60px;
    z-index: -1;
  }

  @media (min-width: 768px) {
    width: 287px;
  }
`;

export const CardText = styled.div`
  padding: 16px;

  a {
    width: fit-content;
  }

  h3 {
    font-family: Inter;
    font-size: 1rem;
    font-weight: 600;
    line-height: 19.36px;
    margin-bottom: 8px;
  }

  p {
    font-family: Inter;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 14.52px;
    margin-bottom: 16px;
  }

  @media (min-width: 768px) {
    h3 {
      font-size: 1.125rem;
      font-weight: 600;
      line-height: 23px;
      letter-spacing: -0.02em;
      margin-bottom: 4px;
    }

    p {
      font-size: 1rem;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 16px;
    }
  }
`;

export const CardImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
  object-position: right;
  position: relative;
`;
