import { toast } from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';
import cancelOrderService from 'src/network/services/orders/cancelOrder';

const useCancelOrder = (
  orderId: string,
  callback?: () => void
) => {
  const queryClient = useQueryClient();

  return useMutation(
    () => cancelOrderService({ orderId }),
    {
      onSuccess: () => {
        toast.dismiss();
        toast.success('Your order has been canceled', {
          id: 'order',
        });
        queryClient.invalidateQueries(['orders', orderId]);
        queryClient.invalidateQueries([
          'orders',
          { status: 'ongoing' },
        ]);
        queryClient.invalidateQueries([
          'orders',
          { status: 'pending' },
        ]);
        if (callback) callback();
      },
    }
  );
};

export default useCancelOrder;
