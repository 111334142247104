import { styled } from 'styled-components';

export const TabsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TabHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 32px;
  background-color: ${p => p.theme.colors.neutral[100]};
  padding: 10px;
  border-radius: 16px;

  @media (max-width: 767px) {
    flex-direction: column;
    gap: 1rem;
  }
`;

export const TabButton = styled.button`
  padding: 8px 12px;
  border: none;
  border-radius: 8px;
  background-color: transparent;
  color: ${props => props.theme.colors.neutral[700]};
  font-weight: 500;
  cursor: pointer;
  transition: 150ms;
  margin-right: 10px;

  &:hover {
    background-color: ${p => p.theme.colors.neutral[200]};
  }

  &[data-active='true'] {
    background-color: ${p => p.theme.colors.primary[500]};
    color: ${props => props.theme.colors.white};

    &:hover {
      background-color: ${p => p.theme.colors.primary[500]};
    }
  }
`;

export const TabContent = styled.div`
  display: none;
  transition: opacity 0.3s ease;
  opacity: 0;

  &[data-active='true'] {
    display: block;
    opacity: 1;
  }
`;
