import { IconProps } from './icon';

const HistoryIcon: React.FC<IconProps> = props => (
  <svg
    {...props}
    width="18"
    height="20"
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 2.33366C6.44772 2.33366 6 2.76272 6 3.29199H12C12 2.76272 11.5523 2.33366 11 2.33366H7ZM3 3.29199H4C4 1.70417 5.34315 0.416992 7 0.416992H11C12.6569 0.416992 14 1.70417 14 3.29199H15C16.6569 3.29199 18 4.57917 18 6.16699V16.7087C18 18.2965 16.6569 19.5837 15 19.5837H3C1.34315 19.5837 0 18.2965 0 16.7087V6.16699C0 4.57917 1.34315 3.29199 3 3.29199ZM3 5.20866C2.44772 5.20866 2 5.63772 2 6.16699V16.7087C2 17.2379 2.44772 17.667 3 17.667H15C15.5523 17.667 16 17.2379 16 16.7087V6.16699C16 5.63772 15.5523 5.20866 15 5.20866H3ZM10 8.08366C10 7.55439 9.55229 7.12533 9 7.12533C8.44771 7.12533 8 7.55439 8 8.08366V12.0539C8 12.3634 8.15597 12.6538 8.41876 12.8337L12.4188 15.5718C12.8682 15.8795 13.4927 15.7797 13.8137 15.349C14.1347 14.9183 14.0307 14.3198 13.5812 14.0122L10 11.5607V8.08366Z"
      fill="currentColor"
    />
  </svg>
);

export default HistoryIcon;
