import { atom } from 'recoil';
import { AuthState } from 'src/interfaces/authState';
import getUserState from 'src/utils/helpers/getUserState';

export const authState = atom<AuthState>({
  key: 'authState',
  default: (async () => {
    return await getUserState();
  })(),
});
