import { toast } from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';
import { useRecoilState } from 'recoil';
import deleteFavouriteService from 'src/network/services/favourites/deleteFavourite';
import { FavouriteProduct } from 'src/network/services/favourites/getFavourites';
import { favouriteModalState } from 'src/stores/menus/favouriteModalState';

const useDeleteFavourite = () => {
  const queryClient = useQueryClient();
  const [modalState, setModalState] = useRecoilState(
    favouriteModalState
  );

  const mutation = useMutation(deleteFavouriteService, {
    onSuccess: res => {
      if (modalState.isOpen) {
        queryClient.setQueryData(
          ['userFavourites'],
          oldData => {
            const cacheData = oldData as FavouriteProduct[];
            return cacheData.filter(
              product =>
                product._id !== modalState.favouriteId
            );
          }
        );

        setModalState({ isOpen: false });
      }

      queryClient.invalidateQueries(['userFavourites']);

      toast.dismiss();
      toast.success(
        'Successfully removed product from your favourites',
        {
          id: 'favourite',
        }
      );
    },
  });

  const {
    mutate: deleteFavourite,
    isLoading: isDeletingFavourite,
    data,
  } = mutation;

  return { deleteFavourite, isDeletingFavourite, data };
};

export default useDeleteFavourite;
