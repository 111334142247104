import { IconProps } from './icon';

const FilterBars: React.FC<IconProps> = props => (
  <svg
    {...props}
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.5 2.16667C1.03976 2.16667 0.666668 2.53976 0.666668 3C0.666668 3.46024 1.03976 3.83333 1.5 3.83333L12.3333 3.83333L12.3333 4.66667C12.3333 5.1269 12.7064 5.5 13.1667 5.5C13.6269 5.5 14 5.1269 14 4.66667L14 3.83333L16.5 3.83333C16.9602 3.83333 17.3333 3.46024 17.3333 3C17.3333 2.53976 16.9602 2.16667 16.5 2.16667L14 2.16667L14 1.33333C14 0.873096 13.6269 0.5 13.1667 0.5C12.7064 0.5 12.3333 0.873096 12.3333 1.33333L12.3333 2.16667L1.5 2.16667ZM0.666667 13C0.666667 12.5398 1.03976 12.1667 1.5 12.1667L12.3333 12.1667L12.3333 11.3333C12.3333 10.8731 12.7064 10.5 13.1667 10.5C13.6269 10.5 14 10.8731 14 11.3333L14 12.1667L16.5 12.1667C16.9602 12.1667 17.3333 12.5398 17.3333 13C17.3333 13.4602 16.9602 13.8333 16.5 13.8333L14 13.8333L14 14.6667C14 15.1269 13.6269 15.5 13.1667 15.5C12.7064 15.5 12.3333 15.1269 12.3333 14.6667L12.3333 13.8333L1.5 13.8333C1.03976 13.8333 0.666667 13.4602 0.666667 13ZM17.3333 8C17.3333 7.53976 16.9602 7.16667 16.5 7.16667L5.66667 7.16667L5.66667 6.33333C5.66667 5.8731 5.29357 5.5 4.83333 5.5C4.3731 5.5 4 5.87309 4 6.33333L4 7.16667L1.5 7.16667C1.03976 7.16667 0.666668 7.53976 0.666668 8C0.666668 8.46024 1.03976 8.83333 1.5 8.83333L4 8.83333L4 9.66667C4 10.1269 4.3731 10.5 4.83333 10.5C5.29357 10.5 5.66667 10.1269 5.66667 9.66667L5.66667 8.83333L16.5 8.83333C16.9602 8.83333 17.3333 8.46024 17.3333 8Z"
      fill="currentColor"
    />
  </svg>
);

export default FilterBars;
