import { ApiResponse } from 'src/network/apiResponse';
import { API_ROUTES } from 'src/network/apiRoutes';
import { axiosInstance } from 'src/network/axios';
import handleServiceError from 'src/utils/helpers/handleServiceError';

export interface Data {
  data: string;
  message: string;
  status: number;
  success: boolean;
}

interface Props {
  favouriteId: string;
}

const deleteFavouriteService = async ({
  favouriteId,
}: Props) => {
  try {
    const response = await axiosInstance.delete<
      ApiResponse<Data>
    >(API_ROUTES.deleteFavourite + `/${favouriteId}`);

    return response.data;
  } catch (error) {
    throw handleServiceError(error);
  }
};

export default deleteFavouriteService;
