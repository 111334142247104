import Footer from 'src/components/molecules/Footer';
import MainHeader from 'src/components/molecules/Headers/MainHeader';
import NavBar from 'src/components/molecules/NavBar';

interface Props {
  hideCart?: boolean;
  hideSearch?: boolean;
  children: React.ReactNode;
}

const PageLayout: React.FC<Props> = ({
  children,
  hideCart = false,
  hideSearch = true,
}) => {
  return (
    <>
      <MainHeader
        hideCart={hideCart}
        hideSearch={hideSearch}
      />
      <NavBar />
      <main>{children}</main>
      <Footer />
    </>
  );
};

export default PageLayout;
