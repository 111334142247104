import { useQuery } from 'react-query';
import getBrandsService from 'src/network/services/lubricants/getBrands';

const useGetBrands = () => {
  const { data, isLoading, isError, error } = useQuery(
    'brands',
    getBrandsService
  );

  return { data, isLoading, isError, error };
};

export default useGetBrands;
