import axios from 'axios';
import queryString from 'query-string';
import { ApiResponse } from 'src/network/apiResponse';
import { API_ROUTES } from 'src/network/apiRoutes';
import handleServiceError from 'src/utils/helpers/handleServiceError';
import { LubricantData } from './getLubricants';

type Data = Record<string, LubricantData[]>;

const getLubricantsByCategoryService = async (
  category: string
) => {
  const query = queryString.stringify({
    oiltype: category,
  });

  try {
    const response = await axios.get<ApiResponse<Data>>(
      API_ROUTES.getLubricantsByCategory + `?${query}`
    );

    return response.data.data;
  } catch (error) {
    throw handleServiceError(error);
  }
};

export default getLubricantsByCategoryService;
