import { IconProps } from './icon';

const CardIcon: React.FC<IconProps> = props => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.5 19L5.5 19C3.84315 19 2.5 17.6569 2.5 16L2.5 10L2.5 8C2.5 6.34315 3.84315 5 5.5 5L19.5 5C21.1569 5 22.5 6.34315 22.5 8V16C22.5 17.6569 21.1569 19 19.5 19ZM4.5 9V8C4.5 7.44772 4.94771 7 5.5 7L19.5 7C20.0523 7 20.5 7.44771 20.5 8V9H4.5ZM4.5 11H20.5V16C20.5 16.5523 20.0523 17 19.5 17L5.5 17C4.94772 17 4.5 16.5523 4.5 16L4.5 11ZM5.5 14C5.5 13.4477 5.94772 13 6.5 13H10.5C11.0523 13 11.5 13.4477 11.5 14C11.5 14.5523 11.0523 15 10.5 15H6.5C5.94772 15 5.5 14.5523 5.5 14Z"
      fill="currentColor"
    />
  </svg>
);

export default CardIcon;
