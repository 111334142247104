import { useState } from 'react';
import CheckIcon from 'src/components/atoms/icons/CheckIcon';
import DeliveryIcon from 'src/components/atoms/icons/DeliveryIcon';
import DotIcon from 'src/components/atoms/icons/DotIcon';
// import ReOrderIcon from 'src/components/atoms/icons/ReOrderIcon';
import { OrderStatus } from 'src/network/services/orders/getOrders';
import CancelOrderModal from 'src/pages/account/OrderDetails/CancelModal';
import { formatPrice } from 'src/utils/helpers/formatPrice';
import {
  CancelButton,
  HeadingItems,
  HeadSection,
  OrderStatusTag,
  OrderTitleGroup,
  OrderTotal,
  OrderTotalWrapper,
  PaymentType,
  // StatusCardButton,
  TimeStamp,
} from './style';

interface Props {
  details: {
    status: OrderStatus;
    orderId: string | number;
    _id: string;
    date: string;
    totalPrice: number;
    hasPaid?: boolean;
  };
}

const OrderStatusCard: React.FC<Props> = ({ details }) => {
  const {
    orderId,
    date,
    status,
    totalPrice,
    _id,
    hasPaid = false,
  } = details;

  const [isCancelModalOpen, setCancelModalOpen] =
    useState(false);

  return (
    <HeadSection>
      <HeadingItems>
        <OrderTitleGroup>
          <h3>
            Order #: <span>{orderId}</span>
          </h3>
          <OrderStatusTag data-status={status}>
            <DotIcon />
            {status}
          </OrderStatusTag>
        </OrderTitleGroup>

        <TimeStamp>{date}</TimeStamp>

        <OrderTotalWrapper>
          <OrderTotal>
            Total: {formatPrice(totalPrice)}
          </OrderTotal>

          <PaymentType $hasPaid={hasPaid}>
            {hasPaid ? (
              <>
                <CheckIcon />
                Paid
              </>
            ) : (
              <>
                <DeliveryIcon />
                Pay on delivery
              </>
            )}
          </PaymentType>
        </OrderTotalWrapper>
      </HeadingItems>

      {status === 'Pending' ? (
        <CancelButton
          variant="secondary"
          onClick={() => setCancelModalOpen(true)}
        >
          <span>Cancel Order</span>
        </CancelButton>
      ) : // : status === 'Canceled' ? (
      //   <StatusCardButton variant="secondary">
      //     <ReOrderIcon />
      //     Re-order
      //   </StatusCardButton>
      // )
      null}

      <CancelOrderModal
        _id={_id}
        isOpen={isCancelModalOpen}
        onClose={() => setCancelModalOpen(false)}
      />
    </HeadSection>
  );
};

export default OrderStatusCard;
