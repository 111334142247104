import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import resendOtpService from 'src/network/services/auth/resendOtp';

const useResendOtp = (cb?: () => void) => {
  const resendOtpMutation = useMutation(resendOtpService, {
    onSuccess: () => {
      toast.dismiss();
      toast.success('Otp Resent');
      cb && cb();
    },
    onError: () => {
      toast.dismiss();
      toast.error('Failed to resend OTP');
    },
  });

  const {
    mutate: resendOtp,
    isLoading: isResendingOtp,
    data,
  } = resendOtpMutation;

  return { resendOtp, isResendingOtp, data };
};

export default useResendOtp;
