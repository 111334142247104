import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { ROUTES } from 'src/constants/routes';
import { authState } from 'src/stores/auth/authState';
import styled from 'styled-components';

interface Props {
  children: React.ReactNode;
}

const PriceDisplay = ({ children }: Props) => {
  const user = useRecoilValue(authState);

  if (!user) {
    return (
      <PriceSignIn to={ROUTES.login}>
        Sign in to view price
      </PriceSignIn>
    );
  }

  return <>{children}</>;
};

export default PriceDisplay;

const PriceSignIn = styled(Link)`
  color: ${p => p.theme.colors.primary[500]};
  text-decoration: underline;
`;
