import { useEffect, useState } from 'react';
import {
  LOWERCASE_REGEX,
  PASSWORD_LENGTH_REGEX,
  SYMBOL_REGEX,
  UPPERCASE_REGEX,
} from 'src/constants/regexp';

export interface PasswordValidateProps {
  hasUppercase: boolean;
  hasLowercase: boolean;
  hasSpecialCharacter: boolean;
  matchesLength: boolean;
}

const useValidatePassword = (
  password: string
): PasswordValidateProps => {
  const [hasLowercase, setLowercase] = useState(false);

  const [hasUppercase, setUppercase] = useState(false);

  const [hasSpecialCharacter, setSpecialCharacter] =
    useState(false);

  const [matchesLength, setMatchesLength] = useState(false);

  useEffect(() => {
    if (UPPERCASE_REGEX.test(password)) {
      setUppercase(true);
    } else {
      setUppercase(false);
    }

    if (LOWERCASE_REGEX.test(password)) {
      setLowercase(true);
    } else {
      setLowercase(false);
    }

    if (SYMBOL_REGEX.test(password)) {
      setSpecialCharacter(true);
    } else {
      setSpecialCharacter(false);
    }

    if (PASSWORD_LENGTH_REGEX.test(password)) {
      setMatchesLength(true);
    } else {
      setMatchesLength(false);
    }
  }, [password]);

  return {
    hasLowercase,
    hasUppercase,
    hasSpecialCharacter,
    matchesLength,
  };
};

export default useValidatePassword;

export const isPasswordValid = (password: string) => {
  let validPassWord = false;

  if (
    UPPERCASE_REGEX.test(password) &&
    LOWERCASE_REGEX.test(password) &&
    SYMBOL_REGEX.test(password) &&
    PASSWORD_LENGTH_REGEX.test(password)
  ) {
    validPassWord = true;
  }

  return validPassWord;
};
