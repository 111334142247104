import { useQuery } from 'react-query';
import getSingleLubricantService from 'src/network/services/lubricants/getSingleLubricant';

const useGetSingleLubricant = (brandProductId: string) => {
  const { data, isLoading, isError, error } = useQuery(
    ['lubricants', brandProductId],
    () => getSingleLubricantService(brandProductId)
  );

  return {
    productData: data?.data,
    isLoading,
    isError,
    error,
  };
};

export default useGetSingleLubricant;
