import { Spinner } from 'src/components/atoms/Button/style';
import { styled } from 'styled-components';

export const ItemWrapper = styled.article`
  width: 100%;
  display: grid;
  grid-template-columns: max-content 1fr;
  gap: 16px;
`;
export const CartImage = styled.div`
  width: 90px;
  aspect-ratio: 1/1;
  min-height: 100%;
  background-color: #f6f7f8;
  display: grid;
  place-items: center;
  border-radius: 8px;

  img {
    width: calc(100% - 10px);
    height: calc(100% - 25px);
    object-fit: contain;
  }

  @media (min-width: 480px) {
    width: 120px;
    height: 120px;
  }
`;

export const DetailsWrapper = styled.div`
  display: grid;
  gap: 8px;
`;

export const ItemDetails = styled.div`
  display: grid;
  grid-template-columns: 1fr max-content;
  align-items: start;
  gap: 4px;
`;

export const CartFooter = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 4px;
  align-items: center;
`;

export const FooterButton = styled.button`
  text-decoration: underline;
  color: #8f8f8f;
  transition: 150ms;
  font-weight: 500;

  &:hover {
    color: ${p => p.theme.colors.primary[500]};
  }
`;

export const Title = styled.h3`
  width: 125px;
  font-weight: 600;
  font-size: 0.875rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (min-width: 1024px) {
    width: 320px;
    font-size: 1rem;
  }
`;

export const CategoryText = styled.p`
  color: ${p => p.theme.colors.neutral[600]};
  font-size: 0.75rem;
`;

export const Price = styled.p`
  font-weight: 500;
  font-size: 0.75rem;

  &[data-total='true'] {
    font-weight: 600;
  }

  @media (min-width: 1024px) {
    font-size: 0.875rem;
  }
`;

export const ModalButtons = styled.div`
  display: flex;
  gap: 12px;
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  button:first-child {
    svg {
      width: 16px;
      height: 16px;
    }
  }

  @media (min-width: 1024px) {
    font-size: 1.25rem;

    button:first-child {
      svg {
        width: 22px;
        height: 22px;
      }
    }
  }
`;

export const UpdateCartInputWrapper = styled.div`
  display: flex;
  gap: 8px;

  button {
    font-size: 0.625rem;
    background-color: ${p => p.theme.colors.neutral[200]};
    border-radius: 50%;
    height: 20px;
    width: 20px;
    display: grid;
    place-items: center;
    transition: 150ms;

    &:active {
      transform: scale(0.85);
    }

    &:disabled {
      opacity: 0.3;
    }
  }

  div {
    position: relative;
    display: grid;
    place-items: center;
    font-size: 0.75rem;
    min-width: 16px;

    &[data-loading='true'] {
      color: ${p => p.theme.colors.neutral[200]};
    }
  }
`;

export const UpdateCartSpinner = styled(Spinner)`
  width: 16px;
  height: 16px;
`;
