import { Route } from 'react-router-dom';
import { ROUTES } from 'src/constants/routes';
import CartPage from '../pages/Cart';
import CheckoutPage from '../pages/Checkout';
import ProductDetails from '../pages/ProductDetails';
import ProductsPage from '../pages/Products';
import ProtectedRoute from './ProtectedRoute';

const getProductRoutes = () => {
  return (
    <Route>
      <Route
        path={ROUTES.products}
        element={<ProductsPage />}
      />

      <Route
        path={ROUTES.products + '/:productId'}
        element={<ProductDetails />}
      />

      <Route
        path={ROUTES.cart}
        element={
          <ProtectedRoute>
            <CartPage />
          </ProtectedRoute>
        }
      />

      <Route
        path={ROUTES.checkout}
        element={
          <ProtectedRoute>
            <CheckoutPage />
          </ProtectedRoute>
        }
      />
    </Route>
  );
};

export default getProductRoutes;
