import { IconProps } from './icon';

const LockIcon: React.FC<IconProps> = props => (
  <svg
    width="0.875em"
    height="1.125em"
    viewBox="0 0 14 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 7.75469V6.5C3 4.29086 4.79086 2.5 7 2.5C9.20914 2.5 11 4.29086 11 6.5V7.75469C9.86617 6.96381 8.48725 6.5 7 6.5C5.51275 6.5 4.13383 6.96381 3 7.75469ZM2.99963 10.5H3V10.4995C3.91223 9.28534 5.3644 8.5 7 8.5C8.6356 8.5 10.0878 9.28534 11 10.4995V10.5H11.0004C11.6281 11.3357 12 12.3744 12 13.5C12 16.2614 9.76142 18.5 7 18.5C4.23858 18.5 2 16.2614 2 13.5C2 12.3744 2.37194 11.3357 2.99963 10.5ZM1 9.89241L1 6.5C1 3.18629 3.68629 0.5 7 0.5C10.3137 0.5 13 3.18629 13 6.5V9.89241C13.6348 10.946 14 12.1803 14 13.5C14 17.366 10.866 20.5 7 20.5C3.13401 20.5 0 17.366 0 13.5C0 12.1803 0.36518 10.946 1 9.89241ZM7 11.5C8.10457 11.5 9 12.3954 9 13.5C9 14.6046 8.10457 15.5 7 15.5C5.89543 15.5 5 14.6046 5 13.5C5 12.3954 5.89543 11.5 7 11.5Z"
      fill="currentColor"
    />
  </svg>
);

export default LockIcon;
