import { IconProps } from './icon';

const ClipboardCheckIcon: React.FC<IconProps> = props => (
  <svg
    width="12"
    height="15"
    viewBox="0 0 12 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.33317 3.49967C9.33317 4.23605 8.73622 4.83301 7.99984 4.83301H3.99984C3.26346 4.83301 2.6665 4.23605 2.6665 3.49967C2.29831 3.49967 1.99984 3.79815 1.99984 4.16634V12.1663C1.99984 12.5345 2.29831 12.833 2.6665 12.833H9.33317C9.70136 12.833 9.99984 12.5345 9.99984 12.1663V4.16634C9.99984 3.79815 9.70136 3.49967 9.33317 3.49967ZM4.6665 0.833008C3.79569 0.833008 3.05487 1.38955 2.78031 2.16634H2.6665C1.56193 2.16634 0.666504 3.06177 0.666504 4.16634V12.1663C0.666504 13.2709 1.56193 14.1663 2.6665 14.1663H9.33317C10.4377 14.1663 11.3332 13.2709 11.3332 12.1663V4.16634C11.3332 3.06177 10.4377 2.16634 9.33317 2.16634H9.21937C8.94481 1.38955 8.20398 0.833008 7.33317 0.833008H4.6665ZM4.6665 3.49967H3.99984V2.83301C3.99984 2.46482 4.29831 2.16634 4.6665 2.16634H7.33317C7.70136 2.16634 7.99984 2.46482 7.99984 2.83301V3.49967H7.33317H6.6665H5.33317H4.6665ZM8.1845 7.91935C8.41628 7.63327 8.37226 7.21346 8.08618 6.98168C7.8001 6.7499 7.38029 6.79392 7.14851 7.08L5.48507 9.13316L4.83374 8.39269C4.59056 8.11624 4.16931 8.08926 3.89286 8.33244C3.6164 8.57562 3.58942 8.99687 3.8326 9.27332L5.00544 10.6067C5.13513 10.7541 5.32321 10.8368 5.51952 10.8329C5.71583 10.8289 5.9004 10.7386 6.02401 10.586L8.1845 7.91935Z"
      fill="currentColor"
    />
  </svg>
);

export default ClipboardCheckIcon;
