export const ROUTES = {
  home: '/',
  login: '/login',
  resetPassword: '/reset-password',
  verifyPasswordOtp: '/verify-password-otp',
  verifyPhone: '/verify-phone',
  updatePassword: '/update-password',
  register: '/register',
  completeSignup: '/complete-signup',

  privacyPolicy: '/',
  termsAndConditions: '/',

  aboutUs: '/',
  contactUs: '/contact',
  careers: '/',
  customerSupport: '/',
  FAQs: '/',
  returnPolicy: '/',
  howItWorks: '/',

  products: '/lubricants/products',
  cart: '/cart',
  checkout: '/checkout',

  profile: '/account/profile',
  address: '/account/address',
  reviews: '/account/reviews',
  favourites: '/account/favourites',

  history: '/account/history',
  orderDetails: '/account/history/order',
  trackOrder: '/account/history/track',

  queries: {
    engineOil: '?oiltype=Engine%20Oil',
    mobil: '?brands=Mobil',
    seahorse: '?brands=Sea%20Horse',
  },

  lubricants: '/lubricants',
} as const;
