import { ApiResponse } from 'src/network/apiResponse';
import { API_ROUTES } from 'src/network/apiRoutes';
import { axiosInstance } from 'src/network/axios';
import handleServiceError from 'src/utils/helpers/handleServiceError';

const updateUserProfileService = async (data: {
  email: string;
}) => {
  try {
    const response = await axiosInstance.patch<
      ApiResponse<{ email: string }>
    >(API_ROUTES.updateEmail, data);
    return response.data.data;
  } catch (error) {
    throw handleServiceError(error);
  }
};

export default updateUserProfileService;
