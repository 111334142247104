import { Container } from 'src/components/atoms/Container';
import { styled } from 'styled-components';

export const ContactWrapper = styled.section`
  padding-block: 20px;

  @media (min-width: 768px) {
    padding-block: 40px;
  }
`;

export const ContactContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  gap: 40px;

  @media (min-width: 768px) {
    flex-direction: row-reverse;
  }

  @media (min-width: 1024px) {
    gap: 80px;
  }
`;

export const ContactFormSection = styled.div`
  box-shadow: 0px 4px 34px 0px #00000008;
  padding: 32px;
  border-radius: 12px;
  border: 1px solid ${p => p.theme.colors.grey[200]};
  /* display: grid; */
  gap: 40px;
  flex: 1;
`;

export const ContactDetailsSection = styled.div`
  display: grid;
  gap: 40px;
  align-items: start;
  flex: 1;

  @media (min-width: 768px) {
    max-width: 287px;
  }
`;

export const ContactHeadingGroup = styled.div`
  display: grid;
  gap: 10px;

  h2 {
    font-size: 2rem;
    font-weight: 600;
  }

  p {
    color: ${p => p.theme.colors.grey[400]};
    font-size: 0.875rem;
  }
`;

export const ContactDetailsTextGroup = styled.div`
  display: grid;
  gap: 32px;

  @media (min-width: 768px) {
    gap: 48px;
  }
`;

export const ContactDetail = styled.article`
  display: flex;
  align-items: start;
  gap: 20px;

  span {
    padding: 10px;
    border: 1px solid ${p => p.theme.colors.grey[200]};
    border-radius: 4px;
  }
`;

export const ContactDetailText = styled.div`
  display: grid;
  gap: 8px;
  justify-items: start;

  h3 {
    font-size: 1rem;
    font-weight: 600;
  }

  p {
    font-size: 0.875rem;
    color: ${p => p.theme.colors.grey[500]};
  }

  a {
    font-size: 0.875rem;
  }
`;

export const ContactSocialLinks = styled.div`
  display: flex;
  gap: 32px;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
`;

export const ContactSocialLink = styled.a`
  width: 38px;
  height: 38px;
  font-size: 1rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${p => p.theme.colors.primary[500]};
  transition: 250ms;
  background-color: ${p => p.theme.colors.secondary};

  &:hover {
    background-color: ${p => p.theme.colors.primary[500]};
    color: ${p => p.theme.colors.secondary};
    transform: scale(1.1);
  }
`;
