import { IconProps } from './icon';

const TruckIcon: React.FC<IconProps> = props => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 6C2 4.89543 2.89543 4 4 4H15C18.866 4 22 7.13401 22 11V17.8713C22 18.4947 21.4947 19 20.8713 19H19.9646C19.7219 20.6961 18.2632 22 16.5 22C14.7368 22 13.2781 20.6961 13.0354 19H9.96456C9.72194 20.6961 8.26324 22 6.5 22C5.05127 22 3.80812 21.1198 3.27617 19.865C2.52938 19.575 2 18.8493 2 18V14V12V6ZM9.66318 17H13.3368C13.6826 16.2722 14.2722 15.6826 15 15.3368V14H4V16.0505C4.63526 15.4022 5.52066 15 6.5 15C7.8962 15 9.10145 15.8175 9.66318 17ZM15 18.5C15 19.3284 15.6716 20 16.5 20C17.3284 20 18 19.3284 18 18.5C18 17.6716 17.3284 17 16.5 17C15.6716 17 15 17.6716 15 18.5ZM4 12H15L15 6L4 6V12ZM17 15.0354C18.1798 15.2042 19.1698 15.9613 19.6632 17H20V12H17V15.0354ZM17 10H19.9C19.5729 8.38867 18.4709 7.05873 17 6.41604V10ZM6.5 17C5.67157 17 5 17.6716 5 18.5C5 19.3284 5.67157 20 6.5 20C7.32843 20 8 19.3284 8 18.5C8 17.6716 7.32843 17 6.5 17Z"
      fill="currentColor"
    />
  </svg>
);

export default TruckIcon;
