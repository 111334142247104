import { Link } from 'react-router-dom';
import { Spinner, StyledButton } from './style';

interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  variant?:
    | 'primary'
    | 'secondary'
    | 'outlined'
    | 'ghost'
    | 'light';
  fluid?: boolean;
  asLink?: boolean;
  href?: string;
  target?: string;
  rel?: string;
  loading?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  children,
  variant = 'primary',

  asLink = false,
  target,
  rel,
  href = '/',

  fluid = false,
  loading = false,

  type = 'button',

  ...p
}) => {
  return asLink ? (
    <StyledButton
      as={Link}
      to={href}
      target={target}
      rel={rel}
      data-variant={variant}
      data-fluid={fluid}
    >
      {children}
    </StyledButton>
  ) : (
    <StyledButton
      data-variant={variant}
      data-fluid={fluid}
      data-loading={loading}
      disabled={loading}
      type={type}
      {...p}
    >
      {loading ? <Spinner data-variant={variant} /> : false}
      {children}
    </StyledButton>
  );
};

export default Button;
