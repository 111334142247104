import styled from 'styled-components';

export const SelectContainer = styled.div`
  width: 100%;
  display: grid;
  gap: 10px;
`;

export const Dropdown = styled.div`
  position: relative;
  width: 100%;
`;

export const SelectButton = styled.button`
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: space-between;
  text-align: left;
  width: 100%;
  overflow: hidden;
  border-radius: 4px;
  padding: 12px 16px;
  background-color: ${p =>
    p.theme.colors.neutral[100]} !important;
  color: ${p => p.theme.colors.black};
  border: 1px solid
    ${props => props.theme.colors.neutral[200]};
  cursor: pointer;

  &[data-error='true'] {
    border: 1px solid
      ${props => props.theme.colors.danger[500]};
  }

  &[data-disabled='true'] {
    pointer-events: none;
    background-color: ${p =>
      p.theme.colors.neutral[200]} !important;
    color: ${p => p.theme.colors.neutral[400]};
  }
`;

export const DropdownStyle = styled.div`
  position: absolute;
  top: 52px;
  left: 0;
  max-height: 40vmax;
  width: 100%;
  padding: 0.4rem;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  background: ${p => p.theme.colors.white};
  border: 1.5px solid ${p => p.theme.colors.neutral[300]};
  transition: max-height 0.2s ease;
  overflow-y: auto;
  z-index: 999;

  &[data-visible='false'] {
    max-height: 40px;
    visibility: hidden;
  }
`;

export const DropdownItem = styled.button`
  display: flex;
  align-items: center;
  text-align: left;
  width: 100%;
  margin: 0.15rem auto;
  padding: 0.8rem 1rem;
  font-size: 0.875rem;
  font-weight: 400;
  color: ${p => p.theme.colors.grey[500]};
  border-radius: 0.3rem;
  cursor: pointer;

  &:hover,
  :focus,
  :focus:hover {
    background-color: ${p => p.theme.colors.secondary};
    outline: none;
  }

  &[data-active='true'] {
    background-color: ${p => p.theme.colors.primary[500]};
    color: ${p => p.theme.colors.white};
    outline: none;
    font-weight: 500;
  }
`;

export const Message = styled.p`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.danger[700]};
`;

export const LoadingIndicator = styled.div`
  height: 40px;
`;
