import { Container } from 'src/components/atoms/Container';
import styled from 'styled-components';

export const StyledSection = styled(Container)`
  display: grid;
  gap: 21px;

  h2 {
    font-family: Inter;
    font-size: 1.5rem;
    font-weight: 900;
    line-height: 29.05px;
    text-align: left;
    margin-bottom: 16px;
  }

  p {
    font-family: Inter;
    font-size: 1rem;
    font-weight: 600;
    line-height: 19.36px;
    text-align: left;
    margin-bottom: 48px;
  }

  button {
    width: 100%;
  }

  @media (min-width: 540px) {
    button {
      width: max-content;
    }
  }

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1.5fr;
    align-items: center;

    h2 {
      font-weight: 800;
      line-height: 28px;
      letter-spacing: -0.02em;
    }

    p {
      font-weight: 400;
      line-height: 24px;
    }

    div {
      padding-inline: 32px;
    }
  }
`;

export const StyledImage = styled.img`
  width: 100%;
  object-fit: cover;
  padding-inline: 32px;

  @media (min-width: 768px) {
    padding-inline: 0;
  }
`;
