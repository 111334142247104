import axios from 'axios';
import { StarsRating } from 'src/components/molecules/Rating';
import { ApiResponse } from 'src/network/apiResponse';
import { API_ROUTES } from 'src/network/apiRoutes';
import handleServiceError from 'src/utils/helpers/handleServiceError';
import { VariablePrice } from './addLubricant';

interface TopRankingData {
  _id: string;
  name: string;
  description: string;
  volume: string;
  specification: string;
  packageSize: string;
  quantity: number;
  fixedPrice: number;
  brandProductId: {
    _id: string;
    name: string;
    brandId: {
      _id: string;
      name: string;
    };
  };
  variablePrice: VariablePrice[];
  productImageId: {
    _id: string;
    frontImageUrl: string;
    sideImageUrl: string;
    rearImageUrl: string;
    mainImageUrl: string;
    brandProductId: string;
    createdAt: string;
    updatedAt: string;
  };
  brandCategoryId: {
    _id: string;
    name: string;
  };
  rating: StarsRating;
  minimumOrderQuantity: number;
  createdAt: string;
  updatedAt: string;
  reviews: [];
  verifiedRatings: number;
  isTopRanking: true;
}

const getTopRankingLubricantsService = async () => {
  try {
    const response = await axios.get<
      ApiResponse<TopRankingData[]>
    >(API_ROUTES.getTopRankingLubricants);

    return response.data.data;
  } catch (error) {
    throw handleServiceError(error);
  }
};

export default getTopRankingLubricantsService;
