import { Link } from 'react-router-dom';
import logoSvg from 'src/assets/images/virpem-logo.svg';
import styled from 'styled-components';

const Logo = () => {
  return (
    <Link to="/">
      <StyledImage src={logoSvg} alt="Virpem Logo" />
    </Link>
  );
};

export default Logo;

const StyledImage = styled.img`
  height: 34px;
`;
