import { useMemo } from 'react';
import StarIcon from '../icons/StarIcon';
import { styled } from 'styled-components';

interface Props {
  count?: number;
  rating?: number;
  onRate: (value: number) => void;
}

const Rate: React.FC<Props> = ({
  count = 5,
  rating = 0,
  onRate,
}) => {
  const starRating = useMemo(() => {
    const getColor = (index: number) => {
      if (rating >= index) {
        return '#f5eb3b';
      }

      return '#DCDCDC';
    };

    return Array(count)
      .fill(0)
      .map((_, i) => i + 1)
      .map(idx => (
        <StarIcon
          key={idx}
          onClick={() => onRate(idx)}
          style={{
            color: getColor(idx),
            cursor: 'pointer',
          }}
        />
      ));
  }, [count, onRate, rating]);

  return <Wrapper>{starRating}</Wrapper>;
};

export default Rate;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 80px;
    height: 40px;
  }

  @media (min-width: 768px) {
    gap: 18px;

    svg {
      height: 80px;
    }
  }
`;
