import { Link } from 'react-router-dom';
import { Container } from 'src/components/atoms/Container';
import styled from 'styled-components';

export const StyledLink = styled(Link)`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: ${p => p.theme.colors.primary[500]};
  display: flex;
  align-items: center;
  gap: 12px;
  margin-block: 20px;

  &[data-small='true'] {
    font-size: 14px;
    gap: 4px;

    svg {
      font-size: 18px;
    }
  }
`;

export const Wrapper = styled(Container)`
  margin-block: 20px;
  display: grid;
  gap: 32px;

  @media (min-width: 1024px) {
    grid-template-columns: 1fr 390px;
    gap: 30px;
    align-items: start;
  }
`;

export const MainDetails = styled.div`
  display: grid;
  gap: 32px;

  @media (max-width: 480px) {
    gap: 16px;
  }
`;

export const Checkout = styled.div`
  position: sticky;
  top: 100px;
`;

export const Card = styled.section`
  background: ${p => p.theme.colors.white};
  box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.03);
  border-radius: 16px;
  padding: 32px 24px;

  @media (max-width: 480px) {
    padding: 14px;
  }
`;

export const OrderDetails = styled(Card)`
  h2 {
    margin: 0;
  }

  display: grid;
  gap: 20px;
`;

export const HeadingGroup = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 20px;

  h2 {
    margin: 0;
  }

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
  }
`;

export const PaymentInfo = styled.div`
  margin-top: 32px;
  display: grid;
  gap: 24px;

  p {
    color: #8f8f8f;
  }
`;
