import styled from 'styled-components';

export const Card = styled.article`
  position: relative;
  background: ${p => p.theme.colors.white};
  box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.03);
  border-radius: 16px;
  padding: 24px;
  display: grid;
  grid-template-columns: max-content 1fr;
  border: 1px solid ${p => p.theme.colors.grey[200]};

  &[data-popup='true'] {
    padding-right: 60px;
  }

  &[data-checkbox-visible='false'] {
    grid-template-columns: 1fr;
  }

  &[data-skeleton='true'] {
    padding-right: 24px;
  }

  @media (max-width: 480px) {
    padding: 14px;

    &[data-popup='true'] {
      padding-right: 45px;
    }

    &[data-skeleton='true'] {
      padding-right: 14px;
    }
  }
`;

export const NameGroup = styled.div`
  display: grid;
  width: 100%;
  gap: 14px;
  grid-template-columns: 1fr max-content;
  margin-bottom: 24px;
  align-items: center;
`;

export const Details = styled.div`
  h4 {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #1f1f1f;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  p {
    line-height: 19px;
    color: #6a6a6a;
    margin-bottom: 12px;
  }
`;

export const Tag = styled.div`
  background-color: #edfded;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 10px;

  width: 70px;
  height: 33px;

  background: #edfded;
  border-radius: 4px;

  font-weight: 500;
  font-size: 0.75rem;
  line-height: 17px;
  color: ${p => p.theme.colors.primary[500]};
`;

export const AddressCardMenu = styled.div`
  position: absolute;
  top: 24px;
  right: 24px;

  @media (max-width: 480px) {
    top: 14px;
    right: 14px;
  }
`;

export const MenuIcon = styled.div`
  padding: 8px;
  border-radius: 10px;
  transition: 200ms ease;

  &:hover {
    background-color: ${p => p.theme.colors.neutral[50]};
  }
`;

export const MenuWrapper = styled.div`
  min-width: max-content;
  font-size: 0.75rem;

  button {
    width: 100%;
    padding-block: 10px;
    padding-inline: 5px;
    display: flex;
    align-items: center;
    gap: 6px;

    &:nth-child(2) {
      border-top: 1px solid
        ${p => p.theme.colors.neutral[200]};

      border-bottom: 1px solid
        ${p => p.theme.colors.neutral[200]};
    }

    &[data-danger='true'] {
      color: ${p => p.theme.colors.danger[500]};
    }

    &:hover {
      background-color: ${p => p.theme.colors.neutral[100]};
    }

    svg {
      width: 16px;
      height: 16px;
    }
  }
`;
