import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTimer } from 'react-timer-hook';
import Button from 'src/components/atoms/Button';
import Form from 'src/components/atoms/Form';
import OtpInput from 'src/components/atoms/OtpInput';
import { styled } from 'styled-components';
import { OtpFormProps, otpFormSchema } from './schema';

interface OtpProps {
  isLoading?: boolean;
  onSubmit: (otp: string) => void;
  onResend?: () => void;
}

const OtpForm: React.FC<OtpProps> = ({
  onSubmit,
  isLoading = false,
  onResend,
}) => {
  const [disableOtp, setDisableOtp] = useState(true);

  const getTime = () => {
    const time = new Date();
    time.setSeconds(time.getSeconds() + 60); // 1 minute timer
    return time;
  };

  const {
    seconds,
    minutes,
    restart: restartTimer,
  } = useTimer({
    expiryTimestamp: getTime(),
    onExpire: () => {
      setDisableOtp(false);
    },
  });

  const {
    setValue,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm<OtpFormProps>({
    defaultValues: {
      otp: '',
    },
    resolver: zodResolver(otpFormSchema),
    mode: 'onBlur',
  });

  const handleOtp = (formData: OtpFormProps) => {
    onSubmit(formData.otp);
  };

  const handleResendOtp = () => {
    if (!onResend) return;

    setDisableOtp(true);
    restartTimer(getTime());
    onResend();
  };

  return (
    <Form
      style={{ gap: '40px' }}
      onSubmit={handleSubmit(handleOtp)}
    >
      <OtpInput
        onChange={otp => {
          clearErrors();
          setValue('otp', otp);
        }}
        error={!!errors.otp}
      />

      <TimerGroup>
        <button
          onClick={handleResendOtp}
          disabled={disableOtp}
        >
          Resend OTP
        </button>

        {minutes === 0 && seconds === 0 ? (
          ''
        ) : (
          <p>
            <span>{minutes}</span>:<span>{seconds}</span>
          </p>
        )}
      </TimerGroup>

      <Button
        type="submit"
        disabled={isLoading}
        loading={isLoading}
      >
        Verify OTP
      </Button>
    </Form>
  );
};

export default OtpForm;

const TimerGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  button {
    font-weight: 700;
    line-height: 19px;
    cursor: pointer;
    color: ${p => p.theme.colors.primary[500]};

    &:disabled {
      color: ${p => p.theme.colors.neutral[400]};
      opacity: 0.5;
    }
  }

  p {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
  }
`;
