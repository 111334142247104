import { IconProps } from './icon';

const ContactIcon: React.FC<IconProps> = props => (
  <svg
    {...props}
    width="1.2em"
    height="1.2em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.28547 14.7645C6.10434 14.9583 4.875 14.1085 4.875 12.8035V12.221C2.09534 11.6897 0 9.32941 0 6.4981V6.37493C0 3.08445 2.77157 0.416992 6.19047 0.416992H10.8095C14.2284 0.416992 17 3.08445 17 6.37493C17 7.06081 16.8796 7.71961 16.658 8.33283C18.6016 8.9635 20 10.7208 20 12.7901C20 15.128 18.2148 17.0679 15.875 17.4342V18.2093C15.875 19.3529 14.4956 19.9945 13.5527 19.2894L11.1435 17.4879C9.37378 17.3805 7.88648 16.2805 7.28547 14.7645ZM15.6792 10.0538C14.5499 11.4366 12.7983 12.3265 10.8306 12.3328L9.04563 13.7173C9.26491 14.7828 10.2484 15.5866 11.4286 15.5866H11.476C11.7151 15.5866 11.9472 15.6631 12.1354 15.8038L13.875 17.1047V16.6188C13.875 16.0488 14.3589 15.5866 14.9559 15.5866H15.0714C16.6888 15.5866 18 14.3346 18 12.7901C18 11.4446 17.0049 10.321 15.6792 10.0538ZM10.4463 10.408C10.2384 10.408 10.0367 10.4765 9.87483 10.602L6.97627 12.8501C6.93556 12.8817 6.875 12.8538 6.875 12.8035V11.19C6.875 11.1016 6.85975 11.0166 6.83166 10.9373C6.79149 10.8241 6.72508 10.7225 6.63938 10.6393C6.49216 10.4965 6.28806 10.408 6.0625 10.408C3.81884 10.408 2 8.65748 2 6.4981V6.37493C2 4.14753 3.87614 2.34187 6.19047 2.34187H10.8095C13.1239 2.34187 15 4.14753 15 6.37493C15 8.60233 13.1239 10.408 10.8095 10.408H10.4463Z"
      fill="currentColor"
    />
  </svg>
);

export default ContactIcon;
