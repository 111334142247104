import styled from 'styled-components';

interface ScrollViewProps {
  children: React.ReactNode;
  horizontal?: boolean;
}

const ScrollView: React.FC<ScrollViewProps> = ({
  horizontal,
  children,
}) => {
  return (
    <ScrollViewContainer data-horizontal={horizontal}>
      {children}
    </ScrollViewContainer>
  );
};

export default ScrollView;

const ScrollViewContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  gap: 16px;
  overflow-x: hidden;
  overflow-y: auto;

  scrollbar-width: thin;
  /* scrollbar-color: transparent transparent;
  scrollbar-track-color: transparent; */

  /* Styling for WebKit browsers (Chrome, Safari) */
  &::-webkit-scrollbar {
    width: 5px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #a5adb5;
    border-radius: 50px;
  }

  &[data-horizontal='true'] {
    flex-direction: row;
    overflow-y: hidden;
    overflow-x: auto;

    /* Styling for WebKit browsers (Chrome, Safari) */
    &::-webkit-scrollbar {
      height: 5px;
    }
  }
`;
