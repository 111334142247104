import { ApiResponse } from 'src/network/apiResponse';
import { API_ROUTES } from 'src/network/apiRoutes';
import { axiosInstance } from 'src/network/axios';
import handleServiceError from 'src/utils/helpers/handleServiceError';
import { VariablePrice } from '../lubricants/addLubricant';

const getOrdersService = async (
  orderStatus: OrderStatusQuery
) => {
  const route = orderStatus
    ? API_ROUTES.getOrders + `?status=${orderStatus}`
    : API_ROUTES.getOrders;

  try {
    const response =
      await axiosInstance.get<ApiResponse<OrderData[]>>(
        route
      );
    return response.data.data;
  } catch (error) {
    throw handleServiceError(error);
  }
};

export default getOrdersService;

export type OrderStatusQuery =
  | 'ongoing'
  | 'canceled'
  | 'completed';

export type OrderStatus =
  | 'Pending'
  | 'Canceled'
  | 'Order delivered';

export interface OrderData {
  _id: string;
  userId: string;
  orderItems: {
    brandProductDetailsId: string;
    name: string;
    quantity: number;
    description: string;
    volume: string;
    specification: string;
    packageSize: string;
    brandProductId: string;
    variablePrice: VariablePrice[];
    fixedPrice: number;
    productImageId: {
      _id: string;
      frontImageUrl: string;
      sideImageUrl: string;
      rearImageUrl: string;
      mainImageUrl: string;
      brandProductId: string;
    };
    brandCategoryId: {
      _id: string;
      name: string;
    };
    rating: number;
    remainingQuantity: number;
    orderQuantity: number;
    totalSelectedProductPrice: number;
    _id: string;
  }[];

  orderItemsTotalPrice: number;
  deliveryPrice: number;
  orderId: number;
  totalPrice: number;
  status: OrderStatus;
  deliveryLocation: string;
  paymentMethod: 'payOnDelivery' | 'payNow';
  city: string;
  state: string;
  createdAt: string;
}
