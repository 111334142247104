import Footer from 'src/components/molecules/Footer';
import MainHeader from 'src/components/molecules/Headers/MainHeader';

interface Props {
  hideCart?: boolean;
  hideSearch?: boolean;
  children: React.ReactNode;
}

const LandingLayout: React.FC<Props> = ({
  children,
  hideCart = false,
  hideSearch = false,
}) => {
  return (
    <>
      <MainHeader
        hideCart={hideCart}
        hideSearch={hideSearch}
        menuType="landingMenu"
      />
      <main>{children}</main>
      <Footer />
    </>
  );
};

export default LandingLayout;
