import { toast } from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';
import deleteAddressService from 'src/network/services/address/deleteAddress';

const useDeleteAddress = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(deleteAddressService, {
    onSuccess: () => {
      toast.dismiss();
      toast.success('Address removed', {
        id: 'address',
      });
      queryClient.invalidateQueries('address');
    },
  });

  const {
    mutate: deleteAddress,
    isLoading: isDeletingAddress,
    data,
  } = mutation;

  return { deleteAddress, isDeletingAddress, data };
};

export default useDeleteAddress;
