import {
  A11y,
  Autoplay,
  Navigation,
  Pagination,
} from 'swiper';
import { SwiperProps } from 'swiper/react';

export const SalesSlideProps: SwiperProps = {
  modules: [A11y, Navigation, Autoplay, Pagination],
  spaceBetween: 20,
  slidesPerView: 1,
  // loop: true,
  autoplay: {
    delay: 2500,
    // pauseOnMouseEnter: true,
    disableOnInteraction: false,
  },
  breakpoints: {
    314: {
      slidesPerView: 2,
    },
    489: {
      slidesPerView: 3,
    },
    663: {
      slidesPerView: 4,
    },
    768: {
      slidesPerView: 3,
    },
    942: {
      slidesPerView: 4,
    },
    1187: {
      slidesPerView: 5,
      // spaceBetween: 40,
    },
  },
  navigation: true,
  pagination: { clickable: true },
};

export const LandingSliderProps: SwiperProps = {
  modules: [A11y, Autoplay],
  // spaceBetween: 20,
  slidesPerView: 1,
  loop: true,
  autoplay: {
    delay: 2500,
    // pauseOnMouseEnter: true,
    disableOnInteraction: false,
  },
};
