import { Route } from 'react-router-dom';
import { ROUTES } from 'src/constants/routes';
import CompleteSignup from '../pages/auth/CompleteSignup';
import LoginPage from '../pages/auth/Login';
import NewPasswordPage from '../pages/auth/NewPassword';
import RegisterPage from '../pages/auth/Register';
import ResetPasswordPage from '../pages/auth/ResetPassword';
import VerifyPasswordOTP from '../pages/auth/VerifyPasswordOTP';
import VerifyPhone from '../pages/auth/VerifyPhone';
import UnsignedOnlyRoute from './UnsignedUsersOnly';

const getAuthRoutes = () => {
  return (
    <Route>
      <Route
        path={ROUTES.login}
        element={
          <UnsignedOnlyRoute>
            <LoginPage />
          </UnsignedOnlyRoute>
        }
      />

      <Route
        path={ROUTES.register}
        element={
          <UnsignedOnlyRoute>
            <RegisterPage />
          </UnsignedOnlyRoute>
        }
      />

      <Route
        path={ROUTES.completeSignup}
        element={<CompleteSignup />}
      />

      <Route
        path={ROUTES.resetPassword}
        element={
          <UnsignedOnlyRoute>
            <ResetPasswordPage />
          </UnsignedOnlyRoute>
        }
      />

      <Route
        path={ROUTES.verifyPasswordOtp}
        element={<VerifyPasswordOTP />}
      />

      <Route
        path={ROUTES.verifyPhone}
        element={<VerifyPhone />}
      />

      <Route
        path={ROUTES.updatePassword}
        element={<NewPasswordPage />}
      />
    </Route>
  );
};

export default getAuthRoutes;
