import { useQuery } from 'react-query';
import getCategoriesService from 'src/network/services/lubricants/getCategories';

const useGetCategories = () => {
  const { data, isLoading, isError, error } = useQuery(
    'categories',
    getCategoriesService
  );

  return { data, isLoading, isError, error };
};

export default useGetCategories;
