import { useQuery } from 'react-query';
import getLubricantsByCategoryService from 'src/network/services/lubricants/getLubricantsByCategory';

const useGetLubricantsByCategory = (category: string) => {
  const query = useQuery(
    ['lubricantsByCategory', category],
    () => getLubricantsByCategoryService(category)
  );

  return query;
};

export default useGetLubricantsByCategory;
