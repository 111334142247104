import { IconProps } from './icon';

const EditIcon: React.FC<IconProps> = props => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.8358 2.79905L2.8759 15.7589C1.39033 17.2445 1.39032 19.6531 2.87589 21.1386C4.36146 22.6242 6.77004 22.6242 8.25561 21.1386L21.2155 8.17877C22.0752 7.3191 22.0752 5.9253 21.2155 5.06563L18.9489 2.79905C18.0892 1.93938 16.6954 1.93938 15.8358 2.79905ZM13.5418 7.9215L17.25 4.21326C17.3286 4.13464 17.4561 4.13464 17.5347 4.21326L19.8013 6.47984C19.8799 6.55846 19.8799 6.68593 19.8013 6.76455L16.093 10.4728L13.5418 7.9215ZM12.1275 9.33571L4.29011 17.1731C3.58559 17.8777 3.58559 19.0199 4.29011 19.7244C4.99463 20.429 6.13688 20.429 6.8414 19.7244L14.6788 11.887L12.1275 9.33571Z"
      fill="currentColor"
    />
  </svg>
);

export default EditIcon;
