import Skeleton from 'react-loading-skeleton';
import Button from 'src/components/atoms/Button';
import CartIcon from 'src/components/atoms/icons/CartIcon';
import HeartIcon from 'src/components/atoms/icons/HeartIcon';
import AccountLayout from 'src/components/layouts/AccountLayout';
import ProductGrid from 'src/components/molecules/ProductGrid';
import ProductGridSkeleton from 'src/components/molecules/ProductGrid/skeleton';
import { ROUTES } from 'src/constants/routes';
import useGetFavourites from 'src/network/react-query/query/favourites/getFavourites';
import { LubricantData } from 'src/network/services/lubricants/getLubricants';
import { EmptyCart } from 'src/pages/Cart/style';
import { Wrapper } from '../OrderHistory/style';
import { HeadGroup } from './style';

const FavouritesPage = () => {
  const { data: favourites, isLoading } =
    useGetFavourites();

  if (isLoading)
    return (
      <AccountLayout>
        <Wrapper>
          <HeadGroup>
            <Skeleton width={200} />
            <Skeleton width={80} />
          </HeadGroup>

          <ProductGridSkeleton />
        </Wrapper>
      </AccountLayout>
    );

  if (favourites) {
    if (favourites.length === 0)
      return (
        <AccountLayout>
          <Wrapper>
            <EmptyFavourites />
          </Wrapper>
        </AccountLayout>
      );

    console.log(
      'favourites: ',
      JSON.stringify(favourites[0], null, 2)
    );

    const productData: LubricantData[] = favourites.map(
      ({
        brandProductDetails: {
          _id,
          brandProductId,
          description,
          fixedPrice,
          minimumOrderQuantity,
          name,
          packageSize,
          quantity,
          rating,
          specification,
          variablePrice,
          volume,
          verifiedRatings,
        },
        productImage,
      }) => ({
        _id,
        brandCategoryId: { name: '', _id: '' },
        brandProductId: {
          _id: brandProductId,
          name: '',
          brandId: {
            _id: '',
            name: '',
          },
        },
        description,
        fixedPrice,
        minimumOrderQuantity,
        name,
        packageSize,
        quantity,
        rating,
        specification,
        variablePrice,
        volume,
        productImageId: productImage,
        productImage: productImage.mainImageUrl,
        verifiedRatings,
      })
    );

    return (
      <AccountLayout>
        <Wrapper>
          <HeadGroup>
            <h1>My favorites</h1>
            <span>
              {favourites.length} item
              {favourites.length > 1 ? 's' : ''}
            </span>
          </HeadGroup>

          <ProductGrid data={productData} />
        </Wrapper>
      </AccountLayout>
    );
  }

  return (
    <AccountLayout>
      <Wrapper>
        <EmptyFavourites />
      </Wrapper>
    </AccountLayout>
  );
};

export default FavouritesPage;

const EmptyFavourites = () => (
  <EmptyCart>
    <span>
      <HeartIcon />
    </span>
    <h2>Your favourites is empty</h2>
    <p>
      You haven&#39;t added anything to your favourites.
      Explore top categories to add items
    </p>
    <Button asLink href={ROUTES.products}>
      <CartIcon />
      Start Shopping
    </Button>
  </EmptyCart>
);
