import { IconProps } from './icon';

const PlusIcon: React.FC<IconProps> = props => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.06164 0.991195C9.06164 0.443773 8.61787 0 8.07044 0C7.52302 0 7.07925 0.443773 7.07925 0.991195L7.07925 6.93836L0.991196 6.93836C0.443774 6.93836 0 7.38214 0 7.92956C0 8.47698 0.443773 8.92075 0.991195 8.92075H7.07925L7.07925 15.0088C7.07925 15.5562 7.52302 16 8.07044 16C8.61786 16 9.06164 15.5562 9.06164 15.0088V8.92075H15.0088C15.5562 8.92075 16 8.47698 16 7.92956C16 7.38214 15.5562 6.93836 15.0088 6.93836L9.06164 6.93836L9.06164 0.991195Z"
      fill="currentColor"
    />
  </svg>
);

export default PlusIcon;
