import { Navigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import Heading from 'src/components/atoms/Heading';
import Text from 'src/components/atoms/Text';
import AuthLayout from 'src/components/layouts/AuthLayout';
import CompleteSignupForm from 'src/components/molecules/CompleteSignupForm';
import { registrationState } from 'src/stores/auth/registrationState';
import { styled } from 'styled-components';

const CompleteSignup = () => {
  const { completeSignupAccess } = useRecoilValue(
    registrationState
  );

  if (!completeSignupAccess) {
    return <Navigate to="/" />;
  }

  return (
    <AuthLayout>
      <Group>
        <Heading as="h1" level={4}>
          Complete sign up
        </Heading>
        <Text shade={700}>Tell us more about you</Text>
      </Group>

      <CompleteSignupForm />
    </AuthLayout>
  );
};

export default CompleteSignup;

const Group = styled.div`
  margin-bottom: 2rem;
`;
