import { atom } from 'recoil';

interface Address {
  _id: string;
  name: string;
  address: string;
  city: string;
  state: string;
  phone: string;
}

interface ActiveState {
  hasChangedAddress: true;
  address: Address;
}

interface InactiveState {
  hasChangedAddress: false;
}

type Props = ActiveState | InactiveState;

export const checkoutStore = atom<Props>({
  key: 'checkoutState',
  default: {
    hasChangedAddress: false,
  },
});
