import axios from 'axios';
import queryString from 'query-string';
import { ApiResponse } from 'src/network/apiResponse';
import { API_ROUTES } from 'src/network/apiRoutes';
import handleServiceError from 'src/utils/helpers/handleServiceError';
import { LubricantData } from './getLubricants';

export interface FilterQueryParams {
  conditionalfrom?: string;
  conditionalto?: string;
  product?: string;
  volume?: string;
  oiltype?: string;
  search?: string;
  price?: string;
}

const getFilterLubricantService = async (
  queryParams: FilterQueryParams
) => {
  try {
    const query = queryString.stringify(queryParams);

    const response = await axios.get<
      ApiResponse<LubricantData[]>
    >(API_ROUTES.filter + `?${query}`);

    return response.data.data;
  } catch (error) {
    throw handleServiceError(error);
  }
};

export default getFilterLubricantService;
