import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import Button from 'src/components/atoms/Button';
import CheckBox from 'src/components/atoms/Checkbox';
import Form from 'src/components/atoms/Form';
import Input from 'src/components/atoms/Input';
import PhoneInput from 'src/components/atoms/PhoneInput';
import { ROUTES } from 'src/constants/routes';
import useCreateAccount from 'src/network/react-query/mutation/auth/createAccount';
import {
  RegisterFormProps,
  registerFormSchema,
  UserRegisterDetails,
} from './schema';
import { Group, StyledLink } from './style';

const RegisterForm = () => {
  const [countryCode, setCountryCode] = useState('+234');
  const { createAccount, isCreatingAccount } =
    useCreateAccount();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    clearErrors,
  } = useForm<RegisterFormProps>({
    defaultValues: {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      password: '',
      confirm_password: '',
      hasSignedTerms: false,
    },
    resolver: zodResolver(registerFormSchema),
    mode: 'onBlur',
  });

  const onSubmit = async (formData: RegisterFormProps) => {
    if (!formData.hasSignedTerms)
      return toast.error(
        'Please read and agree to the terms and conditions'
      );

    const userDetails: UserRegisterDetails = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      password: formData.password,
      hasSignedTerms: true,
      phoneNumber: countryCode + formData.phoneNumber,
    };

    toast.loading('Creating account');
    createAccount(userDetails);
  };

  return (
    <>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        onChange={() => clearErrors()}
      >
        <Group>
          <Input
            id="firstname"
            label="First Name"
            placeholder="Enter first name"
            bg="gray"
            {...register('firstName')}
            error={!!errors.firstName}
            message={errors.firstName?.message}
          />

          <Input
            id="lastname"
            label="Last Name"
            placeholder="Enter Last name"
            bg="gray"
            {...register('lastName')}
            error={!!errors.lastName}
            message={errors.lastName?.message}
          />
        </Group>

        <Controller
          name="phoneNumber"
          control={control}
          render={({ field }) => (
            <PhoneInput
              id="phone"
              {...field}
              onCountryCodeChange={setCountryCode}
              bg="gray"
              label="Phone Number"
              autoComplete="phone"
              error={!!errors.phoneNumber}
              message={errors.phoneNumber?.message}
            />
          )}
        />

        <Input
          id="password"
          label="Password"
          placeholder="Enter password"
          autoComplete="new-password"
          type="password"
          showPasswordValidation
          bg="gray"
          {...register('password')}
          error={!!errors.password}
          message={errors.password?.message}
        />

        <Input
          id="confirmPassword"
          label="Confirm Password"
          placeholder="Retype password"
          autoComplete="new-password"
          type="password"
          bg="gray"
          {...register('confirm_password')}
          error={!!errors.confirm_password}
          message={errors.confirm_password?.message}
        />

        <CheckBox
          id="terms"
          {...register('hasSignedTerms')}
        >
          I have read, understood and I agree to
          Virpem&#39;s{' '}
          <StyledLink to={ROUTES.privacyPolicy}>
            Privacy Policy
          </StyledLink>
          , and
          <StyledLink to={ROUTES.termsAndConditions}>
            Terms and conditions
          </StyledLink>
          .
        </CheckBox>

        <Button
          type="submit"
          loading={isCreatingAccount}
          disabled={isCreatingAccount}
        >
          Create account
        </Button>
      </Form>
    </>
  );
};

export default RegisterForm;
