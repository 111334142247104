import { COLORS } from '../colors';

export const theme = {
  colors: COLORS,

  radius: {
    sm: '4px',
  },

  width: {
    container: '90%',
    max: '1440px',
    text: '65ch',
  },
} as const;

export type ThemeType = typeof theme;
