import { IconProps } from './icon';

const PMSIcon = (props: IconProps) => {
  return (
    <svg
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3 5V4.5C3 3.557 3 3.086 3.293 2.793C3.586 2.5 4.057 2.5 5 2.5C5.943 2.5 6.414 2.5 6.707 2.793C7 3.086 7 3.557 7 4.5V5M13 4H16"
        stroke="#1F1F1F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 1H12.667C10.793 1 10 1.934 10 3.667C10 4.533 9.603 5 8.667 5H5C3.114 5 2.172 5 1.586 5.586C1 6.172 1 7.114 1 9V14C1 17.3 1 18.95 2.025 19.975C3.05 21 4.7 21 8 21H12C15.3 21 16.95 21 17.975 19.975C19 18.95 19 17.3 19 14V6C19 3.643 19 2.464 18.268 1.732C17.535 1 16.357 1 14 1Z"
        stroke="#1F1F1F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 13.587C7 12.123 8.264 10.676 9.15 9.83998C9.37888 9.62115 9.68334 9.49902 10 9.49902C10.3167 9.49902 10.6211 9.62115 10.85 9.83998C11.736 10.676 13 12.123 13 13.587C12.9974 13.9762 12.9175 14.3609 12.7647 14.7189C12.612 15.0769 12.3895 15.4009 12.1103 15.672C11.8311 15.9431 11.5007 16.1559 11.1384 16.2981C10.7761 16.4402 10.3891 16.5089 10 16.5C8.136 16.5 7 15.023 7 13.587Z"
        stroke="#1F1F1F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PMSIcon;
