import { useRecoilState } from 'recoil';
import Button from 'src/components/atoms/Button';
import { Container } from 'src/components/atoms/Container';
import { Divider } from 'src/components/atoms/Divider';
import Heading from 'src/components/atoms/Heading';
import CartIcon from 'src/components/atoms/icons/CartIcon';
import ChevronRight from 'src/components/atoms/icons/ChevronRight';
import EmptyCartIcon from 'src/components/atoms/icons/EmptyCartIcon';
import InfoIcon from 'src/components/atoms/icons/InfoIcon';
import WasteBinIcon from 'src/components/atoms/icons/WasteBinIcon';
import Text from 'src/components/atoms/Text';
import PageLayout from 'src/components/layouts/PageLayout';
import ActionModal from 'src/components/molecules/ActionModal';
import CartItem from 'src/components/molecules/CartItem';
import { ModalButtons } from 'src/components/molecules/CartItem/style';
import TopRankingProducts from 'src/components/molecules/FeaturedSections/TopRanking';
import { ROUTES } from 'src/constants/routes';
import useDeleteCartItem from 'src/network/react-query/mutation/cart/deleteCartItem';
import useGetCart from 'src/network/react-query/query/cart/getCart';
import { cartModalState } from 'src/stores/menus/cartModalState';
import { formatPrice } from 'src/utils/helpers/formatPrice';
import useCurrentUser from 'src/utils/hooks/useCurrentUser';
import {
  HeadingGroup,
  StyledButton,
  StyledHeading,
  TopRankingContainer,
  TopRankingHeader,
  TopRankingSection,
} from '../Home/style';
import CartPageSkeleton from './Skeleton';
import {
  CartCard,
  CartGrid,
  CartSection,
  EmptyCart,
  OrderSummary,
  Small,
  SummaryGroup,
} from './style';

const CartPage = () => {
  const [modalState, setModalState] =
    useRecoilState(cartModalState);

  const { user } = useCurrentUser();

  const { data, isLoading } = useGetCart();

  const deleteCartMutation = useDeleteCartItem();

  if (!user) return null;

  const handleDeleteCartItem = () => {
    if (!modalState.isOpen) return;

    deleteCartMutation.deleteCartItem({
      brandProductDetailId: modalState.brandProductDetailId,
    });
  };

  //loading data
  if (isLoading) {
    return (
      <PageLayout>
        <CartSection>
          <CartPageSkeleton />
        </CartSection>
      </PageLayout>
    );
  }

  //data available
  if (data?.items.length) {
    return (
      <PageLayout>
        <CartSection>
          <CartGrid>
            <CartCard>
              <Heading as="h2" level={6}>
                My Shopping Cart
              </Heading>

              <Divider margin="24px 0" />

              {data?.items.map((cartItem, i) => (
                <div key={i}>
                  <CartItem details={cartItem} />
                  <Divider margin="24px 0" />
                </div>
              ))}
            </CartCard>

            <OrderSummary>
              <Heading as="h2" level={6}>
                Order Summary
              </Heading>

              <Divider margin="24px 0" />

              <SummaryGroup>
                <Text data-heading>Total:</Text>
                <p data-total-price>
                  {formatPrice(data.totalPrice)}
                </p>
              </SummaryGroup>

              <Small>
                <InfoIcon />
                (Excluding shipping fee)
              </Small>

              <Button
                asLink
                href={ROUTES.checkout}
                fluid
                disabled={data.items.length === 0}
              >
                Checkout {data.items.length}
              </Button>
            </OrderSummary>
          </CartGrid>
        </CartSection>

        <ActionModal
          isOpen={modalState.isOpen}
          onClose={() => setModalState({ isOpen: false })}
          heading="Remove From Cart"
          text="Are you sure you want to remove this item?"
          icon={<WasteBinIcon />}
          footer={
            <ModalButtons>
              <Button
                fluid
                onClick={handleDeleteCartItem}
                disabled={
                  deleteCartMutation.isDeletingCartItem
                }
                loading={
                  deleteCartMutation.isDeletingCartItem
                }
              >
                Remove
              </Button>
              <Button
                fluid
                variant="secondary"
                onClick={() =>
                  setModalState({ isOpen: false })
                }
                disabled={
                  deleteCartMutation.isDeletingCartItem
                }
              >
                Cancel
              </Button>
            </ModalButtons>
          }
        />
      </PageLayout>
    );
  }

  //data empty
  return (
    <PageLayout>
      <CartSection>
        <EmptyCart>
          <EmptyCartIcon />
          <h2>Your cart is empty</h2>
          <p>
            You haven&#39;t added anything to your cart.
            Explore top categories to add items
          </p>
          <Button asLink href={ROUTES.products}>
            <CartIcon />
            Start Shopping
          </Button>
        </EmptyCart>
      </CartSection>

      <TopRankingSection>
        <TopRankingHeader>
          <TopRankingContainer>
            <HeadingGroup>
              <StyledHeading as="h2" level={5}>
                Items you may love
              </StyledHeading>
            </HeadingGroup>

            <StyledButton to={ROUTES.products}>
              View All
              <ChevronRight />
            </StyledButton>
          </TopRankingContainer>
        </TopRankingHeader>

        <Container>
          <TopRankingProducts />
        </Container>
      </TopRankingSection>
    </PageLayout>
  );
};

export default CartPage;
