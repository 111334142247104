import MinusIcon from 'src/components/atoms/icons/MinusIcon';
import PlusIcon from 'src/components/atoms/icons/PlusIcon';
import {
  Button,
  NumberInputContainer,
  ValueInput,
} from './style';

interface NumberInputProps {
  min: number;
  max: number;
  value: number;
  onChange: (value: number) => void;
  size?: 'default' | 'sm';
}

const NumberSelect: React.FC<NumberInputProps> = ({
  min,
  max,
  value,
  onChange,
  size = 'default',
}) => {
  const handleDecrease = () => {
    if (value > min) {
      onChange(value - 1);
    }
  };

  const handleIncrease = () => {
    if (value < max) {
      onChange(value + 1);
    }
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = Number(event.target.value);
    if (
      !isNaN(newValue) &&
      newValue >= min &&
      newValue <= max
    ) {
      onChange(newValue);
    }
  };

  return (
    <NumberInputContainer data-size={size}>
      <Button
        onClick={handleDecrease}
        data-size={size}
        disabled={value === min}
      >
        <MinusIcon />
      </Button>
      <ValueInput
        type="number"
        value={value}
        onChange={handleChange}
        min={min}
        max={max}
        data-size={size}
      />
      <Button
        onClick={handleIncrease}
        data-size={size}
        disabled={value === max}
      >
        <PlusIcon />
      </Button>
    </NumberInputContainer>
  );
};

export default NumberSelect;
