export function capitalizeFirstLetter(str: string) {
  // Split the string into an array of words
  const words = str.toLowerCase().split(' ');

  // Capitalize the first letter of each word
  const capitalizedWords = words.map(word => {
    const firstLetter = word.charAt(0).toUpperCase();
    const restOfWord = word.slice(1);
    return firstLetter + restOfWord;
  });

  // Join the words back into a string
  const capitalizedStr = capitalizedWords.join(' ');

  return capitalizedStr;
}
