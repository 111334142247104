import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import Button from 'src/components/atoms/Button';
import Form from 'src/components/atoms/Form';
import Input from 'src/components/atoms/Input';
import { ROUTES } from 'src/constants/routes';
import useUpdatePassword from 'src/network/react-query/mutation/auth/updatePassword';
import { resetPasswordState } from 'src/stores/auth/resetPasswordState';
import ActionModal from '../ActionModal';
import {
  CreatePasswordFormProps,
  createPasswordFormSchema,
} from './schema';

const CreatePasswordForm = () => {
  const { updatePassword, isUpdatingPassword, isSuccess } =
    useUpdatePassword();
  const [{ userId }, setResetPasswordState] =
    useRecoilState(resetPasswordState);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm<CreatePasswordFormProps>({
    defaultValues: {
      new_password: '',
      confirm_password: '',
    },
    resolver: zodResolver(createPasswordFormSchema),
    mode: 'onBlur',
  });

  const handleUpdatePassword = (
    formData: CreatePasswordFormProps
  ) => {
    toast.loading('Updating password');

    updatePassword({
      _id: userId,
      newPassword: formData.new_password,
      confirmNewPassword: formData.confirm_password,
    });
  };

  const handleSuccess = () => {
    setResetPasswordState({
      userId: '',
      otp: '',
      phoneNumber: '',
      newPasswordAccess: false,
      passwordOtpAccess: false,
    });

    setTimeout(() => {
      navigate(ROUTES.login);
    }, 0);
  };

  return (
    <>
      <Form
        onSubmit={handleSubmit(handleUpdatePassword)}
        onChange={() => clearErrors}
      >
        <Input
          id="newPassword"
          label="New Password"
          placeholder="Enter password"
          type="password"
          showPasswordValidation
          {...register('new_password')}
          error={!!errors.new_password}
          message={errors.new_password?.message}
        />

        <Input
          id="confirmPassword"
          label="Confirm Password"
          placeholder="Retype password"
          type="password"
          {...register('confirm_password')}
          error={!!errors.confirm_password}
          message={errors.confirm_password?.message}
        />

        <Button
          type="submit"
          loading={isUpdatingPassword}
          disabled={isUpdatingPassword}
        >
          Reset Password
        </Button>
      </Form>

      <ActionModal
        isOpen={isSuccess}
        onClose={handleSuccess}
        heading="Password Updated Successfully!"
        text={`Congratulations! you have successfully 
        updated your password. Remember to keep your 
        new password safe and strong.`}
        footer={
          <Button onClick={handleSuccess}>
            Back to Login
          </Button>
        }
      />
    </>
  );
};

export default CreatePasswordForm;
