import { useRef } from 'react';
import { useRecoilState } from 'recoil';
import Breadcrumb, {
  BreadcrumbItem,
} from 'src/components/atoms/BreadCrumb';
import CustomSelect from 'src/components/atoms/CustomSelect';
import Heading from 'src/components/atoms/Heading';
import FilterBars from 'src/components/atoms/icons/FilterBars';
import FunnelIcon from 'src/components/atoms/icons/FunnelIcon';
import SearchIcon from 'src/components/atoms/icons/SearchIcon';
import XIcon from 'src/components/atoms/icons/XIcon';
import PageLayout from 'src/components/layouts/PageLayout';
import ProductFilters from 'src/components/molecules/ProductFilters';
import ProductGrid from 'src/components/molecules/ProductGrid';
import ProductGridSkeleton from 'src/components/molecules/ProductGrid/skeleton';
import useFilterLubricants from 'src/network/react-query/query/lubricants/filterLubricants';
import { filterMenuState } from 'src/stores/menus/filterMenuState';
import useClickOutside from 'src/utils/hooks/useClickOutside';
import useSearchAndFilter from 'src/utils/hooks/useSearchAndFilter';
import {
  BreadCrumbContainer,
  EmptyProduct,
  FilterAlert,
  FilterButton,
  FilterCloseButton,
  FilterSection,
  HeadingGroup,
  MainWrapper,
  ProductsSection,
  SearchAlert,
  SearchAlertWrapper,
  SideBarHeadingGroup,
  SortTab,
} from './style';
import useSort from './useSort';

const breadcrumbItems: BreadcrumbItem[] = [
  {
    label: 'Home',
    url: '/',
  },
  {
    label: 'Products',
  },
];

const ProductsPage = () => {
  const filterRef = useRef<HTMLElement>(null);
  useClickOutside(filterRef, () => setFilterOpen(false));
  const [isFilterOpen, setFilterOpen] =
    useRecoilState(filterMenuState);

  const {
    searchAndFilterState,
    updateSearchAndFilterState,
    isFiltering,
    activeFilters,
  } = useSearchAndFilter();
  const { defaultSort, handleSort, sortOptions } =
    useSort();

  const handleClearSearch = () => {
    updateSearchAndFilterState({ search: '' });
  };

  return (
    <PageLayout hideSearch={false}>
      <BreadCrumbContainer>
        <Breadcrumb items={breadcrumbItems} />
      </BreadCrumbContainer>

      <MainWrapper>
        <FilterSection
          data-open={isFilterOpen}
          ref={filterRef}
        >
          <SideBarHeadingGroup>
            <Heading
              as="h2"
              level={5}
              style={{ margin: 0 }}
            >
              Filters
            </Heading>

            <FilterCloseButton
              title="close filter menu"
              onClick={() => setFilterOpen(false)}
            >
              <span data-icon="close">
                <XIcon style={{ fontSize: '1rem' }} />
              </span>

              <span data-icon="funnel">
                <FunnelIcon style={{ fontSize: '1rem' }} />
              </span>
            </FilterCloseButton>
          </SideBarHeadingGroup>

          <ProductFilters
            handleClose={() => setFilterOpen(false)}
          />
        </FilterSection>

        <ProductsSection>
          {searchAndFilterState.search ? (
            <SearchAlertWrapper>
              <SearchAlert>
                Showing search results for:{' '}
                <span>{searchAndFilterState.search}</span>
                <button
                  type="button"
                  onClick={handleClearSearch}
                >
                  <XIcon />
                  Clear search
                </button>
              </SearchAlert>
            </SearchAlertWrapper>
          ) : null}

          <HeadingGroup>
            <FilterButton
              variant="ghost"
              onClick={() => setFilterOpen(true)}
            >
              <FilterBars /> Filter
            </FilterButton>

            <Heading
              as="h2"
              level={5}
              style={{ margin: 0 }}
            >
              {searchAndFilterState.search
                ? 'Results'
                : 'All Products'}
            </Heading>

            <SortTab>
              <label>Sort By:</label>

              <CustomSelect
                value={defaultSort}
                placeholder="Sort price"
                options={sortOptions}
                onChange={handleSort}
              />
            </SortTab>
          </HeadingGroup>

          {isFiltering && (
            <FilterAlert>
              <span>Filtering by:</span>{' '}
              {activeFilters.join(', ')}
            </FilterAlert>
          )}
          <FilteredResults />
        </ProductsSection>
      </MainWrapper>
    </PageLayout>
  );
};

export default ProductsPage;

const FilteredResults = () => {
  const { searchAndFilterState, getPriceRange } =
    useSearchAndFilter();
  const { from, to, brands, oiltype, search, sizes } =
    searchAndFilterState;

  const { data, isLoading } = useFilterLubricants({
    conditionalfrom: from,
    conditionalto: to,
    price: getPriceRange(),
    product: brands,
    volume: sizes,
    oiltype,
    search,
  });

  if (isLoading) return <ProductGridSkeleton />;

  if (data) {
    if (data.length) {
      return <ProductGrid data={data} />;
    }

    return (
      <EmptyProduct>
        <SearchIcon />
        <p>No lubricants available</p>
      </EmptyProduct>
    );
  }

  return (
    <EmptyProduct>
      <SearchIcon />
      <p>No lubricants available</p>
    </EmptyProduct>
  );
};
