import { useEffect } from 'react';
import {
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { atom, useRecoilState } from 'recoil';
import { ROUTES } from 'src/constants/routes';

interface SearchAndFilterState {
  from: string;
  to: string;
  brands: string;
  sizes: string;
  oiltype: string;
  search: string;
  minPrice: string;
  maxPrice: string;
}

const INITIAL_STATE: SearchAndFilterState = {
  from: '',
  to: '',
  brands: '',
  sizes: '',
  oiltype: '',
  search: '',
  minPrice: '',
  maxPrice: '',
};

const searchAndFilterStateAtom = atom<SearchAndFilterState>(
  {
    key: 'searchAndFilterState',
    default: INITIAL_STATE,
  }
);

interface UseSearchAndFilter {
  searchAndFilterState: SearchAndFilterState;
  updateSearchAndFilterState: (
    newState: Partial<SearchAndFilterState>
  ) => void;
  clearSearchParams: (shouldNavigate?: boolean) => void;
  toggleBrand: (brand: string) => void;
  toggleSize: (size: string) => void;
  toggleOilType: (oilType: string) => void;
  getPriceRange: () => string | undefined;
  activeFilters: string[];
  isFiltering: boolean;
}

const useSearchAndFilter = (): UseSearchAndFilter => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [searchAndFilterState, setSearchAndFilterState] =
    useRecoilState(searchAndFilterStateAtom);

  useEffect(() => {
    setSearchAndFilterState(prev => ({
      ...prev,
      ...Object.fromEntries(searchParams),
    }));
  }, [searchParams, setSearchAndFilterState]);

  useEffect(() => {
    if (pathname !== ROUTES.products) {
      setSearchAndFilterState(INITIAL_STATE);
      setSearchParams([]);
    }
  }, [setSearchAndFilterState, pathname, setSearchParams]);

  const updateSearchAndFilterState = (
    newState: Partial<SearchAndFilterState>
  ): void => {
    const updatedState = {
      ...searchAndFilterState,
      ...newState,
    };

    const nonEmptyParams = Object.entries(updatedState)
      .flatMap(([key, value]) => {
        if (
          key === 'brands' ||
          key === 'sizes' ||
          key === 'oiltype'
        ) {
          // Join the values by ',' and filter out empty values
          const updatedValue = (value as string)
            .split(',')
            .filter(Boolean)
            .join(',');
          return updatedValue ? [[key, updatedValue]] : [];
        } else if (key === 'minPrice' && value !== '') {
          return [['minPrice', value.toString()]];
        } else if (key === 'maxPrice' && value !== '') {
          return [['maxPrice', value.toString()]];
        }
        return [[key, value.toString()]];
      })
      .filter(([_, value]) => value !== '');

    setSearchAndFilterState(updatedState);
    setSearchParams(nonEmptyParams as [string, string][]);

    navigate({
      pathname: ROUTES.products,
      search:
        '?' +
        new URLSearchParams(nonEmptyParams).toString(),
    });
  };

  const clearSearchParams = (
    shouldNavigate: boolean = true
  ): void => {
    setSearchAndFilterState(INITIAL_STATE);
    setSearchParams([]);

    if (shouldNavigate) {
      navigate('.'); // Navigate to the current URL to clear the search parameters
    }
  };

  const toggleBrand = (brand: string): void => {
    const { brands } = searchAndFilterState;
    const updatedBrands = new Set(
      (brands || '').split(',')
    );

    if (updatedBrands.has(brand)) {
      updatedBrands.delete(brand);
    } else {
      updatedBrands.add(brand);
    }

    updateSearchAndFilterState({
      brands: [...updatedBrands].join(','),
    });
  };

  const toggleSize = (size: string): void => {
    const { sizes } = searchAndFilterState;
    const updatedSizes = new Set((sizes || '').split(','));

    if (updatedSizes.has(size)) {
      updatedSizes.delete(size);
    } else {
      updatedSizes.add(size);
    }

    updateSearchAndFilterState({
      sizes: [...updatedSizes].join(','),
    });
  };

  const toggleOilType = (oilType: string): void => {
    const { oiltype } = searchAndFilterState;
    const updatedOilTypes = new Set(
      (oiltype || '').split(',')
    );

    if (updatedOilTypes.has(oilType)) {
      updatedOilTypes.delete(oilType);
    } else {
      updatedOilTypes.add(oilType);
    }

    updateSearchAndFilterState({
      oiltype: [...updatedOilTypes].join(','),
    });
  };

  const getPriceRange = () => {
    const { minPrice, maxPrice } = searchAndFilterState;
    if (minPrice && maxPrice) {
      return `${minPrice}-${maxPrice}`;
    }

    if (minPrice) {
      return `${minPrice}-10000000`; //maximum price needed in order to return a response
    }

    if (maxPrice) {
      return `-${maxPrice}`;
    }
  };

  const isFiltering = Object.entries(searchAndFilterState)
    .filter(
      ([key, _]) =>
        key !== 'from' && key !== 'to' && key !== 'search'
    )
    .some(([_, value]) => Boolean(value));

  const activeFilters = [
    ...new Set(
      Object.entries(searchAndFilterState)
        .filter(
          ([key, value]) =>
            key !== 'from' &&
            key !== 'to' &&
            key !== 'search' &&
            Boolean(value)
        )
        .map(([key, _]) => {
          if (key === 'minPrice' || key === 'maxPrice') {
            return 'price';
          }
          return key;
        })
    ),
  ];

  return {
    searchAndFilterState,
    updateSearchAndFilterState,
    clearSearchParams,
    toggleBrand,
    toggleSize,
    toggleOilType,
    getPriceRange,
    isFiltering,
    activeFilters,
  };
};

export default useSearchAndFilter;
