import { Container } from 'src/components/atoms/Container';
import { styled } from 'styled-components';

export const Wrapper = styled(Container)`
  margin-block: 32px;
  display: grid;
  gap: 24px;

  @media (min-width: 768px) {
    width: 100%;
  }
`;

export const Heading = styled.h2`
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
`;

export const TabHeader = styled.div`
  display: grid;

  @media (min-width: 340px) {
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
  }
`;

export const TabButton = styled.button`
  width: 100%;
  padding: 10px;
  border-bottom: 3px solid ${p => p.theme.colors.grey[200]};
  color: ${p => p.theme.colors.neutral[400]};
  display: flex;
  justify-content: center;
  text-align: center;

  &[data-active='true'] {
    color: ${p => p.theme.colors.primary[500]};
    border-bottom-color: ${p =>
      p.theme.colors.primary[500]};
  }

  span {
    display: none;

    @media (min-width: 569px) {
      display: contents;
    }
  }
`;

export const LoadingWrapper = styled.div`
  position: relative;
  display: grid;
  place-items: center;
  width: 100%;
  padding-block: 100px;
`;
