import iotImage from 'src/assets/images/iot.jpg';
import { Container } from 'src/components/atoms/Container';
import styled from 'styled-components';

export const StyledSection = styled(Container)`
  background: linear-gradient(
      180deg,
      rgba(229, 240, 253, 0.9) 13.27%,
      rgba(229, 240, 253, 0.9) 31.77%,
      rgba(229, 240, 253, 0.45) 59.53%,
      rgba(229, 240, 253, 0) 96.53%
    ),
    url(${iotImage});
  background-size: cover;
  background-position: center;

  border-radius: 16px;
  padding: 16px;
  height: 400px;
  display: grid;
  align-content: center;

  h2 {
    font-family: Inter;
    font-size: 1.5rem;
    font-weight: 900;
    line-height: 29.05px;
    text-align: left;
    margin-bottom: 16px;
  }

  p {
    font-family: Inter;
    font-size: 1rem;
    font-weight: 600;
    line-height: 19.36px;
    text-align: left;
    margin-bottom: 48px;
  }

  @media (min-width: 580px) {
    padding: 32px;

    button {
      width: 220px;
    }
  }
`;
