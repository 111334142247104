import { forwardRef } from 'react';
import { styled } from 'styled-components';
import {
  InputMessage,
  InputWrapper,
  StyledLabel,
} from '../Input/style';

interface Props
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  error?: boolean;
  message?: string;
}

const Textarea = forwardRef<HTMLTextAreaElement, Props>(
  ({ label, error = false, message, id, ...rest }, ref) => {
    return (
      <InputWrapper>
        {label ? (
          <StyledLabel htmlFor={id}>{label}</StyledLabel>
        ) : (
          false
        )}

        <StyledTextarea
          id={id}
          ref={ref}
          {...rest}
          data-error={error}
        />

        {message ? (
          <InputMessage>{message}</InputMessage>
        ) : (
          false
        )}
      </InputWrapper>
    );
  }
);
export default Textarea;

export const StyledTextarea = styled.textarea`
  display: block;
  width: 100%;
  padding: 12px 16px;
  border: 1px solid ${p => p.theme.colors.neutral[300]};
  border-radius: 4px;
  background: transparent;
  outline: none;
  transition: border-color 250ms;
  background-color: ${p => p.theme.colors.neutral[100]};
  color: ${p => p.theme.colors.black};
  resize: vertical;

  &[data-error='true'] {
    border-color: ${p => p.theme.colors.danger[700]};
  }
`;
