import { ApiResponse } from 'src/network/apiResponse';
import { API_ROUTES } from 'src/network/apiRoutes';
import { axiosInstance } from 'src/network/axios';
import getUserState from 'src/utils/helpers/getUserState';
import handleServiceError from 'src/utils/helpers/handleServiceError';

interface Payload {
  brandProductDetailId: string;
  orderQuantitys: number;
}

const addCartService = async (payload: Payload) => {
  try {
    const user = await getUserState();
    if (!user) {
      throw Error('User is not signed in');
    }

    const response = await axiosInstance.post<
      ApiResponse<string>
    >(API_ROUTES.addCart, {
      userId: user.userId,
      ...payload,
    });
    return response.data;
  } catch (error) {
    throw handleServiceError(error);
  }
};

export default addCartService;
