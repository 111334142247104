import { Route, RouteProps } from 'react-router-dom';
import { ROUTES } from 'src/constants/routes';
import AddressPage from '../pages/account/Address';
import FavouritesPage from '../pages/account/Favourites';
import OrderDetailsPage from '../pages/account/OrderDetails';
import OrderHistoryPage from '../pages/account/OrderHistory';
import ProfilePage from '../pages/account/Profile';
import TrackOrderPage from '../pages/account/TrackOrder';
import ProtectedRoute from './ProtectedRoute';

const ACCOUNT_ROUTES: RouteProps[] = [
  { path: ROUTES.profile, element: <ProfilePage /> },
  { path: ROUTES.favourites, element: <FavouritesPage /> },
  { path: ROUTES.address, element: <AddressPage /> },
  // { path: ROUTES.reviews, element: <ReviewsPage /> },
  { path: ROUTES.history, element: <OrderHistoryPage /> },
  {
    path: ROUTES.orderDetails + '/:orderId',
    element: <OrderDetailsPage />,
  },
  {
    path: ROUTES.trackOrder + '/:orderId',
    element: <TrackOrderPage />,
  },
];

const getAccountRoutes = () => {
  return (
    <Route>
      {ACCOUNT_ROUTES.map((props, i) => (
        <Route
          key={i}
          {...props}
          element={
            <ProtectedRoute>{props.element}</ProtectedRoute>
          }
        />
      ))}
    </Route>
  );
};

export default getAccountRoutes;
