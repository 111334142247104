import { IconProps } from './icon';

const ChevronDown: React.FC<IconProps> = props => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.41076 6.91058C4.08533 7.23602 4.08533 7.76366 4.41076 8.08909L9.41076 13.0891C9.7362 13.4145 10.2638 13.4145 10.5893 13.0891L15.5893 8.08909C15.9147 7.76366 15.9147 7.23602 15.5893 6.91058C15.2638 6.58515 14.7362 6.58515 14.4108 6.91058L10 11.3213L5.58928 6.91058C5.26384 6.58514 4.7362 6.58514 4.41076 6.91058Z"
      fill="currentColor"
    />
  </svg>
);

export default ChevronDown;
