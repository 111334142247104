import { useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import ContactIcon from 'src/components/atoms/icons/ContactIcon';
import DeliveryIcon from 'src/components/atoms/icons/DeliveryIcon';
import HeartIcon from 'src/components/atoms/icons/HeartIcon';
import HistoryIcon from 'src/components/atoms/icons/HistoryIcon';
import ProfileOutlineIcon from 'src/components/atoms/icons/ProfileOutlineIcon';
import XIcon from 'src/components/atoms/icons/XIcon';
import MainHeader from 'src/components/molecules/Headers/MainHeader';
import ReviewModal from 'src/components/molecules/ReviewModal';
import { ROUTES } from 'src/constants/routes';
import { accountMenuState } from 'src/stores/menus/accountMenuState';
import { ratingModalState } from 'src/stores/menus/ratingModalState';
import useClickOutside from 'src/utils/hooks/useClickOutside';
import {
  HeadGroup,
  Main,
  Sidebar,
  SidebarContent,
  SidebarLink,
  Wrapper,
} from './style';

const AccountLayout: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const sidebarRef = useRef<HTMLDivElement>(null);

  const [isSidebarOpen, setSidebarOpen] = useRecoilState(
    accountMenuState
  );

  const [ratingModalOpen, setRatingModalOpen] =
    useRecoilState(ratingModalState);

  const menuButtonRef = useRef<HTMLButtonElement>(null);

  useClickOutside(sidebarRef, () => setSidebarOpen(false), [
    menuButtonRef,
  ]);

  const location = useLocation();

  return (
    <>
      <MainHeader
        menuType="accountMenu"
        menuButtonRef={menuButtonRef}
        hideSearch
      />

      <Wrapper>
        <Sidebar ref={sidebarRef} data-open={isSidebarOpen}>
          <SidebarContent>
            <HeadGroup>
              <h2>Account</h2>
              <button
                onClick={() => setSidebarOpen(false)}
                title="close account menu"
                type="button"
              >
                <XIcon />
              </button>
            </HeadGroup>

            <SidebarLink
              to={ROUTES.profile}
              onClick={() => setSidebarOpen(false)}
              data-active={location.pathname.includes(
                ROUTES.profile
              )}
            >
              <ProfileOutlineIcon />
              Profile
            </SidebarLink>

            <SidebarLink
              to={ROUTES.address}
              onClick={() => setSidebarOpen(false)}
              data-active={location.pathname.includes(
                ROUTES.address
              )}
            >
              <DeliveryIcon />
              Delivery Address
            </SidebarLink>

            <SidebarLink
              to={ROUTES.history}
              onClick={() => setSidebarOpen(false)}
              data-active={location.pathname.includes(
                ROUTES.history
              )}
            >
              <HistoryIcon />
              Order History
            </SidebarLink>

            {/* <SidebarLink
              to={ROUTES.reviews}
              onClick={() => setSidebarOpen(false)}
              data-active={location.pathname.includes(
                ROUTES.reviews
              )}
            >
              <StarOutline />
              Reviews
            </SidebarLink> */}

            <SidebarLink
              to={ROUTES.favourites}
              onClick={() => setSidebarOpen(false)}
              data-active={location.pathname.includes(
                ROUTES.favourites
              )}
            >
              <HeartIcon />
              My favorites
            </SidebarLink>

            <SidebarLink
              to={ROUTES.contactUs}
              onClick={() => setSidebarOpen(false)}
              data-active={location.pathname.includes(
                ROUTES.contactUs
              )}
            >
              <ContactIcon />
              Contact Us
            </SidebarLink>
          </SidebarContent>
        </Sidebar>

        <Main>{children}</Main>

        <ReviewModal
          isOpen={ratingModalOpen.isOpen}
          onClose={() =>
            setRatingModalOpen({ isOpen: false })
          }
        />
      </Wrapper>
    </>
  );
};

export default AccountLayout;
