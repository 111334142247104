import { Card } from 'src/pages/Checkout/style';
import { styled } from 'styled-components';

export const AddressHeader = styled(Card)`
  display: grid;
  gap: 16px;
  border: 1px solid ${p => p.theme.colors.grey[200]};

  h2 {
    font-weight: 600;
    font-size: 20px;
  }

  @media (min-width: 580px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;
