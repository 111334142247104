export const hidePhone = (phone: string) => {
  return phone
    .split('')
    .map((digit, index) => {
      if (index > 6 && index < phone.length - 3) {
        return '*';
      } else return digit;
    })
    .join('');
};
