import { IconProps } from './icon';

const ProfileIcon: React.FC<IconProps> = props => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20ZM10 2C5.58172 2 2 5.58172 2 10C2 12.2893 2.96156 14.3539 4.50282 15.8122C5.31432 14.1472 7.02319 13 9 13H11.5C13.342 13 14.9256 14.1068 15.6219 15.6916C17.0901 14.2412 18 12.2269 18 10C18 5.58172 14.4183 2 10 2ZM13.9422 16.9629C13.6964 15.8403 12.6963 15 11.5 15H9C7.68573 15 6.56885 15.8451 6.16317 17.0216C7.30226 17.6454 8.60971 18 10 18C11.4333 18 12.7786 17.6231 13.9422 16.9629ZM10 12C7.79086 12 6 10.2091 6 8C6 5.79086 7.79086 4 10 4C12.2091 4 14 5.79086 14 8C14 10.2091 12.2091 12 10 12ZM10 6C8.89543 6 8 6.89543 8 8C8 9.10457 8.89543 10 10 10C11.1046 10 12 9.10457 12 8C12 6.89543 11.1046 6 10 6Z"
      fill="currentColor"
    />
  </svg>
);

export default ProfileIcon;
