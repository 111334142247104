import * as z from 'zod';

export const loginFormSchema = z.object({
  phone: z
    .string()
    .trim()
    .nonempty('Please provide a phone number')
    .min(11, 'Phone Number should be 11 digits')
    .max(11, 'Phone Number should be 11 digits'),

  password: z
    .string()
    .trim()
    .nonempty('Please provide a password')
    .min(8, 'Password should be at least 8 characters')
    .max(30, 'Password is too long'),
});

export type LoginFormProps = z.infer<
  typeof loginFormSchema
>;
