import * as z from 'zod';

export const otpFormSchema = z.object({
  otp: z
    .string()
    .trim()
    .nonempty('Please enter otp')
    .min(6, 'OTP should be 6 characters')
    .max(6, 'OTP should be 6 characters'),
});

export type OtpFormProps = z.infer<typeof otpFormSchema>;
