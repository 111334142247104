import { IconProps } from './icon';

const InfoIcon: React.FC<IconProps> = props => (
  <svg
    {...props}
    width="1.5em"
    height="1.5em"
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_583_1321)">
      <path
        d="M9.9881 16.6671C14.5848 16.6671 18.3112 13.2754 18.3112 9.09138C18.3112 4.90741 14.5848 1.51562 9.9881 1.51562C5.3914 1.51562 1.66504 4.90741 1.66504 9.09138C1.66504 13.2754 5.3914 16.6671 9.9881 16.6671Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.9873 12.1211V9.09082"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.9873 6.06055H9.99563"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_583_1321">
        <rect
          width="19.9753"
          height="18.1818"
          fill="currentColor"
        />
      </clipPath>
    </defs>
  </svg>
);

export default InfoIcon;
