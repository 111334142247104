import axios from 'axios';
import getUserState from 'src/utils/helpers/getUserState';

export const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  async config => {
    const user = await getUserState();

    if (!user) return config;
    config.headers.Authorization = `Bearer ${user.userToken}`;
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);
