import { IconProps } from './icon';

const EnvelopeIcon: React.FC<IconProps> = props => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 3C5 2.44771 5.44771 2 6 2H9.66667H14C14.5523 2 15 2.44772 15 3V5V7V8.84425L10.6139 12.2556C10.2528 12.5365 9.74717 12.5365 9.38606 12.2556L5 8.84425V7V5V3ZM3 5V3C3 1.34315 4.34314 0 6 0H9.66667H14C15.6569 0 17 1.34315 17 3V5C18.6569 5 20 6.34315 20 8V17C20 18.6569 18.6569 20 17 20H3C1.34315 20 0 18.6569 0 17V8C0 6.34315 1.34315 5 3 5ZM17 7V7.28869L17.3087 7.04857C17.2115 7.01704 17.1077 7 17 7ZM3 7C2.89226 7 2.7885 7.01704 2.69127 7.04857L3 7.28869V7ZM2 14.865V9.04464L5.61261 11.8544L2 14.865ZM3 18C2.58966 18 2.23705 17.7529 2.0829 17.3993L7.22853 13.1113L8.15818 13.8343C9.24151 14.6769 10.7585 14.6769 11.8418 13.8343L12.7715 13.1113L17.9171 17.3993C17.763 17.7529 17.4103 18 17 18H3ZM18 9.04464V14.865L14.3874 11.8544L18 9.04464Z"
      fill="currentColor"
    />
  </svg>
);

export default EnvelopeIcon;
