import { CONTACT_DETAILS } from './contactDetails';
import { ROUTES } from './routes';

type FooterLink = {
  heading: string;
  links: {
    text: string;
    href: string;
  }[];
};

export const FOOTER_LINKS: FooterLink[] = [
  {
    heading: 'company',
    links: [
      {
        text: 'About Us',
        href: ROUTES.aboutUs,
      },
      {
        text: 'Terms & Conditions',
        href: ROUTES.termsAndConditions,
      },
      {
        text: 'Privacy Policy',
        href: ROUTES.privacyPolicy,
      },
      {
        text: 'Careers',
        href: ROUTES.careers,
      },
    ],
  },
  {
    heading: 'support',
    links: [
      {
        text: 'Customer Support',
        href: ROUTES.customerSupport,
      },
      {
        text: 'FAQs',
        href: ROUTES.FAQs,
      },
      {
        text: 'Return Policy',
        href: ROUTES.returnPolicy,
      },
      {
        text: 'How it works',
        href: ROUTES.howItWorks,
      },
    ],
  },
  {
    heading: 'contact us',
    links: [
      {
        text: 'Contact',
        href: ROUTES.contactUs,
      },
      CONTACT_DETAILS.phoneNumber,
      CONTACT_DETAILS.emailAddress,
    ],
  },
];
