import { useRecoilState } from 'recoil';
import Button from 'src/components/atoms/Button';
import { Container } from 'src/components/atoms/Container';
import PlusRound from 'src/components/atoms/icons/PlusRound';
import Modal from 'src/components/atoms/Modal';
import AccountLayout from 'src/components/layouts/AccountLayout';
import AddressCard from 'src/components/molecules/AddressCard';
import AddressCardSkeleton from 'src/components/molecules/AddressCard/Skeleton';
import AddressForm from 'src/components/molecules/AddressForm';
import EditAddressForm from 'src/components/molecules/AddressForm/EditAddressForm';
import useGetAddress from 'src/network/react-query/query/address/getAddress';
import { MainDetails } from 'src/pages/Checkout/style';
import {
  addressFormModalState,
  editAddressModalState,
} from 'src/stores/menus/addressModal';
import { styled } from 'styled-components';
import { AddressHeader } from './style';

const AddressPage = () => {
  const [isFormOpen, setFormOpen] = useRecoilState(
    addressFormModalState
  );
  const [editFormState, setEditFormState] = useRecoilState(
    editAddressModalState
  );
  const { data, isLoading } = useGetAddress();

  if (isLoading)
    return (
      <AccountLayout>
        <Wrapper>
          <MainDetails>
            <AddressHeader>
              <h2>Address Book</h2>
              <Button onClick={() => setFormOpen(true)}>
                <PlusRound /> Add New Address
              </Button>
            </AddressHeader>

            {Array(2)
              .fill(null)
              .map((_, index) => (
                <AddressCardSkeleton key={index} />
              ))}
          </MainDetails>
        </Wrapper>
      </AccountLayout>
    );

  if (data)
    return (
      <AccountLayout>
        <Wrapper>
          <MainDetails>
            <AddressHeader>
              <h2>Address Book</h2>
              <Button onClick={() => setFormOpen(true)}>
                <PlusRound /> Add New Address
              </Button>
            </AddressHeader>

            {data.map((address, i) => (
              <AddressCard
                key={i}
                addressDetails={address}
              />
            ))}
          </MainDetails>
        </Wrapper>

        <Modal
          isOpen={isFormOpen}
          onClose={() => setFormOpen(false)}
        >
          <AddressForm />
        </Modal>

        <Modal
          isOpen={editFormState.isOpen}
          onClose={() =>
            setEditFormState({ isOpen: false })
          }
        >
          <EditAddressForm />
        </Modal>
      </AccountLayout>
    );

  return (
    <AccountLayout>
      <Wrapper>
        <MainDetails>
          <AddressHeader>
            <h2>Address Book</h2>
            <Button onClick={() => setFormOpen(true)}>
              <PlusRound /> Add New Address
            </Button>
          </AddressHeader>

          <p>No address available</p>
        </MainDetails>
      </Wrapper>

      <Modal
        isOpen={isFormOpen}
        onClose={() => setFormOpen(false)}
      >
        <AddressForm />
      </Modal>
    </AccountLayout>
  );
};

export default AddressPage;

const Wrapper = styled(Container)`
  margin-block: 20px;

  @media (min-width: 768px) {
    width: 100%;
  }
`;
