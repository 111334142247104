import Cookies from 'js-cookie';
import { useQueryClient } from 'react-query';
import { useRecoilState } from 'recoil';
import { AuthState } from 'src/interfaces/authState';
import { authState } from 'src/stores/auth/authState';
import { setUserCookie } from '../helpers/setUserCookie';

const useCurrentUser = () => {
  const [user, setUser] = useRecoilState(authState);
  const queryClient = useQueryClient();

  const logUserIn = (
    userDetails: NonNullable<AuthState>
  ) => {
    setUserCookie(userDetails);
    setUser(userDetails);
    queryClient.invalidateQueries(userDetails.userId);
  };

  const logUserOut = () => {
    Cookies.remove('VirpemUser');
    setUser(null);
    queryClient.resetQueries(['userFavourites']);
    queryClient.removeQueries();
  };

  return { user, logUserOut, logUserIn };
};

export default useCurrentUser;
