import Text from 'src/components/atoms/Text';
import { styled } from 'styled-components';

export const Group = styled.div`
  display: grid;
  min-width: 180px;
  max-width: 200px;

  &[data-gap] {
    gap: 16px;
  }
`;

export const LinkContent = styled.span`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;

  svg {
    color: ${p => p.theme.colors.primary[500]};
    font-size: 1.5rem;
    transition: 250ms;
  }
`;

export const LinkText = styled.span`
  display: none;

  @media (min-width: 768px) {
    display: contents;
  }
`;

export const Intro = styled(LinkContent)`
  padding: 14px 0 14px 16px;
  font-weight: 500;
  gap: 8px;

  svg {
    font-size: 1.652rem;
  }
`;

export const UserName = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 160px;
`;

export const SublinkContent = styled(LinkContent)`
  width: 100%;
  padding: 14px;
  border-top: 1px solid ${p => p.theme.colors.neutral[200]};
  transition: 250ms;
  font-size: 14px;
  font-weight: 500;

  &:hover {
    background-color: ${p => p.theme.colors.neutral[100]};
  }

  &[data-danger='true'] {
    color: ${p => p.theme.colors.danger[500]};

    svg {
      color: ${p => p.theme.colors.danger[500]};
    }
  }

  svg {
    font-size: 1rem;
  }
`;

export const Avatar = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 100px;
  display: grid;
  place-items: center;
  background-color: ${p => p.theme.colors.primary[500]};
  color: ${p => p.theme.colors.white};
  font-weight: 500;
`;
