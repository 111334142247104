import { Link } from 'react-router-dom';
import { Container } from 'src/components/atoms/Container';
import Heading from 'src/components/atoms/Heading';
import { styled } from 'styled-components';

export const TopRankingSection = styled.section`
  padding-top: 20px;
  padding-bottom: 40px;
`;

export const TopRankingHeader = styled.div`
  background-color: ${p => p.theme.colors.secondary};
`;

export const TopRankingContainer = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100px;

  @media (max-width: 579px) {
    height: 60px;
  }
`;

export const HeadingGroup = styled.div`
  p {
    color: ${p => p.theme.colors.grey[500]};
  }

  @media (max-width: 579px) {
    h2 {
      font-size: 1rem;
    }

    p {
      display: none;
    }
  }
`;

export const StyledHeading = styled(Heading)`
  color: ${p => p.theme.colors.primary[500]};
  font-weight: 700;
  font-size: 1.5rem;
`;

export const StyledButton = styled(Link)`
  display: flex;
  gap: 4px;
  align-items: center;
  color: ${p => p.theme.colors.primary[500]};
  font-weight: 600;

  @media (max-width: 579px) {
    font-size: 0.875rem;
  }
`;
