import { styled } from 'styled-components';

export const Wrapper = styled.div`
  display: grid;
  width: 100%;
  gap: 12px;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${p => p.theme.colors.neutral[300]};
  padding: 5px;
  border-radius: 4px;

  &[data-bg='gray'] {
    background-color: ${p => p.theme.colors.neutral[100]};
    color: ${p => p.theme.colors.black};
  }

  &[data-bg='transparent'] {
    color: ${p => p.theme.colors.black};
  }

  &[data-error='true'] {
    border-color: ${p => p.theme.colors.danger[700]};
  }
`;

export const CountryCodeDropdown = styled.select`
  margin-right: 5px;
  padding: 5px;
  width: 80px;
  cursor: pointer;
  background-color: transparent;
`;

export const PhoneNumberInput = styled.input`
  width: 100%;
  border: none;
  padding: 5px;
  background-color: transparent;
`;

export const InputMessage = styled.p`
  font-size: 0.875rem;
  color: ${p => p.theme.colors.danger[700]};
`;
