import { Container } from 'src/components/atoms/Container';
import styled from 'styled-components';

export const StyledNav = styled.nav`
  position: fixed;
  height: 100vh;
  overflow-y: auto;
  width: 100%;
  max-width: 350px;
  z-index: 9999;
  background-color: #ffffff;
  border-right: 1px solid #dee2e6;
  top: 0;
  left: -100%;
  transition: 250ms ease-in-out;

  &[data-open='true'] {
    left: 0;
  }

  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  scrollbar-track-color: transparent;

  /* Styling for WebKit browsers (Chrome, Safari) */
  &::-webkit-scrollbar {
    width: 4px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #a5adb5;
    border-radius: 50px;
  }

  @media (min-width: 768px) {
    background-color: ${p => p.theme.colors.secondary};
    height: 56px;
    z-index: 888;
    position: sticky;
    top: 80px;
    left: 0;
    max-width: 100%;
    overflow-y: visible;
  }
`;

export const MenuWrapper = styled(Container)`
  padding-block: 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  isolation: isolate;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: center;
    gap: 56px;
  }
`;

export const CloseButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
  position: sticky;
  top: 16px;
  z-index: 3;

  @media (min-width: 768px) {
    display: none;
  }
`;

export const Divider = styled.div`
  height: 2px;
  background-color: ${p => p.theme.colors.grey[200]};

  @media (min-width: 768px) {
    display: none;
  }
`;

export const StyledMenuItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  position: relative;
`;

export const MenuItemHeader = styled.button`
  font-family: Inter;
  font-size: 1rem;
  font-weight: 600;
  line-height: 19.36px;
  transition: 200ms ease;
  cursor: default;
  pointer-events: none;
  align-self: start;

  @media (min-width: 768px) {
    padding-inline: 12px;
    cursor: pointer;
    pointer-events: all;

    &:hover,
    &[data-open='true'] {
      text-decoration: underline;
      text-underline-offset: 18.5px;
      text-decoration-color: ${p =>
        p.theme.colors.primary[500]};
      text-decoration-thickness: 2px;
    }
  }
`;

export const StyledMenuItemContent = styled.div`
  background-color: white;
  transition: all 300ms ease-in-out;

  @media (min-width: 768px) {
    display: none;
    position: absolute;
    top: 40px;
    padding: 16px;
    transform: scale(0);

    box-shadow:
      0 4px 6px rgba(0, 0, 0, 0.1),
      0 1px 3px rgba(0, 0, 0, 0.08);

    &[data-open='true'] {
      display: block;
      transform: scale(1);
    }
  }
`;

export const LinkGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (min-width: 768px) {
    flex-direction: row;
    gap: 50px;
  }
`;

export const StyledLinkGroup = styled.div`
  @media (min-width: 768px) {
    width: 200px;
  }

  h3 {
    font-family: Inter;
    font-size: 1rem;
    font-weight: 600;
    line-height: 19.36px;
    background: rgba(245, 246, 248, 1);
    padding: 16px;
    margin-bottom: 8px;
  }

  a {
    padding: 10px;
    display: flex;
    gap: 10px;
    align-items: center;
    font-family: Inter;
    font-size: 1rem;
    font-weight: 600;
    line-height: 19.36px;
    text-align: left;
    transition: 200ms ease;

    &:hover {
      background-color: ${p => p.theme.colors.secondary};
    }
  }
`;
