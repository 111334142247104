import { styled } from 'styled-components';
import ChevronDown from '../icons/ChevronDown';

export const AccordionContainer = styled.div`
  position: relative;
`;

export const AccordionHeader = styled.button`
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  font-size: inherit;
  font-weight: 600;
  color: inherit;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 14px;
  width: 100%;
  padding: 1rem 0;
  transition: 150ms;
`;

export const HeaderIcon = styled(ChevronDown)`
  transition: 250ms;
  transform: rotate(0);

  &[data-open='true'] {
    transform: rotate(180deg);
  }
`;

export const AccordionContent = styled.div<{
  maxheight: string;
}>`
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.25s ease-in-out;

  &[data-open='true'] {
    max-height: ${props => props.maxheight};
  }
`;

export const AccordionLoading = styled.div`
  padding-bottom: 10px;
`;
