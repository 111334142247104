import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

type Screen =
  | 'mobileSmall'
  | 'mobileLarge'
  | 'tabletSmall'
  | 'tabletLarge'
  | 'laptop'
  | 'desktop';

const useResponsive = () => {
  const [windowSize, setWindowSize] = useState(0);
  const [screen, setScreen] =
    useState<Screen>('mobileSmall');

  let breakpoints: {
    mobileSmall: 320;
    mobileLarge: 460;
    tabletSmall: 620;
    tabletLarge: 768;
    laptop: 1024;
    desktop: 1200;
  } = useMemo(
    () => ({
      mobileSmall: 320,
      mobileLarge: 460,
      tabletSmall: 620,
      tabletLarge: 768,
      laptop: 1024,
      desktop: 1200,
    }),
    []
  );

  const getScreen = useCallback((): Screen => {
    if (windowSize <= breakpoints.mobileSmall) {
      return 'mobileSmall';
    }

    if (windowSize <= breakpoints.mobileLarge) {
      return 'mobileLarge';
    }

    if (windowSize <= breakpoints.tabletSmall) {
      return 'tabletSmall';
    }

    if (windowSize <= breakpoints.tabletLarge) {
      return 'tabletLarge';
    }

    if (windowSize <= breakpoints.laptop) {
      return 'laptop';
    }

    return 'desktop';
  }, [breakpoints, windowSize]);

  useEffect(() => {
    setWindowSize(window.innerWidth);

    window.addEventListener('resize', () =>
      setWindowSize(window.innerWidth)
    );

    return () =>
      window.removeEventListener('resize', () =>
        setWindowSize(window.innerWidth)
      );
  }, []);

  useEffect(() => {
    setScreen(getScreen);
  }, [windowSize, getScreen]);

  return { windowSize, breakpoints, screen };
};

export default useResponsive;
