import { useQuery } from 'react-query';
import getOrdersService from './../../../services/orders/getOrders';

export const useGetPendingOrders = () => {
  const { data, isLoading, isFetching } = useQuery(
    ['orders', { status: 'ongoing' }],
    () => getOrdersService('ongoing')
  );

  return { data, isLoading, isFetching };
};

export const useGetCompletedOrders = () => {
  const { data, isLoading, isFetching } = useQuery(
    ['orders', { status: 'completed' }],
    () => getOrdersService('completed')
  );

  return { data, isLoading, isFetching };
};

export const useGetCanceledOrders = () => {
  const { data, isLoading, isFetching } = useQuery(
    ['orders', { status: 'canceled' }],
    () => getOrdersService('canceled')
  );

  return { data, isLoading, isFetching };
};
