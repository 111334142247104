import React from 'react';
import {
  Buttons,
  CartFooter,
  CartImage,
  CategoryText,
  DetailsWrapper,
  ItemDetails,
  ItemWrapper,
  Price,
  Title,
  UpdateCartInputWrapper,
  UpdateCartSpinner,
} from './style';

import { toast } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import FavouriteButton from 'src/components/atoms/FavouriteButton';
import DeleteIcon from 'src/components/atoms/icons/DeleteIcon';
import MinusIcon from 'src/components/atoms/icons/MinusIcon';
import PlusIcon from 'src/components/atoms/icons/PlusIcon';
import { ROUTES } from 'src/constants/routes';
import useUpdateCart from 'src/network/react-query/mutation/cart/updateCart';
import useAddToFavourites from 'src/network/react-query/mutation/favourites/addToFavourites';
import useDeleteFavourite from 'src/network/react-query/mutation/favourites/deleteFavourite';
import useGetFavourites from 'src/network/react-query/query/favourites/getFavourites';
import { CartItemData } from 'src/network/services/cart/getCart';
import { cartModalState } from 'src/stores/menus/cartModalState';
import { formatPrice } from 'src/utils/helpers/formatPrice';
import { getPriceByQuantity } from 'src/utils/helpers/getPriceByQuantity';
import useToggleFavourite from 'src/utils/hooks/useToggleFavourite';

interface CartItemProps {
  details: CartItemData;
}

const CartItem: React.FC<CartItemProps> = ({ details }) => {
  const setModal = useSetRecoilState(cartModalState);

  const { toggleFavourite } = useToggleFavourite();
  const addFavouriteMutation = useAddToFavourites();
  const deleteFavouriteMutation = useDeleteFavourite();

  const { data: favourites } = useGetFavourites();

  return (
    <ItemWrapper>
      <Link
        to={
          ROUTES.products + `/${details.brandProductId._id}`
        }
      >
        <CartImage>
          <img
            src={details.productImageId.mainImageUrl}
            alt={details.name}
          />
        </CartImage>
      </Link>

      <DetailsWrapper>
        <ItemDetails>
          <div>
            <Title>
              {details.name} {details.specification}
            </Title>
            <CategoryText>
              {details.brandCategoryId.name}
            </CategoryText>
          </div>

          <Buttons>
            {favourites ? (
              <FavouriteButton
                disabled={
                  addFavouriteMutation.isAddingToFavourites ||
                  deleteFavouriteMutation.isDeletingFavourite
                }
                checked={
                  !!favourites.find(
                    value =>
                      value.brandProductId ===
                      details.brandProductId._id
                  )
                }
                onToggle={checked =>
                  toggleFavourite(
                    checked,
                    details.brandProductId._id
                  )
                }
              />
            ) : null}

            <button
              title="delete item"
              onClick={() =>
                setModal({
                  isOpen: true,
                  brandProductDetailId:
                    details.brandProductDetailsId,
                })
              }
            >
              <DeleteIcon />
            </button>
          </Buttons>
        </ItemDetails>

        <CartFooter>
          <Price data-total>
            {formatPrice(
              getPriceByQuantity(
                details.orderQuantity,
                details.fixedPrice,
                details.variablePrice
              )
            )}
          </Price>

          <UpdateCartInput details={details} />
        </CartFooter>
      </DetailsWrapper>
    </ItemWrapper>
  );
};

export default CartItem;

const UpdateCartInput: React.FC<CartItemProps> = ({
  details,
}) => {
  const { updateCart, isUpdatingCart } = useUpdateCart();

  const handleUpdateQuantity = (
    type: 'increment' | 'decrement'
  ) => {
    let newQuantity = details.orderQuantity;
    if (type === 'increment')
      newQuantity = details.orderQuantity + 1;

    if (type === 'decrement')
      newQuantity = details.orderQuantity - 1;

    if (newQuantity < 1)
      return toast.error(
        'Quantity is less than the minimum required',
        {
          id: 'quantityError',
        }
      );

    if (newQuantity > details.quantity) {
      return toast.error(
        'Quantity is above the maximum available',
        {
          id: 'quantityError',
        }
      );
    }

    updateCart({
      brandProductDetailId: details.brandProductDetailsId,
      orderQuantitys: newQuantity,
    });
  };

  return (
    <UpdateCartInputWrapper>
      <button
        title="add"
        disabled={isUpdatingCart}
        onClick={() => handleUpdateQuantity('decrement')}
      >
        <MinusIcon />
      </button>

      <div data-loading={isUpdatingCart}>
        {details.orderQuantity}
        {isUpdatingCart && (
          <UpdateCartSpinner data-variant="outlined" />
        )}
      </div>

      <button
        title="remove"
        disabled={
          isUpdatingCart ||
          details.orderQuantity === details.quantity
        }
        onClick={() => handleUpdateQuantity('increment')}
      >
        <PlusIcon />
      </button>
    </UpdateCartInputWrapper>
  );
};
