import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import updateUserProfileService from 'src/network/services/profile/updateProfile';
import useCurrentUser from 'src/utils/hooks/useCurrentUser';

const useUpdateProfile = () => {
  const { logUserIn, user } = useCurrentUser();

  return useMutation({
    mutationKey: ['update profile'],
    mutationFn: updateUserProfileService,
    onSuccess: data => {
      toast.success('Email updated successfully');
      if (!user) return;
      logUserIn({
        ...user,
        email: data.email,
      });
    },
  });
};

export default useUpdateProfile;
