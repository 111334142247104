import { ApiResponse } from 'src/network/apiResponse';
import { API_ROUTES } from 'src/network/apiRoutes';
import { axiosInstance } from 'src/network/axios';
import handleServiceError from 'src/utils/helpers/handleServiceError';

export interface RateProductPayload {
  productId: string;
  star: number;
  review?: string;
}

const rateProductService = async (
  payload: RateProductPayload
) => {
  try {
    const response = await axiosInstance.post<
      ApiResponse<Response>
    >(API_ROUTES.rating, payload);
    return response.data;
  } catch (error) {
    throw handleServiceError(error);
  }
};

export default rateProductService;
