import LandingLayout from 'src/components/layouts/LandingLayout';
import styled from 'styled-components';
import HeroSection from './components/Hero';
import Navigation from './components/Navigation';
import PopularProducts from './components/PopularProducts';
import VendorSection from './components/VendorSection';
import WaitlistSection from './components/WaitlistSection';

const LandingPage = () => {
  return (
    <LandingLayout>
      <Navigation />
      <StyledArticle>
        <HeroSection />
        <PopularProducts />
        <VendorSection />
        <WaitlistSection />
      </StyledArticle>
    </LandingLayout>
  );
};

export default LandingPage;

const StyledArticle = styled.article`
  padding-top: 24px;
  padding-bottom: 75px;
  display: grid;
  gap: 64px;

  @media (min-width: 768px) {
    gap: 80px;
  }
`;
