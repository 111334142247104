import { Toaster } from 'react-hot-toast';
import { Spinner } from '../Button/style';
import InfoIcon from '../icons/InfoIcon';

const ToastBar = () => {
  return (
    <Toaster
      containerStyle={{
        top: 80,
      }}
      toastOptions={{
        icon: <InfoIcon />,
        style: {
          // width: '90%',
          // maxWidth: '90%',
          background: '#10B981',
          color: 'white',
          display: 'grid',
          gridTemplateColumns: '30px 1fr',
          justifyItems: 'start',
          borderRadius: '5px',
          padding: '16px 32px',
          fontWeight: 600,
          zIndex: 2000,
        },
        success: {
          style: {
            background: '#10B981',
            color: 'white',
          },
        },
        error: {
          style: {
            background: '#F87171',
          },
        },
        loading: {
          icon: (
            <Spinner
              data-variant="primary"
              style={{ position: 'static' }}
            />
          ),
        },
      }}
    />
  );
};

export default ToastBar;
