import Heading from 'src/components/atoms/Heading';
import Modal from 'src/components/atoms/Modal';
import Text from 'src/components/atoms/Text';
import { Content, ImgWrapper } from './style';
import CompletedIcon from 'src/components/atoms/icons/CompletedIcon';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  heading: React.ReactNode;
  text?: React.ReactNode;
  footer: React.ReactNode;
  icon?: React.ReactNode;
}

const ActionModal: React.FC<Props> = ({
  isOpen,
  onClose,
  heading,
  text,
  footer,
  icon,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Content>
        <ImgWrapper>
          {icon ? icon : <CompletedIcon />}
        </ImgWrapper>
        <Heading
          as="h2"
          level={4}
          style={{
            textAlign: 'center',
          }}
        >
          {heading}
        </Heading>
        {text && (
          <Text
            shade={700}
            style={{
              marginBottom: '28px',
              textAlign: 'center',
              lineHeight: 1.8,
            }}
          >
            {text}
          </Text>
        )}
        {footer}
      </Content>
    </Modal>
  );
};

export default ActionModal;
