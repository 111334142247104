import { Suspense, useEffect } from 'react';
import {
  BrowserRouter,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { ThemeProvider } from 'styled-components';
import './assets/fonts/fonts.css';
import ToastBar from './components/atoms/ToastBar';
import { ROUTES } from './constants/routes';
import ContactPage from './pages/Contact';
import NotFoundPage from './pages/NotFound';
import getAccountRoutes from './routes/accountRoutes';
import getAuthRoutes from './routes/authRoutes';
import getProductRoutes from './routes/productRoutes';
import GlobalStyle from './utils/styled-components/GlobalStyle';
import { theme } from './utils/styled-components/theme';

import {
  QueryClient,
  QueryClientProvider,
} from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import FavouriteModal from './components/molecules/FavouriteModal';
// import ServerErrorPage from './pages/500';
import LandingPage from './pages/Landing';
import handleMutationError from './utils/helpers/handleMutationError';
import useAppUpdate from './utils/hooks/useAppUpdate';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      onError: handleMutationError,
    },
  },
});

const App = () => {
  useAppUpdate();

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools />
        <RecoilRoot>
          <Suspense>
            <BrowserRouter>
              <ScrollToTop />
              <ToastBar />
              <Routes>
                {/*                 <Route
                  path="*"
                  element={<ServerErrorPage />}
                /> */}
                <Route
                  path={ROUTES.home}
                  element={<LandingPage />}
                />

                <Route
                  path={ROUTES.contactUs}
                  element={<ContactPage />}
                />

                <Route
                  path="*"
                  element={<NotFoundPage />}
                />

                {getAuthRoutes()}
                {getAccountRoutes()}
                {getProductRoutes()}
              </Routes>
              <FavouriteModal />
            </BrowserRouter>
          </Suspense>
        </RecoilRoot>
      </QueryClientProvider>
    </ThemeProvider>
  );
};

export default App;
