import { styled } from 'styled-components';

export const Grid = styled.div`
  display: grid;
  margin-block: 30px;
  gap: 20px;
  row-gap: 40px;
  grid-template-columns: repeat(
    auto-fill,
    minmax(160px, 1fr)
  );

  @media (min-width: 768px) {
    grid-template-columns: repeat(
      auto-fill,
      minmax(180px, 1fr)
    );
  }
`;
