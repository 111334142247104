import { ApiResponse } from 'src/network/apiResponse';
import { API_ROUTES } from 'src/network/apiRoutes';
import { axiosInstance } from 'src/network/axios';
import handleServiceError from 'src/utils/helpers/handleServiceError';

interface Payload {
  country: string;
  state: string;
  city: string;
  address: string;
  isDefault: boolean;
}

const addAddressService = async (payload: Payload) => {
  try {
    const response = await axiosInstance.post<
      ApiResponse<string>
    >(API_ROUTES.addAddress, payload);
    return response.data;
  } catch (error) {
    throw handleServiceError(error);
  }
};

export default addAddressService;
