import queryString from 'query-string';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import useSearchAndFilter from 'src/utils/hooks/useSearchAndFilter';

type SortType = 'none' | 'high-to-low' | 'low-to-high';
interface SortOptions {
  value: SortType;
  label: string;
}

const useSort = () => {
  const { updateSearchAndFilterState } =
    useSearchAndFilter();
  const location = useLocation();
  const { from = '' } = queryString.parse(location.search);

  const sortOptions: SortOptions[] = [
    {
      value: 'none',
      label: 'Price: None',
    },
    {
      value: 'high-to-low',
      label: 'Price: high to low',
    },
    {
      value: 'low-to-high',
      label: 'Price: low to high',
    },
  ];

  const handleSort = (value: string) => {
    const type = value as SortType;

    if (type === 'none') {
      updateSearchAndFilterState({ from: '', to: '' });
    } else if (type === 'high-to-low') {
      updateSearchAndFilterState({ from: '2', to: '1' });
    } else {
      updateSearchAndFilterState({ from: '1', to: '2' });
    }
  };

  const defaultSort = useMemo(() => {
    return from === ''
      ? 'none'
      : from === '2'
      ? 'high-to-low'
      : 'low-to-high';
  }, [from]);

  return { handleSort, defaultSort, sortOptions };
};

export default useSort;
