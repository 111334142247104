import { ApiResponse } from 'src/network/apiResponse';
import { API_ROUTES } from 'src/network/apiRoutes';
import { axiosInstance } from 'src/network/axios';
import getUserState from 'src/utils/helpers/getUserState';
import handleServiceError from 'src/utils/helpers/handleServiceError';
import { VariablePrice } from '../lubricants/addLubricant';

const getCartService = async () => {
  try {
    const user = await getUserState();
    if (!user) {
      throw Error('User is not signed in');
    }

    const { userId } = user;

    const response = await axiosInstance.get<
      ApiResponse<Data[]>
    >(API_ROUTES.getCart + `/${userId}`);
    return response.data.data[0];
  } catch (error) {
    throw handleServiceError(error);
  }
};

export default getCartService;

export interface CartItemData {
  brandProductDetailsId: string;
  name: string;
  quantity: number;
  description: string;
  volume: string;
  specification: string;
  packageSize: string;
  brandProductId: {
    _id: string;
    name: string;
    brandId: string;
  };
  variablePrice: VariablePrice[];
  fixedPrice: number;
  productImageId: {
    _id: string;
    frontImageUrl: string;
    sideImageUrl: string;
    rearImageUrl: string;
    mainImageUrl: string;
    brandProductId: string;
  };
  brandCategoryId: {
    _id: string;
    name: string;
  };
  rating: number;
  remainingQuantity: number;
  orderQuantity: number;
  totalSelectedProductPrice: number;
  _id: string;
}

interface Data {
  _id: string;
  userId: string;
  items: CartItemData[];

  totalPrice: 914000;
}
