import React, {
  KeyboardEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import Skeleton from 'react-loading-skeleton';
import {
  AccordionContainer,
  AccordionContent,
  AccordionHeader,
  AccordionLoading,
  HeaderIcon,
} from './style';

interface AccordionProps {
  children: React.ReactNode;
  heading: React.ReactNode;
  autoClose?: boolean;
  defaultOpen?: boolean;
  loading?: boolean;
}

export const Accordion: React.FC<AccordionProps> = ({
  children,
  heading,
  autoClose = false,
  defaultOpen = false,
  loading = false,
}) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const [contentHeight, setContentHeight] = useState('0px');
  const accordionRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLButtonElement>(null);

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (
        autoClose &&
        accordionRef.current &&
        !accordionRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    },
    [autoClose]
  );

  const handleKeyDown = (
    event: KeyboardEvent<HTMLButtonElement>
  ) => {
    if (event.key === 'Enter' || event.key === ' ') {
      toggleAccordion();
      event.preventDefault();
    } else if (event.key === 'ArrowUp' && isOpen) {
      focusPreviousHeader();
      event.preventDefault();
    } else if (event.key === 'ArrowDown' && isOpen) {
      focusNextHeader();
      event.preventDefault();
    }
  };

  const toggleAccordion = () => {
    setIsOpen(prevState => !prevState);
  };

  const focusNextHeader = () => {
    if (headerRef.current) {
      const nextHeader = headerRef.current
        .nextElementSibling as HTMLButtonElement;
      if (nextHeader) {
        nextHeader.focus();
      }
    }
  };

  const focusPreviousHeader = () => {
    if (headerRef.current) {
      const previousHeader = headerRef.current
        .previousElementSibling as HTMLButtonElement;
      if (previousHeader) {
        previousHeader.focus();
      }
    }
  };

  useEffect(() => {
    document.addEventListener(
      'mousedown',
      handleClickOutside
    );
    return () => {
      document.removeEventListener(
        'mousedown',
        handleClickOutside
      );
    };
  }, [handleClickOutside]);

  useEffect(() => {
    if (isOpen && contentRef.current) {
      setContentHeight(
        `${contentRef.current.scrollHeight}px`
      );
    } else {
      setContentHeight('0px');
    }
  }, [isOpen, contentRef.current?.scrollHeight]);

  return (
    <AccordionContainer ref={accordionRef}>
      <AccordionHeader
        ref={headerRef}
        onClick={toggleAccordion}
        onKeyDown={handleKeyDown}
        aria-expanded={isOpen}
        aria-controls="accordion-content"
      >
        {heading}
        <HeaderIcon data-open={isOpen} />
      </AccordionHeader>

      {loading ? (
        <AccordionLoading>
          <Skeleton />
          <Skeleton />
        </AccordionLoading>
      ) : (
        <AccordionContent
          ref={contentRef}
          data-open={isOpen}
          maxheight={contentHeight}
          id="accordion-content"
        >
          {children}
        </AccordionContent>
      )}
    </AccordionContainer>
  );
};
