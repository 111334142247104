import * as z from 'zod';

export const addressFormSchema = z.object({
  country: z
    .string()
    .trim()
    .nonempty('Please provide a country'),
  state: z
    .string()
    .trim()
    .nonempty('Please provide a state'),
  city: z.string().trim().nonempty('Please provide a city'),
  address: z
    .string()
    .trim()
    .nonempty('Please provide an address'),
  isDefault: z.boolean(),
});

export type AddressFormProps = z.infer<
  typeof addressFormSchema
>;
