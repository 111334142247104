import { toast } from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'src/constants/routes';
import createOrderService from 'src/network/services/orders/createOrder';

const useCreateOrder = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const mutation = useMutation(createOrderService, {
    onSuccess: res => {
      toast.dismiss();
      toast.success('Your order was placed successfully', {
        id: 'order',
      });
      queryClient.refetchQueries('orders');
      queryClient.refetchQueries('cart');
      navigate(ROUTES.history);
    },
  });

  const {
    mutate: createOrder,
    isLoading: isCreatingOrder,
    data,
  } = mutation;

  return { createOrder, isCreatingOrder, data };
};

export default useCreateOrder;
