import { Container } from 'src/components/atoms/Container';
import { styled } from 'styled-components';

export const CartSection = styled.section`
  margin-block: 20px;
`;

export const CartGrid = styled(Container)`
  display: grid;
  gap: 30px;
  grid-template-columns: 1fr 340px;
  align-items: start;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
`;

export const CartCard = styled.div`
  background: ${p => p.theme.colors.white};
  padding: 2rem;
  box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.03);
  border-radius: 8px;

  @media (max-width: 768px) {
    padding: 14px;
  }
`;

export const OrderSummary = styled(CartCard)`
  position: sticky;
  top: 160px;

  a {
    margin-top: 32px;
  }
`;

export const SummaryGroup = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 2rem;

  p[data-heading='true'] {
    font-weight: 600;
  }

  p[data-price='true'] {
    font-weight: 500;
  }

  p[data-total-price='true'] {
    font-weight: 700;
  }
`;

export const Small = styled.small`
  display: flex;
  align-items: center;
  margin-top: 12px;
  color: #6a6a6a;
  gap: 6px;
`;

export const ProductDetails = styled.div`
  display: grid;
  grid-template-columns: 300px repeat(3, 1fr);
  gap: 32px;

  p {
    font-weight: 600;

    &:nth-child(3) {
      justify-self: center;
    }
  }

  @media (max-width: 840px) {
    display: none;
    & ~ div[data-divider='true'] {
      display: none;
    }
  }
`;

export const EmptyCart = styled(Container)`
  display: grid;
  place-items: center;

  h2 {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
    text-align: center;
  }

  p {
    text-align: center;
    margin-bottom: 2rem;
  }

  span {
    svg {
      width: 100px;
      height: 100px;
    }
  }
`;
