import Skeleton from 'react-loading-skeleton';
import { CartImage, ItemWrapper } from './style';

const CartItemSkeleton = () => {
  return (
    <ItemWrapper>
      <CartImage />

      <div>
        <Skeleton height={14} />
        <Skeleton height={14} />
        <Skeleton height={14} />
        <Skeleton height={14} style={{ width: '50%' }} />
      </div>
    </ItemWrapper>
  );
};

export default CartItemSkeleton;
