import axios from 'axios';
import { ApiResponse } from 'src/network/apiResponse';
import { API_ROUTES } from 'src/network/apiRoutes';
import handleServiceError from 'src/utils/helpers/handleServiceError';

interface Payload {
  username: string;
  password: string;
}

// interface ResponseData {
//   _id: string;
//   phoneNumber: string;
//   token: string;
//   isCompleteSignUp: boolean;
//   firstName: string;
//   lastName: string;
//   tradeType: string;
//   email?: string;
// }
interface ResponseData {
  _id: string;
  role: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  isCompleteSignup: boolean;
  businessType: 'vendor' | 'buyer';
  token: string;
  tradeType: string[];
}

const loginService = async (payload: Payload) => {
  try {
    const response = await axios.post<
      ApiResponse<ResponseData>
    >(API_ROUTES.signIn, payload);

    return response.data;
  } catch (error) {
    throw handleServiceError(error);
  }
};

export default loginService;
