import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from 'src/components/atoms/Button';
import DeleteIcon from 'src/components/atoms/icons/DeleteIcon';
import ProfileIcon from 'src/components/atoms/icons/ProfileIcon';
import Popover from 'src/components/atoms/Popover';
import { ROUTES } from 'src/constants/routes';
import useCurrentUser from 'src/utils/hooks/useCurrentUser';
import useResponsive from 'src/utils/hooks/useResponsive';
import {
  Avatar,
  Group,
  Intro,
  LinkContent,
  LinkText,
  SublinkContent,
  UserName,
} from './style';

const ProfileMenu = () => {
  const [iconVisible, setIconVisible] = useState(false);
  const { user, logUserOut } = useCurrentUser();

  const { windowSize } = useResponsive();

  useEffect(() => {
    if (windowSize >= 768) {
      setIconVisible(true);
    } else {
      setIconVisible(false);
    }
  }, [windowSize]);

  let ProfileLink;

  if (!user) {
    ProfileLink = (
      <Popover
        showIcon={iconVisible}
        heading={
          <LinkContent>
            <ProfileIcon />
            <LinkText>Login / Register</LinkText>
          </LinkContent>
        }
      >
        <Group data-gap>
          <Button asLink href={ROUTES.register}>
            Register
          </Button>

          <Button
            asLink
            href={ROUTES.login}
            variant="secondary"
          >
            Login
          </Button>
        </Group>
      </Popover>
    );
  } else {
    ProfileLink = (
      <Popover
        showIcon={iconVisible}
        heading={
          <LinkContent>
            <ProfileAvatar name={user.firstName} />
            <LinkText>Account</LinkText>
          </LinkContent>
        }
      >
        <Group>
          <Intro>
            <ProfileAvatar name={user.firstName} />
            Hi,
            <UserName size="sm">{user.firstName}</UserName>
          </Intro>

          <Link to={ROUTES.profile}>
            <SublinkContent>
              <ProfileIcon />
              Account
            </SublinkContent>
          </Link>

          <Button variant="ghost" onClick={logUserOut}>
            <SublinkContent data-danger>
              <DeleteIcon />
              Log Out
            </SublinkContent>
          </Button>
        </Group>
      </Popover>
    );
  }

  return ProfileLink;
};

export default ProfileMenu;

const ProfileAvatar = ({ name }: { name: string }) => {
  return <Avatar>{name.charAt(0)}</Avatar>;
};
