import { Link } from 'react-router-dom';
import { styled } from 'styled-components';

export const Card = styled.article`
  display: grid;
  width: 100%;
  padding: 24px;
  gap: 24px;
  background-color: ${p => p.theme.colors.white};
  border: 1px solid #eff1f3;
  box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.03);
  border-radius: 13px;
`;

export const CardHead = styled.div`
  display: grid;
  width: 100%;
  gap: 14px;

  @media (min-width: 768px) {
    display: flex;
    align-items: end;
    justify-content: space-between;
  }
`;

export const OrderDetails = styled.div`
  display: grid;
  gap: 10px;

  h3 {
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 22px;

    span {
      font-weight: 400;
    }
  }

  div {
    display: flex;
    gap: 12px;
    font-size: 0.875rem;
    line-height: 17px;
    color: #444444;
    margin-block: 10px;
  }
`;

export const OrderImages = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 1rem;
`;

export const ImageWrapper = styled.div`
  background-color: ${p => p.theme.colors.grey[200]};
  border-radius: 4px;
  height: 100px;
  width: 100px;
  padding: 2rem;

  @media (min-width: 768px) {
    width: 142px;
    height: 138px;
  }
`;

export const MoreItems = styled(Link)`
  height: 100px;
  width: 100px;
  background-color: #00000052;
  display: grid;
  place-items: center;
  color: ${p => p.theme.colors.white};
  font-size: 1.125rem;
  font-weight: 500;
  border-radius: 4px;

  @media (min-width: 768px) {
    width: 142px;
    height: 138px;
  }
`;
