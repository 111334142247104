import Heading from 'src/components/atoms/Heading';
import Text from 'src/components/atoms/Text';
import AuthLayout from 'src/components/layouts/AuthLayout';
import ResetPasswordForm from 'src/components/molecules/ResetPasswordForm';
import { styled } from 'styled-components';

const ResetPasswordPage = () => {
  return (
    <AuthLayout>
      <Group>
        <Heading as="h1" level={4}>
          Reset Password
        </Heading>
        <Text size="lg" shade={600}>
          Enter the phone number you used to create your
          account. An OTP will be sent to your phone number.
        </Text>
      </Group>

      <ResetPasswordForm />
    </AuthLayout>
  );
};

export default ResetPasswordPage;

const Group = styled.div`
  margin-bottom: 2rem;
`;
