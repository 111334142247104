import { Fragment, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import Button from 'src/components/atoms/Button';
import CheckBox from 'src/components/atoms/Checkbox';
import { Container } from 'src/components/atoms/Container';
import { Divider } from 'src/components/atoms/Divider';
import Heading from 'src/components/atoms/Heading';
import ChevronLeft from 'src/components/atoms/icons/ChevronLeft';
import ChevronRight from 'src/components/atoms/icons/ChevronRight';
import CartItemSkeleton from 'src/components/molecules/CartItem/Skeleton';
import CheckoutAddressPicker from 'src/components/molecules/CheckoutAddressPicker';
import CheckoutItem from 'src/components/molecules/CheckoutItem';
import DeliveryInfo from 'src/components/molecules/DeliveryInfo';
import CheckoutHeader from 'src/components/molecules/Headers/CheckoutHeader';
import OrderSummary from 'src/components/molecules/OrderSummary';
import { ROUTES } from 'src/constants/routes';
import useGetCart from 'src/network/react-query/query/cart/getCart';
import { checkoutActiveTabState } from 'src/stores/checkout/checkoutActiveTab';
import {
  Card,
  Checkout,
  HeadingGroup,
  MainDetails,
  OrderDetails,
  PaymentInfo,
  StyledLink,
  Wrapper,
} from './style';

export enum PAYMENT_TYPE {
  delivery = 'payOnDelivery',
  online = 'payOnline',
}

const CheckoutPage = () => {
  const [paymentType, setPaymentType] =
    useState<PAYMENT_TYPE>(PAYMENT_TYPE.online);
  const [activeTab, setActiveTab] = useRecoilState(
    checkoutActiveTabState
  );

  useEffect(() => {
    return () => {
      setActiveTab('checkoutDetails');
    };
  }, [setActiveTab]);

  return (
    <>
      <CheckoutHeader />
      <main>
        {activeTab === 'checkoutDetails' && (
          <Container>
            <StyledLink to="/">
              <ChevronLeft />
              Go back and continue shopping
            </StyledLink>
          </Container>
        )}

        <Wrapper>
          <MainDetails>
            {activeTab === 'checkoutDetails' ? (
              <CheckoutDetails
                paymentType={paymentType}
                setPaymentType={setPaymentType}
              />
            ) : (
              <CheckoutAddressPicker />
            )}
          </MainDetails>

          <Checkout>
            <OrderSummary
              paymentType={paymentType}
              disabled={activeTab === 'addressBook'}
            />
          </Checkout>
        </Wrapper>
      </main>
    </>
  );
};

export default CheckoutPage;

const CheckoutDetails: React.FC<{
  paymentType: PAYMENT_TYPE;
  setPaymentType: React.Dispatch<
    React.SetStateAction<PAYMENT_TYPE>
  >;
}> = ({ paymentType, setPaymentType }) => {
  const setActiveTab = useSetRecoilState(
    checkoutActiveTabState
  );
  const { data: cartData, isLoading: isGettingCart } =
    useGetCart();

  if (cartData && !cartData.items.length)
    return <Navigate to={ROUTES.cart} />;

  const handlePaymentType = (
    checked: boolean,
    id: PAYMENT_TYPE
  ) => {
    if (checked) {
      setPaymentType(id);
    }
  };

  return (
    <>
      <OrderDetails>
        <Heading as="h2" level={6}>
          Order Details{' '}
          {cartData?.items.length ? (
            <>({cartData.items.length})</>
          ) : null}
        </Heading>

        <Divider />

        {isGettingCart ? (
          Array(2)
            .fill(null)
            .map((_, i) => (
              <Fragment key={i}>
                <CartItemSkeleton />

                <Divider />
              </Fragment>
            ))
        ) : cartData ? (
          cartData.items.map(product => (
            <CheckoutItem
              key={product._id}
              details={{
                brandProductId: product.brandProductId._id,
                name: product.name,
                specification: product.specification,
                category: product.brandCategoryId.name,
                fixedPrice: product.fixedPrice,
                imageUrl:
                  product.productImageId.mainImageUrl,
                orderQuantity: product.orderQuantity,
                variablePrice: product.variablePrice,
                volume: product.volume,
              }}
            />
          ))
        ) : (
          <p>No items availble</p>
        )}
      </OrderDetails>

      <Card>
        <HeadingGroup>
          <Heading as="h2" level={6}>
            Delivery Details{' '}
            {cartData?.items.length ? (
              <>({cartData.items.length})</>
            ) : null}
          </Heading>

          <Button
            variant="ghost"
            onClick={() => setActiveTab('addressBook')}
          >
            Change
            <ChevronRight />
          </Button>
        </HeadingGroup>

        <Divider margin="0 0 28px 0" />

        <DeliveryInfo />
      </Card>

      <Card>
        <Heading as="h2" level={6}>
          Payment method
        </Heading>

        <Divider margin="20px 0" />

        <PaymentInfo>
          <CheckBox
            id={PAYMENT_TYPE.delivery}
            checked={paymentType === PAYMENT_TYPE.delivery}
            onChange={e =>
              handlePaymentType(
                e.target.checked,
                e.target.id as PAYMENT_TYPE
              )
            }
          >
            Pay on delivery
          </CheckBox>

          <CheckBox
            id={PAYMENT_TYPE.online}
            checked={paymentType === PAYMENT_TYPE.online}
            onChange={e =>
              handlePaymentType(
                e.target.checked,
                e.target.id as PAYMENT_TYPE
              )
            }
          >
            Pay with Card or Bank transfer
          </CheckBox>

          <p>You will be redirected to the payment page</p>
        </PaymentInfo>
      </Card>
    </>
  );
};
