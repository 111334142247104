import { useQuery } from 'react-query';
import getOrderByIdService from 'src/network/services/orders/getOrderById';

const useGetOrderById = (orderId: string) => {
  return useQuery(['orders', orderId], () =>
    getOrderByIdService(orderId)
  );
};

export default useGetOrderById;
