import React, { useState } from 'react';
import toast from 'react-hot-toast';
import Button from 'src/components/atoms/Button';
import CustomSelect from 'src/components/atoms/CustomSelect';
import Form from 'src/components/atoms/Form';
import Modal from 'src/components/atoms/Modal';
import Textarea from 'src/components/atoms/Textarea';
import useCancelOrder from 'src/network/react-query/mutation/orders/cancleOrder';
import { styled } from 'styled-components';

const CANCEL_REASONS = [
  'I am no longer interested',
  'Expected delivery date has changed and the product is arriving at a later date',
  'Product is being delivered to a wrong address',
];

interface Props {
  _id: string;
  isOpen: boolean;
  onClose: () => void;
}

const CancelOrderModal: React.FC<Props> = ({
  _id,
  isOpen,
  onClose,
}) => {
  const [cancelReason, setCancelReason] = useState(
    CANCEL_REASONS[0]
  );
  const [otherReason, setOtherReason] = useState('');

  const {
    mutate: cancelOrder,
    isLoading: isCancelingOrder,
  } = useCancelOrder(_id, onClose);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    toast.loading('Cancelling order', { id: 'order' });
    cancelOrder();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      style={{ maxWidth: '557px' }}
    >
      <Heading>
        <h2>Cancel Order</h2>
        <p>Let us know why you want to cancel your order</p>
      </Heading>

      <Content>
        <Form onSubmit={handleSubmit}>
          <CustomSelect
            label="Reason for cancellation"
            placeholder="Select Reason"
            value={cancelReason}
            options={CANCEL_REASONS.map(reason => ({
              label: reason,
              value: reason,
            }))}
            onChange={value => setCancelReason(value)}
          />

          <Textarea
            label="Other reason (state why)"
            placeholder="Reason..."
            value={otherReason}
            onChange={e => setOtherReason(e.target.value)}
            style={{
              height: '166px',
              resize: 'none',
            }}
          />

          <Button
            type="submit"
            disabled={isCancelingOrder}
            loading={isCancelingOrder}
          >
            Cancel Order
          </Button>
        </Form>
      </Content>
    </Modal>
  );
};

export default CancelOrderModal;

const Heading = styled.div`
  margin-bottom: 24px;

  h2 {
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 12px;
  }
`;

const Content = styled.div`
  display: grid;
  gap: 20px;
`;
