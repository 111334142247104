import { styled } from 'styled-components';
import StarFill from 'src/components/atoms/icons/StarFill';
import StarEmpty from 'src/components/atoms/icons/StarEmpty';

export type StarsRating = 0 | 1 | 2 | 3 | 4 | 5;

const Rating: React.FC<{
  stars: StarsRating;
}> = ({ stars }) => {
  const getEmptyStars = (stars: StarsRating) => {
    const emptyStars = Math.round(5 - stars);

    if (emptyStars + Math.round(stars) > 5) {
      const newEmptystars = Math.floor(5 - stars);
      return newEmptystars;
    }
    return emptyStars;
  };

  return (
    <Wrapper>
      {Array(Math.round(stars))
        .fill(null)
        .map((_, i) => (
          <StarFill key={i} />
        ))}

      {Array(getEmptyStars(stars))
        .fill(null)
        .map((_, i) => (
          <StarEmpty key={i} />
        ))}
    </Wrapper>
  );
};

export default Rating;

const Wrapper = styled.div`
  display: flex;
  gap: 4px;

  font-size: 0.75rem;

  @media (min-width: 768px) {
    font-size: 1rem;
  }
`;
