import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import Button from 'src/components/atoms/Button';
import CheckBox from 'src/components/atoms/Checkbox';
import CustomSelect from 'src/components/atoms/CustomSelect';
import Form from 'src/components/atoms/Form';
import Input from 'src/components/atoms/Input';
import { CITIES } from 'src/constants/cities';
import { STATES_AND_CITIES } from 'src/constants/stateAndCities';
import useUpdateAddress from 'src/network/react-query/mutation/address/updateAddress';
import { editAddressModalState } from 'src/stores/menus/addressModal';
import {
  AddressFormProps,
  addressFormSchema,
} from './schema';

const DEFAULT_COUNTRY = 'Nigeria';

const EditAddressForm = () => {
  const editFormState = useRecoilValue(
    editAddressModalState
  );
  const { updateAddress, isUpdatingAddress } =
    useUpdateAddress();

  const { address, city, state, isDefault } =
    //@ts-expect-error This form can only be accessed
    //if the modal state is true
    editFormState.addressDetails;
  //@ts-expect-error This form can only be accessed
  //if the modal state is true
  const addressId = editFormState.addressId;

  const {
    control,
    register,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm<AddressFormProps>({
    defaultValues: {
      country: DEFAULT_COUNTRY,
      address,
      city,
      state,
      isDefault,
    },
    resolver: zodResolver(addressFormSchema),
    mode: 'onBlur',
  });

  const onSubmit = (formData: AddressFormProps) => {
    updateAddress({
      addressId,
      details: {
        address: formData.address,
        city: formData.city,
        state: formData.state,
        isDefault: formData.isDefault,
      },
    });
  };

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      onChange={() => clearErrors()}
    >
      <Input
        bg="gray"
        label="Address"
        placeholder="Address"
        {...register('address')}
        error={!!errors.address}
        message={errors.address?.message}
      />

      <Controller
        name="state"
        control={control}
        render={({ field: { value, onChange } }) => (
          <CustomSelect
            value={value}
            label="State"
            placeholder="Select State"
            options={STATES_AND_CITIES.map(value => ({
              label: value.state,
              value: value.state,
            }))}
            onChange={value => {
              onChange(value);
              setValue('city', '');
            }}
            error={!!errors.address}
            message={errors.address?.message}
          />
        )}
      />

      <Controller
        control={control}
        name="city"
        render={({ field: { value, onChange } }) => (
          <CustomSelect
            value={value}
            label="City"
            placeholder="Select city"
            options={CITIES.sort((a, b) =>
              a.localeCompare(b)
            ).map(city => ({
              label: city,
              value: city,
            }))}
            onChange={value => {
              onChange(value);
            }}
            error={!!errors.city}
            message={errors.city?.message}
          />
        )}
      />

      <CheckBox id="address" {...register('isDefault')}>
        Save as default
      </CheckBox>
      <Button
        loading={isUpdatingAddress}
        disabled={isUpdatingAddress}
        type="submit"
      >
        Save Address
      </Button>
    </Form>
  );
};

export default EditAddressForm;
