import { toast } from 'react-hot-toast';
import { useSetRecoilState } from 'recoil';
import useAddToFavourites from 'src/network/react-query/mutation/favourites/addToFavourites';
import useGetFavourites from 'src/network/react-query/query/favourites/getFavourites';
import { favouriteModalState } from 'src/stores/menus/favouriteModalState';
import useCurrentUser from './useCurrentUser';

const useToggleFavourite = () => {
  const { user } = useCurrentUser();

  const setModalState = useSetRecoilState(
    favouriteModalState
  );

  const { data: favourites, isLoading } =
    useGetFavourites();

  const addFavouriteMutation = useAddToFavourites();

  const toggleFavourite = (
    checked: boolean,
    brandProductId: string
  ) => {
    if (!user)
      return toast.error(
        'You must be logged in to add this product to favourite',
        {
          id: 'favourite',
        }
      );

    if (checked) {
      const favouriteId = favourites?.find(
        favourite =>
          favourite.brandProductId === brandProductId
      )?._id as string;

      setModalState({
        isOpen: true,
        favouriteId,
      });

      return;
    }

    toast.loading('Adding product to favourites', {
      id: 'favourite',
    });

    addFavouriteMutation.addToFavourites({
      isLubricant: true,
      brandProductId,
    });
  };

  return {
    toggleFavourite,
    disableFavourite:
      addFavouriteMutation.isAddingToFavourites ||
      isLoading,
  };
};

export default useToggleFavourite;
