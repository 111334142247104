import { Controller, useForm } from 'react-hook-form';
import Button from 'src/components/atoms/Button';
import Form, { FormProps } from 'src/components/atoms/Form';
import {
  ResetPasswordFormProps,
  resetPasswordFormSchema,
} from './schema';
import { zodResolver } from '@hookform/resolvers/zod';
import PhoneInput from 'src/components/atoms/PhoneInput';
import { useState } from 'react';
import useResetPasswordOtp from 'src/network/react-query/mutation/auth/resetPasswordOtp';
import { toast } from 'react-hot-toast';
import { useSetRecoilState } from 'recoil';
import { resetPasswordState } from 'src/stores/auth/resetPasswordState';

const ResetPasswordForm: React.FC<FormProps> = () => {
  const [countryCode, setCountryCode] = useState('+234');
  const setResetPasswordState = useSetRecoilState(
    resetPasswordState
  );
  const { resetPasswordOtp, isSendingOtp } =
    useResetPasswordOtp();

  const {
    control,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm<ResetPasswordFormProps>({
    defaultValues: {
      phone: '',
    },
    resolver: zodResolver(resetPasswordFormSchema),
    mode: 'onBlur',
  });

  const handleResetPassword = (
    data: ResetPasswordFormProps
  ) => {
    const phoneNumber = countryCode + data.phone;

    setResetPasswordState(prev => ({
      ...prev,
      phoneNumber,
    }));
    toast.loading('Sending Otp');
    resetPasswordOtp({ phoneNumber: phoneNumber });
  };

  return (
    <Form
      onSubmit={handleSubmit(handleResetPassword)}
      onChange={() => clearErrors()}
    >
      <Controller
        name="phone"
        control={control}
        render={({ field }) => (
          <PhoneInput
            id="phone"
            {...field}
            onCountryCodeChange={setCountryCode}
            bg="gray"
            label="Phone Number"
            autoComplete="phone"
            error={!!errors.phone}
            message={errors.phone?.message}
          />
        )}
      />

      <Button
        fluid
        type="submit"
        disabled={isSendingOtp}
        loading={isSendingOtp}
      >
        Reset Password
      </Button>
    </Form>
  );
};

export default ResetPasswordForm;
