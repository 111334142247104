import styled from 'styled-components';

export const Label = styled.label`
  cursor: pointer;
  display: grid;
  grid-template-columns: 20px 1fr;
  gap: 14px;
  align-items: center;
  width: fit-content;
  width: -moz-fit-content;
`;

export const CustomCheckBox = styled.div`
  cursor: pointer;
  display: block;
  position: relative;
  height: 20px;
  width: 20px;
  color: ${({ theme }) => theme.colors.black};
`;

export const CheckBoxInput = styled.input`
  cursor: pointer;
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
  z-index: 1;
`;

export const CheckMark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid
    ${({ theme }) => theme.colors.neutral[300]};
  border-radius: 2px;

  &[data-error='true'] {
    border-color: ${({ theme }) =>
      theme.colors.danger[500]};
  }

  &::after {
    position: absolute;
    content: '';
    display: none;
    left: 7px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  ${CheckBoxInput}:checked ~ & {
    background-color: ${({ theme }) =>
      theme.colors.primary[500]};
    border-color: ${p => p.theme.colors.primary[500]};

    &::after {
      display: block;
    }
  }
`;

export const CheckBoxText = styled.span`
  word-wrap: break-word;
`;
