import Logo from 'src/components/atoms/Logo';
import {
  CheckoutHeaderContainer,
  StyledHeader,
  TextGroup,
} from './style';
import LockIcon from 'src/components/atoms/icons/LockIcon';

const CheckoutHeader = () => {
  return (
    <StyledHeader>
      <CheckoutHeaderContainer>
        <TextGroup>
          <LockIcon />
          <p>Secure Checkout</p>
        </TextGroup>
        <Logo />
      </CheckoutHeaderContainer>
    </StyledHeader>
  );
};

export default CheckoutHeader;
