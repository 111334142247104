import styled from 'styled-components';
import HeartFilled from '../icons/HeartFilled';
import HeartIcon from '../icons/HeartIcon';

interface FavouriteProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  checked: boolean;
  onToggle: (checked: boolean) => void;
  disabled?: boolean;
}

const FavouriteButton: React.FC<FavouriteProps> = ({
  checked,
  onToggle,
  disabled = false,
  ...rest
}) => {
  return (
    <StyledButton
      onClick={() => onToggle(checked)}
      disabled={disabled}
      {...rest}
      title="add to favourites"
    >
      {checked ? (
        <HeartFilled style={{ color: '#DC2626' }} />
      ) : (
        <HeartIcon />
      )}
    </StyledButton>
  );
};

export default FavouriteButton;

const StyledButton = styled.button`
  &:disabled {
    opacity: 0.2;
  }
`;
