import { IconProps } from './icon';

const DeliveryIcon: React.FC<IconProps> = props => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.5858 0.416992H5.41421C4.61856 0.416992 3.8555 0.719893 3.29289 1.25906L0.87868 3.57268C0.316071 4.11185 0 4.84311 0 5.60561V7.12533V16.7087C0 18.2965 1.34315 19.5837 3 19.5837H17C18.6569 19.5837 20 18.2965 20 16.7087V7.12533V5.60561C20 4.84312 19.6839 4.11185 19.1213 3.57268L16.7071 1.25906C16.1445 0.719894 15.3814 0.416992 14.5858 0.416992ZM17 4.25033L15.2929 2.61435C15.1054 2.43463 14.851 2.33366 14.5858 2.33366H5.41421C5.149 2.33366 4.89464 2.43463 4.70711 2.61435L3 4.25033H17ZM2 7.12533V16.7087C2 17.2379 2.44772 17.667 3 17.667H17C17.5523 17.667 18 17.2379 18 16.7087V7.12533C18 6.59605 17.5523 6.16699 17 6.16699H3C2.44772 6.16699 2 6.59605 2 7.12533ZM4 14.792C4 14.2627 4.44772 13.8337 5 13.8337H7C7.55228 13.8337 8 14.2627 8 14.792C8 15.3213 7.55228 15.7503 7 15.7503H5C4.44772 15.7503 4 15.3213 4 14.792ZM5 10.9587C4.44772 10.9587 4 11.3877 4 11.917C4 12.4463 4.44772 12.8753 5 12.8753H9C9.55228 12.8753 10 12.4463 10 11.917C10 11.3877 9.55228 10.9587 9 10.9587H5Z"
      fill="currentColor"
    />
  </svg>
);

export default DeliveryIcon;
