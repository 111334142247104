import { useState, useRef, useEffect } from 'react';
import ChevronDown from '../icons/ChevronDown';
import {
  PopoverContainer,
  PopoverContent,
  TriggerButton,
} from './style';

interface PopoverProps {
  heading: React.ReactNode;
  children: React.ReactNode;
  showIcon?: boolean;
}

const Popover: React.FC<PopoverProps> = ({
  heading,
  children,
  showIcon = true,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener(
      'mousedown',
      handleOutsideClick
    );

    return () => {
      document.removeEventListener(
        'mousedown',
        handleOutsideClick
      );
    };
  }, []);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <PopoverContainer ref={containerRef}>
      <TriggerButton onClick={handleToggle}>
        {heading} {showIcon && <ChevronDown />}
      </TriggerButton>
      {isOpen && (
        <PopoverContent>{children}</PopoverContent>
      )}
    </PopoverContainer>
  );
};

export default Popover;
