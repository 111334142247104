import Button from 'src/components/atoms/Button';
import { StyledSection } from './style';

const WaitlistSection = () => {
  return (
    <StyledSection as="section">
      <h2>Coming Soon!</h2>
      <p>
        The Future of Oil Management: Advanced IoT
        Integration
      </p>

      <Button variant="secondary">Join waitlist</Button>
    </StyledSection>
  );
};

export default WaitlistSection;
