import { atom } from 'recoil';

interface ActiveState {
  isOpen: true;
  brandProductDetailId: string;
}

interface InactiveState {
  isOpen: false;
}

type Props = ActiveState | InactiveState;

export const cartModalState = atom<Props>({
  key: 'cartState',
  default: {
    isOpen: false,
  },
});
