import { SignJWT } from 'jose';
import Cookies from 'js-cookie';
import { AuthState } from 'src/interfaces/authState';

export const setUserCookie = async (
  userDetails: NonNullable<AuthState>
) => {
  const jwtKey = process.env.JWT || '123456789Virpem';

  const token = await new SignJWT(userDetails)
    .setProtectedHeader({ alg: 'HS256' })
    .setExpirationTime('365d')
    .sign(new TextEncoder().encode(jwtKey));

  Cookies.set('VirpemUser', token, {
    expires: 365,
  });
};
