import axios from 'axios';
import { ApiResponse } from 'src/network/apiResponse';
import { API_ROUTES } from 'src/network/apiRoutes';
import handleServiceError from 'src/utils/helpers/handleServiceError';

interface Payload {
  phoneNumber: string;
}

interface ResponseData {
  userId: string;
}

const resetPasswordOtpService = async (
  payload: Payload
) => {
  try {
    const response = await axios.post<
      ApiResponse<ResponseData>
    >(API_ROUTES.resendOtp, payload);

    return response.data;
  } catch (error) {
    throw handleServiceError(error);
  }
};

export default resetPasswordOtpService;
