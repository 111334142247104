import { toast } from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';
import addToFavouritesService from 'src/network/services/favourites/addToFavourites';

const useAddToFavourites = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(addToFavouritesService, {
    onSuccess: res => {
      queryClient.removeQueries(['userFavourites']);
      toast.dismiss();
      toast.success(
        'Successfully added product to your favourites',
        {
          id: 'favourite',
        }
      );
    },
  });

  const {
    mutate: addToFavourites,
    isLoading: isAddingToFavourites,
    data,
  } = mutation;

  return { addToFavourites, isAddingToFavourites, data };
};

export default useAddToFavourites;
