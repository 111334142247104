import { toast } from 'react-hot-toast';
import { useMutation } from 'react-query';
import rateProductService from 'src/network/services/ratings/rateProduct';

const useRateProduct = (successCallback?: () => void) => {
  const mutation = useMutation(rateProductService, {
    onSuccess: () => {
      toast.dismiss();
      toast.success('Review sent', {
        id: 'rating',
      });
      if (successCallback) successCallback();
    },
  });

  return mutation;
};

export default useRateProduct;
