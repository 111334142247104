import { styled } from 'styled-components';

export const Card = styled.article`
  flex: 1;
  display: grid;
  gap: 16px;
  width: 100%;
  /* max-width: 224px; */
  margin-inline: auto;

  @media (max-width: 640px) {
    gap: 10px;

    p {
      font-size: 0.875rem;
    }
  }
`;

export const CardImg = styled.div`
  width: 100%;
  height: 190px;
  border-radius: 8px;
  background-color: #f6f7f8;
  position: relative;
  overflow: hidden;
  display: grid;
  place-items: center;
  padding: 24px;

  img {
    height: 140px;
    object-fit: contain;
  }

  @media (max-width: 540px) {
    height: 130px;
    padding: 12px;

    img {
      height: 100px;
    }
  }
`;

export const CardDetails = styled.div`
  display: grid;
  gap: 12px;

  @media (max-width: 640px) {
    gap: 5px;
  }
`;

export const TitleGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 20px;
  align-items: start;
  gap: 12px;

  h3 {
    font-weight: 600;
    font-size: 1rem;
    line-height: 19px;
  }

  svg {
    width: 24px;
    height: 24px;
    font-size: 1.4rem;
  }

  @media (max-width: 640px) {
    h3 {
      font-size: 0.875rem;
    }

    svg {
      width: 16px;
      height: 16px;
    }
  }
`;

export const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const Price = styled(FlexWrapper)`
  p:first-child {
    font-size: 1rem;
    font-weight: 600;
  }

  @media (max-width: 640px) {
    p {
      font-size: 0.875rem !important;
    }
  }
`;

export const Tag = styled.div`
  width: 62px;
  height: 39px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fefae0;
  color: ${p => p.theme.colors.primary[500]};
  font-weight: 600;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
`;

export const ReviewTextWrapper = styled.span`
  display: flex;
  gap: 4px;
`;

export const ReviewText = styled.span`
  display: none;

  @media (min-width: 768px) {
    display: inline-block;
  }
`;
