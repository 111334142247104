import { jwtVerify } from 'jose';
import Cookies from 'js-cookie';
import { AuthState } from 'src/interfaces/authState';

const getUserState = async (): Promise<AuthState> => {
  const userToken = Cookies.get('VirpemUser');

  if (!userToken) {
    return null;
  }

  const jwtKey = process.env.JWT || '123456789Virpem';

  try {
    const { payload } = await jwtVerify(
      userToken,
      new TextEncoder().encode(jwtKey)
    );

    return payload as AuthState;
  } catch {
    return null;
  }
};

export default getUserState;
