import { styled } from 'styled-components';

export const Wrapper = styled.ul`
  display: grid;
  gap: 16px;

  li {
    display: grid;
    grid-template-columns: 120px 1fr;

    h4 {
      font-weight: 500;
    }

    p {
      color: ${p => p.theme.colors.grey[400]};
    }
  }
`;

export const EmptyAddressWrapper = styled.div`
  display: grid;
  justify-items: start;
  gap: 20px;

  h3 {
    font-weight: 700;
    font-size: 1.25em;
  }
`;
