import { ROUTES } from 'src/constants/routes';
import useGetTopRankingLubricants from 'src/network/react-query/query/lubricants/getTopRankingLubricants';
import Featured from '../Featured';

const TopRankingProducts = () => {
  const { data, error, isLoading } =
    useGetTopRankingLubricants();

  return (
    <>
      <Featured
        heading="Top Ranking"
        subHeading="Discover the top ranking products at Virpem"
        viewAllUrl={ROUTES.products}
        products={data?.map(
          ({
            _id,
            brandCategoryId,
            brandProductId,
            description,
            fixedPrice,
            minimumOrderQuantity,
            name,
            packageSize,
            productImageId,
            quantity,
            specification,
            rating,
            variablePrice,
            verifiedRatings,
            volume,
          }) => ({
            _id,
            brandCategoryId,
            brandProductId,
            description,
            fixedPrice,
            minimumOrderQuantity,
            name,
            packageSize,
            productImageId,
            quantity,
            rating,
            specification,
            variablePrice,
            verifiedRatings,
            volume,
          })
        )}
        error={error}
        isLoading={isLoading}
      />
    </>
  );
};

export default TopRankingProducts;
