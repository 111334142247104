import { styled } from 'styled-components';

export const FilterContainer = styled.div`
  padding: 20px 0;
`;

export const PriceGroup = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 4px;
`;

export const OptionGroup = styled.div`
  margin: 1rem 4px;
  display: grid;
  gap: 20px;
`;

export const OptionAmount = styled.span`
  color: ${p => p.theme.colors.neutral[500]};
`;

export const FilterButton = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 10px;

  @media (min-width: 1024px) {
    max-width: 120px;
    button {
      &:nth-child(2) {
        display: none;
      }
    }
  }
`;
