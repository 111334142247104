import { styled } from 'styled-components';

export const SearchbarWrapper = styled.div``;

export const SearchButton = styled.button`
  display: flex;

  &[data-main='true'] {
    background-color: ${p => p.theme.colors.primary[500]};
    color: ${p => p.theme.colors.white};
    height: 100%;
    width: 50px;
    display: grid;
    place-items: center;
    border-radius: 4px;
    transition: 150ms;

    &:hover {
      opacity: 0.7;
    }
  }

  @media (min-width: 1024px) {
    display: none;
  }
`;

export const FixedWrapper = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background-color: ${p => p.theme.colors.white};
  z-index: 1000;
  border-bottom: 1px solid
    ${p => p.theme.colors.neutral[200]};

  @media (min-width: 1024px) {
    all: unset;
  }
`;

export const SearchContainer = styled.div`
  width: ${p => p.theme.width.container};
  margin-inline: auto;
  display: flex;
  gap: 12px;
  align-items: center;
  height: 80px;
`;

export const Search = styled.form`
  display: flex;
  width: 100%;
  gap: 6px;
  height: 60%;
`;
