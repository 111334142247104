import { atom } from 'recoil';
import { AddressData } from 'src/network/services/address/getAddress';

interface ActiveState {
  isOpen: true;
  addressDetails: AddressData;
  addressId: string;
}

interface InactiveState {
  isOpen: false;
}

type Props = ActiveState | InactiveState;

export const editAddressModalState = atom<Props>({
  key: 'editAddressState',
  default: {
    isOpen: false,
  },
});

export const addressFormModalState = atom<boolean>({
  key: 'addressFormModal',
  default: false,
});
