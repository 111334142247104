import { useRef, useState } from 'react';
import useClickOutside from 'src/utils/hooks/useClickOutside';
import { Spinner } from '../Button/style';
import ChevronDown from '../icons/ChevronDown';
import {
  Dropdown,
  DropdownItem,
  DropdownStyle,
  LoadingIndicator,
  Message,
  SelectButton,
  SelectContainer,
} from './style';

interface SelectOption {
  label: string;
  value: string;
}

interface SelectProps {
  value?: string;
  disabled?: boolean;
  label?: string;
  placeholder: string;
  options?: SelectOption[];
  onChange?: (value: string, label: string) => void;
  error?: boolean;
  message?: string;
  loading?: boolean;
}

const CustomSelect: React.FC<SelectProps> = ({
  value = '',
  label,
  options = [],
  onChange,
  error = false,
  disabled = false,
  message,
  placeholder,
  loading = false,
}) => {
  const [open, setOpen] = useState(false);
  const selectRef = useRef<HTMLDivElement>(null);
  const selectButtonRef = useRef<HTMLButtonElement>(null);

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (value: string, label: string) => {
    // handleValueChange(label);
    if (onChange) onChange(value, label);
    handleClose();
    selectButtonRef.current?.focus(); // Return focus to the select button after selection
  };

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLButtonElement>
  ) => {
    if (event.key === 'Enter') {
      setOpen(prev => !prev);
    }
  };

  useClickOutside(selectRef, () => handleClose());

  return (
    <SelectContainer>
      {label && <label id="select-label">{label}</label>}

      <Dropdown ref={selectRef}>
        <SelectButton
          type="button"
          onClick={() => setOpen(prev => !prev)}
          onKeyDown={handleKeyDown}
          ref={selectButtonRef}
          data-error={error}
          data-disabled={disabled}
          aria-haspopup="listbox"
          aria-expanded={open}
          aria-labelledby="select-label"
        >
          {value !== '' ? value : placeholder}

          <span>
            <ChevronDown />
          </span>
        </SelectButton>

        <DropdownStyle
          data-visible={open}
          role="listbox"
          aria-activedescendant={value}
        >
          {options.length ? (
            options.map((option, index) => (
              <DropdownItem
                type="button"
                onClick={() =>
                  handleChange(option.value, option.label)
                }
                onKeyDown={handleKeyDown}
                role="option"
                data-active={option.value === value}
                tabIndex={open ? 0 : -1} // Make options focusable only when the dropdown is open
                id={`option-${index}`}
                key={index}
                aria-selected={option.value === value}
              >
                {option.label}
              </DropdownItem>
            ))
          ) : loading ? (
            <LoadingIndicator>
              <Spinner data-variant="secondary" />
            </LoadingIndicator>
          ) : (
            <p>No item</p>
          )}
        </DropdownStyle>
      </Dropdown>

      {message ? <Message>{message}</Message> : null}
    </SelectContainer>
  );
};

export default CustomSelect;
