import axios from 'axios';
import { ApiResponse } from 'src/network/apiResponse';
import { API_ROUTES } from 'src/network/apiRoutes';
import handleServiceError from 'src/utils/helpers/handleServiceError';

interface Data {
  _id: string;
  name: string;
}

const getCategoriesService = async () => {
  try {
    const response = await axios.get<ApiResponse<Data[]>>(
      API_ROUTES.getCategories
    );

    return response.data;
  } catch (error) {
    throw handleServiceError(error);
  }
};

export default getCategoriesService;
