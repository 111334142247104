import { ReactNode, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';

interface ConfirmDialogPortalProps {
  children: ReactNode;
}

export const portalRootNode =
  document.getElementById('portal-root');

export const portalRoot = createRoot(
  portalRootNode as HTMLElement
);

export const ConfirmDialogPortal: React.FC<
  ConfirmDialogPortalProps
> = ({ children }) => {
  const el = document.createElement('div');

  useEffect(() => {
    portalRootNode?.appendChild(el);
    return () => {
      portalRootNode?.removeChild(el);
    };
  }, [el]);

  return ReactDOM.createPortal(children, el);
};

export default ConfirmDialogPortal;
