import Heading from 'src/components/atoms/Heading';
import { styled } from 'styled-components';

export const StyledFooter = styled.footer`
  padding-block: 90px 70px;
  background-color: ${p => p.theme.colors.primary[500]};
  color: ${p => p.theme.colors.white};

  @media (max-width: 570px) {
    padding-block: 40px;
  }
`;

export const FooterLinks = styled.div`
  display: grid;
  grid-template-columns: 1.5fr repeat(3, 1fr);
  gap: 100px;

  @media (max-width: 1023px) {
    grid-template-columns: 1fr 1fr;
    gap: 70px;
  }
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }

  @media (max-width: 570px) {
    gap: 50px;
  }
`;

export const LinkSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const LinkHeading = styled(Heading)`
  text-transform: uppercase;
  font-weight: 700;
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ListItem = styled.li`
  color: #dbfbda;
`;

export const ExtraDetails = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 32px;

  @media (max-width: 767px) {
    flex-direction: column;
    justify-content: center;
  }
`;

export const SocialLinks = styled.div`
  display: flex;
  gap: 32px;
  align-items: center;
`;

export const SocialLink = styled.a`
  width: 38px;
  height: 38px;
  font-size: 1.25rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #dbfbda;
  transition: 250ms;

  &:hover {
    background-color: ${p => p.theme.colors.neutral[200]};
    color: ${p => p.theme.colors.primary[500]};
    transform: scale(1.1);
  }
`;

export const CopyrightText = styled.p`
  color: #dbfbda;
  text-align: center;
`;
