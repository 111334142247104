import { Container } from 'src/components/atoms/Container';
import ChevronRight from 'src/components/atoms/icons/ChevronRight';
import Text from 'src/components/atoms/Text';
import { LubricantData } from 'src/network/services/lubricants/getLubricants';
import ProductGrid from '../ProductGrid';
import ProductGridSkeleton from '../ProductGrid/skeleton';
import {
  FeaturedContainer,
  FeaturedHeader,
  FeaturedSection,
  HeadingGroup,
  MessageText,
  StyledButton,
  StyledHeading,
} from './styles';

interface FeaturedProps {
  heading: string;
  subHeading?: string;
  viewAllUrl?: string;
}

interface FeaturedDisplayProps {
  products?: LubricantData[];
  isLoading: boolean;
  error: unknown;
}

const Featured = (
  props: FeaturedProps & FeaturedDisplayProps
) => {
  const {
    heading,
    products,
    viewAllUrl,
    subHeading,
    error,
    isLoading,
  } = props;

  if (products && products.length < 1) return null;

  return (
    <FeaturedSection>
      <FeaturedHeader>
        <FeaturedContainer>
          <HeadingGroup>
            <StyledHeading as="h2" level={5}>
              {heading}
            </StyledHeading>
            {subHeading && (
              <Text shade={700} size="sm">
                {subHeading}
              </Text>
            )}
          </HeadingGroup>

          {viewAllUrl && (
            <StyledButton to={viewAllUrl}>
              View All
              <ChevronRight />
            </StyledButton>
          )}
        </FeaturedContainer>
      </FeaturedHeader>

      <Container>
        <FeaturedDisplay
          error={error}
          isLoading={isLoading}
          products={products}
        />
      </Container>
    </FeaturedSection>
  );
};

export default Featured;

const FeaturedDisplay = (props: FeaturedDisplayProps) => {
  const { error, isLoading, products } = props;

  return (
    <>
      {isLoading && <ProductGridSkeleton />}
      {products && products.length > 0 && (
        <ProductGrid data={products} />
      )}
      {products && products.length === 0 && (
        <MessageText>No products available</MessageText>
      )}
      {error && (
        <MessageText>Error fetching data</MessageText>
      )}
    </>
  );
};
