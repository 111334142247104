import { styled } from 'styled-components';

export const StyledHeading = styled.h1<{ w: number }>`
  font-weight: ${({ w }) => w};
  line-height: 30px;
  margin-block: 10px;
`;

export const Display1 = styled(StyledHeading)`
  font-size: 4.5rem; //72px
  line-height: 80px;
  letter-spacing: -0.04em;

  @media (max-width: 579px) {
    font-size: 3.75rem; //60px
    line-height: 72px;
    letter-spacing: -0.04em;
  }
`;
export const Display2 = styled(StyledHeading)`
  font-size: 3.75rem; //60px
  line-height: 72px;
  letter-spacing: -0.04em;

  @media (max-width: 579px) {
    font-size: 3rem; //48px
    line-height: 56px;
    letter-spacing: -0.04em;
  }
`;
export const Heading1 = styled(StyledHeading)`
  font-size: 3rem; //48px
  line-height: 56px;
  letter-spacing: -0.04em;

  @media (max-width: 579px) {
    font-size: 2.125rem; //34px
    line-height: 40px;
    letter-spacing: -0.04em;
  }
`;
export const Heading2 = styled(StyledHeading)`
  font-size: 2.438rem; //39px
  line-height: 47px;
  letter-spacing: -0.02em;

  @media (max-width: 579px) {
    font-size: 2.063rem; //33px
    line-height: 40px;
    letter-spacing: -0.02em;
  }
`;
export const Heading3 = styled(StyledHeading)`
  font-size: 2.063rem; //33px
  line-height: 40px;
  letter-spacing: -0.02em;

  @media (max-width: 579px) {
    font-size: 1.75rem; //28px
    line-height: 34px;
    letter-spacing: -0.02em;
  }
`;
export const Heading4 = styled(StyledHeading)`
  font-size: 1.75rem; //28px
  line-height: 34px;
  letter-spacing: -0.02em;
`;
export const Heading5 = styled(StyledHeading)`
  font-size: 1.438rem; //23px
  line-height: 28px;
  letter-spacing: -0.02em;
`;
export const Heading6 = styled(StyledHeading)`
  font-size: 1.188rem; //19px
  line-height: 23px;
  letter-spacing: -0.02em;
`;
