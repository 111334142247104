import { IconProps } from './icon';

const Facebook: React.FC<IconProps> = props => (
  <svg
    {...props}
    width="0.875em"
    height="1em"
    viewBox="0 0 9 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.82989 15.3127V8.345H8.18051L8.52991 5.61692H5.82989V3.87925C5.82989 3.09202 6.04922 2.55304 7.17905 2.55304H8.61068V0.120806C7.91411 0.0461568 7.21395 0.0101126 6.5134 0.0128391C4.43567 0.0128391 3.00915 1.28124 3.00915 3.60976V5.61182H0.673828V8.3399H3.01425V15.3127H5.82989Z"
      fill="currentColor"
    />
  </svg>
);

export default Facebook;
