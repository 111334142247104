import axios from 'axios';
import { UserRegisterDetails } from 'src/components/molecules/RegisterForm/schema';
import { ApiResponse } from 'src/network/apiResponse';
import { API_ROUTES } from 'src/network/apiRoutes';
import handleServiceError from 'src/utils/helpers/handleServiceError';

const createAccountService = async (
  payload: UserRegisterDetails
) => {
  try {
    const response = await axios.post<
      ApiResponse<UserRegisterDetails>
    >(API_ROUTES.signUp, {
      ...payload,
      businessType: 'buyer',
    });

    return response.data;
  } catch (error) {
    throw handleServiceError(error);
  }
};

export default createAccountService;
