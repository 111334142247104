import ProductCardSkeleton from '../ProductCard/skeleton';
import { Grid } from './style';

const ProductGridSkeleton = () => {
  return (
    <Grid>
      {Array(6)
        .fill(null)
        .map((_, index) => (
          <ProductCardSkeleton key={index} />
        ))}
    </Grid>
  );
};

export default ProductGridSkeleton;
