import { ApiResponse } from 'src/network/apiResponse';
import { API_ROUTES } from 'src/network/apiRoutes';
import { axiosInstance } from 'src/network/axios';
import handleServiceError from 'src/utils/helpers/handleServiceError';

interface Payload {
  currentPassword: string;
  newPassword: string;
}

const changeCurrentPassowordService = async (
  payload: Payload
) => {
  try {
    const response = await axiosInstance.patch<
      ApiResponse<string>
    >(API_ROUTES.changeCurrentPassword, payload);

    return response.data;
  } catch (error) {
    throw handleServiceError(error);
  }
};

export default changeCurrentPassowordService;
