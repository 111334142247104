import { Link } from 'react-router-dom';
import { Container } from 'src/components/atoms/Container';
import { styled } from 'styled-components';

export const Wrapper = styled(Container)`
  margin-block: 32px;
  display: grid;
  gap: 24px;

  @media (min-width: 768px) {
    width: 100%;
  }
`;

export const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: 16px;
  font-weight: 600;
  font-size: 1.125rem;
  justify-self: start;
`;

export const HeadingGroup = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 12px;

  h2 {
    display: flex;
    align-items: baseline;
    gap: 16px;
  }

  @media (min-width: 1024px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const OrderCount = styled.span`
  color: ${p => p.theme.colors.grey[500]};
  font-size: 0.875rem;
  display: block;
  margin-top: 12px;

  @media (min-width: 768px) {
    display: inline;
    margin-left: 6px;
  }
`;

export const OrderError = styled.div`
  position: relative;
  display: grid;
  place-items: center;
  gap: 16px;
  padding-block: 80px;
  text-align: center;

  h2 {
    font-weight: 700;
    font-size: 1.25rem;
  }
`;

export const OrderItemWrapper = styled.div`
  display: grid;
  width: 100%;

  button {
    justify-self: right;
    margin-top: 10px;
  }
`;
