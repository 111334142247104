import { toast } from 'react-hot-toast';
import { Navigate, useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import Button from 'src/components/atoms/Button';
import Heading from 'src/components/atoms/Heading';
import Text from 'src/components/atoms/Text';
import AuthLayout from 'src/components/layouts/AuthLayout';
import ActionModal from 'src/components/molecules/ActionModal';
import OtpForm from 'src/components/molecules/OtpForm';
import { ROUTES } from 'src/constants/routes';
import useResendOtp from 'src/network/react-query/mutation/auth/resendOtp';
import useVerifyPhone from 'src/network/react-query/mutation/auth/verifyPhone';
import { registrationState } from 'src/stores/auth/registrationState';
import { hidePhone } from 'src/utils/helpers/hidePhone';
import { styled } from 'styled-components';

const VerifyPhone = () => {
  const { phoneNumber, otpAccess } = useRecoilValue(
    registrationState
  );
  const {
    verifyPhone,
    isVerifyingPhone,
    isSuccess,
    userId,
  } = useVerifyPhone();
  const navigate = useNavigate();
  const setRegistrationDetails = useSetRecoilState(
    registrationState
  );
  const { resendOtp } = useResendOtp();

  if (!otpAccess) {
    return <Navigate to="/" />;
  }

  const handleSubmit = (otp: string) => {
    toast.loading('Verifying Otp');
    verifyPhone({
      otp,
      phoneNumber: phoneNumber,
    });
  };

  const handleSuccess = () => {
    setRegistrationDetails(prev => ({
      ...prev,
      userId,
      completeSignupAccess: true,
    }));

    setTimeout(() => {
      navigate(ROUTES.completeSignup);
    }, 0);
  };

  const handleResendOtp = () => {
    toast.loading('Resending OTP');
    resendOtp({
      phoneNumber,
    });
  };

  return (
    <>
      <AuthLayout>
        <Group>
          <Heading as="h1" level={4}>
            Verify Phone
          </Heading>
          <Text size="lg" shade={600}>
            An OTP has been sent to{' '}
            <b>{hidePhone(phoneNumber)}</b> please enter OTP
          </Text>
        </Group>

        <OtpForm
          onSubmit={handleSubmit}
          isLoading={isVerifyingPhone}
          onResend={handleResendOtp}
        />

        <ActionModal
          isOpen={isSuccess}
          onClose={handleSuccess}
          heading="Phone Number verified successfully!"
          text={`Congratulations! Your phone number has been 
          verified successfully! Complete your details 
          to continue shopping`}
          footer={
            <Button onClick={handleSuccess}>
              Complete Sign Up
            </Button>
          }
        />
      </AuthLayout>
    </>
  );
};

export default VerifyPhone;

const Group = styled.div`
  margin-bottom: 40px;
`;
