import Skeleton from 'react-loading-skeleton';
import { Card } from './style';

const AddressCardSkeleton = () => {
  return (
    <Card data-checkbox-visible={false} data-skeleton>
      <Skeleton />
      <Skeleton />
      <Skeleton />
      <Skeleton width="50%" />
    </Card>
  );
};

export default AddressCardSkeleton;
