import { STATES_AND_CITIES } from 'src/constants/stateAndCities';
import { capitalizeFirstLetter } from 'src/utils/helpers/capitalizeFirstLetter';
import * as z from 'zod';

export const completeSignupFormSchema = z
  .object({
    country: z
      .string()
      .trim()
      .nonempty('Please select a country')
      .transform(value => capitalizeFirstLetter(value)),

    state: z
      .string()
      .trim()
      .nonempty('Please select a state')
      .transform(value => capitalizeFirstLetter(value)),

    city: z
      .string()
      .trim()
      .nonempty('Please select a city')
      .transform(value => capitalizeFirstLetter(value)),

    address: z
      .string()
      .trim()
      .nonempty('Please enter an address'),

    company_name: z
      .string()
      .trim()
      .nonempty('Please enter company name')
      .transform(value => capitalizeFirstLetter(value)),

    trade_type: z
      .string()
      .trim()
      .nonempty('Please select a trade type'),
  })
  .refine(
    ({ state, city }) =>
      STATES_AND_CITIES.find(
        value => value.state === state
      )?.lgas.includes(city),
    {
      message:
        'Please select a city that corresponds with your selected state',
      path: ['city'],
    }
  );

export type CompleteSignupFormProps = z.infer<
  typeof completeSignupFormSchema
>;
