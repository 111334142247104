import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useRecoilValue } from 'recoil';
import Button from 'src/components/atoms/Button';
import CustomSelect from 'src/components/atoms/CustomSelect';
import Form from 'src/components/atoms/Form';
import Input from 'src/components/atoms/Input';
import { STATES_AND_CITIES } from 'src/constants/stateAndCities';
import { TRADE_TYPES } from 'src/constants/tradeTypes';
import useCompleteSignup from 'src/network/react-query/mutation/auth/completeSignup';
import { registrationState } from 'src/stores/auth/registrationState';
import {
  CompleteSignupFormProps,
  completeSignupFormSchema,
} from './schema';

const enum DEFAULT_LOCATION {
  country = 'Nigeria',
  state = 'Edo',
  city = 'Benin',
}

const CompleteSignupForm = () => {
  const [selectedState, setSelectedState] =
    useState<string>(DEFAULT_LOCATION.state);

  const { userId } = useRecoilValue(registrationState);

  const { completeSignup, isCompletingSignup } =
    useCompleteSignup();

  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setValue,
    getValues,
  } = useForm<CompleteSignupFormProps>({
    defaultValues: {
      country: DEFAULT_LOCATION.country,
      state: DEFAULT_LOCATION.state,
      city: DEFAULT_LOCATION.city,
      company_name: '',
      trade_type: TRADE_TYPES.retailer,
    },
    resolver: zodResolver(completeSignupFormSchema),
    mode: 'onBlur',
  });

  const handleCompleteSignup = (
    formData: CompleteSignupFormProps
  ) => {
    toast.loading('Sending details');

    completeSignup({
      _id: userId,
      businessName: formData.company_name,
      country: formData.country,
      city: formData.city,
      state: formData.state,
      address: formData.address,
    });
  };

  return (
    <Form
      onSubmit={handleSubmit(handleCompleteSignup)}
      onChange={() => clearErrors()}
    >
      <CustomSelect
        disabled
        label="Country"
        placeholder={getValues('country')}
      />

      <CustomSelect
        label="State"
        placeholder="Select state"
        value={getValues('state')}
        options={STATES_AND_CITIES.map(value => ({
          label: value.state,
          value: value.state,
        }))}
        onChange={value => {
          clearErrors();
          setValue('state', value);
          setSelectedState(value);
          if (value === DEFAULT_LOCATION.state) {
            setValue('city', DEFAULT_LOCATION.city);
          } else {
            setValue('city', '');
          }
        }}
        error={!!errors.state}
        message={errors.state?.message}
      />

      <CustomSelect
        label="City"
        placeholder="Select city"
        value={getValues('city')}
        options={STATES_AND_CITIES.find(
          value => value.state === selectedState
        )?.lgas.map(city => ({
          label: city,
          value: city,
        }))}
        onChange={value => {
          clearErrors();
          setValue('city', value);
        }}
        error={!!errors.city}
        message={errors.city?.message}
      />

      <Input
        id="address"
        label="Address"
        placeholder="Enter your address"
        bg="gray"
        {...register('address')}
        error={!!errors.address}
        message={errors.address?.message}
      />

      <Input
        id="companyName"
        label="Company name"
        placeholder="Enter company name"
        bg="gray"
        {...register('company_name')}
        error={!!errors.company_name}
        message={errors.company_name?.message}
      />

      <Button
        type="submit"
        loading={isCompletingSignup}
        disabled={isCompletingSignup}
      >
        Continue shopping
      </Button>
    </Form>
  );
};

export default CompleteSignupForm;
