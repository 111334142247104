import { styled } from 'styled-components';

type NeutralShade =
  | 50
  | 100
  | 200
  | 300
  | 400
  | 500
  | 600
  | 700
  | 800
  | 900;

interface TextProps
  extends React.HTMLAttributes<HTMLParagraphElement> {
  shade?: NeutralShade;
  size?: 'sm' | 'base' | 'lg';
  children: React.ReactNode;
}

const Text: React.FC<TextProps> = ({
  shade,
  size = 'base',
  children,
  ...rest
}) => {
  return (
    <StyledText shade={shade} data-size={size} {...rest}>
      {children}
    </StyledText>
  );
};

export default Text;

const StyledText = styled.p<{ shade?: NeutralShade }>`
  color: ${({ shade, theme }) =>
    shade ? theme.colors.neutral[shade] : 'inherit'};
  font-size: 1rem;

  &[data-size='sm'] {
    font-size: 0.875rem;
  }
  &[data-size='lg'] {
    font-size: 1.125rem;
  }
`;
