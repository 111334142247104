import { IconProps } from './icon';

const Twitter: React.FC<IconProps> = props => (
  <svg
    {...props}
    width="1em"
    height="0.875em"
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.76891 3.906L7.8036 4.4779L7.22555 4.40787C5.12147 4.13943 3.28329 3.22906 1.72257 1.7001L0.959553 0.941457L0.763018 1.50169C0.346826 2.75053 0.612727 4.0694 1.47979 4.95643C1.94223 5.44663 1.83818 5.51666 1.04048 5.22488C0.763018 5.1315 0.52024 5.06147 0.497118 5.09649C0.416192 5.17819 0.693653 6.24029 0.91331 6.66046C1.21389 7.24403 1.82662 7.81593 2.49715 8.15441L3.06363 8.42285L2.3931 8.43452C1.74569 8.43452 1.72257 8.44619 1.79194 8.69129C2.02315 9.44994 2.93646 10.2553 3.95382 10.6054L4.6706 10.8505L4.04631 11.224C3.12144 11.7609 2.03472 12.0643 0.947992 12.0877C0.427753 12.0994 0 12.146 0 12.1811C0 12.2978 1.41043 12.9514 2.23125 13.2081C4.69372 13.9668 7.61862 13.64 9.81519 12.3445C11.3759 11.4224 12.9366 9.58999 13.665 7.81593C14.058 6.87055 14.4511 5.14318 14.4511 4.3145C14.4511 3.77762 14.4858 3.70759 15.1332 3.06566C15.5147 2.69217 15.8731 2.28367 15.9425 2.16696C16.0581 1.9452 16.0465 1.9452 15.4569 2.14362C14.4742 2.49376 14.3355 2.44707 14.8211 1.92186C15.1794 1.54837 15.6072 0.871429 15.6072 0.673014C15.6072 0.638 15.4338 0.696357 15.2372 0.8014C15.0291 0.918115 14.5667 1.09319 14.2199 1.19823L13.5956 1.39664L13.0291 1.01149C12.717 0.8014 12.2777 0.567971 12.0464 0.497943C11.4568 0.334543 10.5551 0.357886 10.0233 0.544629C8.57817 1.06984 7.66487 2.42373 7.76891 3.906Z"
      fill="currentColor"
    />
  </svg>
);

export default Twitter;
