import { ApiResponse } from 'src/network/apiResponse';
import { API_ROUTES } from 'src/network/apiRoutes';
import { axiosInstance } from 'src/network/axios';
import handleServiceError from 'src/utils/helpers/handleServiceError';

interface Payload {
  orderId: string;
}

const cancelOrderService = async ({ orderId }: Payload) => {
  try {
    const response = await axiosInstance.delete<
      ApiResponse<string>
    >(API_ROUTES.cancelOrder + `/${orderId}`);
    return response.data;
  } catch (error) {
    throw handleServiceError(error);
  }
};

export default cancelOrderService;
