import { IconProps } from './icon';

const StarFill: React.FC<IconProps> = props => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.57341 1.39058C8.02244 0.00861168 9.97756 0.00860953 10.4266 1.39058L11.3574 4.25532C11.5582 4.87336 12.1341 5.2918 12.784 5.2918H15.7962C17.2492 5.2918 17.8534 7.15122 16.6778 8.00532L14.2409 9.77583C13.7152 10.1578 13.4952 10.8348 13.696 11.4529L14.6268 14.3176C15.0759 15.6996 13.4941 16.8488 12.3186 15.9947L9.88168 14.2242C9.35595 13.8422 8.64405 13.8422 8.11832 14.2242L5.68143 15.9947C4.50586 16.8488 2.92413 15.6996 3.37316 14.3176L4.30397 11.4529C4.50479 10.8348 4.2848 10.1578 3.75907 9.77583L1.32217 8.00532C0.1466 7.15122 0.750763 5.2918 2.20385 5.2918H5.21602C5.86586 5.2918 6.44179 4.87336 6.6426 4.25532L7.57341 1.39058Z"
      fill="#FBBC05"
    />
  </svg>
);

export default StarFill;
