import { IconProps } from './icon';

const MessageIcon: React.FC<IconProps> = props => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 10.7034V10.3685C2 6.29897 5.28324 3 9.33333 3H14.6667C18.7168 3 22 6.29898 22 10.3685C22 14.4186 18.7479 17.7056 14.7244 17.7367L11.4101 20.5118C10.056 21.6457 8 20.6782 8 18.9069V17.6657C4.60771 17.1781 2 14.2468 2 10.7034ZM10.1298 18.968L13.6888 15.9879C13.8899 15.8196 14.1434 15.7274 14.4051 15.7274H14.6667C17.6122 15.7274 20 13.3281 20 10.3685C20 7.40884 17.6122 5.00958 14.6667 5.00958H9.33333C6.38781 5.00958 4 7.40884 4 10.3685V10.7034C4 13.478 6.23858 15.7274 9 15.7274C9.46779 15.7274 9.86056 16.0501 9.96979 16.4861C9.98952 16.5648 10 16.6472 10 16.7321V18.9069C10 18.9743 10.0782 19.0111 10.1298 18.968Z"
      fill="currentColor"
    />
  </svg>
);

export default MessageIcon;
