export const PRODUCT_SPECIFICATIONS = [
  '5W30',
  '20W50',
  '0W20',
  '0W30',
] as const;

export const PRODUCT_VOLUMES = [
  '1L',
  '4L',
  '5L',
  '25L',
] as const;

export const PRODUCT_VOLUME_DIGITS = [
  '1',
  '4',
  '5',
  '25',
] as const;
