import Button from 'src/components/atoms/Button';
import Form from 'src/components/atoms/Form';
import Heading from 'src/components/atoms/Heading';
import Input from 'src/components/atoms/Input';
import Text from 'src/components/atoms/Text';
import { styled } from 'styled-components';

const Newsletter = () => {
  return (
    <section>
      <Group>
        <Heading as="h2" level={4}>
          STAY UP TO DATE
        </Heading>
        <Text>Our best promotions sent to your inbox</Text>
      </Group>

      <Form style={{ justifyItems: 'flex-start' }}>
        <Input
          id="newsletterEmail"
          placeholder="Please enter your email"
          bg="gray"
        />

        <Button type="submit" variant="light">
          Subscribe
        </Button>
      </Form>
    </section>
  );
};

export default Newsletter;

const Group = styled.div`
  margin-bottom: 1.5rem;

  h2 {
    margin-top: 0;

    @media (max-width: 570px) {
      font-size: 1.5rem;
    }
  }
`;
