import { useQuery } from 'react-query';
import getCartService from 'src/network/services/cart/getCart';

const useGetCart = () => {
  const { data, isLoading, refetch } = useQuery(
    ['userCart'],
    getCartService
  );

  return { data, isLoading, refetchCart: refetch };
};

export default useGetCart;
