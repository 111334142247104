import * as z from 'zod';

export const resetPasswordFormSchema = z.object({
  phone: z
    .string()
    .trim()
    .nonempty('Please provide a phone number')
    .length(10, 'Phone number should be 10 digits')
    .refine(
      value => /^[0-9]+$/.test(value),
      'Only numbers are allowed'
    ),
});

export type ResetPasswordFormProps = z.infer<
  typeof resetPasswordFormSchema
>;
