import { useQuery } from 'react-query';
import getTopRankingLubricantsService from 'src/network/services/lubricants/getTopRankingLubricants';

const useGetTopRankingLubricants = () => {
  const { data, isLoading, isError, error } = useQuery(
    ['Top ranking lubricants'],
    getTopRankingLubricantsService
  );

  return { data, isLoading, isError, error };
};

export default useGetTopRankingLubricants;
