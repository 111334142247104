import { Link } from 'react-router-dom';
import { Container } from 'src/components/atoms/Container';
import { Divider } from 'src/components/atoms/Divider';
import { FOOTER_LINKS } from 'src/constants/footerLinks';
import { SOCIAL_MEDIA_LINKS } from 'src/constants/socialMedia';
import Newsletter from '../Newsletter';
import {
  CopyrightText,
  ExtraDetails,
  FooterLinks,
  LinkHeading,
  LinkSection,
  List,
  ListItem,
  SocialLink,
  SocialLinks,
  StyledFooter,
} from './style';

const Footer = () => {
  return (
    <StyledFooter>
      <Container>
        <FooterLinks>
          <Newsletter />

          {FOOTER_LINKS.map((linkGroup, i) => (
            <LinkSection key={i}>
              <LinkHeading as="h2" level={5}>
                {linkGroup.heading}
              </LinkHeading>

              <List>
                {linkGroup.links.map((link, i) => (
                  <ListItem key={i}>
                    <Link to={link.href}>{link.text}</Link>
                  </ListItem>
                ))}
              </List>
            </LinkSection>
          ))}
        </FooterLinks>

        <Divider
          margin="2rem 0"
          shade={400}
          style={{ opacity: 0.15 }}
        />

        <ExtraDetails>
          <SocialLinks>
            {SOCIAL_MEDIA_LINKS.map(link => (
              <SocialLink
                key={link.name}
                href={link.href}
                target="_blank"
                rel="noopener noreferrer"
              >
                {link.icon}
              </SocialLink>
            ))}
          </SocialLinks>

          <CopyrightText>
            Copyright &copy; {new Date().getFullYear()}{' '}
            Virpem. All Rights Reserved.
          </CopyrightText>
        </ExtraDetails>
      </Container>
    </StyledFooter>
  );
};

export default Footer;
