import { toast } from 'react-hot-toast';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { ROUTES } from 'src/constants/routes';
import completeSignupService from 'src/network/services/auth/completeSignup';
import { registrationState } from 'src/stores/auth/registrationState';
import useCurrentUser from 'src/utils/hooks/useCurrentUser';

const useCompleteSignup = () => {
  const navigate = useNavigate();
  const setRegistrationState = useSetRecoilState(
    registrationState
  );
  const { logUserIn } = useCurrentUser();

  const completeSignupMutation = useMutation(
    completeSignupService,
    {
      onSuccess: ({ data }) => {
        toast.dismiss();
        toast.success('SignUp complete!');
        setRegistrationState({
          userId: '',
          phoneNumber: '',
          otpAccess: false,
          completeSignupAccess: false,
        });

        logUserIn({
          firstName: data.firstName,
          lastName: data.lastName,
          phoneNumber: data.phoneNumber,
          userId: data._id,
          userToken: data.token,
        });

        setTimeout(() => {
          navigate(ROUTES.products);
        }, 0);
      },
    }
  );

  const {
    mutate: completeSignup,
    isLoading: isCompletingSignup,
    data,
  } = completeSignupMutation;

  return { completeSignup, isCompletingSignup, data };
};

export default useCompleteSignup;
