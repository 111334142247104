import Button from 'src/components/atoms/Button';
import { Container } from 'src/components/atoms/Container';
import { styled } from 'styled-components';

export const BreadCrumbContainer = styled(Container)`
  margin-block: 40px 20px;

  @media (max-width: 580px) {
    margin-block: 20px 0;
  }
`;

export const MainWrapper = styled(Container)`
  display: grid;
  grid-template-columns: 350px 1fr;
  align-items: start;
  gap: 30px;

  @media (max-width: 1023px) {
    grid-template-columns: 1fr;
  }
`;

export const FilterSection = styled.aside`
  background-color: ${p => p.theme.colors.white};
  position: sticky;
  top: 160px;
  height: calc(100vh - 180px);
  padding-block: 2rem;
  padding-inline: 0 2rem;
  overflow-y: auto;
  margin-bottom: 35px;

  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  scrollbar-track-color: transparent;

  /* Styling for WebKit browsers (Chrome, Safari) */
  &::-webkit-scrollbar {
    width: 4px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #a5adb5;
    border-radius: 50px;
  }

  @media (max-width: 1023px) {
    position: fixed;
    padding-inline: 2rem;
    z-index: 888;
    top: 80px;
    left: -400px;
    height: calc(100vh - 80px);
    width: min(350px, 100%);
    border-right: 1px solid #d6dbe0;
    transition: 250ms ease-in-out;

    &[data-open='true'] {
      left: 0;
    }
  }
`;

export const ProductsSection = styled.section`
  width: 100%;
  padding-block: 1rem 2rem;
`;

export const HeadingGroup = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 579px) {
    h2 {
      display: none;
    }
  }

  @media (max-width: 1023px) {
    h2 {
      display: none;
    }
  }
`;

export const SideBarHeadingGroup = styled(HeadingGroup)`
  @media (max-width: 1023px) {
    justify-content: right;
  }
`;

export const SortTab = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  align-items: center;
  gap: 14px;
  width: 260px;

  label {
    font-weight: 600;
  }

  @media (max-width: 579px) {
    display: flex;
    flex: 1;
    width: max-content;
    label {
      display: none;
    }
  }
`;

export const FilterButton = styled(Button)`
  font-weight: 600;
  text-decoration: underline;
  display: none;

  @media (max-width: 1023px) {
    display: flex;
    width: max-content;
  }

  svg {
    font-size: 1rem;
  }
`;

export const FilterCloseButton = styled.button`
  span[data-icon='close'] {
    display: none;
  }
  pointer-events: none;

  @media (max-width: 1023px) {
    pointer-events: all;
    justify-self: flex-end;
    span[data-icon='close'] {
      display: block;
    }

    span[data-icon='funnel'] {
      display: none;
    }
  }
`;

export const EmptyProduct = styled.div`
  height: 250px;
  display: grid;
  place-items: center;
  align-content: center;
  gap: 32px;
  margin-block: 1rem;

  svg {
    width: 80px;
    height: 80px;
    color: ${p => p.theme.colors.primary[500]};
  }

  p {
    font-size: 1.25rem;
    font-weight: 500;
  }
`;

export const SearchAlertWrapper = styled.div`
  padding-block: 16px;
`;

export const SearchAlert = styled.p`
  font-size: 0.875rem;
  font-weight: 700;

  button {
    color: ${p => p.theme.colors.primary[500]};
    background-color: ${p => p.theme.colors.secondary};
    padding: 4px 12px;
    border-radius: 4px;
    margin-top: 10px;
    display: flex;
    gap: 4px;
    align-items: center;
    font-weight: 500;
    font-size: 0.75rem;

    svg {
      width: 14px;
    }
  }

  span {
    font-weight: normal;
  }
`;

export const FilterAlert = styled.div`
  padding-block: 20px;
  text-transform: capitalize;

  span {
    font-weight: 700;
  }
`;
