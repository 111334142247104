import Skeleton from 'react-loading-skeleton';
import { ROUTES } from 'src/constants/routes';
import useGetLubricantsByCategory from 'src/network/react-query/query/lubricants/getLubricantsByCategory';
import { isObjectEmpty } from 'src/utils/helpers/isObjectEmpty';
import {
  ErrorDisplay,
  SubMenuGroup,
  SubMenuLink,
} from './style';

interface MenuItemProps {
  closeNav: () => void;
}

export const ATFMenu: React.FC<MenuItemProps> = ({
  closeNav,
}) => {
  const atfQuery = useGetLubricantsByCategory('ATF');

  if (atfQuery.isLoading) {
    return (
      <>
        {Array(3)
          .fill(null)
          .map((_, index) => (
            <SubMenuGroup data-small key={index}>
              <div style={{ padding: '10px' }}>
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </div>
            </SubMenuGroup>
          ))}
      </>
    );
  }

  if (atfQuery.data) {
    if (isObjectEmpty(atfQuery.data))
      return (
        <ErrorDisplay>This Category is Empty</ErrorDisplay>
      );

    return (
      <>
        {Object.keys(atfQuery.data).map((brand, i) => (
          <SubMenuGroup data-small key={i}>
            <SubMenuLink
              to={ROUTES.products + `?brands=${brand}`}
            >
              {brand}
            </SubMenuLink>

            {atfQuery.data &&
              atfQuery.data[brand].map((product, i) => (
                <SubMenuLink
                  key={i}
                  to={
                    ROUTES.products +
                    `?search=${product.name}`
                  }
                  data-small
                >
                  {product.name}
                </SubMenuLink>
              ))}
          </SubMenuGroup>
        ))}
      </>
    );
  }

  return (
    <ErrorDisplay>Unable to load products</ErrorDisplay>
  );
};
