import { Link } from 'react-router-dom';
import Button from 'src/components/atoms/Button';
import Form, { FormProps } from 'src/components/atoms/Form';
import Input from 'src/components/atoms/Input';
import { ROUTES } from 'src/constants/routes';
import { styled } from 'styled-components';
import { useForm } from 'react-hook-form';
import { LoginFormProps, loginFormSchema } from './schema';
import { zodResolver } from '@hookform/resolvers/zod';
import useLogin from 'src/network/react-query/mutation/auth/login';
import { toast } from 'react-hot-toast';

const LoginForm: React.FC<FormProps> = props => {
  const { login, isLoginIn } = useLogin();

  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm<LoginFormProps>({
    defaultValues: {
      phone: '',
      password: '',
    },
    resolver: zodResolver(loginFormSchema),
    mode: 'onBlur',
  });

  const handleLogin = (formData: LoginFormProps) => {
    toast.loading('Verifying details');

    login({
      username: formData.phone,
      password: formData.password,
    });
  };

  return (
    <Form
      {...props}
      onSubmit={handleSubmit(handleLogin)}
      onChange={() => clearErrors()}
    >
      <Input
        label="Phone Number"
        placeholder="Enter phone number"
        id="phone"
        bg="gray"
        type="text"
        {...register('phone')}
        error={!!errors.phone}
        message={errors.phone?.message}
      />

      <Input
        label="Password"
        placeholder="Enter password"
        id="passoword"
        bg="gray"
        type="password"
        {...register('password')}
        error={!!errors.password}
        message={errors.password?.message}
      />

      <ForgotPasswordLink to={ROUTES.resetPassword}>
        Forgot Password
      </ForgotPasswordLink>

      <Button
        fluid
        type="submit"
        disabled={isLoginIn}
        loading={isLoginIn}
      >
        Log In
      </Button>
    </Form>
  );
};

export default LoginForm;

const ForgotPasswordLink = styled(Link)`
  text-align: right;
  justify-self: right;
  color: ${p => p.theme.colors.primary[500]};
  font-size: 0.875rem;
  font-weight: 600;
`;
