import { skipWaitingAndActivateSW } from 'src/serviceWorkerRegistration';
import styled from 'styled-components';
import ConfirmDialog from './ConfirmDialog';
import { portalRoot } from './ConfirmDialogPortal';

const closeDialog = () => {
  portalRoot.unmount();
};

export const PendingUpdateDialog = () => {
  return (
    <ConfirmDialog>
      <Content>
        <h2>New Version</h2>
        <p>
          New version available! Click update to get new
          changes.
        </p>

        <ButtonWrapper>
          <button onClick={skipWaitingAndActivateSW}>
            Update
          </button>
          <button onClick={closeDialog}>Cancel</button>
        </ButtonWrapper>
      </Content>
    </ConfirmDialog>
  );
};

export const IsUpdatingDialog = () => {
  return (
    <ConfirmDialog>
      <Content>
        <h2>Updating App</h2>
        <p>Updating app. Please wait...</p>
      </Content>
    </ConfirmDialog>
  );
};

export const CompleteUpdateDialog = () => {
  const refresh = () => {
    window.location.reload();
  };

  return (
    <ConfirmDialog>
      <Content>
        <h2>Update Successful!</h2>
        <p>Please refresh the app to load new content.</p>

        <ButtonWrapper>
          <button onClick={refresh}>Refresh</button>
        </ButtonWrapper>
      </Content>
    </ConfirmDialog>
  );
};

const Content = styled.div`
  display: grid;
  gap: 20px;

  h2 {
    font-size: 1.25rem;
    font-weight: 700;
  }
`;

const ButtonWrapper = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;

  button {
    font-style: normal;
    padding: 10px 32px;
    border: none;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    position: relative;
    cursor: pointer;
    transition: 250ms;
    font-weight: 600;
    background-color: #370617;
    color: white;

    &:nth-child(2) {
      color: #370617;
      background-color: #fdedf2;
    }

    &:hover {
      opacity: 0.75;
    }
    &:active {
      transform: scale(0.96);
    }
  }
`;
