import React from 'react';
import { Link } from 'react-router-dom';
import FavouriteButton from 'src/components/atoms/FavouriteButton';
import Text from 'src/components/atoms/Text';
import { ROUTES } from 'src/constants/routes';
import useGetFavourites from 'src/network/react-query/query/favourites/getFavourites';
import { LubricantData } from 'src/network/services/lubricants/getLubricants';
import { formatPrice } from 'src/utils/helpers/formatPrice';
import useToggleFavourite from 'src/utils/hooks/useToggleFavourite';
import PriceDisplay from '../PriceDisplay';
import Rating from '../Rating';
import {
  Card,
  CardDetails,
  CardImg,
  FlexWrapper,
  Price,
  ReviewText,
  ReviewTextWrapper,
  Tag,
  TitleGroup,
} from './style';

interface Props {
  tag?: boolean;
  productData: LubricantData;
}

const ProductCard: React.FC<Props> = ({
  tag = false,
  productData,
}) => {
  const { toggleFavourite, disableFavourite } =
    useToggleFavourite();

  const { data: favourites } = useGetFavourites();

  const {
    name,
    specification,
    volume,
    productImageId,
    fixedPrice,
    variablePrice,
    brandProductId,
    minimumOrderQuantity,
    rating,
    verifiedRatings,
  } = productData;

  //API is returning different data structures, gotta do some type checking
  //to ensure the correct brandProductId is used
  const transFormedBrandProductId =
    typeof brandProductId === 'string'
      ? brandProductId
      : brandProductId._id;

  const isCardChecked = !!favourites?.find(
    value =>
      value.brandProductId === transFormedBrandProductId
  );

  return (
    <Card>
      <Link
        to={
          ROUTES.products + `/${transFormedBrandProductId}`
        }
      >
        <CardImg>
          <img
            src={productImageId.mainImageUrl}
            alt="product"
          />
          {tag && <Tag>-50%</Tag>}
        </CardImg>
      </Link>

      <CardDetails>
        <TitleGroup>
          <Link
            to={
              ROUTES.products +
              `/${transFormedBrandProductId}`
            }
          >
            <h3>
              {name} {specification} {volume}
            </h3>
          </Link>

          {
            <FavouriteButton
              disabled={disableFavourite}
              checked={isCardChecked}
              onToggle={checked =>
                toggleFavourite(
                  checked,
                  transFormedBrandProductId
                )
              }
            />
          }
        </TitleGroup>

        <Text shade={600}>
          MOQ: {minimumOrderQuantity}{' '}
          {minimumOrderQuantity > 1 ? 'ctns' : 'ctn'}
        </Text>

        <FlexWrapper>
          <Rating stars={rating} />
          <ReviewTextWrapper>
            &#40;{verifiedRatings}
            <ReviewText>
              {' '}
              {verifiedRatings === 1 ? 'review' : 'reviews'}
            </ReviewText>
            &#41;
          </ReviewTextWrapper>
        </FlexWrapper>

        <PriceDisplay>
          <Price>
            <Text>
              {formatPrice(
                variablePrice.length > 0
                  ? variablePrice[0].amount
                  : fixedPrice
              )}
            </Text>
            {tag ? (
              <Text
                shade={400}
                style={{
                  textDecorationLine: 'line-through',
                }}
              >
                &#8358; 40,000
              </Text>
            ) : null}
          </Price>
        </PriceDisplay>
      </CardDetails>
    </Card>
  );
};

export default ProductCard;
