import { Fragment } from 'react';
import { Navigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { ROUTES } from 'src/constants/routes';
import { authState } from 'src/stores/auth/authState';

const ProtectedRoute: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const user = useRecoilValue(authState);

  if (!user) {
    return <Navigate to={ROUTES.login} />;
  }

  return <Fragment>{children}</Fragment>;
};

export default ProtectedRoute;
