import { styled } from 'styled-components';

export const HeadGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  justify-content: space-between;

  h1 {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
  }

  span {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: ${p => p.theme.colors.grey[500]};
  }
`;
