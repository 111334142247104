import { RefObject } from 'react';
import { useSetRecoilState } from 'recoil';
import MenuBars from 'src/components/atoms/icons/MenuBars';
import Logo from 'src/components/atoms/Logo';
import { accountMenuState } from 'src/stores/menus/accountMenuState';
import { landingMenuState } from 'src/stores/menus/landingMenuState';
import { navbarState } from 'src/stores/menus/navbarState';
import useCurrentUser from 'src/utils/hooks/useCurrentUser';
import CartButton from '../CartButton';
import ProfileMenu from '../ProfileMenu';
import SearchBar from '../SearchBar';
import {
  Group,
  HeaderContainer,
  IconGroup,
  MenuButton,
  StyledHeader,
} from './style';

interface HeaderProps {
  hideCart?: boolean;
  hideMenu?: boolean;
  hideSearch?: boolean;
  menuType?: 'accountMenu' | 'navMenu' | 'landingMenu';
  menuButtonRef?: RefObject<HTMLButtonElement>;
}

const MainHeader: React.FC<HeaderProps> = ({
  hideCart = false,
  hideMenu = false,
  hideSearch = false,
  menuType,
  menuButtonRef,
}) => {
  const setNavbarOpen = useSetRecoilState(navbarState);
  const setAccountMenuOpen = useSetRecoilState(
    accountMenuState
  );
  const setLandingMenuOpen = useSetRecoilState(
    landingMenuState
  );
  const { user } = useCurrentUser();

  //the menu button toggles different menus depending on
  //the menuType passed to it. If none is provided, it
  //defaults to the nav menu.
  const HandleMenu = () => {
    switch (menuType) {
      case 'accountMenu':
        setAccountMenuOpen(true);
        return;

      case 'navMenu':
        setNavbarOpen(true);
        return;

      case 'landingMenu':
        setLandingMenuOpen(true);
        return;

      default:
        setNavbarOpen(true);
        return;
    }
  };

  return (
    <StyledHeader>
      <HeaderContainer>
        <Group>
          {hideMenu ? null : (
            <MenuButton
              ref={menuButtonRef}
              title="Open menu"
              onClick={HandleMenu}
            >
              <MenuBars />
            </MenuButton>
          )}

          <Logo />
        </Group>

        <IconGroup>
          {hideSearch ? <span /> : <SearchBar />}
          <Group data-icons>
            <ProfileMenu />
            {hideCart || !user ? null : <CartButton />}
          </Group>
        </IconGroup>
      </HeaderContainer>
    </StyledHeader>
  );
};

export default MainHeader;
