import { VariablePrice } from 'src/network/services/lubricants/addLubricant';

export const getPriceByQuantity = (
  quantity: number,
  fixedPrice: number,
  variablePrice: VariablePrice[]
) => {
  if (fixedPrice) {
    return fixedPrice * quantity;
  }

  let priceRange =
    variablePrice.find(
      range =>
        range.minimumOrderQuantity <= quantity &&
        range.maximumOrderQuantity >= quantity
    ) || variablePrice[variablePrice.length - 1];

  if (!priceRange) {
    console.error('VariablePrice: ', variablePrice);
    return 0;
  }

  return priceRange.amount * quantity;
};
