import { Link } from 'react-router-dom';
import { styled } from 'styled-components';

export const Main = styled.main`
  @media (min-width: 768px) {
    margin-left: 2rem;
  }
`;

export const Wrapper = styled.div`
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 240px 1fr;
    width: ${p => p.theme.width.container};
    margin-inline: auto;
    max-width: ${p => p.theme.width.max};
  }
`;

export const Sidebar = styled.aside`
  position: fixed;
  z-index: 888;
  top: 80px;
  left: -100%;
  background-color: ${p => p.theme.colors.white};
  border-right: 1px solid
    ${p => p.theme.colors.neutral[200]};
  padding: 2rem 1rem;
  height: calc(100vh - 80px);
  overflow-y: auto;
  width: min(100%, 320px);
  transition: 250ms ease-in-out;

  &[data-open='true'] {
    left: 0;
  }

  @media (min-width: 768px) {
    position: sticky;
    left: 0;
    top: 80px;
    padding-left: 0;
  }
`;

export const SidebarContent = styled.div`
  display: grid;
  gap: 24px;
`;

export const HeadGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  justify-content: space-between;

  h2 {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
  }

  @media (min-width: 580px) {
    button {
      display: none;
    }
  }
`;

export const SidebarLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 14px 26px;
  color: ${p => p.theme.colors.primary[500]};
  position: relative;
  transition: 300ms;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    width: 0;
    height: 80%;
    background-color: ${p => p.theme.colors.primary[500]};
    border-radius: 0 4px 4px 0;
    transition: 150ms;
    transition-delay: 100ms;
  }

  &:hover {
    background-color: ${p => p.theme.colors.secondary};

    &::before {
      width: 6px;
    }
  }

  &[data-active='true'] {
    background-color: ${p => p.theme.colors.secondary};

    &::before {
      width: 6px;
    }
  }
`;
