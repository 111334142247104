import { useId } from 'react';
import { toast } from 'react-hot-toast';
import { useRecoilState, useSetRecoilState } from 'recoil';
import CheckBox from 'src/components/atoms/Checkbox';
import ClipboardCheckIcon from 'src/components/atoms/icons/ClipboardCheckIcon';
import DeleteIcon from 'src/components/atoms/icons/DeleteIcon';
import EditIcon from 'src/components/atoms/icons/EditIcon';
import VerticalDots from 'src/components/atoms/icons/VerticalDots';
import Popover from 'src/components/atoms/Popover';
import useDeleteAddress from 'src/network/react-query/mutation/address/deleteAddress';
import useUpdateAddress from 'src/network/react-query/mutation/address/updateAddress';
import { AddressData } from 'src/network/services/address/getAddress';
import { checkoutStore } from 'src/stores/checkout/checkoutState';
import { editAddressModalState } from 'src/stores/menus/addressModal';
import useCurrentUser from 'src/utils/hooks/useCurrentUser';
import {
  AddressCardMenu,
  Card,
  Details,
  MenuIcon,
  MenuWrapper,
  NameGroup,
  Tag,
} from './style';

interface Props {
  addressDetails: AddressData;
  showCheckbox?: boolean;
  showPopup?: boolean;
}

const AddressCard: React.FC<Props> = ({
  addressDetails,
  showCheckbox = false,
  showPopup = true,
}) => {
  const { _id, state, isDefault, address, city } =
    addressDetails;

  const { user } = useCurrentUser();
  const { deleteAddress, isDeletingAddress } =
    useDeleteAddress();
  const checkboxId = useId();
  const setEditAddressState = useSetRecoilState(
    editAddressModalState
  );
  const { updateAddress, isUpdatingAddress } =
    useUpdateAddress();
  const [checkoutState, setCheckoutState] =
    useRecoilState(checkoutStore);

  if (!user) return null;

  const handleDeleteAddress = () => {
    toast.loading('Removing address', {
      id: 'address',
    });
    deleteAddress({
      addressId: _id,
    });
  };

  const handleEditAddress = () => {
    setEditAddressState({
      isOpen: true,
      addressDetails,
      addressId: _id,
    });
  };

  const handleSetAsDefault = () => {
    toast.loading('Updating address', {
      id: 'updateAddress',
    });
    updateAddress({
      addressId: _id,
      details: {
        isDefault: true,
      },
    });
  };

  const getIsChecked = (): boolean => {
    if (checkoutState.hasChangedAddress)
      return checkoutState.address._id === _id;

    return isDefault;
  };

  return (
    <Card
      data-popup={showPopup}
      data-checkbox-visible={showCheckbox}
    >
      {showPopup && (
        <AddressCardMenu>
          <Popover
            showIcon={false}
            heading={
              <MenuIcon>
                <VerticalDots />
              </MenuIcon>
            }
          >
            <MenuWrapper>
              <button onClick={handleEditAddress}>
                <EditIcon /> Edit Address
              </button>
              {!isDefault && (
                <button
                  onClick={handleSetAsDefault}
                  disabled={isUpdatingAddress}
                >
                  <ClipboardCheckIcon />
                  Set as default
                </button>
              )}
              <button
                data-danger
                onClick={handleDeleteAddress}
                disabled={isDeletingAddress}
              >
                <DeleteIcon /> Delete Address
              </button>
            </MenuWrapper>
          </Popover>
        </AddressCardMenu>
      )}

      {showCheckbox && (
        <CheckBox
          id={checkboxId}
          checked={getIsChecked()}
          onChange={e => {
            if (e.target.checked) {
              setCheckoutState({
                hasChangedAddress: true,
                address: {
                  _id,
                  address,
                  state,
                  city,
                  name: `${user.firstName} ${user.lastName}`,
                  phone: user.phoneNumber,
                },
              });
            }
          }}
        />
      )}

      <Details>
        <NameGroup>
          <h4>
            {user.firstName} {user.lastName}
          </h4>
          {isDefault && <Tag>Default</Tag>}
        </NameGroup>
        <p>
          {address ? address : ''}, {city}
        </p>
        <p>{state}</p>
        <p>{user.phoneNumber}</p>
      </Details>
    </Card>
  );
};

export default AddressCard;
