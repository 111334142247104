import { IconProps } from './icon';

const ClipboardIcon: React.FC<IconProps> = props => (
  <svg
    {...props}
    width="16"
    height="20"
    viewBox="0 0 16 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 0C3.89543 0 3 0.895431 3 2C1.34314 2 0 3.34315 0 5V17C0 18.6569 1.34314 20 3 20H13C14.6569 20 16 18.6569 16 17V5C16 3.34315 14.6569 2 13 2C13 0.89543 12.1046 0 11 0H5ZM10 4C10.5523 4 11 3.55228 11 3V2H10L9 2H7L6 2H5V3C5 3.51777 5.3935 3.94363 5.89776 3.99484C5.93105 3.99822 5.96483 3.99997 5.99901 4C5.99934 4 5.99967 4 6 4L10 4ZM3.17071 4C3.58254 5.16519 4.69378 6 6 6L10 6C11.3062 6 12.4175 5.16519 12.8293 4H13C13.5523 4 14 4.44772 14 5V17C14 17.5523 13.5523 18 13 18H3C2.44772 18 2 17.5523 2 17V5C2 4.44772 2.44772 4 3 4H3.17071ZM4 10C4 9.44772 4.44772 9 5 9H11C11.5523 9 12 9.44772 12 10C12 10.5523 11.5523 11 11 11H5C4.44772 11 4 10.5523 4 10ZM5 13C4.44772 13 4 13.4477 4 14C4 14.5523 4.44772 15 5 15H11C11.5523 15 12 14.5523 12 14C12 13.4477 11.5523 13 11 13H5Z"
      fill="currentColor"
    />
  </svg>
);

export default ClipboardIcon;
