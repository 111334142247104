import { IconProps } from './icon';

const EmptyCartIcon: React.FC<IconProps> = props => (
  <svg
    {...props}
    width="323"
    height="258"
    viewBox="0 0 323 258"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" width="322" height="258" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M81.6445 172.86H198.852C199.516 172.86 200.162 172.788 200.784 172.652C201.407 172.788 202.053 172.86 202.716 172.86H269.692C274.672 172.86 278.708 168.817 278.708 163.83C278.708 158.842 274.672 154.8 269.692 154.8H261.964C256.985 154.8 252.948 150.757 252.948 145.77C252.948 140.782 256.985 136.74 261.964 136.74H286.436C291.416 136.74 295.452 132.697 295.452 127.71C295.452 122.722 291.416 118.68 286.436 118.68H258.1C263.08 118.68 267.116 114.637 267.116 109.65C267.116 104.662 263.08 100.62 258.1 100.62H175.668C180.648 100.62 184.684 96.5767 184.684 91.5896C184.684 86.6024 180.648 82.5596 175.668 82.5596H102.252C97.2731 82.5596 93.2365 86.6024 93.2365 91.5896C93.2365 96.5767 97.2731 100.62 102.252 100.62H50.7325C45.7531 100.62 41.7165 104.662 41.7165 109.65C41.7165 114.637 45.7531 118.68 50.7325 118.68H82.9325C87.9119 118.68 91.9485 122.722 91.9485 127.71C91.9485 132.697 87.9119 136.74 82.9325 136.74H31.4125C26.4331 136.74 22.3965 140.782 22.3965 145.77C22.3965 150.757 26.4331 154.8 31.4125 154.8H81.6445C76.6651 154.8 72.6285 158.842 72.6285 163.83C72.6285 168.817 76.6651 172.86 81.6445 172.86ZM291.589 172.86C296.568 172.86 300.604 168.817 300.604 163.83C300.604 158.842 296.568 154.8 291.589 154.8C286.609 154.8 282.572 158.842 282.572 163.83C282.572 168.817 286.609 172.86 291.589 172.86Z"
      fill="#FDF1F5"
    />
    <path
      d="M215.596 190.92H244.483M82.9318 190.92H110.565H82.9318ZM66.3525 190.92H75.2038H66.3525ZM250.537 190.92H254.236H250.537Z"
      stroke="#370617"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M126.724 55.8601L141.02 71.9246M193.828 55.8601L179.532 71.9246L193.828 55.8601ZM160.212 50.3096V71.9246V50.3096Z"
      stroke="#570A25"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M120.023 91.5898H200.661L193.439 102.439L203.068 109.671H117.616L128.448 102.439L120.023 91.5898Z"
      fill="#FDEDF2"
    />
    <rect
      x="115.132"
      y="107.07"
      width="91.448"
      height="96.75"
      rx="2"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M121.037 159.968V115.643C121.037 113.816 122.531 112.336 124.375 112.336L202.913 195.002C202.913 197.437 200.967 199.411 198.566 199.411H125.384C122.983 199.411 121.037 197.437 121.037 195.002V178.529V174.06V159.968ZM121.037 169.538V164.574V169.538Z"
      fill="#FDEDF2"
    />
    <path
      d="M116.42 160.557V112.082C116.42 110.085 118.056 108.466 120.075 108.466H203.696C205.011 108.466 206.076 109.545 206.076 110.877V198.872C206.076 201.534 203.945 203.693 201.316 203.693H121.18C118.551 203.693 116.42 201.534 116.42 198.872V180.856V175.969M116.42 171.024V165.594"
      stroke="#370617"
      strokeWidth="2.5"
      strokeLinecap="round"
    />
    <path
      d="M118.82 108.466V94.0009C118.82 92.6694 119.787 91.5901 120.98 91.5901L200.907 91.5901C202.1 91.5901 203.067 92.6694 203.067 94.0009V108.466"
      stroke="#370617"
      strokeWidth="2.5"
    />
    <path
      d="M141.085 133.78C143.412 133.78 145.298 131.891 145.298 129.561C145.298 127.231 143.412 125.342 141.085 125.342C138.759 125.342 136.873 127.231 136.873 129.561C136.873 131.891 138.759 133.78 141.085 133.78Z"
      fill="#FDEDF2"
      stroke="#370617"
      strokeWidth="2.5"
      strokeLinecap="round"
    />
    <path
      d="M180.802 133.78C183.129 133.78 185.015 131.891 185.015 129.561C185.015 127.231 183.129 125.342 180.802 125.342C178.476 125.342 176.59 127.231 176.59 129.561C176.59 131.891 178.476 133.78 180.802 133.78Z"
      fill="#FDEDF2"
      stroke="#370617"
      strokeWidth="2.5"
      strokeLinecap="round"
    />
    <path
      d="M180.2 133.779C180.2 144.431 171.578 153.065 160.943 153.065C150.308 153.065 141.687 144.431 141.687 133.779"
      stroke="#370617"
      strokeWidth="2.5"
      strokeLinecap="round"
    />
    <path
      d="M120.096 92.791L128.569 101.168C129.076 101.668 129.081 102.485 128.581 102.992C128.495 103.08 128.396 103.155 128.288 103.214L118.819 108.465"
      stroke="#370617"
      strokeWidth="2.5"
      strokeLinecap="round"
    />
    <path
      d="M201.995 92.8789L193.887 101.161C193.389 101.67 193.397 102.487 193.905 102.986C193.989 103.069 194.085 103.14 194.19 103.197L203.833 108.466"
      stroke="#370617"
      strokeWidth="2.5"
      strokeLinecap="round"
    />
  </svg>
);

export default EmptyCartIcon;
