import { useRecoilState, useSetRecoilState } from 'recoil';
import Button from 'src/components/atoms/Button';
import PlusRound from 'src/components/atoms/icons/PlusRound';
import Modal from 'src/components/atoms/Modal';
import useGetAddress from 'src/network/react-query/query/address/getAddress';
import { AddressHeader } from 'src/pages/account/Address/style';
import { checkoutActiveTabState } from 'src/stores/checkout/checkoutActiveTab';
import {
  addressFormModalState,
  editAddressModalState,
} from 'src/stores/menus/addressModal';
import AddressCard from '../AddressCard';
import AddressForm from '../AddressForm';
import EditAddressForm from '../AddressForm/EditAddressForm';

const CheckoutAddressPicker = () => {
  const [isFormOpen, setFormOpen] = useRecoilState(
    addressFormModalState
  );

  const { data, isLoading, error } = useGetAddress();
  const [editFormState, setEditFormState] = useRecoilState(
    editAddressModalState
  );

  const setCheckoutActiveTab = useSetRecoilState(
    checkoutActiveTabState
  );

  if (isLoading) return <p>Loading...</p>;
  if (error)
    return <p>An error occured while fetching addresses</p>;

  if (data)
    return (
      <>
        <AddressHeader>
          <h2>Address Book</h2>
          <Button onClick={() => setFormOpen(true)}>
            <PlusRound /> Add New Address
          </Button>
        </AddressHeader>

        {data.map((address, i) => (
          <AddressCard
            key={i}
            addressDetails={address}
            showCheckbox
          />
        ))}

        <Button
          style={{ justifySelf: 'end' }}
          onClick={() =>
            setCheckoutActiveTab('checkoutDetails')
          }
        >
          {data.length ? 'Save' : 'Back'}
        </Button>

        <Modal
          isOpen={isFormOpen}
          onClose={() => setFormOpen(false)}
        >
          <AddressForm />
        </Modal>

        <Modal
          isOpen={editFormState.isOpen}
          onClose={() =>
            setEditFormState({ isOpen: false })
          }
        >
          <EditAddressForm />
        </Modal>
      </>
    );

  return null;
};

export default CheckoutAddressPicker;
