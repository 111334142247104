import Skeleton from 'react-loading-skeleton';
import { Card } from './style';

const ProductCardSkeleton = () => {
  return (
    <Card>
      <Skeleton height={130} />
      <Skeleton count={3} />
      <Skeleton width="50%" />
    </Card>
  );
};

export default ProductCardSkeleton;
