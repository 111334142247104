import { Container } from 'src/components/atoms/Container';
import { styled } from 'styled-components';

export const DetailsSection = styled.section`
  padding-block: 20px 40px;
`;

export const DetailsWrapper = styled(Container)`
  display: grid;
  gap: 30px;
  grid-template-columns: 1.3fr 1.5fr 0.8fr;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
`;

export const ImageGroup = styled.div`
  display: block;
`;

export const DetailsGroup = styled.div`
  display: block;
`;

export const OrderGroup = styled.div`
  display: block;
`;

export const MainProductImage = styled.div`
  height: 300px;
  background-color: #f6f7f8;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
`;

export const SubProductImages = styled.div`
  width: 100%;
  margin-top: 16px;
  display: flex;
  gap: 16px;
`;

export const SubImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 120px;
  background-color: #f6f7f8;
  border-radius: 8px;
  padding: 1rem;

  img {
    height: 100%;
    object-fit: contain;
  }
`;

export const TitleGroup = styled.div`
  display: flex;
  gap: 16px;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 12px;
`;

export const Quantity = styled.p`
  color: ${p => p.theme.colors.danger[600]};
  margin-bottom: 20px;
  font-weight: 500;
`;

export const ProductFixedPrice = styled.p`
  font-weight: 700;
  margin-block: 20px;
`;

export const ProductPrices = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 44px;
`;

export const PriceGroup = styled.div`
  h3 {
    font-weight: 800;
    font-size: 18px;
    margin-top: 8px;
  }
`;

export const DeliveryDetails = styled.div`
  border-radius: 8px;
  border: 1px solid ${p => p.theme.colors.neutral[200]};
`;

export const DeliveryText = styled.div`
  padding: 1rem 2rem;
  display: flex;
  gap: 18px;

  div > h3 {
    font-weight: 600;
    margin-bottom: 10px;
  }
`;

export const ProductQuantity = styled.div`
  display: grid;
  gap: 24px;
  margin-bottom: 24px;
`;

export const TotalPrice = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;

  span {
    font-size: 18px;
    font-weight: 600;
  }
`;

export const CheckoutButtons = styled.div`
  display: grid;
  gap: 12px;
  margin-top: 50px;
`;

export const Danger = styled.span`
  color: ${p => p.theme.colors.danger[600]};
`;

export const TabsContainer = styled(Container)`
  margin-block: 20px 60px;

  @media (min-width: 768px) {
    margin-block: 60px 120px;
  }
`;

export const ErrorNotice = styled(Container)`
  padding-block: 100px;
  display: grid;
  gap: 16px;
  place-items: center;
  text-align: center;

  h2 {
    font-size: 1.5rem;
    font-weight: 700;
  }
`;

export const RelatedProductText = styled.p`
  margin-block: 2rem;
`;
