import { useMutation } from 'react-query';
import getDeliveryFeeService from 'src/network/services/orders/getDeliveryFee';

const useDeliveryFee = () => {
  const mutation = useMutation(getDeliveryFeeService);

  const {
    mutate: getDeliveryFee,
    isLoading: isGettingDeliveryFee,
    data,
  } = mutation;

  return { getDeliveryFee, isGettingDeliveryFee, data };
};

export default useDeliveryFee;
