import { isPasswordValid } from 'src/components/atoms/Input/useValidatePassword';
import * as z from 'zod';

export const profileFormSchema = z.object({
  email: z.string().email('Please provide a valid email'),
});

export type ProfileFormProps = z.infer<
  typeof profileFormSchema
>;

export const passwordFormSchema = z
  .object({
    current_password: z
      .string()
      .trim()
      .nonempty('Please provide a password')
      .min(8, 'Password should be at least 8 characters')
      .max(30, 'Password is too long')
      .regex(/^[\S]+$/, 'Password should be one phrase'),

    new_password: z
      .string()
      .trim()
      .nonempty('Please provide a password')
      .min(8, 'Password should be at least 8 characters')
      .max(30, 'Password is too long')
      .regex(/^[\S]+$/, 'Password should be one phrase')
      .refine(
        value => isPasswordValid(value),
        'Password does not match required format'
      ),

    confirm_password: z
      .string()
      .trim()
      .nonempty('Please provide a password'),
  })
  .refine(
    data => data.new_password === data.confirm_password,
    {
      message: 'Passwords do not match',
      path: ['confirm_password'],
    }
  );

export type PasswordFormProps = z.infer<
  typeof passwordFormSchema
>;
