import { IconProps } from './icon';

const StarOutline: React.FC<IconProps> = props => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0001 0.416992C10.7212 0.416992 11.3865 0.789026 11.7413 1.39068L14.1766 5.52062L18.3841 6.30946C19.117 6.44689 19.709 6.96425 19.9192 7.65108C20.1294 8.33791 19.9228 9.07974 19.3833 9.57478L16.1832 12.5116L16.9235 17.3912C17.0309 18.0991 16.7181 18.8053 16.1121 19.223C15.5062 19.6407 14.708 19.7002 14.0419 19.3774L10.0001 17.4189L5.95825 19.3774C5.29216 19.7002 4.49395 19.6407 3.888 19.223C3.28205 18.8053 2.9692 18.0991 3.07659 17.3912L3.81689 12.5116L0.616788 9.57478C0.0773606 9.07974 -0.129298 8.3379 0.0808884 7.65108C0.291075 6.96425 0.883081 6.44689 1.61606 6.30946L5.8235 5.52062L8.25885 1.39068C8.61364 0.789025 9.27893 0.416992 10.0001 0.416992ZM7.11151 7.23214L2.00006 8.19047L5.94433 11.8102L5.05578 17.667L10.0001 15.2712L14.9443 17.667L14.0558 11.8102L18.0001 8.19047L12.8886 7.23214L10.0001 2.33366L7.11151 7.23214Z"
      fill="currentColor"
    />
  </svg>
);

export default StarOutline;
