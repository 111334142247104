import { Container } from 'src/components/atoms/Container';
import styled from 'styled-components';

export const StyledHeroSection = styled(Container)`
  height: 548px;
  border-radius: 24px;
  overflow: hidden;
  background-color: lightblue;
  position: relative;

  .swiper {
    height: 100%;
  }
`;

export const HeroImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const HeroSectionOverlay = styled.div`
  position: absolute;
  inset: 0;
  background: linear-gradient(
    180deg,
    rgba(229, 240, 253, 0.9) 13.27%,
    rgba(229, 240, 253, 0.9) 31.77%,
    rgba(229, 240, 253, 0.45) 59.53%,
    rgba(229, 240, 253, 0) 96.53%
  );

  z-index: 2;

  display: grid;
  gap: 48px;
  align-content: center;
  padding: 16px;

  @media (min-width: 768px) {
    padding: 32px;
  }
`;

export const HeroText = styled.div`
  display: grid;
  gap: 16px;
  max-width: 618px;

  h1 {
    font-family: Inter;
    font-size: 1.5rem;
    font-weight: 800;
    line-height: 28px;
    letter-spacing: -0.02em;
    text-align: left;
  }

  p {
    font-family: Inter;
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.02em;
    text-align: left;
  }

  @media (min-width: 768px) {
    gap: 24px;

    h1 {
      font-size: 3rem;
      line-height: 56px;
      letter-spacing: -0.04em;
    }

    p {
      font-size: 1.125rem;
      line-height: 23px;
      letter-spacing: -0.02em;
    }
  }
`;

export const HeroButtons = styled.div`
  display: grid;
  gap: 16px;

  @media (min-width: 580px) {
    display: flex;
  }
`;
