import { toast } from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';
import updateCartItemService from 'src/network/services/cart/updateCart';

const useUpdateCart = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(updateCartItemService, {
    onSuccess: res => {
      toast.dismiss();
      toast.success('Cart item updated', {
        id: 'cart',
      });
      queryClient.refetchQueries('userCart');
    },
  });

  const {
    mutate: updateCart,
    isLoading: isUpdatingCart,
    data,
  } = mutation;

  return { updateCart, isUpdatingCart, data };
};

export default useUpdateCart;
