import { ApiResponse } from 'src/network/apiResponse';
import { API_ROUTES } from 'src/network/apiRoutes';
import { axiosInstance } from 'src/network/axios';
import getUserState from 'src/utils/helpers/getUserState';
import handleServiceError from 'src/utils/helpers/handleServiceError';

const getUserProfileService = async () => {
  try {
    const user = await getUserState();
    if (!user) {
      throw Error('User is not signed in');
    }
    const { userId } = user;

    const response = await axiosInstance.get<
      ApiResponse<ProfileDetails>
    >(API_ROUTES.getProfile + `/${userId}`);
    return response.data.data;
  } catch (error) {
    throw handleServiceError(error);
  }
};

export default getUserProfileService;

export interface ProfileDetails {
  _id: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  role: string;
  isCompleteSignup: boolean;
  email: string;
  address: {
    _id: string;
    state: string;
    city: string;
    address: string;
  };
}
