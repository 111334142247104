import { styled } from "styled-components";
import { DividerProps } from ".";

export const StyledDivider = styled.div<DividerProps>`
  width: ${({ orientation }) =>
    orientation === 'vertical' ? '1px' : '100%'};
  height: ${({ orientation }) =>
    orientation === 'vertical' ? '100%' : '1px'};
  background-color: ${({ shade, theme }) =>
    theme.colors.neutral[shade || 300]};
  margin: ${({ margin }) => margin || '0'};
  border: none;
  ${({ orientation }) =>
    orientation === 'horizontal'
      ? 'border-top: 1px solid transparent;'
      : 'border-left: 1px solid transparent;'}
`;

export const StyledText = styled.span`
  display: block;
  margin-inline: auto;
  width: max-content;
  padding: 0 20px;
  color: ${({ theme }) => theme.colors.black};
  background-color: #ffffff;
  font-weight: bold;
  font-size: 14px;
  position: relative;
  top: -12px;
`;