import { AxiosError } from 'axios';
import { toast } from 'react-hot-toast';

const GENERIC_ERROR_MESSAGE =
  'Server Error. Please Try again.';

const handleMutationError = (error: unknown) => {
  toast.dismiss();
  if (
    error instanceof AxiosError ||
    error instanceof Error
  ) {
    toast.error(error.message || GENERIC_ERROR_MESSAGE);
  } else {
    toast.error('Error connecting to the server');
  }
};

export default handleMutationError;
