export function formatDate(inputDate: string): string {
  const date = new Date(inputDate);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(
    2,
    '0'
  ); // Months are zero-based
  const year = String(date.getFullYear());

  return `${day}/${month}/${year}`;
}
