import {
  Display1,
  Display2,
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Heading6,
} from './style';

interface HeadingProps
  extends React.HTMLAttributes<HTMLHeadingElement> {
  as: keyof JSX.IntrinsicElements;
  level: 1 | 2 | 3 | 4 | 5 | 6 | 'display1' | 'display2';
  w?: 400 | 500 | 600 | 700 | 800;
  children: React.ReactNode;
}

const Heading: React.FC<HeadingProps> = ({
  as,
  level,
  w = 700,
  children,
  ...rest
}) => {
  return level === 1 ? (
    <Heading1 as={as} w={w} {...rest}>
      {children}
    </Heading1>
  ) : level === 2 ? (
    <Heading2 as={as} w={w} {...rest}>
      {children}
    </Heading2>
  ) : level === 3 ? (
    <Heading3 as={as} w={w} {...rest}>
      {children}
    </Heading3>
  ) : level === 4 ? (
    <Heading4 as={as} w={w} {...rest}>
      {children}
    </Heading4>
  ) : level === 5 ? (
    <Heading6 as={as} w={w} {...rest}>
      {children}
    </Heading6>
  ) : level === 6 ? (
    <Heading6 as={as} w={w} {...rest}>
      {children}
    </Heading6>
  ) : level === 'display1' ? (
    <Display1 as={as} w={w} {...rest}>
      {children}
    </Display1>
  ) : level === 'display2' ? (
    <Display2 as={as} w={w} {...rest}>
      {children}
    </Display2>
  ) : (
    <Heading1 as={as} w={w} {...rest}>
      {children}
    </Heading1>
  );
};

export default Heading;
