import { toast } from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';
import { useSetRecoilState } from 'recoil';
import updateAddressService from 'src/network/services/address/updateAddress';
import { editAddressModalState } from 'src/stores/menus/addressModal';

const useUpdateAddress = () => {
  const queryClient = useQueryClient();
  const setFormState = useSetRecoilState(
    editAddressModalState
  );

  const mutation = useMutation(updateAddressService, {
    onSuccess: res => {
      toast.dismiss();
      queryClient.invalidateQueries('address');
      setFormState({ isOpen: false });
      toast.success('Address updated successfully');
    },
    onError: () => {
      setFormState({ isOpen: false });
    },
  });

  const {
    mutate: updateAddress,
    isLoading: isUpdatingAddress,
    data,
  } = mutation;

  return { updateAddress, isUpdatingAddress, data };
};

export default useUpdateAddress;
