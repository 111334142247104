import { IconProps } from './icon';

const CloseIcon: React.FC<IconProps> = props => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.0003 27.3332C6.63653 27.3332 0.666992 21.3636 0.666992 13.9998C0.666992 6.63604 6.63653 0.666504 14.0003 0.666504C21.3641 0.666504 27.3337 6.63604 27.3337 13.9998C27.3337 21.3636 21.3641 27.3332 14.0003 27.3332ZM14.0003 3.33317C8.10929 3.33317 3.33366 8.1088 3.33366 13.9998C3.33366 19.8909 8.10929 24.6665 14.0003 24.6665C19.8914 24.6665 24.667 19.8909 24.667 13.9998C24.667 8.1088 19.8914 3.33317 14.0003 3.33317ZM18.9431 9.05703C19.4638 9.57773 19.4638 10.4219 18.9431 10.9426L15.8859 13.9998L18.9431 17.057C19.4638 17.5777 19.4638 18.4219 18.9431 18.9426C18.4224 19.4633 17.5782 19.4633 17.0575 18.9426L14.0003 15.8855L10.9431 18.9426C10.4224 19.4633 9.57822 19.4633 9.05752 18.9426C8.53682 18.4219 8.53682 17.5777 9.05752 17.057L12.1147 13.9998L9.05752 10.9426C8.53682 10.4219 8.53682 9.57773 9.05752 9.05703C9.57822 8.53633 10.4224 8.53633 10.9431 9.05703L14.0003 12.1142L17.0575 9.05703C17.5782 8.53633 18.4224 8.53633 18.9431 9.05703Z"
      fill="currentColor"
    />
  </svg>
);

export default CloseIcon;
