import { ApiResponse } from 'src/network/apiResponse';
import { API_ROUTES } from 'src/network/apiRoutes';
import { axiosInstance } from 'src/network/axios';
import handleServiceError from 'src/utils/helpers/handleServiceError';

interface Payload {
  addressId: string;
  details: {
    country?: string;
    state?: string;
    city?: string;
    address?: string;
    customerId?: string;
    businessId?: string;
    isDefault?: boolean;
  };
}

const updateAddressService = async (payload: Payload) => {
  try {
    const response = await axiosInstance.put<
      ApiResponse<string>
    >(
      API_ROUTES.updateAddress + `/${payload.addressId}`,
      payload.details
    );
    return response.data;
  } catch (error) {
    throw handleServiceError(error);
  }
};

export default updateAddressService;
