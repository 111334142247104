import { StyledDivider, StyledText } from './style';

export type DividerProps = {
  orientation?: 'horizontal' | 'vertical';
  shade?:
    | 50
    | 100
    | 200
    | 300
    | 400
    | 500
    | 600
    | 700
    | 800
    | 900;
  margin?: string;
  text?: string; // New prop for displaying text
};

export const Divider: React.FC<
  DividerProps & React.HTMLAttributes<HTMLDivElement>
> = ({
  orientation = 'horizontal',
  text, // New prop for displaying text
  ...rest
}) => {
  return (
    <StyledDivider
      orientation={orientation}
      {...rest}
      role="separator"
    >
      {text && <StyledText>{text}</StyledText>}
    </StyledDivider>
  );
};
