import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import Button from 'src/components/atoms/Button';
import {
  NLNGIcon,
  NNPCIcon,
  SAIPEMIcon,
  TOTALIcon,
} from 'src/components/atoms/icons/brands';
import CloseIcon from 'src/components/atoms/icons/CloseIcon';
import IOTIcon from 'src/components/atoms/icons/IOT';
import LPGIcon from 'src/components/atoms/icons/LPG';
import LubricantIcon from 'src/components/atoms/icons/Lubricant';
import PMSIcon from 'src/components/atoms/icons/PMS';
import { ROUTES } from 'src/constants/routes';
import { landingMenuState } from 'src/stores/menus/landingMenuState';
import useClickOutside from 'src/utils/hooks/useClickOutside';
import {
  CloseButtonWrapper,
  Divider,
  LinkGroupWrapper,
  MenuItemHeader,
  MenuWrapper,
  StyledLinkGroup,
  StyledMenuItem,
  StyledMenuItemContent,
  StyledNav,
} from './style';

interface MenuLink {
  icon: React.ReactNode;
  url: string;
  title: string;
}

const Navigation = () => {
  const navRef = useRef<HTMLElement>(null);
  const [isNavbarOpen, setNavbarOpen] = useRecoilState(
    landingMenuState
  );

  const closeNav = () => setNavbarOpen(false);
  useClickOutside(navRef, closeNav);

  return (
    <StyledNav data-open={isNavbarOpen} ref={navRef}>
      <MenuWrapper>
        <CloseButtonWrapper>
          <Button
            variant="ghost"
            onClick={closeNav}
            title="close menu"
          >
            <CloseIcon />
          </Button>
        </CloseButtonWrapper>

        <MenuItem
          heading="ALL PRODUCTS"
          content={<AllProducts />}
        />

        <Divider />

        <MenuItem
          heading="FEATURED"
          content={<FeaturedProducts />}
        />
      </MenuWrapper>
    </StyledNav>
  );
};

export default Navigation;

const MenuItem = (props: {
  heading: string;
  content: React.ReactNode;
}) => {
  const { content, heading } = props;
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);

  useClickOutside(ref, () => setIsOpen(false));

  return (
    <StyledMenuItem ref={ref}>
      <MenuItemHeader
        onClick={() => setIsOpen(p => !p)}
        data-open={isOpen}
      >
        {heading}
      </MenuItemHeader>

      <StyledMenuItemContent data-open={isOpen}>
        {content}
      </StyledMenuItemContent>
    </StyledMenuItem>
  );
};

const TOP_PRODUCTS_LINKS: MenuLink[] = [
  {
    title: 'LUBRICANTS',
    url: ROUTES.products,
    icon: <LubricantIcon />,
  },
];

const COMING_SOON_LINKS: MenuLink[] = [
  {
    title: 'PMS',
    url: '#',
    icon: <PMSIcon />,
  },
  {
    title: 'AGO',
    url: '#',
    icon: <PMSIcon />,
  },
  {
    title: 'LPG',
    url: '#',
    icon: <LPGIcon />,
  },
  {
    title: 'IoTs',
    url: '#',
    icon: <IOTIcon />,
  },
];

const TOP_BRAND_LINKS: MenuLink[] = [
  {
    title: 'NNPC',
    url: '#',
    icon: <NNPCIcon />,
  },
  {
    title: 'NLNG',
    url: '#',
    icon: <NLNGIcon />,
  },
  {
    title: 'SAIPEM',
    url: '#',
    icon: <SAIPEMIcon />,
  },
  {
    title: 'TOTAL',
    url: '#',
    icon: <TOTALIcon />,
  },
];

const AllProducts = () => {
  return (
    <LinkGroupWrapper>
      <LinkGroup
        heading="Top Products"
        links={TOP_PRODUCTS_LINKS}
      />

      <LinkGroup
        heading="Coming Soon"
        links={COMING_SOON_LINKS}
      />
    </LinkGroupWrapper>
  );
};

const FeaturedProducts = () => {
  return (
    <LinkGroupWrapper>
      <LinkGroup
        heading="Top Brands"
        links={TOP_BRAND_LINKS}
      />
    </LinkGroupWrapper>
  );
};

interface LinkGroupProps {
  heading: string;
  links: MenuLink[];
}

const LinkGroup = (props: LinkGroupProps) => {
  const { heading, links } = props;

  return (
    <StyledLinkGroup>
      <h3>{heading}</h3>

      {links.map((link, index) => {
        return (
          <Link to={link.url} key={index}>
            {link.icon} {link.title}
          </Link>
        );
      })}
    </StyledLinkGroup>
  );
};
