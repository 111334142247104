import { useEffect, useState } from 'react';
import { showConfirmDialog } from 'src/components/molecules/Confirm/confirm';

const useAppUpdate = () => {
  const [
    isPendingServiceWorker,
    setIsPendingServiceWorker,
  ] = useState<boolean>(false);

  useEffect(() => {
    // Listen for messages from the service worker
    window.navigator.serviceWorker &&
      window.navigator.serviceWorker.addEventListener(
        'message',
        event => {
          const { data } = event;
          if (data && data.type === 'SW_UPDATED') {
            // Handle the service worker updated event
            showConfirmDialog('completeAppUpdate');
          }
        }
      );

    async function checkPendingServiceWorker() {
      const hasPendingServiceWorker =
        await isServiceWorkerWaiting();
      setIsPendingServiceWorker(hasPendingServiceWorker);
    }

    checkPendingServiceWorker();
  }, []);

  if (isPendingServiceWorker)
    showConfirmDialog('pendingUpdate');
};

export default useAppUpdate;

export async function isServiceWorkerWaiting(): Promise<boolean> {
  if ('serviceWorker' in navigator) {
    try {
      const registration =
        await navigator.serviceWorker.getRegistration();

      if (registration && registration.waiting) {
        return true;
      }
    } catch (error) {
      console.error(
        'Error checking for pending service worker:',
        error
      );
    }
  }

  return false;
}
