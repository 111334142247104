import Heading from 'src/components/atoms/Heading';
import { CartCard, CartGrid, OrderSummary } from './style';
import { Divider } from 'src/components/atoms/Divider';
import CartItemSkeleton from 'src/components/molecules/CartItem/Skeleton';
import Skeleton from 'react-loading-skeleton';

const CartPageSkeleton = () => {
  return (
    <CartGrid>
      <CartCard>
        <Heading as="h2" level={6}>
          My Shopping Cart
        </Heading>

        <Divider margin="24px 0" />

        {Array(2)
          .fill(null)
          .map((_, i) => (
            <div key={i}>
              <CartItemSkeleton />
              <Divider margin="24px 0" />
            </div>
          ))}
      </CartCard>

      <OrderSummary>
        <Heading as="h2" level={6}>
          Order Summary
        </Heading>

        <Divider margin="24px 0" />

        <Skeleton />
        <Skeleton width={100} />
        <Skeleton height={40} />
      </OrderSummary>
    </CartGrid>
  );
};

export default CartPageSkeleton;
