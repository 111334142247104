import React, { useState } from 'react';
import { toast } from 'react-hot-toast';
import { useRecoilValue } from 'recoil';
import Button from 'src/components/atoms/Button';
import Form from 'src/components/atoms/Form';
import Modal from 'src/components/atoms/Modal';
import Rate from 'src/components/atoms/Rate';
import Textarea from 'src/components/atoms/Textarea';
import useRateProduct from 'src/network/react-query/mutation/ratings/rateProduct';
import { RateProductPayload } from 'src/network/services/ratings/rateProduct';
import { ratingModalState } from 'src/stores/menus/ratingModalState';
import { styled } from 'styled-components';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const ReviewModal: React.FC<Props> = ({
  isOpen,
  onClose,
}) => {
  const [rating, setRating] = useState(0);
  const [reviewText, setReviewText] = useState('');
  const ratingModal = useRecoilValue(ratingModalState);
  const { mutate, isLoading } = useRateProduct(() => {
    setRating(0);
    setReviewText('');
    onClose();
  });

  if (!ratingModal.isOpen) return null;

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (rating === 0 && reviewText.trim().length === 0) {
      onClose();
      toast.error('No review made');
      return;
    }

    toast.loading('Sending review', {
      id: 'rating',
    });

    const payload: RateProductPayload = {
      productId: ratingModal.productId,
      star: rating,
    };

    if (reviewText.trim()) payload.review = reviewText;

    mutate(payload);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      style={{ maxWidth: '557px' }}
    >
      <Heading>
        <h2>Rate Order</h2>
        <p>Leave an honest review about your order</p>
      </Heading>

      <Content>
        <Rate
          rating={rating}
          onRate={value => {
            value === rating
              ? setRating(0)
              : setRating(value);
          }}
        />

        <Form onSubmit={handleSubmit}>
          <Textarea
            label="Write a review (optional)"
            placeholder="Write a review"
            value={reviewText}
            onChange={e => setReviewText(e.target.value)}
            style={{
              height: '166px',
              resize: 'none',
            }}
          />

          <Button
            type="submit"
            disabled={!rating || isLoading}
            loading={isLoading}
          >
            Submit
          </Button>
        </Form>
      </Content>
    </Modal>
  );
};

export default ReviewModal;

const Heading = styled.div`
  margin-bottom: 24px;

  h2 {
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 12px;
  }
`;

const Content = styled.div`
  display: grid;
  gap: 20px;
`;
