import truck from 'src/assets/images/oil-truck.png';
import Button from 'src/components/atoms/Button';
import { VENDOR_APP_REG_URL } from 'src/constants/externalLinks';
import { StyledImage, StyledSection } from './style';

const VendorSection = () => {
  return (
    <StyledSection as="section">
      <div>
        <h2>
          Expand your reach to a growing network of buyers
        </h2>
        <p>
          Join our B2B platform and connect with businesses
          looking for AGO, PMS, LPG, and Lubricants.
        </p>
        <Button
          asLink
          target="_blank"
          href={VENDOR_APP_REG_URL}
          variant="secondary"
        >
          Become a Vendor
        </Button>
      </div>

      <StyledImage src={truck} alt="oil-truck" />
    </StyledSection>
  );
};

export default VendorSection;
