import Skeleton from 'react-loading-skeleton';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import Button from 'src/components/atoms/Button';
import useGetAddress from 'src/network/react-query/query/address/getAddress';
import { checkoutActiveTabState } from 'src/stores/checkout/checkoutActiveTab';
import { checkoutStore } from 'src/stores/checkout/checkoutState';
import useCurrentUser from 'src/utils/hooks/useCurrentUser';
import { EmptyAddressWrapper, Wrapper } from './style';

const DeliveryInfo = () => {
  const { data, isLoading } = useGetAddress();
  const { user } = useCurrentUser();
  const checkoutState = useRecoilValue(checkoutStore);
  const setActiveTab = useSetRecoilState(
    checkoutActiveTabState
  );

  if (!user) return null;

  if (isLoading)
    return (
      <>
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton style={{ width: '50%' }} />
      </>
    );

  if (data) {
    if (!data.length)
      return (
        <EmptyAddressWrapper>
          <h3>No address found</h3>
          <p>Please add at least one delivery address</p>
          <Button
            type="button"
            onClick={() => setActiveTab('addressBook')}
          >
            Add
          </Button>
        </EmptyAddressWrapper>
      );

    const { address, city, state } =
      checkoutState.hasChangedAddress
        ? checkoutState.address
        : data.find(address => address.isDefault) ||
          data[0];

    const { firstName, lastName, phoneNumber } = user;

    return (
      <Wrapper>
        <li>
          <h4>Name</h4>
          <p>
            {firstName} {lastName}
          </p>
        </li>
        <li>
          <h4>Address</h4>
          <p>{address}</p>
        </li>
        <li>
          <h4>City</h4>
          <p>{city}</p>
        </li>
        <li>
          <h4>State</h4>
          <p>{state}</p>
        </li>
        <li>
          <h4>Phone No</h4>
          <p>{phoneNumber}</p>
        </li>
      </Wrapper>
    );
  }

  return null;
};

export default DeliveryInfo;
