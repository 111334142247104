import { IconProps } from './icon';

const FunnelIcon: React.FC<IconProps> = props => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.909 16.5C11.909 17.6046 11.0136 18.5 9.90903 18.5L8.09085 18.5C7.56041 18.5 7.05171 18.2893 6.67663 17.9142C6.30156 17.5391 6.09085 17.0304 6.09085 16.5L6.09085 9.33725L0.596396 3.92481C0.0181643 3.35521 -0.158475 2.49263 0.149313 1.74159C0.457102 0.990545 1.18828 0.5 1.99994 0.500001L15.9999 0.5C16.8116 0.5 17.5428 0.990543 17.8506 1.74158C18.1583 2.49263 17.9817 3.35521 17.4035 3.92481L11.909 9.33725L11.909 16.5ZM7.95231 8.4108C8.04222 8.56334 8.09085 8.73845 8.09085 8.91863L8.09085 16.5L9.90903 16.5L9.90903 8.91863C9.90903 8.73845 9.95765 8.56334 10.0476 8.4108C10.0913 8.3366 10.1448 8.26773 10.2073 8.20622L15.9999 2.5L1.99994 2.5L7.79262 8.20622C7.85506 8.26773 7.90857 8.3366 7.95231 8.4108Z"
      fill="currentColor"
    />
  </svg>
);

export default FunnelIcon;
