import image1 from 'src/assets/images/gas-cylinder.png';
import image3 from 'src/assets/images/oil-brands-2.png';
import image2 from 'src/assets/images/pump-nozzle.png';
import Button from 'src/components/atoms/Button';
import { ROUTES } from 'src/constants/routes';
import {
  Card,
  CardImage,
  CardText,
  CardWrapper,
  Scroll,
  StyledSection,
} from './style';

const PopularProducts = () => {
  return (
    <StyledSection as="section">
      <h2>Explore Popular Products</h2>

      <Scroll>
        <CardWrapper>
          <Card>
            <CardText>
              <h3>LPG</h3>
              <p>3,245 Products</p>

              <Button
                asLink
                href={ROUTES.products}
                variant="secondary"
              >
                Shop now
              </Button>
            </CardText>

            <CardImage src={image1} alt="gas-cylinder" />
          </Card>

          <Card>
            <CardText>
              <h3>AGO</h3>
              <p>4,123 Products</p>

              <Button
                asLink
                href={ROUTES.products}
                variant="secondary"
              >
                Shop now
              </Button>
            </CardText>

            <CardImage src={image2} alt="pump-nozzle" />
          </Card>

          <Card>
            <CardText>
              <h3>LUBRICANTS</h3>
              <p>7,245 Products</p>

              <Button
                asLink
                href={ROUTES.products}
                variant="secondary"
              >
                Shop now
              </Button>
            </CardText>

            <CardImage src={image3} alt="oil-brands" />
          </Card>

          <Card>
            <CardText>
              <h3>PMS</h3>
              <p>2,245 Products</p>

              <Button
                asLink
                href={ROUTES.products}
                variant="secondary"
              >
                Shop now
              </Button>
            </CardText>

            <CardImage src={image2} alt="pump-nozzle" />
          </Card>
        </CardWrapper>
      </Scroll>
    </StyledSection>
  );
};

export default PopularProducts;
