import { styled } from 'styled-components';

export const Content = styled.div`
  display: grid;
  gap: 14px;
`;

export const ImgWrapper = styled.div`
  height: 140px;
  width: 140px;
  border-radius: 50%;
  justify-self: center;
`;
