import Skeleton from 'react-loading-skeleton';
import { BreadCrumbContainer } from '../Products/style';
import {
  DetailsGroup,
  DetailsSection,
  DetailsWrapper,
  ImageGroup,
  TabsContainer,
} from './style';
import { Divider } from 'src/components/atoms/Divider';

const ProductDetailsSkeleton = () => {
  return (
    <>
      <BreadCrumbContainer>
        <Skeleton width={300} />
      </BreadCrumbContainer>
      <DetailsSection>
        <DetailsWrapper>
          <ImageGroup>
            <Skeleton height={300} />
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr',
                gap: '16px',
                marginTop: '16px',
              }}
            >
              <Skeleton height={120} />
              <Skeleton height={120} />
              <Skeleton height={120} />
            </div>
          </ImageGroup>

          <DetailsGroup>
            <Skeleton height={20} count={3} />

            <Divider margin="1.5rem 0" />

            <Skeleton height={120} count={2} />
          </DetailsGroup>

          <div>
            <Skeleton />
            <Skeleton height={80} />
            <Skeleton count={4} />
            <Skeleton height={60} />
            <Skeleton height={60} />
          </div>
        </DetailsWrapper>

        <TabsContainer>
          <Skeleton height={150} />
        </TabsContainer>
      </DetailsSection>
    </>
  );
};

export default ProductDetailsSkeleton;
