import { LubricantData } from 'src/network/services/lubricants/getLubricants';
import ProductCard from '../ProductCard';
import { Grid } from './style';

interface Props {
  data: LubricantData[];
}

const ProductGrid: React.FC<Props> = ({ data }) => {
  return (
    <Grid>
      {data ? (
        data.map(lubricant => (
          <ProductCard
            key={lubricant._id}
            productData={lubricant}
          />
        ))
      ) : (
        <p>No product avilable</p>
      )}
    </Grid>
  );
};

export default ProductGrid;
