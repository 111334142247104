import { useRecoilState } from 'recoil';
import Button from 'src/components/atoms/Button';
import WasteBinIcon from 'src/components/atoms/icons/WasteBinIcon';
import useDeleteFavourite from 'src/network/react-query/mutation/favourites/deleteFavourite';
import { favouriteModalState } from 'src/stores/menus/favouriteModalState';
import ActionModal from '../ActionModal';

const FavouriteModal = () => {
  const [modalState, setModalState] = useRecoilState(
    favouriteModalState
  );

  const deleteFavouriteMutation = useDeleteFavourite();

  const handleRemoveFavourite = () => {
    if (!modalState.isOpen) return;

    deleteFavouriteMutation.deleteFavourite({
      favouriteId: modalState.favouriteId,
    });
  };

  const handleClose = () => {
    if (deleteFavouriteMutation.isDeletingFavourite) return;
    setModalState({ isOpen: false });
  };

  return (
    <ActionModal
      isOpen={modalState.isOpen}
      onClose={handleClose}
      heading="Remove From Favourite"
      text="Are you sure you want to remove this item?"
      icon={<WasteBinIcon />}
      footer={
        <div
          style={{
            display: 'flex',
            gap: '12px',
          }}
        >
          <Button
            fluid
            onClick={handleRemoveFavourite}
            disabled={
              deleteFavouriteMutation.isDeletingFavourite
            }
            loading={
              deleteFavouriteMutation.isDeletingFavourite
            }
          >
            Remove
          </Button>
          <Button
            variant="secondary"
            fluid
            onClick={() =>
              setModalState({
                isOpen: false,
              })
            }
            disabled={
              deleteFavouriteMutation.isDeletingFavourite
            }
          >
            Cancel
          </Button>
        </div>
      }
    />
  );
};

export default FavouriteModal;
