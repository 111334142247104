import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import Button from 'src/components/atoms/Button';
import { Divider } from 'src/components/atoms/Divider';
import Form from 'src/components/atoms/Form';
import Input from 'src/components/atoms/Input';
import AccountLayout from 'src/components/layouts/AccountLayout';
import ActionModal from 'src/components/molecules/ActionModal';
import useChangeCurrentPassword from 'src/network/react-query/mutation/auth/changeCurrentPassword';
import useUpdateProfile from 'src/network/react-query/mutation/profile/updateProfile';
import useGetUserProfile from 'src/network/react-query/query/profile/getUserProfile';
import useCurrentUser from 'src/utils/hooks/useCurrentUser';
import {
  PasswordFormProps,
  passwordFormSchema,
  ProfileFormProps,
  profileFormSchema,
} from './formSchema';
import { Card, FieldGroup, ProfileContent } from './style';

const ProfilePage = () => {
  return (
    <AccountLayout>
      <ProfileContent>
        <h1>Profile</h1>
        <ProfileDetailsSection />
        <PasswordSection />
      </ProfileContent>
    </AccountLayout>
  );
};

export default ProfilePage;

const ProfileDetailsSection = () => {
  const { user } = useCurrentUser();
  const { data: profileDetails } = useGetUserProfile();
  const updateProfileMutation = useUpdateProfile();

  const {
    register: registerProfile,
    handleSubmit: handleSubmitProfile,
    formState: { errors: profileErrors },
    clearErrors: clearProfileErrors,
  } = useForm<ProfileFormProps>({
    defaultValues: {
      email: '',
    },
    resolver: zodResolver(profileFormSchema),
    mode: 'onSubmit',
  });

  const onSubmitProfile = (data: ProfileFormProps) => {
    if (updateProfileMutation.isLoading) return;
    updateProfileMutation.mutate({ email: data.email });
  };

  return (
    <Card>
      <h2>Personal Information</h2>
      <Divider />

      <Form
        onChange={() => clearProfileErrors()}
        onSubmit={handleSubmitProfile(onSubmitProfile)}
      >
        <FieldGroup>
          <Input
            label="First Name"
            placeholder={user?.firstName}
            disabled
          />

          <Input
            label="Last Name"
            placeholder={user?.lastName}
            disabled
          />
        </FieldGroup>

        <Input
          label="Phone Number"
          type="text"
          placeholder={user?.phoneNumber}
          disabled
        />

        <Input
          label="Email Address"
          type="email"
          placeholder={
            profileDetails?.email || 'Enter email address'
          }
          {...registerProfile('email')}
          error={!!profileErrors.email}
          message={profileErrors.email?.message}
        />

        <Button
          type="submit"
          loading={updateProfileMutation.isLoading}
          disabled={updateProfileMutation.isLoading}
        >
          Save Changes
        </Button>
      </Form>
    </Card>
  );
};

const PasswordSection = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { isSuccess, isLoading, mutate } =
    useChangeCurrentPassword();

  const closeModal = () => setIsModalOpen(false);

  const {
    register: registerPassword,
    handleSubmit: handleSubmitPassword,
    formState: { errors: passwordErrors },
    clearErrors: clearPasswordErrors,
    reset,
  } = useForm<PasswordFormProps>({
    defaultValues: {
      current_password: '',
      new_password: '',
      confirm_password: '',
    },
    resolver: zodResolver(passwordFormSchema),
    mode: 'onBlur',
  });

  useEffect(() => {
    if (isSuccess) {
      setIsModalOpen(true);
      reset();
    }
  }, [isSuccess, reset]);

  const onSubmitPassword = (data: PasswordFormProps) => {
    toast.loading('Changing password...');
    mutate({
      currentPassword: data.current_password,
      newPassword: data.confirm_password,
    });
  };

  return (
    <Card>
      <h2>Password and security</h2>
      <Divider />

      <Form
        onChange={() => clearPasswordErrors()}
        onSubmit={handleSubmitPassword(onSubmitPassword)}
      >
        <Input
          label="Current Password"
          type="password"
          placeholder="Enter password"
          {...registerPassword('current_password')}
          error={!!passwordErrors.current_password}
          message={passwordErrors.current_password?.message}
        />

        <Input
          label="New Password"
          type="password"
          placeholder="Enter password"
          showPasswordValidation
          {...registerPassword('new_password')}
          error={!!passwordErrors.new_password}
          message={passwordErrors.new_password?.message}
        />

        <Input
          label="Confirm New Password"
          type="password"
          placeholder="Enter password"
          {...registerPassword('confirm_password')}
          error={!!passwordErrors.confirm_password}
          message={passwordErrors.confirm_password?.message}
        />

        <Button
          type="submit"
          disabled={isLoading}
          loading={isLoading}
        >
          Save Changes
        </Button>
      </Form>
      <ActionModal
        isOpen={isModalOpen}
        onClose={closeModal}
        heading="Password Updated Successfully!"
        text={`Congratulations! you have successfully 
        updated your password. Remember to keep your 
        new password safe and strong.`}
        footer={<Button onClick={closeModal}>Close</Button>}
      />
    </Card>
  );
};
