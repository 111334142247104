import { toast } from 'react-hot-toast';
import { Navigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import Heading from 'src/components/atoms/Heading';
import Text from 'src/components/atoms/Text';
import AuthLayout from 'src/components/layouts/AuthLayout';
import OtpForm from 'src/components/molecules/OtpForm';
import useResendOtp from 'src/network/react-query/mutation/auth/resendOtp';
import useVerifyPasswordOtp from 'src/network/react-query/mutation/auth/verifyPasswordOtp';
import { resetPasswordState } from 'src/stores/auth/resetPasswordState';
import { hidePhone } from 'src/utils/helpers/hidePhone';
import { styled } from 'styled-components';

const VerifyPasswordOTP = () => {
  const [
    { passwordOtpAccess, phoneNumber },
    setResetPasswordState,
  ] = useRecoilState(resetPasswordState);
  const { verifyPasswordOtp, isVerifyingOtp } =
    useVerifyPasswordOtp();
  const { resendOtp } = useResendOtp();

  if (!passwordOtpAccess) {
    return <Navigate to="/" />;
  }

  const handleSubmit = (otp: string) => {
    setResetPasswordState(prev => ({
      ...prev,
      otp,
    }));
    toast.loading('Verifying Otp');
    verifyPasswordOtp({
      phoneNumber,
      otp,
    });
  };

  const handleResendOtp = () => {
    toast.loading('Resending Otp');
    resendOtp({
      phoneNumber,
    });
  };

  return (
    <AuthLayout>
      <Group>
        <Heading as="h1" level={4}>
          Enter OTP
        </Heading>
        <Text size="lg" shade={600}>
          An OTP has been sent to{' '}
          <b>{hidePhone(phoneNumber)}</b> please enter OTP
        </Text>
      </Group>

      <OtpForm
        onSubmit={handleSubmit}
        isLoading={isVerifyingOtp}
        onResend={handleResendOtp}
      />
    </AuthLayout>
  );
};

export default VerifyPasswordOTP;

const Group = styled.div`
  margin-bottom: 40px;
`;
