import Facebook from 'src/components/atoms/icons/Facebook';
import Instagram from 'src/components/atoms/icons/Instagram';
import Twitter from 'src/components/atoms/icons/Twitter';

export const SOCIAL_MEDIA_LINKS = [
  {
    name: 'facebook',
    href: 'https://www.facebook.com/profile.php?id=100095540623098&mibextid=ZbWKwL',
    icon: <Facebook />,
  },
  {
    name: 'instagram',
    href: 'https://instagram.com/mephaltihq?igshid=NTc4MTIwNjQ2YQ==',
    icon: <Instagram />,
  },
  {
    name: 'twitter',
    href: 'https://twitter.com/mephaltihq?t=EKYKpAQrp9c39OhxZeYIIA&s=09',
    icon: <Twitter />,
  },
];

export const WHATSPP_ORDER_NUMBER = '2348144705621';
