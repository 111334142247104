import { IconProps } from './icon';

const Instagram: React.FC<IconProps> = props => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.36311 6.53265C7.8023 6.53265 6.52857 7.80638 6.52857 9.36719C6.52857 10.928 7.8023 12.2017 9.36311 12.2017C10.9239 12.2017 12.1976 10.928 12.1976 9.36719C12.1976 7.80638 10.9239 6.53265 9.36311 6.53265ZM17.8646 9.36719C17.8646 8.19339 17.8752 7.03023 17.8093 5.85857C17.7434 4.49765 17.4329 3.28983 16.4378 2.29466C15.4405 1.29736 14.2348 0.989027 12.8739 0.923107C11.7001 0.857188 10.5369 0.86782 9.36524 0.86782C8.19144 0.86782 7.02828 0.857188 5.85661 0.923107C4.4957 0.989027 3.28788 1.29949 2.29271 2.29466C1.29541 3.29196 0.987074 4.49765 0.921154 5.85857C0.855235 7.03236 0.865867 8.19552 0.865867 9.36719C0.865867 10.5389 0.855235 11.7041 0.921154 12.8758C0.987074 14.2367 1.29753 15.4445 2.29271 16.4397C3.29001 17.437 4.4957 17.7453 5.85661 17.8113C7.03041 17.8772 8.19357 17.8666 9.36524 17.8666C10.539 17.8666 11.7022 17.8772 12.8739 17.8113C14.2348 17.7453 15.4426 17.4349 16.4378 16.4397C17.4351 15.4424 17.7434 14.2367 17.8093 12.8758C17.8774 11.7041 17.8646 10.541 17.8646 9.36719ZM9.36311 13.7285C6.9496 13.7285 5.00179 11.7807 5.00179 9.36719C5.00179 6.95368 6.9496 5.00587 9.36311 5.00587C11.7766 5.00587 13.7244 6.95368 13.7244 9.36719C13.7244 11.7807 11.7766 13.7285 9.36311 13.7285ZM13.9031 5.84581C13.3395 5.84581 12.8845 5.39075 12.8845 4.82724C12.8845 4.26374 13.3395 3.80868 13.9031 3.80868C14.4666 3.80868 14.9216 4.26374 14.9216 4.82724C14.9218 4.96105 14.8956 5.09358 14.8444 5.21723C14.7933 5.34088 14.7183 5.45324 14.6237 5.54785C14.529 5.64247 14.4167 5.71749 14.293 5.76862C14.1694 5.81975 14.0369 5.84598 13.9031 5.84581Z"
      fill="currentColor"
    />
  </svg>
);

export default Instagram;
