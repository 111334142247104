import { Link } from 'react-router-dom';
import { Divider } from 'src/components/atoms/Divider';
import Heading from 'src/components/atoms/Heading';
import Text from 'src/components/atoms/Text';
import AuthLayout from 'src/components/layouts/AuthLayout';
import LoginForm from 'src/components/molecules/LoginForm';
import { ROUTES } from 'src/constants/routes';
import { styled } from 'styled-components';

const LoginPage = () => {
  return (
    <AuthLayout>
      <Heading as="h1" level={4}>
        Log in to your account
      </Heading>
      <Text shade={600}>
        Dont&#39;t have an account?{' '}
        <StyledLink to={ROUTES.register}>
          Create an account
        </StyledLink>
      </Text>

      <Divider margin="2rem 0" />

      <LoginForm />
    </AuthLayout>
  );
};

export default LoginPage;

const StyledLink = styled(Link)`
  color: ${p => p.theme.colors.primary[500]};
  font-weight: 700;
`;
