import axios from 'axios';
import { ApiResponse } from 'src/network/apiResponse';
import { API_ROUTES } from 'src/network/apiRoutes';
import handleServiceError from 'src/utils/helpers/handleServiceError';
import { LubricantData } from './getLubricants';

const getSingleLubricantService = async (
  brandProductId: string
) => {
  try {
    const response = await axios.get<
      ApiResponse<LubricantData>
    >(API_ROUTES.getLubricant + `/${brandProductId}`);

    return response.data;
  } catch (error) {
    throw handleServiceError(error);
  }
};

export default getSingleLubricantService;
