import { toast } from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import { ROUTES } from 'src/constants/routes';
import addCartService from 'src/network/services/cart/addToCart';

const useAddCart = ({
  shouldNavigate,
}: {
  shouldNavigate?: boolean;
}) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const mutation = useMutation(addCartService, {
    onSuccess: () => {
      toast.dismiss();
      if (shouldNavigate) {
        queryClient.removeQueries('userCart');
        navigate(ROUTES.cart);
      } else {
        queryClient.refetchQueries('userCart');
        toast.success(
          <p>
            Item added to cart.{' '}
            <Link to={ROUTES.cart}>
              <b>View Cart</b>
            </Link>
          </p>,
          {
            id: 'cart',
          }
        );
      }
    },
  });

  const {
    mutate: addToCart,
    isLoading: isAddingToCart,
    data,
  } = mutation;

  return { addToCart, isAddingToCart, data };
};

export default useAddCart;
