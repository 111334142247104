import AccountLayout from 'src/components/layouts/AccountLayout';
import { StyledLink, Wrapper } from '../OrderDetails/style';
import ChevronLeft from 'src/components/atoms/icons/ChevronLeft';
// import OrderStatusCard from 'src/components/molecules/OrderStatusCard';
import { Card } from '../Profile/style';
import { OrderTimeline, TimelineItem } from './style';
import { ROUTES } from 'src/constants/routes';

const TrackOrderPage = () => {
  return (
    <AccountLayout>
      <Wrapper>
        <StyledLink to={ROUTES.orderDetails + '/orderID'}>
          <ChevronLeft />
          Track Order
        </StyledLink>

        {/* <OrderStatusCard /> */}

        <Card>
          <OrderTimeline>
            <TimelineItem data-active>
              <p>Order placed</p>
              <span>Saturday May 20, 2023</span>
            </TimelineItem>

            <TimelineItem data-next>
              <p>Order processing</p>
              <span>Monday May 22, 2023</span>
            </TimelineItem>

            <TimelineItem>
              <p>Order out for delivery</p>
              <span>Estimated Tuesday May 23, 2023</span>
            </TimelineItem>

            <TimelineItem>
              <p>Order delivered</p>
              <span>Estimated Tuesday May 23, 2023</span>
            </TimelineItem>
          </OrderTimeline>
        </Card>
      </Wrapper>
    </AccountLayout>
  );
};

export default TrackOrderPage;
