import React, { useEffect } from 'react';
import CloseIcon from '../icons/CloseIcon';
import {
  ModalCloseButton,
  ModalContent,
  ModalContentWrapper,
  ModalOverlay,
} from './style';

export interface ModalProps
  extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  children,
  ...rest
}) => {
  useEffect(() => {
    const handleEscapeKey = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', handleEscapeKey);
    }

    return () => {
      document.removeEventListener(
        'keydown',
        handleEscapeKey
      );
    };
  }, [isOpen, onClose]);

  if (!isOpen) {
    return null;
  }

  return (
    <ModalOverlay id="modal">
      <ModalContentWrapper {...rest}>
        <ModalCloseButton onClick={onClose} title="close">
          <CloseIcon />
        </ModalCloseButton>
        <ModalContent>{children}</ModalContent>
      </ModalContentWrapper>
    </ModalOverlay>
  );
};

export default Modal;
