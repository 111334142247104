import { ApiResponse } from 'src/network/apiResponse';
import { API_ROUTES } from 'src/network/apiRoutes';
import { axiosInstance } from 'src/network/axios';
import handleServiceError from 'src/utils/helpers/handleServiceError';

interface Payload {
  _id: string;
  businessName: string;
  address: string;
  city: string;
  state: string;
  country: string;
}

interface ResponseData {
  _id: string;
  role: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  isCompleteSignup: boolean;
  businessType: 'vendor' | 'buyer';
  token: string;
  tradeType: string[];
}

const completeSignupService = async (payload: Payload) => {
  try {
    const response = await axiosInstance.post<
      ApiResponse<ResponseData>
    >(API_ROUTES.completeSignUp, payload);
    return response.data;
  } catch (error) {
    throw handleServiceError(error);
  }
};

export default completeSignupService;
