import styled from 'styled-components';

interface Props {
  htmlString: string;
}

const HTMLRenderer: React.FC<Props> = ({ htmlString }) => {
  return (
    <ProseWrapper
      dangerouslySetInnerHTML={{ __html: htmlString }}
    />
  );
};

export default HTMLRenderer;

const ProseWrapper = styled.div`
  font-size: 1rem;
  line-height: 1.6;
  max-width: 85ch;

  h1 {
    font-size: 2.25rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }

  h2 {
    font-size: 1.875rem;
    font-weight: bold;
    margin-bottom: 0.75rem;
  }

  h3 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  h4 {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 0.25rem;
  }

  h5 {
    font-size: 1.125rem;
    font-weight: bold;
    margin-bottom: 0.25rem;
  }

  h6 {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 0.25rem;
  }

  p {
    margin-bottom: 1rem;
  }

  ul,
  ol {
    margin-bottom: 1rem;
    padding-left: 2rem;
    list-style: inside;
  }

  ul li,
  ol li {
    margin-bottom: 0.5rem;
  }

  img {
    width: 100%;
    max-width: 200px;
    margin: 14px auto;
  }
`;
