import { toast } from 'react-hot-toast';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { ROUTES } from 'src/constants/routes';
import createAccountService from 'src/network/services/auth/createAccount';
import { registrationState } from 'src/stores/auth/registrationState';

const useCreateAccount = () => {
  const setRegistrationDetails = useSetRecoilState(
    registrationState
  );
  const navigate = useNavigate();

  const createAccountMutation = useMutation(
    createAccountService,
    {
      onSuccess: (_, vars) => {
        toast.dismiss();
        toast.success('Account creation successful');
        setRegistrationDetails(prev => ({
          ...prev,
          phoneNumber: vars.phoneNumber,
          otpAccess: true,
        }));

        setTimeout(() => {
          navigate(ROUTES.verifyPhone);
        }, 0);
      },
    }
  );

  const {
    mutate: createAccount,
    isLoading: isCreatingAccount,
    data,
  } = createAccountMutation;

  return { createAccount, isCreatingAccount, data };
};

export default useCreateAccount;
