import React, { useState } from 'react';
import {
  TabButton,
  TabContent,
  TabHeader,
  TabsContainer,
} from './style';
import HTMLRenderer from '../HtmlRenderer';

export interface Tab {
  id: string;
  label: string;
  content: React.ReactNode;
}

interface TabsProps {
  tabs: Tab[];
}

const Tabs: React.FC<TabsProps> = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(tabs[0].id);

  const handleTabChange = (tabId: string) => {
    setActiveTab(tabId);
  };

  return (
    <TabsContainer>
      <TabHeader>
        {tabs.map(tab => (
          <TabButton
            key={tab.id}
            data-active={activeTab === tab.id}
            onClick={() => handleTabChange(tab.id)}
          >
            {tab.label}
          </TabButton>
        ))}
      </TabHeader>
      {tabs.map(tab => (
        <TabContent
          key={tab.id}
          data-active={activeTab === tab.id}
        >
          {tab.id === 'description' &&
          typeof tab.content === 'string' ? (
            <HTMLRenderer htmlString={tab.content} />
          ) : (
            tab.content
          )}
        </TabContent>
      ))}
    </TabsContainer>
  );
};

export default Tabs;
