import { ApiResponse } from 'src/network/apiResponse';
import { API_ROUTES } from 'src/network/apiRoutes';
import { axiosInstance } from 'src/network/axios';
import handleServiceError from 'src/utils/helpers/handleServiceError';

interface Payload {
  addressId: string;
}

const deleteAddressService = async (payload: Payload) => {
  try {
    const response = await axiosInstance.delete<
      ApiResponse<string>
    >(API_ROUTES.deleteAddress + `/${payload.addressId}`);
    return response.data;
  } catch (error) {
    throw handleServiceError(error);
  }
};

export default deleteAddressService;
