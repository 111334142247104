import React from 'react';
import {
  BreadcrumbContainer,
  BreadcrumbItemLink,
} from './style';
import ChevronRight from 'src/components/atoms/icons/ChevronRight';

export interface BreadcrumbItem {
  label: string;
  url?: string;
}

interface BreadcrumbProps {
  items: BreadcrumbItem[];
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({
  items,
}) => {
  return (
    <BreadcrumbContainer>
      {items.map((item, index) => (
        <React.Fragment key={index}>
          {item.url ? (
            <BreadcrumbItemLink to={item.url}>
              {item.label}
            </BreadcrumbItemLink>
          ) : (
            <span>{item.label}</span>
          )}
          {index < items.length - 1 && (
            <span>
              <ChevronRight />
            </span>
          )}
        </React.Fragment>
      ))}
    </BreadcrumbContainer>
  );
};

export default Breadcrumb;
