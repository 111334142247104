import { styled } from 'styled-components';

export type FormProps =
  React.FormHTMLAttributes<HTMLFormElement>;

const Form: React.FC<
  React.PropsWithChildren<FormProps>
> = ({ children, ...rest }) => {
  return <StyledForm {...rest}>{children}</StyledForm>;
};

export default Form;

const StyledForm = styled.form`
  width: 100%;
  display: grid;
  gap: 1.5rem;
`;
