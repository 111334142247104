import { ApiResponse } from 'src/network/apiResponse';
import { API_ROUTES } from 'src/network/apiRoutes';
import { axiosInstance } from 'src/network/axios';
import handleServiceError from 'src/utils/helpers/handleServiceError';

interface Data {
  address: string;
  deliveryPrice: number;
}

interface Payload {
  address: string;
}

const getDeliveryFeeService = async (payload: Payload) => {
  try {
    const response = await axiosInstance.post<
      ApiResponse<Data>
    >(API_ROUTES.getDeliveryFee, payload);
    return response.data.data;
  } catch (error) {
    throw handleServiceError(error);
  }
};

export default getDeliveryFeeService;
