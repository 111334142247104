import image3 from 'src/assets/images/gas-truck.jpg';
import image4 from 'src/assets/images/high-speed-train.jpg';
import image5 from 'src/assets/images/oil-containers.jpg';
import image2 from 'src/assets/images/oil-plant.jpg';
import image1 from 'src/assets/images/trucks.jpg';
import Button from 'src/components/atoms/Button';
import { VENDOR_APP_REG_URL } from 'src/constants/externalLinks';
import { LandingSliderProps } from 'src/utils/swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  HeroButtons,
  HeroImage,
  HeroSectionOverlay,
  HeroText,
  StyledHeroSection,
} from './style';

const HeroSection = () => {
  return (
    <StyledHeroSection as="section">
      <Swiper {...LandingSliderProps}>
        <SwiperSlide>
          <HeroImage src={image1} alt="trucks" />
        </SwiperSlide>
        <SwiperSlide>
          <HeroImage src={image2} alt="oil-plant" />
        </SwiperSlide>
        <SwiperSlide>
          <HeroImage src={image3} alt="gas-truck" />
        </SwiperSlide>
        <SwiperSlide>
          <HeroImage src={image4} alt="high-speed-train" />
        </SwiperSlide>
        <SwiperSlide>
          <HeroImage src={image5} alt="oil-containers" />
        </SwiperSlide>
      </Swiper>

      <HeroSectionOverlay>
        <HeroText>
          <h1>
            The B2B Marketplace for
            <br />
            Oil and Gas Products
          </h1>
          <p>
            Find reliable vendors, source a variety of oil
            products (AGO, PMS, LPG, Lubricants), and manage
            logistics all in one place.
          </p>
        </HeroText>

        <HeroButtons>
          <Button asLink href="/register">
            Sign up as a buyer
          </Button>
          <Button
            asLink
            target="_blank"
            href={VENDOR_APP_REG_URL}
            variant="secondary"
          >
            Become a Vendor
          </Button>
        </HeroButtons>
      </HeroSectionOverlay>
    </StyledHeroSection>
  );
};

export default HeroSection;
