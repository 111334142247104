import { Link } from 'react-router-dom';
import { Divider } from 'src/components/atoms/Divider';
import Heading from 'src/components/atoms/Heading';
import Text from 'src/components/atoms/Text';
import AuthLayout from 'src/components/layouts/AuthLayout';
import RegisterForm from 'src/components/molecules/RegisterForm';
import { ROUTES } from 'src/constants/routes';
import { styled } from 'styled-components';

const RegisterPage = () => {
  return (
    <AuthLayout>
      <Heading as="h1" level={4}>
        Create an account
      </Heading>
      <Text shade={600}>
        Already have an account ?{' '}
        <LoginLink to={ROUTES.login}>Log In</LoginLink>
      </Text>

      <Divider margin="2rem 0" />

      <RegisterForm />
    </AuthLayout>
  );
};

export default RegisterPage;

const LoginLink = styled(Link)`
  font-weight: 700;
  color: ${p => p.theme.colors.primary[500]};
`;
