import { ApiResponse } from 'src/network/apiResponse';
import { API_ROUTES } from 'src/network/apiRoutes';
import { axiosInstance } from 'src/network/axios';
import handleServiceError from 'src/utils/helpers/handleServiceError';

interface Payload {
  address: string;
  paymentMethod?: 'payOnDelivery' | 'payNow';
  redirectUrl?: string;
  state: string;
  city: string;
}

interface Response {
  paymentLink: string;
}

const createOrderService = async (payload: Payload) => {
  const {
    address,
    paymentMethod = 'payOnDelivery',
    redirectUrl,
    state,
    city,
  } = payload;

  try {
    const response = await axiosInstance.post<
      ApiResponse<Response>
    >(API_ROUTES.createOrder, {
      address,
      paymentMethod,
      redirectUrl,
      state,
      city,
    });
    return response.data;
  } catch (error) {
    throw handleServiceError(error);
  }
};

export default createOrderService;
