import { useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import XIcon from 'src/components/atoms/icons/XIcon';
import { ROUTES } from 'src/constants/routes';
import { navbarState } from 'src/stores/menus/navbarState';
import useClickOutside from 'src/utils/hooks/useClickOutside';
import { AllProductsMenu } from './AllProducts';
import { ATFMenu } from './ATF';
import { EngineOilMenu } from './EngineOil';
import {
  NavbarContainer,
  NavbarDropdownButton,
  NavbarHeader,
  NavBarIcon,
  NavBarLink,
  NavbarLinkWrapper,
  StyledNav,
  StyledNavBarItem,
  SubMenu,
  SubMenuWrapper,
} from './style';

const NavBar = () => {
  const navRef = useRef<HTMLElement>(null);
  const [isNavbarOpen, setNavbarOpen] =
    useRecoilState(navbarState);

  const closeNav = () => setNavbarOpen(false);
  useClickOutside(navRef, closeNav);

  return (
    <StyledNav data-open={isNavbarOpen} ref={navRef}>
      <NavbarHeader>
        <h2>Categories</h2>
        <button onClick={() => setNavbarOpen(false)}>
          <XIcon />
        </button>
      </NavbarHeader>

      <NavbarContainer>
        {/* <StyledNavBarItem>
          <NavbarLinkWrapper>
            <NavBarLink to="/" onClick={closeNav}>
              Sale
            </NavBarLink>
          </NavbarLinkWrapper>
        </StyledNavBarItem> */}

        <NavbarItem
          link={
            <NavBarLink
              to={ROUTES.products}
              onClick={closeNav}
            >
              All Products
            </NavBarLink>
          }
          subMenu={<AllProductsMenu closeNav={closeNav} />}
        />

        <NavbarItem
          link={
            <NavBarLink
              to={ROUTES.products + '?oiltype=Engine+Oil'}
              onClick={closeNav}
            >
              Engine Oil
            </NavBarLink>
          }
          subMenu={<EngineOilMenu closeNav={closeNav} />}
        />

        <NavbarItem
          link={
            <NavBarLink
              to={
                ROUTES.products +
                '?oiltype=Automotive+Transmission+Fluid'
              }
              onClick={closeNav}
            >
              ATF
            </NavBarLink>
          }
          subMenu={<ATFMenu closeNav={closeNav} />}
          subMenuAlign="right"
        />
      </NavbarContainer>
    </StyledNav>
  );
};

export default NavBar;

interface NavbarItemProps {
  link: React.ReactNode;
  subMenu: React.ReactNode;
  subMenuAlign?: 'left' | 'right' | 'default';
}

const NavbarItem: React.FC<NavbarItemProps> = ({
  link,
  subMenu,
  subMenuAlign = 'default',
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleIsOpen = () => {
    setIsOpen(prev => !prev);
  };

  return (
    <StyledNavBarItem data-align={subMenuAlign}>
      <NavbarLinkWrapper>
        {link}

        <NavbarDropdownButton
          onClick={toggleIsOpen}
          title="toggle menu"
          $isOpen={isOpen}
        >
          <NavBarIcon />
        </NavbarDropdownButton>
      </NavbarLinkWrapper>

      <SubMenuWrapper $isOpen={isOpen}>
        <SubMenu $isOpen={isOpen}>{subMenu}</SubMenu>
      </SubMenuWrapper>
    </StyledNavBarItem>
  );
};
