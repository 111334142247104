import React, { useEffect, useMemo } from 'react';
import { toast } from 'react-hot-toast';
import Skeleton from 'react-loading-skeleton';
import { useRecoilValue } from 'recoil';
import Button from 'src/components/atoms/Button';
import { Divider } from 'src/components/atoms/Divider';
import Heading from 'src/components/atoms/Heading';
import Input from 'src/components/atoms/Input';
import { ROUTES } from 'src/constants/routes';
import useCreateOrder from 'src/network/react-query/mutation/orders/createOrder';
import useDeliveryFee from 'src/network/react-query/mutation/orders/deliveryFee';
import usePayOnline from 'src/network/react-query/mutation/orders/payOnline';
import useGetAddress from 'src/network/react-query/query/address/getAddress';
import useGetCart from 'src/network/react-query/query/cart/getCart';
import { PAYMENT_TYPE } from 'src/pages/Checkout';
import { checkoutStore } from 'src/stores/checkout/checkoutState';
import { formatPrice } from 'src/utils/helpers/formatPrice';
import { getDefaultAddress } from 'src/utils/helpers/getDefaultAddress';
import useCurrentUser from 'src/utils/hooks/useCurrentUser';
import OrderSummarySkeleton from './Skeleton';
import { OrderInfo, Summary } from './style';

interface OrderSummaryProps {
  disabled?: boolean;
  paymentType?: PAYMENT_TYPE;
}

const DEFAULT_DELIVERY_FEE = 400;

const OrderSummary: React.FC<OrderSummaryProps> = ({
  disabled = false,
  paymentType = PAYMENT_TYPE.online,
}) => {
  const { user } = useCurrentUser();
  const { data: cartData, isLoading: isGettingCart } =
    useGetCart();
  const { createOrder, isCreatingOrder } = useCreateOrder();
  const { mutate: payOnline, isLoading: isPayingOnline } =
    usePayOnline();
  const { data: addressData, isLoading: isGettingAddress } =
    useGetAddress();
  const {
    data: deliveryFeeData,
    getDeliveryFee,
    isGettingDeliveryFee,
  } = useDeliveryFee();

  const checkoutState = useRecoilValue(checkoutStore);

  const addressLocation = useMemo(() => {
    if (addressData && addressData.length) {
      const defaultAddress = getDefaultAddress(addressData);

      if (!defaultAddress) return '';

      if (checkoutState.hasChangedAddress) {
        return checkoutState.address.address;
      } else {
        return defaultAddress;
      }
    }

    return '';
  }, [addressData, checkoutState]);

  useEffect(() => {
    if (addressLocation) {
      getDeliveryFee({ address: addressLocation });
    }
  }, [addressLocation, getDeliveryFee]);

  if (!user) return null;

  if (isGettingCart || isGettingAddress)
    return <OrderSummarySkeleton />;

  if (
    cartData &&
    cartData.items.length &&
    addressData &&
    addressData.length
  ) {
    const { address, city, state } =
      checkoutState.hasChangedAddress
        ? checkoutState?.address
        : addressData?.find(
            address => address?.isDefault
          ) || addressData[0];

    const handleCreateOrder = () => {
      const redirectUrl =
        window.location.origin + ROUTES.history;

      if (paymentType === PAYMENT_TYPE.online) {
        toast.loading('Creating order', { id: 'order' });
        payOnline({
          address,
          paymentMethod: 'payNow',
          redirectUrl,
          state,
          city,
        });
        return;
      }

      toast.loading('Creating order', { id: 'order' });

      createOrder({
        address,
        redirectUrl,
        state,
        city,
      });
    };

    const totalQuantity = cartData.items.reduce(
      (accumulator, currentValue) => {
        return accumulator + currentValue.orderQuantity;
      },
      0
    );

    const deliveryFee =
      deliveryFeeData?.deliveryPrice ||
      DEFAULT_DELIVERY_FEE * totalQuantity;

    return (
      <Summary>
        <Heading as="h2" level={6}>
          Order Summary
        </Heading>

        <Divider margin="20px 0" />

        <Input
          label="Enter Promo Code"
          placeholder="Promo Code"
          bg="gray"
        />

        <OrderInfo>
          <li>
            <h4>Subtotal:</h4>
            <p>{formatPrice(cartData.totalPrice)}</p>
          </li>
          <li>
            <h4>Discount:</h4>
            <p>-{formatPrice(0)}</p>
          </li>
          <li>
            <h4>Delivery:</h4>
            {isGettingDeliveryFee ? (
              <Skeleton width={100} />
            ) : (
              <p>{formatPrice(deliveryFee)}</p>
            )}
          </li>
          <li>
            <Divider />
          </li>
          <li data-total>
            <h4>Total:</h4>
            {isGettingDeliveryFee ? (
              <Skeleton width={100} />
            ) : (
              <p>
                {formatPrice(
                  cartData.totalPrice + deliveryFee
                )}
              </p>
            )}
          </li>
        </OrderInfo>

        <Button
          fluid
          loading={isCreatingOrder}
          disabled={
            disabled || isCreatingOrder || isPayingOnline
          }
          onClick={handleCreateOrder}
        >
          {paymentType === PAYMENT_TYPE.online
            ? 'Proceed to payment'
            : 'Confirm Order'}
        </Button>
      </Summary>
    );
  }

  return null;
};

export default OrderSummary;
