import { atom } from 'recoil';

interface Props {
  userId: string;
  phoneNumber: string;
  otp: string;
  passwordOtpAccess: boolean;
  newPasswordAccess: boolean;
}

export const resetPasswordState = atom<Props>({
  key: 'resetPasswordState',
  default: {
    userId: '',
    phoneNumber: '',
    otp: '',
    passwordOtpAccess: false,
    newPasswordAccess: false,
  },
});
